<div
  capraTooltip
  [tooltipPlacement]="tooltipPlacement"
  [tooltipContent]="tooltipHtmlContent"
  tooltipContentIsHtml="true"
  tooltipSize="regular"
  [tooltipButtonSelectors]="tooltipButtonSelectors"
  [tooltipIsDisabled]="!displayStateIcon || !tooltipHtmlContent"
  (tooltipButtonClick)="tooltipButtonClick.emit($event)"
  test-data="tool-tip-wrapper"
>
  <capra-icon [icon]="mainIcon" test-data="main-icon"></capra-icon>
  <capra-icon
    *ngIf="displayStateIcon"
    [icon]="stateIcon"
    class="state-icon-stroked"
    test-data="state-icon-stroked"
  ></capra-icon>
  <div *ngIf="displayStateIcon && renderFiller" class="state-icon-center-filler" test-data="state-icon-filler"></div>
  <capra-icon *ngIf="displayStateIcon" [icon]="stateIcon" class="state-icon" test-data="state-icon"></capra-icon>
</div>
