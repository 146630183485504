import { inject, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, of, shareReplay } from 'rxjs';

export const getThoughtspotIntegrated = (document: Document) => {
  const meta: HTMLMetaElement | null = document.querySelector(`meta[name=thoughtspot-integrated]`);
  let value: string | null = null;

  if (meta) {
    value = meta.content;
  }

  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return null;
};

@Injectable({
  providedIn: 'root',
})
export class ThoughtspotIntegrationService {
  public currOrgIntegrated$: Observable<boolean>;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.currOrgIntegrated$ = of(this.currOrgIntegrated()).pipe(shareReplay(1));
  }

  currOrgIntegrated(): boolean {
    return !!getThoughtspotIntegrated(this.document);
  }

  static currOrgIntegrated() {
    return !!getThoughtspotIntegrated(window.document);
  }
}
