import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  BreadcrumbsComponent as CapraBreadcrumbsComponent,
  Breadcrumb,
  BreadcrumbClickedEvent as CapraBreadcrumbClickedEvent,
} from '@mode/capra';
import { BreadcrumbTypes, DisplayMode } from '@mode/shared/contract-common';

export interface BreadcrumbClickedEvent extends CapraBreadcrumbClickedEvent {
  breadcrumbType: BreadcrumbTypes.BreadcrumbType;
}

@Component({
  standalone: true,
  selector: 'mode-breadcrumbs',
  imports: [CapraBreadcrumbsComponent, CommonModule],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnChanges {
  @Input() breadcrumbs: BreadcrumbTypes.Breadcrumb[] = [];
  @Input() displayMode: DisplayMode = DisplayMode.Menu;
  @Output() breadcrumbClicked = new EventEmitter<BreadcrumbClickedEvent>();

  public readonly DisplayMode = DisplayMode;
  public breadcrumbItems: Breadcrumb[] = [];
  private _breadcrumbTypesRecord = this._createBreadcrumbTypesRecord();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['breadcrumbs'] || changes['displayMode']) {
      const breadcrumbs = this.breadcrumbs?.filter(({ name }) => !!name) ?? [];

      this.breadcrumbItems = breadcrumbs.map(({ name, href, icon, tooltipContent, badge }) => ({
        name: name as string,
        target: this.displayMode === DisplayMode.Menu ? '_self' : undefined,
        href,
        icon,
        tooltipContent,
        cssClass: icon === 'icon-dataset' ? 'breadcrumb-purple-icon' : '',
        badge,
      }));

      this._breadcrumbTypesRecord = this._createBreadcrumbTypesRecord(breadcrumbs);
    }
  }

  public onClick({ $event, name }: CapraBreadcrumbClickedEvent): void {
    this.breadcrumbClicked.emit({
      $event,
      name,
      breadcrumbType: this._breadcrumbTypesRecord[name],
    });
  }

  private _createBreadcrumbTypesRecord(
    breadcrumbs: BreadcrumbTypes.Breadcrumb[] = []
  ): Record<string, BreadcrumbTypes.BreadcrumbType> {
    const record: Record<string, BreadcrumbTypes.BreadcrumbType> = {};

    for (const { name, type } of breadcrumbs) {
      if (name) {
        record[name] = type;
      }
    }

    return record;
  }
}
