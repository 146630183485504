import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import * as AiAssistActions from './ai-assist.actions';
import { QueryViewerFeedback } from '@mode/queries/ai-assist/util';

export const AI_ASSIST_FEATURE_KEY = 'aiAssist';

export interface AiAssistEntityState {
  queryToken: string;
  generatedSQL?: string | null;
  requestId?: string | null;
  errorMsg?: string | null;
  loading?: boolean;
  rating?: QueryViewerFeedback | null;
}

export interface AiAssistState {
  aiAssistEntities: EntityState<AiAssistEntityState>;
}

export const aiAssistAdapter: EntityAdapter<AiAssistEntityState> = createEntityAdapter<AiAssistEntityState>({
  selectId: (state) => state.queryToken,
});

export const initialAiAssistState: AiAssistState = {
  aiAssistEntities: aiAssistAdapter.getInitialState(),
};

const aiAssistEntitiesReducer = createReducer(
  initialAiAssistState.aiAssistEntities,
  on(AiAssistActions.generateSQL, (state, { queryToken }) =>
    aiAssistAdapter.upsertOne(
      { queryToken, loading: true, generatedSQL: null, requestId: null, errorMsg: null, rating: null },
      state
    )
  ),
  on(AiAssistActions.generateSQLSuccess, (state, { generatedSQL, queryToken, requestId }) =>
    aiAssistAdapter.upsertOne(
      {
        queryToken,
        generatedSQL,
        requestId,
        loading: false,
        errorMsg: null,
      },
      state
    )
  ),
  on(AiAssistActions.generateSQLFailure, (state, { errorMsg, queryToken, requestId }) =>
    aiAssistAdapter.upsertOne(
      {
        queryToken,
        requestId,
        loading: false,
        generatedSQL: null,
        errorMsg,
      },
      state
    )
  ),
  on(AiAssistActions.submitPositiveFeedback, (state, { queryToken }) =>
    aiAssistAdapter.upsertOne(
      {
        queryToken,
        rating: QueryViewerFeedback.Positive,
      },
      state
    )
  ),
  on(AiAssistActions.submitPositiveFeedbackFailure, (state, { queryToken }) =>
    aiAssistAdapter.upsertOne(
      {
        queryToken,
        rating: null,
      },
      state
    )
  ),
  on(AiAssistActions.submitNegativeFeedback, (state, { queryToken }) =>
    aiAssistAdapter.upsertOne(
      {
        queryToken,
        rating: QueryViewerFeedback.Negative,
      },
      state
    )
  ),
  on(AiAssistActions.submitNegativeFeedbackFailure, (state, { queryToken }) =>
    aiAssistAdapter.upsertOne(
      {
        queryToken,
        rating: null,
      },
      state
    )
  ),
  on(AiAssistActions.discardGeneration, (state, { queryToken }) =>
    aiAssistAdapter.upsertOne(
      {
        queryToken,
        generatedSQL: null,
        rating: null,
      },
      state
    )
  )
);

export const { selectAll, selectEntities, selectIds, selectTotal } = aiAssistAdapter.getSelectors();

export const aiAssistReducer: ActionReducerMap<AiAssistState> = {
  aiAssistEntities: aiAssistEntitiesReducer,
};
