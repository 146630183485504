<ul class="breadcrumb-list">
  <li *ngFor="let breadcrumb of breadcrumbItems; index as $index; trackBy: trackByFn"
    class="breadcrumb-item {{ breadcrumb.cssClass ?? '' }}"
    [class.breadcrumb-item-highlighted]="breadcrumb.highlighted || $index === breadcrumbItems.length - 1"
    data-test="capra-breadcrumbs-item"
  >
    <a class="breadcrumb-link"
      [class.breadcrumb-link-has-href]="!!breadcrumb.href"
      [class.breadcrumb-link-is-dropdown-link]="displayMode === 'dropdown'"
      rel="noopener noreferrer"
      [attr.href]="breadcrumb.href ?? null"
      [attr.target]="breadcrumb.target ?? null"
      data-test="capra-breadcrumbs-link"
      (click)="breadcrumbClicked.emit({ $event: $event, name: breadcrumb.name })"
      capraTooltip
      [tooltipPlacement]="tooltipPlacement"
      [tooltipSize]="tooltipSize"
      [tooltipIsDisabled]="!breadcrumb.tooltipContent"
      [tooltipContent]="breadcrumb.tooltipContent ?? ''"
      [tooltipContentIsHtml]="true"
    >
      <capra-icon *ngIf="breadcrumb.icon"
        class="breadcrumb-icon"
        [icon]="breadcrumb.icon"
        data-test="capra-breadcrumbs-item-icon"
      ></capra-icon>
      <capra-badge
        *ngIf="breadcrumb.badge"
        class="breadcrumb-badge"
        [text]="breadcrumb.badge"
        data-test="breadcrumb-badge"
        [type]="badgeType"
      ></capra-badge>
      <span class="breadcrumb-text"
        data-test="capra-breadcrumbs-item-text"
      >
        {{ breadcrumb.name }}
      </span>
      <capra-icon *ngIf="displayMode === 'dropdown'"
        class="dropdown-caret"
        icon="icon-caret-down"
        data-test="capra-breadcrumbs-dropdown-caret"
      ></capra-icon>
      <capra-icon *ngIf="$index < breadcrumbItems.length - 1"
        class="breadcrumb-separator"
        icon="icon-caret-right"
        data-test="capra-breadcrumbs-separator"
      ></capra-icon>
    </a>
  </li>
</ul>
