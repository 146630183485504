import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { NgModule } from '@angular/core';
import { MAT_LEGACY_CHIPS_DEFAULT_OPTIONS } from '@angular/material/legacy-chips';
import { MAT_LEGACY_SELECT_CONFIG } from '@angular/material/legacy-select';

import { ConfirmDialog } from '@mode/shared/contract-common';
import { ConfirmDialogService, DatePickerComponent, SelectWithSearchComponent } from '..';
import { AnimatedRefreshIconComponent } from './animated-refresh-icon/animated-refresh-icon.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CalendlyPopupButtonComponent } from './calendly-popup-button/calendly-popup-button.component';
import { ClipboardDirective } from './clipboard/clipboard.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DialogLoadingViewComponent } from './dialog-loading-view/dialog-loading-view.component';
import { DialogService } from './dialog/dialog.service';
import { DialogHeaderComponent } from './dialog/header/dialog-header.component';
import { EmptyComponent } from './empty/empty.component';
import { FlatTableNgComponent } from './flat-table-ng/flat-table-ng.component';
import { LabeledMenuComponent } from './labeled-menu/labeled-menu.component';
import { NewContainerMenuComponent } from './new-container-menu/new-container-menu.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ReportTypeIconComponent } from './report-type-icon/report-type-icon.component';
import { SimpleErrorComponent } from './simple-error/simple-error.component';
import { SortSettingsDialogComponent } from './sort-settings-dialog/sort-settings-dialog.component';
import { SortSettingsModalDialogService } from './sort-settings-dialog/sort-settings-dialog.service';
import { SqlNoResultsMessageComponent } from './sql-no-results-message/sql-no-results-message.component';
import { StateIndicatorIconComponent } from './state-indicator-icon/state-indicator-icon.component';
import { TableLoadingComponent } from './table-loading/table-loading.component';
import { TimeAgoComponent } from './time-ago/time-ago.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { WorkspaceAvatarComponent } from './workspace-avatar/workspace-avatar.component';

@NgModule({
  imports: [
    AnimatedRefreshIconComponent,
    BreadcrumbsComponent,
    CalendlyPopupButtonComponent,
    ClipboardDirective,
    ConfirmDialogComponent,
    DatePickerComponent,
    DialogHeaderComponent,
    DialogLoadingViewComponent,
    EmptyComponent,
    FlatTableNgComponent,
    NewContainerMenuComponent,
    PaginatorComponent,
    ReportTypeIconComponent,
    SelectWithSearchComponent,
    SimpleErrorComponent,
    SqlNoResultsMessageComponent,
    StateIndicatorIconComponent,
    TableLoadingComponent,
    TimeAgoComponent,
    ToggleSwitchComponent,
    WorkspaceAvatarComponent,
    SortSettingsDialogComponent,
    LabeledMenuComponent,
  ],
  exports: [
    AnimatedRefreshIconComponent,
    BreadcrumbsComponent,
    CalendlyPopupButtonComponent,
    ClipboardDirective,
    ConfirmDialogComponent,
    DatePickerComponent,
    DialogHeaderComponent,
    DialogLoadingViewComponent,
    EmptyComponent,
    FlatTableNgComponent,
    NewContainerMenuComponent,
    PaginatorComponent,
    ReportTypeIconComponent,
    SimpleErrorComponent,
    SqlNoResultsMessageComponent,
    StateIndicatorIconComponent,
    TableLoadingComponent,
    TimeAgoComponent,
    ToggleSwitchComponent,
    WorkspaceAvatarComponent,
    SelectWithSearchComponent,
    SortSettingsDialogComponent,
    LabeledMenuComponent,
  ],
  providers: [
    ConfirmDialogService,
    DialogService,
    SortSettingsModalDialogService,
    {
      provide: MAT_LEGACY_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [COMMA, ENTER, SPACE],
      },
    },
    [{ provide: MAT_LEGACY_SELECT_CONFIG, useValue: { disableOptionCentering: true } }],
    [{ provide: Window, useValue: window }],
    [{ provide: ConfirmDialog, useExisting: ConfirmDialogService }],
  ],
})
export class SharedUiModule {}
