import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { IconComponent } from '../icon/icon.component';

export enum BadgeType {
  Green = 'green',
  Blue = 'blue',
  Purple = 'purple',
  DarkPurple = 'dark-purple',
  Pink = 'pink',
  Orange = 'orange',
  Yellow = 'yellow',
  DarkYellow = 'dark-yellow',
  Tan = 'tan',
  Gray = 'gray',
  DarkGray = 'dark-gray',
}

/**
 * The badge component handles the implementation of all of our contextual badge
 * types. Parameters are avaialable to configure presentation.
 */
@Component({
  standalone: true,
  selector: 'capra-badge',
  imports: [CommonModule, IconComponent],
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  /** The badge text */
  @Input() text = '';

  /** The badge type determines color and style */
  @Input() type = BadgeType.Blue;

  /** An optional icon to insert to the left of the text.
   * See Icon Component for more information.
   */
  @Input() icon: string | null = null;

  readonly BadgeType = BadgeType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly rebrand = window['MODE_FLAGS' as any]?.['temp-rebrand-milestone-3-2024.07' as any]; // Rebrand Milestone 3
}
