import { ScaleLinear } from 'd3-scale';
import { DataType } from '@mode-switch/express';
import { SimpleLegend } from './simple-legend';

export type DatumType = string | number;

export type LegendDomain = DatumType[];

export enum FieldType {
  Dimension = 'dimension',
  Measure = 'measure',
}

export interface LegendInfo {
  id: string;
  fieldInfo: LegendFieldInfo;
  legendInst: SimpleLegend;
  width: number;
  height: number;
}

export interface LegendFieldInfo {
  name: string;
  color: string;
  alias: string;
  type: FieldType;
  dataType: DataType;
}

export interface LegendMeasurements {
  width: number;
  height: number;
  minWidth: number;
  minHeight: number;
}

export type LegendData = { color: string; value: string; rawValue: DatumType }[];

export type ScaleType = ScaleLinear<any, any>;
