import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const DATA_INDEX_AUTH_FACADE = new InjectionToken<AuthFacade>('AuthFacade');
export const AI_GATEWAY_AUTH_FACADE = new InjectionToken<AuthFacade>('AuthFacade');

export interface Token {
  subject: string;
  jwt: string;
}

export interface AuthService {
  getAuthToken(): Observable<Token>;
}

export enum AuthorizedServices {
  DataIndex = 'dataindex',
  AiGateway = 'aigateway',
}

export interface AuthFacade {
  authToken$: Observable<Token>;

  authorizationExpired(): void;
  requestAuthorization(): void;
  requestAuthorizationForDefinitionEditor(): void;
}
