import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ErrorReporter } from '@mode/shared/contract-common';
import { format } from 'date-fns';
import { Observable, combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { BrowserEventsService } from './browser-events.service';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';

export enum BrowserStorageKeyName {
  TabId = 'TAB_ID',
  TabIdList = 'TAB_ID_LIST',
  LastActiveTabId = 'LAST_ACTIVE_TAB_ID',
  SessionIsActive = 'SESSION_IS_ACTIVE',
}

const NOW = new Date();

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  public lastActiveTabId$!: Observable<string | null | undefined>;
  public tabIdList$!: Observable<string[]>;

  public tabIsActive$: Observable<boolean> = combineLatest([
    this.sessionStorageService.observeItem(BrowserStorageKeyName.TabId),
    this.localStorageService.observeItem(BrowserStorageKeyName.LastActiveTabId),
  ]).pipe(map(([tabId, lastActiveTabId]): boolean => tabId === lastActiveTabId));

  constructor(
    private _window: Window,
    @Inject(DOCUMENT) private _document: Document,
    private errorReporter: ErrorReporter,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private browserEvents: BrowserEventsService
  ) {}

  public observeLastActiveTabId(): Observable<string | null | undefined> {
    const lastActiveTabId = this.getLastActiveTabId();
    const tabId = this.getTabId();

    if (!this.tabIdList$) {
      throw new Error('tabIdList$ must be initialized beforehand');
    }

    // If {@link BrowserStorageKeyName.LastActiveTabId} is available (in localStorage) return
    // it, otherwise return this {@link BrowserStorageKeyName.TabId} initially or whenever
    // this tab is selected in the browser
    this.lastActiveTabId$ = merge(
      this.localStorageService.observeItem(BrowserStorageKeyName.LastActiveTabId),
      // set current tab id as last active tab when tab becomes visible
      this.browserEvents.visibilityChangeEvent$.pipe(
        // ignore if tab is hidden
        filter(() => !this._document.hidden),
        // return tab id if tab is not hidden
        map((): string => this.getTabId())
      ),

      // when this tab is closed get last tab id from the list to use as last active tab id
      this.browserEvents.beforeUnloadEvent$.pipe(
        // only proceed if this tab is the last active tab
        filter(() => this.getTabId() === this.getLastActiveTabId()),
        // grab tablist without this tab's id
        withLatestFrom(this.tabIdList$, (event, tabIdList) => tabIdList.filter((tabId) => tabId !== this.getTabId())),
        // retrieve most recent tab id (before the tab is closed)
        map((tabIdList: string[]) => tabIdList.pop())
      )
    ).pipe(startWith<any>(this._document.hidden && lastActiveTabId ? lastActiveTabId : tabId), distinctUntilChanged());

    return this.lastActiveTabId$;
  }

  public observeTabIdList(): Observable<string[]> {
    const tabId = this.getTabId();
    const tabIdListWithTabId = [tabId];
    const isNotTabId = (id: string) => id !== this.getTabId();

    this.tabIdList$ = merge(
      // whenever tab id list is changed ensure current tab id is in list
      this.localStorageService.observeItem<string[]>(BrowserStorageKeyName.TabIdList).pipe(
        // close observable when tab is closed
        takeUntil(this.browserEvents.beforeUnloadEvent$),
        // ignore if tabIdList doesnt exist
        filter((tabIdList: any) => tabIdList && Number.isFinite(tabIdList.length)),
        // ignore if tabIdList contains current tab id
        filter((tabIdList: any) => !tabIdList.includes(tabId)),
        // add tab id to list
        map((tabIdList: any): string[] => tabIdList.concat(tabId))
      ),

      // when the tab is closed, lock the tab  id list and remove current tab id
      this.browserEvents.beforeUnloadEvent$.pipe(
        // omit tab id before closing tab
        map(() => this.getTabIdList().filter(isNotTabId))
      )
    ).pipe(startWith(tabIdListWithTabId), distinctUntilChanged());

    return this.tabIdList$;
  }

  private getLastActiveTabId(): string | null {
    const lastActiveTabId = this.localStorageService.getObject<string>(BrowserStorageKeyName.LastActiveTabId);

    if (typeof lastActiveTabId === 'string' && lastActiveTabId !== 'undefined') {
      return lastActiveTabId;
    }

    return null;
  }

  public getTabId(): string {
    let tabId = this.sessionStorageService.getObject<string>(BrowserStorageKeyName.TabId);

    if (!tabId) {
      tabId = this.generateTabId();
      this.sessionStorageService.setStringifiedItem(BrowserStorageKeyName.TabId, tabId);
    }

    return String(tabId);
  }

  /**
   * Generate randomized id token
   * @example generateTabId() //-> "xxxxxxxxxxxxx-sssssssss"
   * @private
   */
  private generateTabId(): string {
    return `${format(NOW, 'T')}-${Math.random().toString(36).substring(2, 11)}`;
  }

  public getTabIdList(): string[] {
    let tabIdList = this.localStorageService.getObject<string[]>(BrowserStorageKeyName.TabIdList);

    if (Array.isArray(tabIdList)) {
      return tabIdList;
    } else {
      return [];
    }
  }

  public watchTabs(): void {
    try {
      this.observeTabIdList().subscribe((tabIdList: string[]) =>
        this.localStorageService.setStringifiedItem(BrowserStorageKeyName.TabIdList, tabIdList)
      );
      this.observeLastActiveTabId().subscribe((tabId: string | null | undefined) =>
        this.localStorageService.setStringifiedItem(BrowserStorageKeyName.LastActiveTabId, tabId)
      );
    } catch (e: any) {
      this.errorReporter.notify({ error: e });
      console.error(e);
    }
  }
}
