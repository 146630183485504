import { ChartTypes } from '@mode/shared/contract-common';

const CSS: { [key in ChartTypes.FlatTableColorSchemes]?: string } = {
  [ChartTypes.FlatTableColorSchemes.None]: 'color-scheme-none',
  [ChartTypes.FlatTableColorSchemes.DeprecatedUseCoolGrayInstead]: 'color-scheme-cool-gray',
  [ChartTypes.FlatTableColorSchemes.CoolGray]: 'color-scheme-cool-gray',
  [ChartTypes.FlatTableColorSchemes.ModeGray]: 'color-scheme-mode-gray',
  [ChartTypes.FlatTableColorSchemes.ModeGreen]: 'color-scheme-mode-green',
  [ChartTypes.FlatTableColorSchemes.Purple]: 'color-scheme-purple',
  [ChartTypes.FlatTableColorSchemes.Green]: 'color-scheme-green',
  [ChartTypes.FlatTableColorSchemes.Orange]: 'color-scheme-orange',
  [ChartTypes.FlatTableColorSchemes.Pink]: 'color-scheme-pink',
  [ChartTypes.FlatTableColorSchemes.Blue]: 'color-scheme-blue',
};

export function getCssClass(colorScheme: ChartTypes.FlatTableColorSchemes) {
  const lookup = CSS;
  if (colorScheme in lookup) {
    return lookup[colorScheme];
  }
  return lookup[getDefault()];
}

const OPTIONS = [
  { name: 'None', value: ChartTypes.FlatTableColorSchemes.None },
  { name: 'Mode Gray', value: ChartTypes.FlatTableColorSchemes.ModeGray },
  { name: 'Mode Green', value: ChartTypes.FlatTableColorSchemes.ModeGreen },
  { name: 'Cool Gray', value: ChartTypes.FlatTableColorSchemes.CoolGray },
  { name: 'Purple', value: ChartTypes.FlatTableColorSchemes.Purple },
  { name: 'Green', value: ChartTypes.FlatTableColorSchemes.Green },
  { name: 'Orange', value: ChartTypes.FlatTableColorSchemes.Orange },
  { name: 'Pink', value: ChartTypes.FlatTableColorSchemes.Pink },
  { name: 'Blue', value: ChartTypes.FlatTableColorSchemes.Blue },
];

/**
 * Create an array of objects for colorSchemes for use with `ng-options`.
 */
export function getSchemes() {
  return OPTIONS;
}

export function getDefault(): ChartTypes.FlatTableColorSchemes {
  return ChartTypes.FlatTableColorSchemes.ModeGray;
}

// Should be removed with https://github.com/mode/muze-issues/issues/946
// The database is contains 'default' in most instances,
// but we want to use `cool-gray` moving forward.
export function migrateRebrand2022(colorScheme: ChartTypes.FlatTableColorSchemes): ChartTypes.FlatTableColorSchemes {
  if (colorScheme == ChartTypes.FlatTableColorSchemes.DeprecatedUseCoolGrayInstead) {
    return ChartTypes.FlatTableColorSchemes.CoolGray;
  }
  return colorScheme;
}
