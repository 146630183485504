import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { IconComponent } from '@mode/capra';

@Component({
  standalone: true,
  selector: 'mode-dialog-loading-view',
  imports: [CommonModule, IconComponent],
  templateUrl: './dialog-loading-view.component.html',
  styleUrls: ['./dialog-loading-view.component.scss'],
})
export class DialogLoadingViewComponent {
  @Input() title = 'Loading\u2026';
  @Input() titleEllipsis = false;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2564
  @Input() subtitle: string;
}
