import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconComponent } from '@mode/capra';

@Component({
  standalone: true,
  selector: 'mode-simple-error',
  imports: [CommonModule, IconComponent],
  styleUrls: ['./simple-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <div *ngIf="capraIcon" class="simple-error-icon-container">
        <capra-icon [icon]="capraIcon"></capra-icon>
      </div>
      <div *ngIf="legacyIcon && !capraIcon" class="simple-error-icon-container">
        <i [ngClass]="[legacyIcon]"></i>
      </div>
      <div class="simple-error-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class SimpleErrorComponent {
  /** Class identifier for the legacy grunt icons */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2564
  @Input() legacyIcon: string;

  /** Icon slug for the capra design system icons */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2564
  @Input() capraIcon: string;
}
