import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

export const ToastAnimation = trigger('flyInOut', [
  state(
    'inactive',
    style({
      opacity: 1,
    })
  ),
  transition(
    'inactive => active',
    animate(
      '500ms cubic-bezier(0.56, -0.01, 0, 1.255)',
      keyframes([
        style({
          transform: 'translate3d(200%, 0, 0)',
        }),
        style({
          transform: 'translate3d(0, 0, 0)',
        }),
      ])
    )
  ),
  transition(
    'active => removed',
    animate(
      '500ms cubic-bezier(0.56, -0.01, 0, 1.255)',
      keyframes([
        style({
          transform: 'translate3d(0, 0, 0)',
        }),
        style({
          transform: 'translate3d(200%, 0, 0)',
        }),
      ])
    )
  ),
]);
