import { ChartTypes } from '@mode/shared/contract-common';
import { hsl as d3Hsl } from 'd3-color';
import { generateUUID } from '../js-helpers';

export const FLAT_TABLE_LEGENDS_CONTAINER_DEFAULT_LEFT_PADDING = 10;
export const FLAT_TABLE_LEGENDS_CONTAINER_DEFAULT_RIGHT_PADDING = 10;
export const FLAT_TABLE_LEGENDS_CONTAINER_MAX_WIDTH_PERC = 0.25; // 25% of the total available width.

export const FLAT_TABLE_GRADIENT_LEGEND_MIN_WIDTH = 140;
export const FLAT_TABLE_GRADIENT_LEGEND_MIN_HEIGHT = 70;

export const DEFAULT_FLAT_TABLE_LEGEND_CONFIG: Readonly<ChartTypes.FlatTableLegendConfig> = {
  title: {
    titleBodyDistance: 0,
  },
  gradient: {
    height: 20,
  },
  item: {
    height: 22,
    iconWidth: 22,
    iconHeight: 22,
    iconTextDistance: 5,
    itemGap: 5,
  },
  // Set of default colors if range is not provided
  range: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'],
  domainRangeMap: {},
};

export function calculateTextDimens(text: string): ChartTypes.TextDimens {
  return {
    width: text.length * 8, // Assuming width of a single char is 8px.
    height: 15, // Assuming height of a single char is 15px.
  };
}

export function genLegendId(): string {
  return generateUUID();
}

export function isDarkColor(color: string): boolean {
  const { l } = d3Hsl(color);
  return l <= 0.5;
}
