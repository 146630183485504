import { OpenTelemetryConfig } from '@mode/shared/contract-common';
import { Observable } from 'rxjs';

export abstract class EnvironmentFacade {
  abstract environmentName$: Observable<string>;
  abstract dataIndexBase$: Observable<string>;
  abstract aiGatewayBase$: Observable<string>;
  abstract openTelemetryConfig$: Observable<OpenTelemetryConfig>;
  abstract recurlyPublicKey$: Observable<string>;
  abstract init(): void;
}
