import { AvatarTypes, SpaceType } from '@mode/shared/contract-common';
import { OrgTypes, PreferenceTypes, UserTypes, GroupTypes, SchedulesTypes } from '@mode/shared/contract-common';
import { SpaceResource } from '../..';
import {
  AccountResource,
  ColorPaletteResource,
  UserPreferenceResource,
  UserGroupResource,
  UserResource,
  ScheduleResource,
  SchedulesResource,
  ApiKeyResource,
  ApiKeysResource,
} from '../accounts/account-response.types';
import { getEmbedded, getEmbeddedCollection, getForm, getHref, hasLink } from '@mode/shared/util-halogen';
import { format } from 'date-fns';

export function toAvatar(resource: AvatarTypes.Avatars): AvatarTypes.Avatars {
  switch (resource.type) {
    case AvatarTypes.AvatarType.Uploaded: // falls through
    case AvatarTypes.AvatarType.Gravatar:
      return {
        type: resource.type,
        href: resource.href,
      };
    case AvatarTypes.AvatarType.Initials:
      return {
        type: resource.type,
        colorClass: resource.color_class,
        color_class: resource.color_class, // for backwards compatibility
        initials: resource.initials,
        seed: resource.seed,
      };
    case AvatarTypes.AvatarType.Placeholder:
      return {
        type: resource.type,
      };
  }
}

export function toUser(resource: UserResource): UserTypes.AuthenticatedUser {
  return {
    avatar: toAvatar(resource.avatar),
    displayName: resource.name,
    username: resource.username,
    isAnonymous: false,
    email: resource.email,
    token: resource.token,
    orgCount: resource.organizations_count,
    links: {
      home: getHref(resource, 'home_web'),
      newOrganization: getHref(resource, 'web_new_organization'),
      settings: getHref(resource, 'web_settings'),
    },
    // TODO: more fields
  };
}

export function toPreference(resource: UserPreferenceResource): PreferenceTypes.PreferenceState {
  return {
    defaultAccount: resource.default_account,
    editorBrowserEnabled: resource.editor_browser_enabled,
    editorTheme: resource.editor_theme,
    features: resource.features,
    hiddenBanners: resource.hidden_banners as any, // Should we map explicitly?
    homeListType: resource.home_list_type,
    homeView: resource.home_view,
    notebookSidebar: resource.notebook_sidebar,
    sqlEditorPreferences: resource.sql_editor_preferences,
  };
}

export function toUserOrganization(resource: AccountResource): UserTypes.UserOrganization {
  return {
    id: resource.id,
    token: resource.token,
    avatar: toAvatar(resource.avatar),
    username: resource.username,
    displayName: resource.name,

    links: {
      datasource: getHref(resource, 'select_data_sources_web'),
      home: getHref(resource, 'home_web'),
      newReport: getHref(resource, 'new_report_web'),
    },
  };
}

export function toOrganization(resource: AccountResource): OrgTypes.Organization {
  const features = getEmbedded(resource, 'organization_features') || ({} as any);

  return {
    id: resource.id,
    token: resource.token,
    avatar: toAvatar(resource.avatar),
    username: resource.username,
    displayName: resource.name,
    planCode: resource.plan_code,
    seatLimitReached: resource['seat_limit_reached'],
    createdAt: resource.created_at,
    user: resource.user,
    membershipType: resource.membership_type,
    isMember: resource.plan_code != null, // indirect way to determine if the current user is a member
    isFreePlan: resource.plan_code === OrgTypes.PlanCode.Free,
    isStudioSeatLimitExceed: resource.plan_code === OrgTypes.PlanCode.Free && resource['seat_limit_reached'] === true,
    isThoughtspotIntegrated: resource['thoughtspot_integrated'],
    datasourceCount: resource.data_source_count,
    adminDataSourceConnectionsOnly: resource.admin_data_source_connections_only,
    adminCollectionCreationOnly: resource.admin_collection_creation_only,
    datasetSizeLimitMb: resource.dataset_size_limit_mb,
    orgSeatLimitReached: resource['org_seat_limit_reached'],
    pythonPerformanceFirstTier: resource['python_performance_first_tier'],
    organizationRestricted: resource['organization_restricted'],
    memberApiTokensEnabled: resource['settings']?.member_api_keys_enabled,
    googleSheetsEnabled: resource['settings']?.google_sheets_enabled,

    permissions: {
      accessDefinitions: hasLink(resource, 'definitions'),
      accessSpaces: hasLink(resource, 'web_spaces'),
    },
    features: {
      limitedMemberships: features['limited_memberships'] || false,
      directoryIntegration: features['directory_integration'] || false,
      secretEmbeds: features['secret_embeds'] || false,
      signedEmbeds: features['signed_embeds'] || false,
      externalSharing: features['external_sharing'] || false,
      prependScript: features['prepend_script'] || false,
      orgStats: features['org_stats'] || false,
      dataSourceRestricted: features['data_source_restricted'] || false,
      spaceRestricted: features['space_restricted'] || false,
      identityControl: features['identity_control'] || false,
      customReportThemes: features['custom_report_themes'] || false,
      reportExploration: features['report_exploration'] || false,
      scimProvisioning: features['scim_provisioning'] || false,
      batchReportStats: features['batch_report_stats'] || false,
      dbtMetrics: features['dbt_metrics'] || false,
      tsDatasetsIntegration: features['ts_datasets_integration'] || false,
    },
    links: {
      colorPalettes: getHref(resource, 'web_color_palettes_settings'),
      datasource: getHref(resource, 'select_data_sources_web'),
      datasources: getHref(resource, 'web_data_sources_settings'),
      datasourceConnectionRequestWeb: getHref(resource, 'data_source_connection_request_web'),
      definitions: getHref(resource, 'definitions'),
      newOrganization: getHref(resource, 'web_new_organization'),
      themesSettings: getHref(resource, 'web_settings_themes'),
      spaces: getHref(resource, 'web_spaces'),
      home: getHref(resource, 'home_web'),
      invite: getHref(resource, 'new_invite_web'),
      newReport: getHref(resource, 'new_report_web'),
      publicDatasource: getHref(resource, 'web_public_datasource_home'),
      homeReports: getHref(resource, 'home_reports_web'),
      homeSearch: getHref(resource, 'home_search_web'),
      homeStarred: getHref(resource, 'home_starred_web'),
      homeDiscover: getHref(resource, 'home_discover_web'),
      homeExplorations: getHref(resource, 'home_explorations_web'),
      reportViews: getHref(resource, 'report_views'),
      settings: getHref(resource, 'web_settings'),
      customSpaces: getHref(resource, 'custom_spaces'),
      moveToSpaces: getHref(resource, 'move_to_spaces'),
      trial: getHref(resource, 'web_trial_appointments'),
      googleAccount: getHref(resource, 'web_google_account'),
    },
    // TODO: more fields
  };
}

export function toColorPalette(resource: ColorPaletteResource): OrgTypes.ColorPalette {
  return {
    name: resource.name,
    predefined: resource.predefined,
    token: resource.token,
    isDefault: resource['is_default'],
    type: resource.palette_type,
    value: resource.value,
  };
}

export function toGroup(resource: UserGroupResource): GroupTypes.UserGroup {
  return {
    groupType: resource.group_type,
    managed: resource.managed,
    memberCount: resource.member_count,
    name: resource.name,
    state: resource.state,
    token: resource.token,
  };
}

export function toAdmin(resource: AccountResource): OrgTypes.Admin {
  return {
    name: resource.name,
    username: resource.username,
    id: resource.id,
  };
}

export function toOrgCollection(resource: SpaceResource): OrgTypes.Collection {
  return {
    token: resource.token,
    name: resource.name,
    defaultAccessLevel: resource.default_access_level,
    type: resource.space_type,
    isPrivate: resource.space_type === SpaceType.private,
    restricted: resource.restricted,
    viewable: resource['viewable?'],
    viewed: resource['viewed?'] ?? true,
  };
}

export function toSchedules(resource: SchedulesResource, embedProperty = 'schedules'): SchedulesTypes.Schedules {
  const schedules = getEmbeddedCollection<ScheduleResource>(resource, embedProperty) || [];
  return {
    pagination: resource.pagination,
    schedules: schedules.map(toSchedule),
  };
}

export function toSchedule(schedule: ScheduleResource): SchedulesTypes.Schedule {
  return {
    dayOfMonth: schedule.day_of_month,
    dayOfWeek: schedule.day_of_week,
    frequency: schedule.frequency,
    hour: schedule.hour,
    lastScheduledRun: schedule.last_scheduled_run,
    minute: schedule.minute,
    paused: schedule.paused,
    reportName: schedule.report_name || 'Untitled',
    name: schedule['name'],
    reportToken: schedule.report_token,
    reportType: schedule.report_type,
    subscribed: schedule.subscribed,
    subscriberCount: schedule.subscriber_count,
    timeZone: schedule.time_zone,
    token: schedule.token,
    deleteForm: getForm(schedule, 'destroy'),
    editForm: getForm(schedule, 'edit'),
    lastScheduledRunText: formatScheduleRunDate(schedule.last_scheduled_run),
    frequencyText: getFrequencyText(schedule),
    links: {
      reportWeb: getHref(schedule, 'report_web'),
    },
    delivery: schedule['delivery'],
  };
}

export function toApiKeys(resource: ApiKeysResource): OrgTypes.ApiKeys {
  const apiKeys = getEmbeddedCollection<ApiKeyResource>(resource, 'api_keys') || [];
  return {
    pagination: resource.pagination,
    apiKeys: apiKeys.map(toApiKey),
    createForm: getForm(resource, 'create'),
  };
}

export function toApiKey(apiKey: ApiKeyResource): OrgTypes.ApiKey {
  return {
    createdAt: apiKey.created_at,
    creator: {
      name: getEmbedded(apiKey, 'creator')?.['name'],
    },
    expired: apiKey.expired,
    expiresAt: apiKey['expires_at'],
    lastSeenAt: apiKey.last_seen_at,
    name: apiKey.name,
    revokeAt: apiKey.revoked_at,
    token: apiKey.token_name,
    tokenSecret: apiKey.token_secret,
    revokeForm: getForm(apiKey, 'revoke'),
    revoker: {
      name: getEmbedded(apiKey, 'revoker')?.['name'],
    },
  };
}

function getFrequencyText(schedule: ScheduleResource) {
  let text = '';
  switch (schedule.frequency) {
    case 'every_15_minutes':
      text = 'Every 15 minutes';
      if (schedule.minute) {
        text += ' starting at ' + schedule.minute;
      }
      break;
    case 'every_30_minutes':
      text = 'Every 30 minutes';
      if (schedule.minute) {
        text += ' starting at ' + schedule.minute;
      }
      break;
    case 'hourly':
      text = 'Every hour';
      if (schedule.minute) {
        text += ' at ' + schedule.minute;
      }
      break;
    case 'daily':
      text = 'Daily at ' + schedule.hour + ' ' + schedule.time_zone;
      break;
    case 'weekly':
      text = 'Every ' + schedule.day_of_week + ' at ' + schedule.hour + ' ' + schedule.time_zone;
      break;
    case 'monthly':
      text = 'Every ' + schedule.day_of_month + ' of the month at ' + schedule.hour + ' ' + schedule.time_zone;
      break;
  }

  return text;
}

/**
 * Helper function to format for schedule run date
 */
function formatScheduleRunDate(dateString: string): string {
  return dateString ? format(Date.parse(dateString), 'MM/dd/yyyy h:mm:ssa')?.toLowerCase() : '';
}
