import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAiAssist from './state/ai-assist.reducer';
import { AiAssistEffects } from './state/ai-assist.effects';
import { QueryViewerFacade } from './state/query-viewer.facade';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AiGatewayAuthInterceptor } from './ai-gateway-auth.interceptor';
import { SubmitFeedbackFacade } from './state/submit-feedback.facade';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(fromAiAssist.AI_ASSIST_FEATURE_KEY, fromAiAssist.aiAssistReducer),
    EffectsModule.forFeature([AiAssistEffects]),
  ],
  providers: [
    QueryViewerFacade,
    SubmitFeedbackFacade,
    { provide: HTTP_INTERCEPTORS, useClass: AiGatewayAuthInterceptor, multi: true },
  ],
})
export class QueriesAiAssistDomainModule {}
