import { Injectable } from '@angular/core';
import { AllFlags, FeatureFlag, FeatureFlagsFacade } from '@mode/shared/contract-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { isPresent } from '@mode/shared/util-js';

@Injectable()
export class FeatureFlagsService implements FeatureFlagsFacade {
  allFlags$: Observable<AllFlags>;

  // We should have done this with NgRx, but it's not worth fixing since we aren't making an external API call.
  private allFlagsNotifier: BehaviorSubject<AllFlags | null>;

  constructor() {
    this.allFlagsNotifier = new BehaviorSubject<AllFlags | null>(null);
    this.allFlags$ = this.allFlagsNotifier.pipe(filter(isPresent));
  }

  /**
   * for when determining whether a flag is boolean enabled or disabled
   */
  asObservable(feature: FeatureFlag): Observable<boolean> {
    return this.allFlags$.pipe(
      map((f) => {
        return f[feature] === true;
      })
    );
  }

  /**
   * For when the flag contains non-boolean values (number, strings, etc)
   */
  featureValueAsObservable<Type extends string | number | boolean>(feature: FeatureFlag): Observable<Type> {
    return this.allFlags$.pipe(
      map((f) => {
        return f[feature] as Type;
      })
    );
  }

  asPromise(feature: FeatureFlag) {
    return this.asObservable(feature).pipe(first()).toPromise();
  }

  init(allFlags: AllFlags): void {
    this.allFlagsNotifier.next(allFlags);
  }
}
