export abstract class LegacyAssetService {
  abstract embedReportCSS: string;
  abstract pythonIframeNg: string;
  abstract embedPreviewPathNg: string;
}

declare global {
  interface Window {
    ASSET_PATH_MAP: LegacyAssetService;
  }
}
