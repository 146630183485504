<div class="heading-wrap">
  <div class="heading">
    <ng-content select="[heading]"></ng-content>
    <capra-icon-button *ngIf="!hideClose"
      mat-dialog-close
      class="close-button"
      data-test="mode-dialog-header-close"
      aria-label="close modal"
      icon="icon-close"
    ></capra-icon-button>
  </div>
  <div class="sub-heading">
    <ng-content select="[sub-heading]"></ng-content>
  </div>
</div>
