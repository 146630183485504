import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IconComponent, TooltipDirective } from '@mode/capra';

@Component({
  standalone: true,
  selector: 'mode-state-indicator-icon',
  imports: [CommonModule, IconComponent, TooltipDirective],
  templateUrl: './state-indicator-icon.component.html',
  styleUrls: ['./state-indicator-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateIndicatorIconComponent implements OnInit {
  // This component will render a mainIcon and a sub stateIcon indicator when disaplayStateIcon is enabled
  @Input() displayStateIcon = false;
  @Input() mainIcon = '';
  @Input() stateIcon = '';
  @Input() tooltipPlacement? = 'bottom-start';
  @Input() tooltipHtmlContent? = '';
  @Input() tooltipButtonSelectors?: string[];
  @Output() tooltipButtonClick? = new EventEmitter<string>();

  renderFiller = false;

  ngOnInit(): void {
    // Render white filler only in circular icons that requires it by design
    // We anticipate to add one more icon to this check
    this.renderFiller = this.stateIcon === 'icon-update';
  }
}
