<div *ngIf="!hasImage && !community"
  [class]="[
    'avatar',
    'type-' + type,
    'size-' + size,
    options?.color_class ?? 'mode-avatar-color-4'
  ]"
>
  {{ options?.initials }}
</div>

<img *ngIf="hasImage && !community"
  class="avatar"
  [class]="['type-' + type, 'size-' + size]"
  [src]="options?.href"
  alt="user uploaded image"
/>

<div *ngIf="community"
  [class]="[
    'avatar',
    'type-user',
    'size-' + size
  ]"
>
  <capra-icon icon="illo-community-blue"></capra-icon>
</div>
