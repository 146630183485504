import { Observable, OperatorFunction, Subject, concat, filter, from, map, pipe } from 'rxjs';

// EventCreator logic based on NgRx ActionCreators
interface CustomAttrs<T> {
  _as: 'props';
  _p: T;
}

export declare interface TypedEvent<TName extends string, A extends string> extends EventAction, EventProps {
  readonly traceName: TName;
  readonly action: A;
}

type FunctionWithParametersType<P extends unknown[], R = void> = (...args: P) => R;

type Creator<
  P extends any[] = any[],
  R extends TypedEvent<string, string> = TypedEvent<string, string>
> = FunctionWithParametersType<P, R>;

export type EventCreator<TName extends string = string, A extends string = string, C extends Creator = Creator> = C &
  TypedEvent<TName, A>;

function defineType<T extends string, A extends string, C extends Creator = Creator>(
  traceName: T,
  action: A,
  creator: C
): EventCreator<T, A, C> {
  return Object.defineProperties(creator, {
    traceName: {
      value: traceName,
      writable: false,
    },
    action: {
      value: action,
      writable: false,
    },
  }) as EventCreator<T, A, C>;
}

export function createEvent<T extends string, A extends string>(
  traceName: T,
  action: A
): EventCreator<T, A, (props?: EventProps) => TypedEvent<T, A>>;
export function createEvent<T extends string, A extends string, P extends object>(
  traceName: T,
  action: A,
  config?: CustomAttrs<P>
): EventCreator<T, A, (props: P & EventProps) => P & TypedEvent<T, A>>;
export function createEvent<T extends string, A extends string, P extends object>(
  traceName: T,
  action: A,
  config?: CustomAttrs<P>
): EventCreator<T, A> {
  const as = config ? config._as : 'empty';
  switch (as) {
    case 'empty':
      return defineType(traceName, action, (props?: EventProps) => ({ ...props, traceName, action }));
    case 'props':
      return defineType(traceName, action, (props: P & EventProps) => ({
        ...props,
        traceName,
        action,
      }));
    default:
      throw new Error('Unexpected config.');
  }
}

export function attrs<P>(): CustomAttrs<P> {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { _as: 'props', _p: undefined! };
}

export enum TraceLevel {
  Debug,
  Info,
  Warn,
  Error,
}

export enum Consumers {
  HoneycombHelixCsvExport,
  SegmentCsvExport,
}

export interface EventProps {
  /**
   * A unique id that can be used by consumers to link multiple events together as a part of the
   * same flow.
   */
  traceId?: string;
  /**
   * Overrides the default timestamp generated by the recordEvent function.
   */
  timestamp?: number;
  /**
   * Not used yet
   */
  level?: TraceLevel;
}

export type EventOptions<CA extends {}> = EventProps & Omit<CA, keyof EventProps>;

export interface EventAction extends EventProps {
  traceName: string;
  action: string;
}

export interface BaseRecord {
  url: string | undefined;
  timestamp: number;

  username?: string;
  account?: string;
  app?: GlobalData['app'];
}

export type EventRecord<TName extends string, A extends string, Attrs extends {} = {}> = TypedEvent<TName, A> &
  Attrs &
  BaseRecord;
export type EventRecordFromCreator<C extends EventCreator> = EventRecord<
  C extends EventCreator<infer T, string> ? T : never,
  C extends EventCreator<string, infer T> ? T : never,
  C extends EventCreator<string, string, infer T> ? ReturnType<T> : never
>;

export type UntypedEventRecord = EventRecord<string, string>;

export interface GlobalData {
  username?: string;
  account?: string;
  app: 'headless' | 'main' | 'embed';
  startTime: number;
}

/**
 * Stores any events that occurred before the consumers are initialized
 */
export const preInitEvents: UntypedEventRecord[] = [];
export const eventNotifier = new Subject<UntypedEventRecord>();

export const events$ = new Observable<UntypedEventRecord>((subscriber) => {
  const unsub = concat(from(preInitEvents), eventNotifier).subscribe({
    next: (record) => {
      subscriber.next(record);
    },
    complete: () => subscriber.complete(),
    error: (err) => subscriber.error(err),
  });

  return unsub;
});

/**
 * Filters the events stream for only events that match the provided traceName.
 * @param traceName the tracename to filter by
 * @returns
 */
export function ofTrace<
  EC extends EventCreator<string, string, Creator> = EventCreator,
  T1 extends string | EC = string,
  U extends EventRecord<string, string> = EventRecord<string, string>,
  V = T1 extends string ? ReturnType<EC> & BaseRecord : ReturnType<Extract<T1, EC>> & BaseRecord
>(trace: T1): OperatorFunction<U, V> {
  return pipe(
    filter((ev: U) => ev.traceName === (typeof trace === 'string' ? trace : trace.traceName)),
    map((ev: U) => ev as unknown as V)
  );
}

// Taken from https://stackoverflow.com/questions/57016728/is-there-a-way-to-define-type-for-array-with-unique-items-in-typescript
type UniqueArray<T> = T extends readonly [infer X, ...infer Rest]
  ? InArray<Rest, X> extends true
    ? ['Encountered value with duplicates:', X]
    : readonly [X, ...UniqueArray<Rest>]
  : T;

type InArray<T, X> = T extends readonly [X, ...infer _Rest]
  ? true
  : T extends readonly [X]
  ? true
  : T extends readonly [infer _, ...infer Rest]
  ? InArray<Rest, X>
  : false;

/**
 * Filters for a specific action or actions
 * @param action
 * @returns
 */
export function ofAction<
  T1 extends string,
  T2 extends string[],
  EC extends EventCreator<T1, T2[number], Creator<any[], TypedEvent<T1, T2[number]>>>[],
  U extends EventRecord<string, string> = EventRecord<string, string>,
  V = ReturnType<EC[number]> & BaseRecord & Record<string, any>
>(traceName: T1, ...allowedActions: UniqueArray<T2>): OperatorFunction<U, V>;
export function ofAction<
  EC extends EventCreator<string, string, Creator>[],
  U extends EventRecord<string, string> = EventRecord<string, string>,
  V = ReturnType<EC[number]> & BaseRecord
>(...allowedEvents: EC): OperatorFunction<U, V>;
export function ofAction<
  EC extends EventCreator<string, string, Creator>[],
  U extends EventRecord<string, string> = EventRecord<string, string>,
  V = ReturnType<EC[number]> & BaseRecord
>(...allowedEvents: EC | string[]): OperatorFunction<U, V> {
  return pipe(
    filter((ev: U) => {
      if (typeof allowedEvents[0] === 'string') {
        return allowedEvents[0] === ev.traceName && allowedEvents.slice(1).some((action) => action === ev.action);
      } else {
        return (allowedEvents as EC).some((aev) => {
          return aev.traceName === ev.traceName && aev.action === ev.action;
        });
      }
    }),
    map((ev: U) => ev as unknown as V)
  );
}
