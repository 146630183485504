import { Injectable } from '@angular/core';
import { SessionLoggedOutDialogComponent } from './session-logged-out-dialog/session-logged-out-dialog.component';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { DialogService } from '@mode/shared/ui';

@Injectable({
  providedIn: 'root',
})
export class SessionLoggedOutDialogService {
  public dialogIsOpen = false;
  private dialogRef: MatLegacyDialogRef<SessionLoggedOutDialogComponent> | undefined;

  constructor(private dialogService: DialogService) {}

  open(): void {
    if (this.dialogIsOpen) {
      return;
    }
    this.dialogIsOpen = true;
    this.dialogRef = this.dialogService.open({
      component: SessionLoggedOutDialogComponent,
      matDialogConfig: { disableClose: true },
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogIsOpen = false;
    });
  }

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
