import { Injectable } from '@angular/core';

import { ActiveToast, ComponentType, DisableTimoutType, IndividualConfig, ToastrService } from 'ngx-toastr';
import { MessageToastComponent } from './message-toast/messsage-toast.component';
import { ToastType } from '@mode/capra';

export interface ToastConfig<TypeOfPayload, TypeOfComponent> {
  closeButton?: boolean;
  disableTimeout?: DisableTimoutType;
  tapToDismiss?: boolean;
  timeOut?: number;
  extendedTimeout?: number;
  toastComponent?: ComponentType<TypeOfComponent>;
  payload?: TypeOfPayload | ToastPayload;
}

export interface ToastPayload {
  toastType: ToastType;
}

@Injectable()
export class ToastService {
  constructor(private _toastrService: ToastrService) {}

  public success<TypeOfPayload, TypeOfComponent>(
    message: string,
    config?: ToastConfig<TypeOfPayload, TypeOfComponent>
  ): ActiveToast<TypeOfComponent | MessageToastComponent> {
    return this._toastrService.success(message, undefined, this._convertConfig(config, { toastType: 'success' }));
  }

  public error<TypeOfPayload, TypeOfComponent>(
    message: string,
    config?: ToastConfig<TypeOfPayload, TypeOfComponent>
  ): ActiveToast<TypeOfComponent | MessageToastComponent> {
    return this._toastrService.error(message, undefined, this._convertConfig(config, { toastType: 'error' }));
  }

  public warn<TypeOfPayload, TypeOfComponent>(
    message: string,
    config?: ToastConfig<TypeOfPayload, TypeOfComponent>
  ): ActiveToast<TypeOfComponent | MessageToastComponent> {
    return this._toastrService.warning(message, undefined, this._convertConfig(config, { toastType: 'warning' }));
  }

  public info<TypeOfPayload, TypeOfComponent>(
    message: string,
    config?: ToastConfig<TypeOfPayload, TypeOfComponent>
  ): ActiveToast<TypeOfComponent | MessageToastComponent> {
    return this._toastrService.info(message, undefined, this._convertConfig(config, { toastType: 'processing' }));
  }

  public remove(toastId: number): boolean {
    return this._toastrService.remove(toastId);
  }

  private _convertConfig<TypeOfPayload, TypeOfComponent>(
    config: ToastConfig<TypeOfPayload, TypeOfComponent> = {},
    payload: ToastPayload
  ): Partial<IndividualConfig> {
    return {
      closeButton: config.closeButton ?? false,
      disableTimeOut: config.disableTimeout ?? false,
      tapToDismiss: config.tapToDismiss ?? true,
      timeOut: config.timeOut ?? this._toastrService.toastrConfig.timeOut,
      extendedTimeOut: config.extendedTimeout ?? this._toastrService.toastrConfig.extendedTimeOut,
      toastComponent: config?.toastComponent ?? this._toastrService.toastrConfig.toastComponent,
      payload: { ...config?.payload, ...payload },
    };
  }
}
