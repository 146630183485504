import { ApiTypes } from '@mode/shared/contract-common';

export interface HttpParams {
  [key: string]: string | string[];
}

/**
 * This method does a depth first crawl over the Includes object to convert them into parameters for
 * making a HAL request
 * @param rootString string representation of the previous level
 * @param result HttpParams to add new values to
 * @param data Current includes to convert
 */
function serializeAtLevel(rootString: string, result: HttpParams, data: ApiTypes.Includes): void {
  if (typeof data === 'string') {
    result[rootString + '[' + data + ']'] = '1';
  } else if (Array.isArray(data)) {
    data.forEach((value: ApiTypes.Includes) => {
      serializeAtLevel(rootString, result, value);
    });
  } else if (data !== null && typeof data === 'object') {
    // Handle only
    const value = data['only'];
    if (value) {
      result[rootString + '[only]'] = Array.isArray(value) ? value.toString() : value;
    }

    // Handle other keys
    for (const key in data) {
      const value = data[key];
      if (key !== 'only') {
        serializeAtLevel(rootString + '[' + key + ']', result, value);
      }
    }
  }
}

/**
 *
 * @param includes
 * @returns includes converted to HttpParams
 */
export function serializeIncludes(includes: ApiTypes.Includes): HttpParams {
  if (includes == null) {
    return {};
  }

  const serialized: HttpParams = {};
  serializeAtLevel('embed', serialized, includes);
  return serialized;
}

/**
 *
 * @param params Object containing the includes. Includes will be deleted from this object.
 * @returns HttpParams representation of the includes
 */
export function serializeAndStripIncludes(params: { includes?: ApiTypes.Includes }): HttpParams {
  if (!params || typeof params.includes === 'undefined') {
    return {};
  }

  const includes = serializeIncludes(params.includes);
  delete params.includes;
  return includes;
}
