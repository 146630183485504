import { ChartTypes } from '@mode/shared/contract-common';

export interface TableFormatSetting {
  label: string;
  value: any;
  icon?: string;
  class?: string;
  example?: string;
  pattern?: string;
}
interface DisplayUnitSettings extends TableFormatSetting {
  value: ChartTypes.DisplayUnit;
}
/**
 * Flat table settings, labels, and accessors.
 *
 * Each item has at least an id. If a label or value is not present, the id
 * is also used for that.
 */
export const currencySymbolPositionOptions: TableFormatSetting[] = [
  { label: 'Before', value: 'before' },
  { label: 'After', value: 'after' },
];

export const currencySymbolOptions: TableFormatSetting[] = [
  { label: '$', value: '$' },
  { label: '€', value: '€' },
  { label: '¥', value: '¥' },
  { label: '£', value: '£' },
  { label: 'CHF', value: 'CHF' },
];

export const currencyPresetOptions: TableFormatSetting[] = [
  { label: 'US Dollar', value: ChartTypes.CurrencyPreset.USD, example: '$1,000.00' },
  { label: 'Euro', value: ChartTypes.CurrencyPreset.EUR, example: '€1,000.00' },
  { label: 'Japanese Yen', value: ChartTypes.CurrencyPreset.JPY, example: '¥1,000.00' },
  { label: 'British Pound Sterling', value: ChartTypes.CurrencyPreset.GBP, example: '£1,000.00' },
  { label: 'Australian Dollar', value: ChartTypes.CurrencyPreset.AUD, example: '$1,000.00' },
  { label: 'Swiss Franc', value: ChartTypes.CurrencyPreset.CHF, example: 'CHF1 000,00' },
  { label: 'Canadian Dollar', value: ChartTypes.CurrencyPreset.CAD, example: '$1 000,00' },
  { label: 'Mexican Peso', value: ChartTypes.CurrencyPreset.MXN, example: '$1,000.00' },
  { label: 'Chinese Yuan', value: ChartTypes.CurrencyPreset.CNY, example: '¥1,000.00' },
  { label: 'New Zealand Dollar', value: ChartTypes.CurrencyPreset.NZD, example: '$1,000.00' },
  { label: 'Custom', value: ChartTypes.CurrencyPreset.Custom },
];

export const thousandsSeparatorOptions: TableFormatSetting[] = [
  { label: 'comma', value: ',' },
  { label: 'period', value: '.' },
  { label: 'space', value: ' ' },
  { label: 'none', value: '' },
];

export const decimalSymbolOptions: TableFormatSetting[] = [
  { label: 'period', value: '.' },
  { label: 'comma', value: ',' },
];

export const thousandsGroupingOptions: TableFormatSetting[] = [
  { label: 'three digits', value: 3 },
  { label: 'two digits', value: 2 },
];

export const negativeBehaviorOptions: TableFormatSetting[] = [
  { label: '(123,000.00)', value: 'parens' },
  { label: '-123,000.00', value: 'minusSign' },
];

export const percentTransformOptions: TableFormatSetting[] = [
  { label: 'Multiply the values returned in the column by 100', value: true },
];

export const datePatternOptions: TableFormatSetting[] = [
  { label: 'Mon YYYY', pattern: 'MMM yyyy', value: 'full_mon' },
  { label: 'Day Mon YYYY', pattern: 'dd MMM yyyy', value: 'full_day' },
  { label: 'Mon Day YYYY', pattern: 'MMM dd yyyy', value: 'usa_full' },
  { label: 'YYYY-MM-DD', pattern: 'yyyy-MM-dd', value: 'numeric_date' },
  { label: 'YYYY-MM-DD HH:MM:SS', pattern: 'yyyy-MM-dd HH:mm:ss', value: 'hour' },
  { label: 'MM/DD/YYYY', pattern: 'MM/dd/yyyy', value: 'month_date_year' },
];

export const linkColorOptions: TableFormatSetting[] = [
  { label: 'Mode Blue', value: 'mode-blue', class: 'mode-blue' },
  { label: 'Mode Purple', value: 'mode-purple', class: 'mode-purple' },
  { label: 'Mode Green', value: 'mode-green', class: 'mode-green' },
  { label: 'Mode Orange', value: 'mode-orange', class: 'mode-orange' },
  { label: 'Mode Pink', value: 'mode-pink', class: 'mode-pink' },
  { label: 'Blue', value: 'blue', class: 'blue' },
  { label: 'Purple', value: 'purple', class: 'purple' },
  { label: 'Green', value: 'green', class: 'green' },
  { label: 'Orange', value: 'orange', class: 'orange' },
  { label: 'Pink', value: 'pink', class: 'pink' },
  { label: 'Black', value: 'black', class: 'black' },
];

export const newLinkStyleOptions: TableFormatSetting[] = [
  { label: 'Plain text', value: 'none', icon: 'icon-mark-label' },
  { label: 'Underline', value: 'underline', icon: 'icon-text-underline' },
  { label: 'Link icon', value: 'link-icon', icon: 'icon-link' },
];

export const linkStyleOptions: TableFormatSetting[] = [
  { label: 'None', value: 'none', icon: 'icon-mark-label' },
  { label: 'Underline', value: 'underline', icon: 'icon-text-underline' },
];

export const displayUnitOptions: DisplayUnitSettings[] = [
  { label: 'None', value: '' },
  { label: 'Thousands (K)', value: 'K', example: '100K' },
  { label: 'Millions (M)', value: 'M', example: '1M' },
  { label: 'Billions (B)', value: 'B', example: '1B' },
  { label: 'Billions (G)', value: 'G', example: '1G' },
];

/**
 * Helper to get any other supplemental key from a settings object given the value.
 *
 * ie - Date formats are saved as a name, but have an actual pattern related to rendering.
 */
export function getTableOptionsKeyFromValue(value: any, key: string, optionsArray: TableFormatSetting[]) {
  const option = optionsArray.find((opt) => opt.value === value);
  if (option) {
    return option.hasOwnProperty(key) ? option[key as keyof TableFormatSetting] : null;
  }
}

/**
 * Helper to get the date pattern based on the stored value.
 */
export function getDatePatternValueFromValue(value: string) {
  return getTableOptionsKeyFromValue(value, 'pattern', datePatternOptions);
}

export const textFormatOptions = {
  currencyPresetOptions,
  currencySymbolOptions,
  currencySymbolPositionOptions,
  thousandsSeparatorOptions,
  thousandsGroupingOptions,
  decimalSymbolOptions,
  negativeBehaviorOptions,
  percentTransformOptions,
  datePatternOptions,
  linkColorOptions,
  linkStyleOptions,
  newLinkStyleOptions,
  displayUnitOptions,
};
