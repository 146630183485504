import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelectionListChange } from '@angular/material/legacy-list';
import { IconComponent, trackByFactory } from '@mode/capra';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { ParameterOptions, ReportTypes } from '@mode/shared/contract-common';
import { DOCUMENT } from '@angular/common';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mode-select-with-search',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    MatLegacyCheckboxModule,
    MatLegacyListModule,
    MatLegacyMenuModule,
    ReactiveFormsModule,
  ],
  templateUrl: './select-with-search.component.html',
  styleUrls: ['./select-with-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectWithSearchComponent implements OnInit, OnChanges {
  @Input()
  fieldType: ReportTypes.ReportRunFormFieldType = ReportTypes.ReportRunFormFieldType.Select;

  @Input()
  maxItems = 1000;

  @Input()
  defaultOptions: ParameterOptions[] = [];

  @Output()
  selectedOptionChanged = new EventEmitter<[string | number, boolean][]>();

  private selections: Set<string | number> = new Set<string | number>();

  overlayContainer: Element | undefined | null;

  trackOption = trackByFactory<ParameterOptions>(({ value }) => value);

  constructor(@Optional() @Inject(DOCUMENT) private document: Document | undefined) {}

  searchOptionsControl = new UntypedFormControl();
  allOptions$ = new BehaviorSubject<ParameterOptions[]>([]);
  visibleOptions$: Observable<ParameterOptions[]> = combineLatest([
    this.searchOptionsControl.valueChanges.pipe(startWith('')),
    this.allOptions$,
  ]).pipe(
    map(([, allOptions]) => {
      let matchedCount = 0;
      // The below implementation for filtering, rather than using a simple Array filter
      // is used to optimize and bail early when dealing with large amounts of data
      const filterTextLower = (this.searchOptionsControl.value || '').toLowerCase();
      const results = [...allOptions].sort(this.sortBySelected).filter((o) => {
        if (matchedCount >= this.maxItems) {
          return false;
        }
        const matches = !filterTextLower || o.label?.toString().toLowerCase().includes(filterTextLower);
        if (matches) {
          matchedCount++;
        }
        return matches;
      });
      results.filter((r) => this.selections.has(r.value)).forEach((r) => (r.isSelected = true));
      return results;
    })
  );

  allOptionsAreSelected$ = combineLatest([
    this.searchOptionsControl.valueChanges.pipe(startWith('')),
    this.allOptions$,
    this.visibleOptions$,
  ]).pipe(
    map(([, allOptions, visibleOptions]) => {
      // If no search term, then select all should be driven off all options. Otherwise, only from visible options.
      const options = this.searchOptionsControl.value ? visibleOptions : allOptions;
      return options.length && options.every((x) => x.isSelected);
    })
  );

  selectAllText$ = combineLatest([
    this.searchOptionsControl.valueChanges.pipe(startWith('')),
    this.visibleOptions$,
  ]).pipe(
    map(([value, visibleOptions]) => {
      return value === '' ? '(Select All)' : `(Select  ${visibleOptions.length} results)`;
    })
  );

  someOptionsAreSelected$ = combineLatest([
    this.searchOptionsControl.valueChanges.pipe(startWith('')),
    this.allOptions$,
    this.visibleOptions$,
  ]).pipe(
    map(([, allOptions, visibleOptions]) => {
      const options = this.searchOptionsControl.value ? visibleOptions : allOptions;
      return options.some((x) => x.isSelected);
    })
  );

  descriptionOfSelectedItems$ = this.allOptions$.pipe(
    filter((options) => options.length > 0),
    map((options) => options.filter((x) => x.isSelected)),
    map((selectedOptions) => {
      const everyOptionIsSelected =
        this.isMultiSelect() && this.defaultOptions.length === selectedOptions.length && selectedOptions.length > 0;
      const someOptionsAreSelected = selectedOptions.length > 0;

      if (everyOptionIsSelected) {
        return 'All Selected';
      } else if (someOptionsAreSelected) {
        if (selectedOptions.length === 1 || this.isSingleSelect()) {
          return selectedOptions[0].label?.toString();
        } else {
          return `${selectedOptions[0].label} + ${selectedOptions.length - 1} more selected`;
        }
      } else if (this.isSingleSelect()) {
        // Emit event to update value of select: this will ensure the first option
        // is selected when a user clicks "run"
        const updatedOptions = this.defaultOptions.map((x) => ({
          isSelected: this.defaultOptions[0] === x,
          value: x.value,
          label: x.label,
        }));
        this.selectedOptionChanged.emit(updatedOptions.map((x) => [x.value, x.isSelected]));
        // if this is a single select, default to the first option
        return this.defaultOptions[0].label?.toString();
      } else {
        return 'Nothing Selected';
      }
    })
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['defaultOptions']) {
      this.allOptions$.next(this.defaultOptions.map((o) => ({ ...o, label: o.label == null ? 'null' : o.label })));
    }
  }

  ngOnInit() {
    this.selections = new Set(this.defaultOptions.filter((option) => option.isSelected).map((x) => x.value));
  }

  toggleSelectAll = (checked: boolean, visibleOptions: ParameterOptions[]) => {
    const optionsToSelect = this.searchOptionsControl.value ? visibleOptions : this.allOptions$.value;
    const updatedOptions = optionsToSelect.map((x) => ({
      isSelected: checked,
      value: x.value,
      label: x.label,
    }));
    this.selectedOptionChanged.emit(updatedOptions.map((x) => [x.value, x.isSelected]));
    this.updateSelections(updatedOptions);
  };

  optionSelected = (change: MatLegacySelectionListChange) => {
    const updatedOptions: ParameterOptions[] = [];
    change.options.forEach((o) => {
      const optionBeingChanged = this.allOptions$.value.find((a) => a.value === o.value);
      if (!optionBeingChanged) {
        return;
      }
      updatedOptions.push({ ...optionBeingChanged, isSelected: o.selected });
    });

    this.selectedOptionChanged.emit(change.options.map((x) => [x.value, x.selected]));
    this.updateSelections(updatedOptions);
  };

  // TODO: Remove when modal dialog is converted to Angular modal
  menuOpened = () => {
    this.overlayContainer =
      this.overlayContainer === undefined
        ? this.document?.querySelector('.select-with-search-dialog')?.closest('.cdk-overlay-container')
        : this.overlayContainer;
    this.overlayContainer?.classList.add('overlay-dropdown');
  };

  menuClosed = () => {
    this.overlayContainer?.classList.remove('overlay-dropdown');
  };

  private isSingleSelect() {
    return this.fieldType === ReportTypes.ReportRunFormFieldType.Select;
  }

  private isMultiSelect() {
    return this.fieldType === ReportTypes.ReportRunFormFieldType.Multiselect;
  }

  private updateSelections(options: ParameterOptions[]) {
    const updatingAllOptions = this.isMultiSelect() && options.length === this.allOptions$.value.length;
    if (updatingAllOptions && options.length) {
      // More efficient handling when selecting/deselecting all options
      if (options[0].isSelected) {
        this.selections = new Set(options.map((o) => o.value));
      } else {
        this.selections.clear();
      }
      this.allOptions$.next(options);
      return;
    }

    options.forEach((option) => {
      if (option.isSelected) {
        if (this.isSingleSelect()) {
          this.selections.clear();
        }
        this.selections.add(option.value);
      } else {
        this.selections.delete(option.value);
      }
    });
    const allOptionsAfterUpdate = this.allOptions$.value.map((uo) => {
      const o = options.find((op) => op.value === uo.value);
      const returnedOption = this.isMultiSelect() ? uo : { ...uo, isSelected: false };
      return o ? { ...uo, isSelected: o.isSelected } : returnedOption;
    });
    this.allOptions$.next(allOptionsAfterUpdate);
  }

  sortBySelected(a: ParameterOptions, b: ParameterOptions) {
    if ((a.isSelected && b.isSelected) || (!a.isSelected && !b.isSelected)) {
      return 0;
    } else if (a.isSelected) {
      return -1;
    } else if (b.isSelected) {
      return 1;
    } else {
      return 0;
    }
  }
}
