import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject, Subscription, fromEvent, merge, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  private idle: Observable<Event> = new Observable();
  private timer: Subscription = new Subscription();
  private timeOutMilliSeconds = 1000;
  private idleSubscription: Subscription = new Subscription();
  public expired: Subject<boolean> = new Subject<boolean>();

  constructor(private ngZone: NgZone) {}

  public startWatching(timeOutSeconds: number): Observable<boolean> {
    this.timeOutMilliSeconds = timeOutSeconds * 1000;

    this.idle = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize')
    );

    this.ngZone.runOutsideAngular(() => {
      this.idleSubscription = this.idle.subscribe(() => {
        this.resetTimer();
      });
    });

    this.startTimer();

    return this.expired;
  }

  private startTimer() {
    this.timer = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe(() => {
      this.expired.next(true);
    });
  }

  public resetTimer() {
    this.timer.unsubscribe();
    this.expired.next(false);
    this.startTimer();
  }

  public stopTimer() {
    this.timer.unsubscribe();
    this.idleSubscription.unsubscribe();
  }
}
