import { Injectable, InjectionToken } from '@angular/core';
import { Template, TemplateParser } from './uri-template-parser.types';
import * as _urlTemplate from 'url-template';

@Injectable({ providedIn: 'root' })
export class UriTemplateService implements TemplateParser {
  parse(template: string): Template {
    return _urlTemplate.parse(template);
  }
}
