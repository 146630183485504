import { DataType } from '@mode-switch/express';

import { FieldFormatTypes, FormatShortcutType, FormatType, NegativeBehavior } from './field-format.types';
import { ChartTypes } from '../..';

export type BaseFieldFormatSubset = Pick<ChartTypes.FieldFormat, ChartTypes.FieldFormatTypes.Base>;

export interface ValueFieldFormatMap {
  [formula: string]: BaseFieldFormatSubset;
}

export interface ChartNumberRange {
  min: string | number | null; // strings because the UI can set them to string
  max: string | number | null;
}

export enum NumberFormatTypes {
  PERCENT = 'percent',
  CURRENCY = 'currency',
  THOUSANDS = 'thousands',
  MILLIONS = 'millions',
  BILLIONS = 'billions',
  NONE = 'none',
}

export interface ChartNumberFormat {
  format: NumberFormatTypes | null;
  precision: 0 | 1 | 2 | 3 | null;
}

export const COLOR_DOMAIN_DATA_LIMIT = 250;

export interface ChartColors {
  theme: string | 'custom';
  custom: ReadonlyArray<string>;
  map?: Readonly<{
    [seriesName: string]: string;
  }>;
  reportLevel?: true;
}

export interface FieldColorMap {
  [formula: string]: FieldColor;
}

export interface FieldColor {
  colorPaletteToken: string;
  palette: ReadonlyArray<string>;
  map: Readonly<ColorEntries>;
  gradient?: ContinuousGradient;
  unassignedColor?: string;
}

export type ColorEntries = Record<string, number>;

export enum GradientType {
  SEQUENTIAL = 'sequential',
  DIVERGING = 'diverging',
}

// The GradientStep defines specific points along a continuous range which
// can have colors assigned. This could be expanded later to include additional
// data points such as DATA_MEAN, DATA_MEDIAN, etc.
export enum GradientStep {
  DATA_MIN = 'DATA_MIN',
  DATA_MIDPOINT = 'DATA_MIDPOINT',
  DATA_MAX = 'DATA_MAX',
}

export interface ContinuousGradient {
  gradientType: GradientType;
  colorEntries: GradientColorEntries;
  stepped?: boolean;
  numSteps?: number;
}

// The GradientColorEntries describes an object containing keys either from GradientStep or a
// specific number value and values which are the color string (ex: #e4ab5d). Typically, when
// specifying a gradient it is defined in one of two ways:
//  - By Data: In this case, the colors are associated with particular values in the data
//    that change when the data changes. Ex: Minimum data value => Green and maximum value => Red.
//    { DATA_MIN: '#3deb34', DATA_MAX: '#f5050d }
//  - By Value: The colors are assigned to specific values regardless of how the data may change.
//    Ex: -1 => Red and 1 => Green.
//    { -1: '#f5050d', 1: '#3deb34' }
// There can also be a mix of these types. This is typical of a divergent gradient where the user
// always want the inflection point to be at a certain value. Ex: The middle point of the gradient
// should always be at zero and the color white:
// { DATA_MIN: '#3deb34', 0: '#ffffff', DATA_MAX: '#f5050d }
// This type is Partial because not all values of GradientStep will always be in the object.
export type GradientColorEntries = Partial<Record<keyof typeof GradientStep | number, string>>;

export type FacetSizeMap = Record<string, FacetSize>;
export interface FacetSize {
  width?: number;
  height?: number;
}

export interface ChartLegend {
  enabled: boolean;
  position: LegendPosition;
  title: string | null;
  titleEnabled: boolean;
  [FieldFormatTypes.Base]: ChartTypes.BaseFieldFormat | undefined;
}

export type ScaleType = 'linear' | 'logarithmic';
export type LegendPosition = 'auto' | 'top' | 'right' | 'bottom' | 'left';
export type LabelRotation = 0 | 45 | 90 | -45 | -90;
export type DateFormat =
  | 'auto'
  | 'hour'
  | 'full_mon'
  | 'usa_full'
  | 'mon_day'
  | 'year'
  | 'none'
  | 'date'
  | 'full_day'
  | 'numeric_date'
  | 'usa_default';

/**
 * Flat table zebra stripe color schemes.
 */
export const enum FlatTableColorSchemes {
  None = 'none',
  DeprecatedUseCoolGrayInstead = 'default',
  Purple = 'purple',
  Green = 'green',
  Orange = 'orange',
  Pink = 'pink',
  Blue = 'blue',
  CoolGray = 'cool-gray',
  ModeGray = 'mode-gray',
  ModeGreen = 'mode-green',
}

/**
 * Flat table row densities.
 */
export const enum FlatTableDensities {
  Compact = 'compact',
  Standard = 'standard',
  Open = 'open',
}

export const enum FlatTableDefaultGeneralFormat {
  FlatTableViz = 'viz', // a flat table visualization, may be used to backfill when new general settings are added
  Results = 'results', // SQL results grid like defaults
}

export type FlatTableAlignmentOptions = 'left' | 'center' | 'right';
export type FlatTableCurrencyPosition = 'before' | 'after';

export type FlatTableSizingStyle = 'custom' | 'sizeToFit' | 'autoSize';

export interface FlatTableColumnFormatting {
  id: string; // Column formula
  colType: DataType.Name; // The column data type
  colFormatType: FormatType;
  isDefaultFormat: boolean;
  shortcutType?: FormatShortcutType;
  width?: number;
  alignment: FlatTableAlignmentOptions;
  formatOptions?: FlatTableColumnFormattingOptions;
}

export interface FlatTableColumnFormattingOptions {
  decimalPlaces?: number;
  decimalSymbol?: string;
  thousandsSeparator?: string;
  thousandsGrouping?: number;
  currencySymbol?: string;
  customCurrencySymbol?: string;
  currencySymbolPosition?: FlatTableCurrencyPosition;
  percentTransform?: boolean;
  datePattern?: string;
  negativeBehavior?: NegativeBehavior;
  linkColor?: string;
  linkStyle?: string;
}

export interface BigValueTrendFormat {
  label: string | null;
}

export interface BigValueFormat {
  description: string | null;
  primaryTrendFormat: BigValueTrendFormat;
}

export interface ChartFormat {
  xAxis: Readonly<{
    rotate: LabelRotation | null;
    dateFormat: DateFormat;
    numberRange: Readonly<ChartNumberRange>;
    numberFormat: Readonly<ChartNumberFormat>;
    title: string | null;
    titleEnabled: boolean;
    compact: boolean;
    showAxisLabels: boolean;
    axisLabels: string | null;
    [FieldFormatTypes.Base]: ChartTypes.BaseFieldFormat | undefined;
  }>;

  yAxis: Readonly<{
    numberRange: Readonly<ChartNumberRange>;
    numberFormat: Readonly<ChartNumberFormat>;
    title: string | null;
    titleEnabled: boolean;
    scaleType: ScaleType;
    [FieldFormatTypes.Base]: ChartTypes.BaseFieldFormat | undefined;
    valueFieldFormatMap: ValueFieldFormatMap | undefined;
  }>;

  y2Axis: Readonly<{
    numberRange: Readonly<ChartNumberRange>;
    numberFormat: Readonly<ChartNumberFormat>;
    title: string | null;
    titleEnabled: boolean;
    scaleType: ScaleType;
    [FieldFormatTypes.Base]: ChartTypes.BaseFieldFormat | undefined;
  }>;

  flatTable: Readonly<{
    general: Readonly<FlatTableFormatting>;
    columns: Readonly<FlatTableColumnSettings[]>;
  }>;
  bigValue: Readonly<BigValueFormat>;

  legend: Readonly<ChartLegend>;

  showValues: boolean;
  pieLabelsNotPercentages: boolean;
  nullToZero: boolean;
  colors: Readonly<ChartColors>;
  /**
   * This is the original QuickChart flag which controlled both +/- granularity and temporal drilldown.
   * For VE/PTQC, this flag is used solely for showing the +/- granularity control.
   * Drill anywhere (aka dynamic drilldown) is supported for all VE charts and is controlled in
   * Report Viewer via a Report Setting
   */
  drilldownEnabled: boolean | undefined;
  useMuzeRenderer: boolean | undefined;
}

export interface FlatTableFormatting {
  colorScheme: FlatTableColorSchemes;
  density: FlatTableDensities;
  colGridlines: boolean;
  rowGridlines: boolean;
  rowNumbers: boolean;
  sizingStyle: FlatTableSizingStyle;
  dataProfilingEnabled: boolean;
  pageSize: number;
}

export interface FlatTableColumnSettings {
  id: string; // Column formula
  colType: DataType.Name; // The column data type
  width?: number;
  conditionalFormatting?: ConditionalFormatting;
}

export type ConditionalFormatting = {
  isEnabled: boolean;
  legendId?: string;
  color?: FieldColor;
  addToText?: boolean;
  selectedColorPalette?: any;
};
