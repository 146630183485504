import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

const API_URL = /\/api\//i;

@Injectable()
export class PreloadInterceptor implements HttpInterceptor {
  constructor(private window: Window) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method === 'GET' && API_URL.test(request.url)) {
      /**
       * Note, this implementation assumes that all `/api` requests only have embed[*] as possible query string
       * parameters. Other parameters are ignored for HAL preloading purposes. If the assumption is true,
       * then this should never be an issue.
       */
      const url = new URL(request.url, this.window.location.protocol + '//' + this.window.document.domain);

      let selector = `[id="${url.pathname}"]`;

      // add embeds to the query selector
      request.params.keys().forEach((key) => {
        if (key.startsWith('embed[')) {
          selector += `[embed\\[${key.substring(6, key.length - 1)}\\]="1"]`;
        }
      });

      // In case the query string is passed through the URL instead.
      url.searchParams.forEach((value, key) => {
        if (key.startsWith('embed[')) {
          selector += `[embed\\[${key.substring(6, key.length - 1)}\\]="${value}"]`;
        }
      });

      // only scan the HEAD node, since we don't expect preloads to be in the body
      const preload = this.window.document.head.querySelector(selector);
      if (preload != null && preload.textContent) {
        const resource = JSON.parse(preload.textContent);
        return of(
          new HttpResponse({
            status: 200,
            body: resource,
          })
        );
      }
    }

    return next.handle(request);
  }
}
