import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';

import { BadgeComponent, IconButtonComponent, IconComponent } from '@mode/capra';
import { SharedUtilSegmentModule, TrackClickData } from '@mode/shared/util-segment';

export type NewContainerMenuItem = {
  href: string;
  text: string;
  icon: string;
  class?: string;
  testAttribute: string;
  trackClickData?: TrackClickData;
};

@Component({
  standalone: true,
  selector: 'mode-new-container-menu',
  imports: [
    BadgeComponent,
    CommonModule,
    IconButtonComponent,
    IconComponent,
    MatLegacyMenuModule,
    SharedUtilSegmentModule,
  ],
  templateUrl: './new-container-menu.component.html',
  styleUrls: ['./new-container-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewContainerMenuComponent {
  @Input() menuItems: NewContainerMenuItem[] = [];
  @Input() theme: 'light' | 'dark' = 'light';
}
