import { generateUUID } from '@mode/shared/util-js';
import { assign, head, remove } from 'lodash';

export interface Timer {
  id: string;
  startTime: number;
  endTime: number | null;
}

export class TimerUtility {
  private _timerQueue: Timer[] = [];

  static buildKey(namespace: string, id: string): string {
    return [namespace, id].join('::');
  }

  public start(id: string): string {
    const startTime: number = this._telemetryNow();
    const endTime = null;

    this._timerQueue.push({ id, startTime, endTime });
    return generateUUID();
  }

  public end(id: string): Timer | null {
    if (!id) {
      throw new Error('ID not defined');
    }

    const timer = head(remove(this._timerQueue, { id }));
    if (timer) {
      return <Timer>assign(timer, { endTime: this._telemetryNow() });
    } else {
      return null;
    }
  }

  public hasTimer(id: string): boolean {
    return this._timerQueue.some((t) => t.id === id);
  }

  private _telemetryNow(): number {
    return window.performance.now();
  }
}
