import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LegendInfo } from '../types';
import { FlatTableSimpleLegendComponent } from '../simple-legend';
import { CommonModule } from '@angular/common';

interface GradientStop {
  offset: number;
  stopColor: string;
}

interface TickInfo {
  idx: number;
  text: string;
}

@Component({
  standalone: true,
  selector: 'mode-flat-table-gradient-legend',
  imports: [CommonModule, FlatTableSimpleLegendComponent],
  templateUrl: './gradient-legend.component.html',
  styleUrls: ['./gradient-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatTableGradientLegendComponent implements OnInit {
  @Input() legendInfo!: LegendInfo;
  @Input() legendColor!: string;

  gradientHeight!: number;
  linearGradientId!: string;
  linearGradientStops!: GradientStop[];
  ticks!: TickInfo[];

  ngOnInit(): void {
    this.gradientHeight = this.legendInfo.legendInst.config().gradient.height;
    this.linearGradientId = `linear-gradient-id-${this.legendInfo.id}`;
    this.linearGradientStops = this.calculateGradientStops();
    this.ticks = this.genTicks();
  }

  calculateGradientStops(): GradientStop[] {
    const legendData = this.legendInfo.legendInst.data();
    const interval = 100 / Math.max(legendData.length - 1, 1);
    return legendData.map((datum, idx) => {
      return {
        offset: idx * interval,
        stopColor: datum.color,
      };
    });
  }

  genTicks(): TickInfo[] {
    const legendData = this.legendInfo.legendInst.data();
    const visibleTicksData = [legendData[0], legendData[legendData.length - 1]];

    return visibleTicksData.map((td, idx) => ({
      idx,
      text: td.value,
    }));
  }
}
