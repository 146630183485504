import { Compiler } from '@mode-switch/adapt';
import { FlamingoAdapter } from '@mode-switch/adapter-flamingo';
import { DataType, ExprStage } from '@mode-switch/express';
import { Attr, Calc, Field, Model } from '@mode-switch/model';
import { ChartTypes, FlamingoTypes } from '@mode/shared/contract-common';

export const DEFAULT_FLAMINGO_PAGE_SIZE = 100;

export function makePagingResult(
  paging: ChartTypes.ChartPaging,
  count: number
): FlamingoTypes.PagingResult | undefined {
  if (paging && count != null) {
    return {
      offset: paging.offset,
      total: count,
      hasNext: paging.offset + paging.pageSize < count,
      hasPrev: paging.offset > 0,
      pageSize: paging.pageSize,
    };
  } else if (paging) {
    // If paging exists, but count is empty, we need to return an empty paging object for the table
    return {
      offset: 0,
      total: 0,
      hasNext: false,
      hasPrev: false,
      pageSize: 0,
    };
  }

  return undefined;
}

export function dimensions(
  adapter: FlamingoAdapter,
  model: Model<FlamingoTypes.ModeRole>,
  viewName: string
): Field<FlamingoTypes.ModeRole>[] {
  const view = Model.Views.find(model, viewName);

  if (view == null) {
    throw new Error(`Cannot find view with name ${viewName} in model ${model.name}`);
  }

  const fields: FlamingoTypes.ModeField[] = [];

  for (let attr of view.attrs) {
    const useNAPlaceholder = (() => {
      const compiled = adapter.compile(model, viewName, attr.formula.source);
      if (compiled.type === Compiler.Result.Type.Success) {
        switch (compiled.expr.stage) {
          case ExprStage.Aggregate:
          case ExprStage.Analytic: {
            return true;
          }
        }
      }
      return false;
    })();

    if (useNAPlaceholder) {
      const formula = Attr.dimension('"N/A"', DataType.StringType.make());
      attr = Calc.categorical(formula, attr.name);
    }

    const field = Field.categorical(attr.formula, attr.name, attr.name);
    fields.push(field);
  }

  return fields;
}
