import { AvatarTypes, CollectionAccessLevel, ModeForm, PaginationTypes } from '../..';

export const enum PlanCode {
  Standard = 'standard',
  Plus = 'plus',
  Free = 'free',
}

export const enum PlanCodeContext {
  Report = '[PlanCode] Report',
  User = '[PlanCode] User',
}

export const enum ColorPaletteType {
  Categorical = 'categorical',
  Sequential = 'sequential',
  Divergent = 'divergent',
}

export type Organization = {
  id: number;
  token: string;
  username: string;
  avatar: AvatarTypes.Avatars;
  displayName: string;
  membershipType: 'admin' | 'full' | 'limited';
  user: boolean; // if true, then this is actually a User and not an organization
  isMember: boolean; // if the current user is a member of the organization
  isFreePlan: boolean; // quick way to check if plan code is free plan
  isStudioSeatLimitExceed: boolean;
  planCode: PlanCode;
  seatLimitReached: boolean;
  createdAt: number;
  datasourceCount: number;
  adminDataSourceConnectionsOnly: boolean; // if true, only admins can add data sources
  adminCollectionCreationOnly: boolean; // if true, only admins can create collection
  datasetSizeLimitMb: number | undefined;
  orgSeatLimitReached: boolean;
  pythonPerformanceFirstTier: boolean;

  organizationRestricted: boolean;

  // from resouce.settings
  memberApiTokensEnabled: boolean | undefined;
  googleSheetsEnabled: boolean | undefined;

  /**
   * The current user's permissions regarding this organization
   */
  permissions: {
    accessDefinitions: boolean;
    accessSpaces: boolean;
  };

  features: {
    // TODO: replace with an enum-indexed object when we use TS 4.4
    limitedMemberships: boolean;
    directoryIntegration: boolean;
    secretEmbeds: boolean;
    signedEmbeds: boolean;
    externalSharing: boolean;
    prependScript: boolean;
    orgStats: boolean;
    dataSourceRestricted: boolean;
    spaceRestricted: boolean;
    identityControl: boolean;
    customReportThemes: boolean;
    reportExploration: boolean;
    scimProvisioning: boolean;
    batchReportStats: boolean;
    dbtMetrics: boolean;
    tsDatasetsIntegration: boolean;
  };

  links: {
    colorPalettes: string | null;
    customSpaces: string | null;
    datasource: string | null;
    datasources: string | null;
    datasourceConnectionRequestWeb: string | null;
    definitions: string | null;
    moveToSpaces: string | null;
    newOrganization: string | null;
    themesSettings: string | null;
    spaces: string | null;
    home: string | null;
    homeReports: string | null;
    homeSearch: string | null;
    homeStarred: string | null;
    homeDiscover: string | null;
    homeExplorations: string | null;
    invite: string | null;
    newReport: string | null;
    publicDatasource: string | null;
    reportViews: string | null;
    settings: string | null;
    trial: string | null;
    googleAccount: string | null;
  };
} & MaybeIntegrated;

export interface MaybeIntegrated {
  isThoughtspotIntegrated: boolean;
}

export interface OrgNotDeterminedYet {
  isThoughtspotIntegrated: false;
}

export const isOrg = (org: Organization | OrgNotDeterminedYet): org is Organization =>
  'user' in org && 'planCode' in org && 'username' in org && 'createdAt' in org;

export interface ColorPalette {
  token: string;
  name: string;
  type: ColorPaletteType;
  isDefault: boolean;
  value: string;
  predefined: boolean;
}

export interface Admin {
  id: number;
  name: string;
  username: string;
}

export interface DefaultPalettes {
  categorical: ColorPalette;
  divergent: ColorPalette;
  sequential: ColorPalette;
}

export interface Collection {
  token: string;
  name: string;
  defaultAccessLevel: string | CollectionAccessLevel | null;
  type: string;
  restricted: boolean;
  isPrivate: boolean;
  viewable: boolean;
  viewed?: boolean;
}

export interface ApiKey {
  createdAt: string;
  creator: {
    name: string;
  };
  expired: boolean;
  expiresAt: string;
  lastSeenAt?: string;
  name: string;
  revokeAt?: string;
  token: string;
  tokenSecret?: string;
  revokeForm: any;
  revoker?: {
    name: string;
  };
}

export interface ApiKeys {
  pagination: PaginationTypes.Pagination;
  apiKeys: ApiKey[];
  createForm: ModeForm | undefined;
}

export interface ApiKeyConfig {
  name: string;
  expiresAt: number;
}

export interface UserAccountForm {
  user: UserForm;
  accept_email: boolean;
  upt: string;
  uptd: string;
  i: string | undefined;
  t: string | undefined;
}
export interface UserForm {
  name: string;
  username: string;
  password: string;
  password_confirmation: string;
  preference: { timezone: string };
}

export interface DatabaseSelector {
  icon: string;
  link?: string;
  name: string;
  provider: string;
  vendor: string;
  view: string;
}

export interface DataSourcesListResponse {
  data_sources: string;
  status: string;
}

export interface DatasourceMetadata {
  linkUrl: string;
  datasourceName: string;
  provider: string;
  vendor: string;
  tlsOptional: boolean;
  tlsSupported: boolean;
  firewallConfigSuggested: boolean;
  rdsSupported: boolean;
  isBridged: boolean;
}

export interface InviteErrorResponse {
  id: string;
  message: string;
  _links: {
    help: {
      href: string;
    };
  };
}

export interface InviteRequestPayload {
  invite: {
    invitee: { email: string };
    message: string;
    internal_source: string;
  };
}

export interface UserPermission {
  can_invite: boolean;
  status: string;
}
