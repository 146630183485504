import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnvironmentFacade } from '@mode/shared/environment/contract';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EnvironmentFacadeService } from './+state/environment-facade.service';
import { EnvironmentEffects } from './+state/environment.effects';
import * as fromEnvironment from './+state/environment.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromEnvironment.ENVIRONMENT_FEATURE_KEY, fromEnvironment.environmentReducer),
    EffectsModule.forFeature([EnvironmentEffects]),
  ],
  providers: [{ provide: EnvironmentFacade, useClass: EnvironmentFacadeService }],
})
export class SharedEnvironmentDomainModule {}
