import { Injectable } from '@angular/core';
import { FeatureFlagsFacade } from '@mode/shared/contract-common';
import { EnvironmentFacade } from '@mode/shared/environment/contract';
import { MemoizedSelector, Store, select } from '@ngrx/store';
import { Observable, filter, switchMap } from 'rxjs';
import * as EnvironmentActions from './environment.actions';
import * as EnvironmentSelectors from './environment.selectors';

@Injectable()
export class EnvironmentFacadeService implements EnvironmentFacade {
  constructor(private store: Store, private featureFlagsFacade: FeatureFlagsFacade) {}

  environmentName$ = this.observeWhenReady(EnvironmentSelectors.selectEnvironmentName);
  dataIndexBase$ = this.observeWhenReady(EnvironmentSelectors.selectDataIndexBase);
  aiGatewayBase$ = this.observeWhenReady(EnvironmentSelectors.selectAiGatewayBase);
  openTelemetryConfig$ = this.observeWhenReady(EnvironmentSelectors.selectOpenTelemetryConfig);
  recurlyPublicKey$ = this.observeWhenReady(EnvironmentSelectors.selectReculryPublicKey);

  private observeWhenReady<Result>(selector: MemoizedSelector<object, Result>): Observable<Result> {
    return this.store.pipe(select(EnvironmentSelectors.selectEnvironmentLoaded)).pipe(
      filter((loaded) => loaded),
      switchMap(() => this.store.pipe(select(selector)))
    );
  }

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(EnvironmentActions.initEnvironment());
  }
}
