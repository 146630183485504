import { FeatureFlag } from '@mode/shared/contract-common';

declare global {
  interface Window {
    MODE_FLAGS?: { [name: string]: boolean | number | undefined };
  }
}

/**
 * Returns any flags set during bootstrap. Will not be updated if any context
 * information changes during the session.
 * @see app-initializer.ts attachFlagsToWindow()
 * @param FeatureFlag feature flag enum
 *
 * @deprecated Use FeatureFlagsFacade instead
 */
export function getInitialFlagValue<T extends number | boolean = boolean>(flag: FeatureFlag) {
  return window.MODE_FLAGS?.[flag] as T | undefined;
}
