import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatCalendarCellClassFunction,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';

import { IconButtonComponent, IconComponent } from '@mode/capra';
import { DatePickerHeaderComponent } from './date-picker-header/date-picker-header.component';

/**
 * Displays an input with a date picker toggle icon which displays
 * the Material calendar component with a custom header.
 *
 * All Dates (input and output) are in UTC time.
 */
@Component({
  standalone: true,
  imports: [IconButtonComponent, IconComponent, FormsModule, MatDatepickerModule, MatNativeDateModule],
  selector: 'mode-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnChanges {
  readonly default_placeholder = 'mm/dd/yyyy';
  readonly header = DatePickerHeaderComponent;

  /** Value to display in the input when the 'date' is null */
  @Input() placeholder = this.default_placeholder;
  /** Currently selected date */
  @Input() date: Date | null = null;
  /** Date where DatePicker should start */
  @Input() minDate: Date | null = null;

  @Output() dateChange = new EventEmitter<Date | null>();

  startDate: Date | null = null;
  currentDate: Date | null = null;

  ngOnChanges(): void {
    this.startDate = this.convertToLocal(this.date ?? this.minDate);
    this.currentDate = this.convertToLocal(this.date);
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const date = this.convertToUTC(event.value);
    this.dateChange.emit(date);
  }

  dateCellCSSClass: MatCalendarCellClassFunction<Date> = () => {
    return 'mode-date-picker-cells';
  };

  convertToLocal(date: Date | null | undefined) {
    if (date == null) {
      return null;
    }

    const adjustedDate = new Date(date);
    adjustedDate.setMinutes(adjustedDate.getMinutes() + adjustedDate.getTimezoneOffset());
    return adjustedDate;
  }

  convertToUTC(date: Date | null | undefined) {
    if (date == null) {
      return null;
    }

    const adjustedDate = new Date(date);
    adjustedDate.setMinutes(adjustedDate.getMinutes() - adjustedDate.getTimezoneOffset());
    return adjustedDate;
  }
}
