<capra-icon-button
  class="new-container-button {{ theme }}"
  icon="icon-plus-global"
  [matMenuTriggerFor]="menu"
  aria-label="Create new report or dataset menu trigger"
  data-test="green-plus-button"
></capra-icon-button>

<mat-menu class="menu new-container-menu-panel" #menu="matMenu" xPosition="before" (closed)="(null)">
  <a
    *ngFor="let menuItem of menuItems"
    [ngClass]="menuItem.class"
    [attr.href]="menuItem.href"
    [attr.data-test]="menuItem.testAttribute"
    [trackClickData]="menuItem.trackClickData"
    target="_self"
    mat-menu-item
    modeTrackClick
  >
    <capra-icon [icon]="menuItem.icon"></capra-icon>{{ menuItem.text
    }}<capra-badge *ngIf="menuItem.isBeta" type="green" text="BETA"></capra-badge>
  </a>
</mat-menu>
