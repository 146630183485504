import { OrgTypes } from '@mode/shared/contract-common';
import * as LD from 'launchdarkly-js-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

export interface FeatureFlagContext {
  billing_plan_code?: OrgTypes.PlanCode | 'user';
  email?: string;
  org_created_at?: number;
  organization?: string;
  orgName?: string; // orgName is an alias for organization
  username?: string;
}

export function init(sdkKey: string, username: string | undefined, context: FeatureFlagContext, bootstrap?: any) {
  const anonymous = !username;
  const key = !anonymous ? username : undefined;
  const options: LD.LDOptions = {
    sendEvents: false,
  };

  if (bootstrap) {
    options.bootstrap = bootstrap;
  }

  const client = LD.initialize(
    sdkKey,
    {
      key,
      email: context?.email,
      custom: context as any, // It's just a JSON blob, so force cast it
      anonymous,
    },
    options
  );

  return client;
}

export function getAllFlags(client: LD.LDClient): Promise<LDFlagSet> {
  return client.waitUntilReady().then(() => client.allFlags());
}

// WIP
export function identify(
  client: LD.LDClient,
  username: string | undefined,
  context: FeatureFlagContext
): Promise<LDFlagSet> {
  const key = username || client.getUser().key;
  if (!key) {
    throw new Error('Identify requires a username or key');
  }

  return client.identify({
    key,
    email: context?.email,
    custom: context as any,
  });
}
