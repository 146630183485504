import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyMenuModule, MatLegacyMenuTrigger } from '@angular/material/legacy-menu';
import { IconComponent, TooltipDirective, TooltipPlacements, trackByFactory } from '@mode/capra';
import { LabeledMenuItemRendererComponent } from './labeled-menu-item-renderer.component';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { xor } from 'lodash';

export interface CustomComponent {
  component: Type<any>;
  inputs: Map<string, unknown>;
}

export interface MenuItem<T = string> {
  readonly name: string;
  readonly value?: T;
  readonly desc?: string;
  readonly disabled?: boolean;
  readonly divider?: boolean;
  readonly section?: boolean;
  readonly tooltip?: string;
  readonly decorator?: CustomComponent;
}

export type LabeledMenuHeight = 'normal' | 'compact';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    LabeledMenuItemRendererComponent,
    MatLegacyMenuModule,
    MatDividerModule,
    TooltipDirective,
  ],
  selector: 'mode-labeled-menu',
  templateUrl: './labeled-menu.component.html',
  styleUrls: ['./labeled-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledMenuComponent implements AfterViewInit, OnChanges, OnDestroy {
  _selected: MenuItem | MenuItem[] | undefined;
  public readonly tooltipPlacement = TooltipPlacements.Bottom;

  @Input() label = '';
  @Input()
  set selected(value: MenuItem | MenuItem[] | undefined) {
    this._selected = value;
    this.selectedDisplayName = this.getSelectedDisplayName(value);
  }
  get selected() {
    return this._selected;
  }
  @Input() menuItems: MenuItem[] = [];
  @Input() menuClass = '';
  @Input() multiselectEnabled = false;
  @Input() activeIcon: 'icon-dot-indicator' | 'icon-check' = 'icon-dot-indicator';
  @Input() placeholder = '--';
  @Input() highlighted = false;
  @Input() highlightedError = false;
  @Input() buttonHeight: LabeledMenuHeight = 'normal';
  @Input() showMenuItemDecorator = false;
  @Input() keepMenuOpenAfterSelect = false;

  @Output() menuChange = new EventEmitter<MenuItem | MenuItem[]>();
  @Output() menuOpened = new EventEmitter<boolean>();
  @Output() menuClosed = new EventEmitter<boolean>();

  @ViewChild('customComponent', { read: ViewContainerRef }) customComponent: ViewContainerRef | undefined;
  @ViewChild('labeledMenuTrigger') menuTrigger: MatLegacyMenuTrigger | undefined;

  selectedDisplayName = '';
  isOpen = false;
  trackByName = trackByFactory<MenuItem>(({ name }) => name);

  private _isOpen$ = new BehaviorSubject(this.isOpen);
  private _destroy$ = new Subject<void>();

  constructor(private changeDetectorRef: ChangeDetectorRef, @Inject(DOCUMENT) private document: Document) {
    this._isOpen$.pipe(takeUntil(this._destroy$)).subscribe((isOpen) => {
      this.isOpen = isOpen;

      if (isOpen) {
        this.menuOpened.emit(true);
      } else {
        this.menuClosed.emit(true);
      }
    });
  }

  ngOnChanges({ multiselectEnabled }: SimpleChanges): void {
    this.setDynamicRenderer();

    if (multiselectEnabled) {
      this.activeIcon = 'icon-check';
    }
  }

  ngAfterViewInit(): void {
    this.setDynamicRenderer();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  setDynamicRenderer() {
    const selectedDecorator = this.getSelectedDecorator();
    if (this.showMenuItemDecorator && selectedDecorator?.component !== undefined && this.customComponent) {
      this.customComponent.clear();

      const compRef = this.customComponent.createComponent(selectedDecorator.component);
      selectedDecorator.inputs.forEach((value, input) => {
        compRef.instance[input] = value;
      });
      this.changeDetectorRef.detectChanges();
    }
  }

  getSelectedDecorator(): CustomComponent | undefined {
    if (!Array.isArray(this.selected)) {
      return this.selected?.decorator;
    }

    return;
  }

  setIsOpen(isOpen: boolean) {
    this._isOpen$.next(isOpen);
  }

  getSelectedDisplayName(selected = this._selected): string {
    if (selected && !Array.isArray(selected) && selected.name) {
      return selected.name;
    }

    if (selected && Array.isArray(selected)) {
      return selected.map((s) => s.name).join(', ');
    }

    return '';
  }

  onChange(item: MenuItem) {
    if (this.menuTrigger && !this.keepMenuOpenAfterSelect) {
      this.menuTrigger.closeMenu();
    }

    if (this.multiselectEnabled) {
      let selection: MenuItem[] = [];

      if (this.selected) {
        // flatten currently selected items
        selection = selection.concat(this.selected).flat(1);
      }

      // Use {@link lodash.xor} utility to produce symmetric difference of what's already selected
      // and the newly selected item. If the selected item already exists remove it.
      /// If the selected item does not already exist in the selection list, add it
      return this.menuChange.emit(xor([...selection], [item]));
    }

    return this.menuChange.emit(item);
  }

  isSelected(target: MenuItem) {
    return [this.selected].flat(1).some((item: MenuItem | undefined) => item && item.name === target.name);
  }
}
