import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsService, AnalyticsEvent, TrackClickData } from './analytics.service';

@Directive({
  selector: '[modeTrackClick]',
})
export class TrackClickDirective {
  @Input() trackClickData: TrackClickData | undefined;

  constructor(private analyticsService: AnalyticsService) {}

  @HostListener('click', ['$event'])
  onClick(): void {
    if (this.trackClickData) {
      this.analyticsService.track(AnalyticsEvent.Name.UiClicked, {
        location: this.trackClickData?.location,
        action: this.trackClickData?.action,
      });
    }
  }
}
