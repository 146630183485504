import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NegativeFeedbackToast } from '@mode/queries/ai-assist/util';
import { ToastAnimation } from '@mode/shared/util-toast';

@Component({
  selector: 'mode-negative-feedback-toast',
  templateUrl: './negative-feedback-toast.component.html',
  styleUrls: ['./negative-feedback-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ToastAnimation],
})
export class NegativeFeedbackToastComponent extends NegativeFeedbackToast {
  giveFeedback(): void {
    this.toastPackage.triggerAction();
    this.remove();
  }
}
