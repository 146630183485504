import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AI_ASSIST_FEATURE_KEY, AiAssistState } from './ai-assist.reducer';

// Lookup the 'AiAssist' feature state managed by NgRx
export const selectAiAssistState = createFeatureSelector<AiAssistState>(AI_ASSIST_FEATURE_KEY);

export const selectAiAssistEntities = createSelector(selectAiAssistState, (state) => state.aiAssistEntities.entities);

export const selectAiAssistLoading = (queryToken: string) =>
  createSelector(selectAiAssistEntities, (aiAssistEntities) => aiAssistEntities?.[queryToken]?.loading);
export const selectRequestId = (queryToken: string) =>
  createSelector(selectAiAssistEntities, (aiAssistEntities) => aiAssistEntities?.[queryToken]?.requestId);
export const selectGeneratedSQL = (queryToken: string) =>
  createSelector(selectAiAssistEntities, (aiAssistEntities) => aiAssistEntities?.[queryToken]?.generatedSQL);
export const selectErrorMsg = (queryToken: string) =>
  createSelector(selectAiAssistEntities, (aiAssistEntities) => aiAssistEntities?.[queryToken]?.errorMsg);
export const selectFeedback = (queryToken: string) =>
  createSelector(selectAiAssistEntities, (aiAssistEntities) => aiAssistEntities?.[queryToken]?.rating);
