import type { ICellRendererComp } from 'ag-grid-community';
import type { ICellRendererParams } from 'ag-grid-community';
import { ChartTypes } from '@mode/shared/contract-common';
import { renderImageCellContents } from '@mode/shared/util-js';

export class ImageCellRenderer implements ICellRendererComp {
  public eGui: any;
  public eValue: any;

  // gets called once before the renderer is used
  public init(params: ICellRendererParams) {
    // get settings
    const colSettings = params.context.flatTableFormat.columns.find(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: TS2532
      (col: ChartTypes.FlatTableColumnSettings) => col.id === params.colDef.colId
    );

    // create the cell only if content exists
    // empty values won't generate an image that will definitely be broken
    if (params.value) {
      this.eGui = renderImageCellContents(params.value, colSettings);
    }
  }

  // gets called once when grid ready to insert the element
  public getGui() {
    return this.eGui;
  }

  // gets called whenever the user gets the cell to refresh
  public refresh(params: ICellRendererParams) {
    // set value into cell again
    this.eGui.src = encodeURI(params.value);
    return true;
  }
}
