/* eslint-disable no-console */
import { Logger } from '@mode/shared/contract-common';

export class ConsoleLogger implements Logger {
  log(...args: any[]): void {
    this.consoleLog('log', ...args);
  }
  info(...args: any[]): void {
    this.consoleLog('info', ...args);
  }
  warn(...args: any[]): void {
    this.consoleLog('warn', ...args);
  }
  error(...args: any[]): void {
    this.consoleLog('error', ...args);
  }
  debug(...args: any[]): void {
    this.consoleLog('debug', ...args);
  }

  private consoleLog(method: string, ...args: any[]) {
    const consoleObj = console as any;
    consoleObj[method](...args);
  }
}
