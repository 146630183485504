import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Toast } from 'ngx-toastr';
import { ToastAnimation } from '../toast-animation';

/**
 * This component is dynamically rendered by the `ToastService` and not intended for standalone use.
 */
@Component({
  selector: 'mode-message-toast',
  templateUrl: './message-toast.component.html',
  styleUrls: ['./message-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ToastAnimation],
})
export class MessageToastComponent extends Toast {
  type = this.options.payload.toastType;
  showCloseBtn = this.options.closeButton;
  canTapToDismiss = this.options.tapToDismiss;

  dismissToast() {
    this.remove();
  }
}
