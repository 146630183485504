import { Injectable } from '@angular/core';
import Bugsnag, { BreadcrumbType, BrowserConfig, Client, Event, NotifiableError, OnErrorCallback } from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

export { Event };

@Injectable({
  providedIn: 'root',
})
export class BugsnagService {
  private client: Client;
  constructor(private window: Window) {
    // A bit of a funny workaround
    // We currently generate the Bugsnag.start() call in an *.html.erb file.
    // It's nice that it happens there because that's very early in our loading process
    // However, that means we can't use the Bugsnag defined in @bugsnag/js, and have to
    // make sure we get the one off the window instead.
    const BugsnagFromStartup = (this.window as any)['Bugsnag'];
    this.client = BugsnagFromStartup?.['_client'];
  }

  start(config: BrowserConfig) {
    // For later starts, we can use @Bugsnag/js directly
    this.client = Bugsnag.start(config);
  }

  /**
   * @deprecated Use ErrorReporter instead
   */
  notify(
    error: NotifiableError,
    onError?: OnErrorCallback,
    postReportCallback?: (err: any, event: Event) => void
  ): void {
    this.client?.notify(error, onError, postReportCallback);
  }

  leaveBreadcrumb(message: string, metadata?: { [key: string]: any }, type?: BreadcrumbType): void {
    this.client?.leaveBreadcrumb(message, metadata, type);
  }

  addMetadata(section: string, values: { [key: string]: any }): void {
    this.client?.addMetadata(section, values);
  }

  createErrorHandler() {
    return this.client ? new BugsnagErrorHandler(this.client) : null;
  }
}
