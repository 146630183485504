// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck -- There are over 60 typescript strict mode violations
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { DataType } from '@mode-switch/express';
import { Encoding } from '@mode-vegas/vegas';
import { IconComponent, TooltipDirective } from '@mode/capra';
import {
  ChartTypes,
  ConditionalFormattingTypes,
  DataProfilingTypes,
  FeatureFlag,
  FeatureFlagsFacade,
  FlamingoTypes,
} from '@mode/shared/contract-common';
import {
  FLAMINGO_RESERVED_REFS,
  FLAT_TABLE_LEGENDS_CONTAINER_DEFAULT_LEFT_PADDING,
  FLAT_TABLE_LEGENDS_CONTAINER_DEFAULT_RIGHT_PADDING,
  FLAT_TABLE_LEGENDS_CONTAINER_MAX_WIDTH_PERC,
  FlatTableColorScheme,
  PagingHelpers,
  TableCellRenderer,
  customAutosizeColumn,
  customAutosizeColumns,
  detectAllColsRendered,
  fitColumnsEqually,
  getCellPaddingFromDensity,
  getCellRendererNameByType,
  getDefaultSettingsByColumn,
  getDefaultTextFormat,
  getFormatterByType,
  getSizeFromDensity,
  isDarkColor,
  noRowsTemplate,
  prepareDataProfilingColumnsInfoMap,
  setActualColumnWidth,
} from '@mode/shared/util-js';
import { Switch } from '@mode/shared/util-switch';
import type {
  CellClassParams,
  ColDef,
  Column,
  Grid,
  GridOptions,
  ProcessCellForExportParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { DataProfileDisplayInfo, DataProfilingMap } from 'libs/shared/contract-common/src/lib/data-profiling.types';
import { isEqual } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { first, throttleTime } from 'rxjs/operators';
import { CustomTextFilter } from '../custom-ag-grid-components/custom-text-filter';
import { CustomTextFloatingFilter } from '../custom-ag-grid-components/custom-text-floating-filter';
import { ImageCellRenderer } from '../custom-ag-grid-components/image-cell-renderer';
import { UrlCellRenderer } from '../custom-ag-grid-components/url-cell-renderer';
import { LoaderComponent } from '../loader/loader.component';
import { TimeAgoPipe } from '../pipes/time-ago/time-ago.pipe';
import { TimeAgoComponent } from '../time-ago/time-ago.component';
import {
  DatumType,
  FieldType,
  FlatTableGradientLegendComponent,
  GradientLegend,
  LegendDomain,
  LegendFieldInfo,
  LegendInfo,
  LegendMeasurements,
  SimpleLegend,
} from './legends';

interface LegendsContainerInfo {
  container: HTMLElement | null;
  width: number;
  height: number;
  padding: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  };
}

@Component({
  standalone: true,
  selector: 'mode-flat-table-ng',
  imports: [
    CommonModule,
    FormsModule,
    IconComponent,
    ReactiveFormsModule,
    TimeAgoComponent,
    TooltipDirective,
    FlatTableGradientLegendComponent,
    LoaderComponent,
  ],
  templateUrl: './flat-table-ng.component.html',
  styleUrls: ['./flat-table-ng.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatTableNgComponent implements OnChanges, OnDestroy, OnInit {
  // main bindings
  @Input() content: FlamingoTypes.RecordsResult['content'];
  @Input() columns: FlamingoTypes.RecordsResult['columns'];
  @Input() paging: FlamingoTypes.PagingResult; // if not provided, it is assumed pagination is not being used and should not be shown (ie - pivot results)
  @Input() format: ChartTypes.ChartFormat;
  @Input() fieldFormats: ChartTypes.FieldFormatMap;
  @Input() encoding: Encoding; // optional, but required for the contextual icons based on columns state (ie - filtering)
  @Input() customConfig: ChartTypes.FlatTableCustomConfig; // optional, only used when custom config is passed in via Alamode api as overrides

  // Optional binding defaults to use if not set
  @Input() token: string = null; // this token can be any identifier for this table, it is used to determine when to teardown, so its app purpose is irrelevant
  @Input() activeTableColumn: string;
  @Input() isBuilder = false;
  @Input() isFallbackData?: boolean;
  @Input() isReport = false;
  @Input() isPhantom = false;
  @Input() showFormatMenuOption = false;
  @Input() showFilterMenuOption = false;
  @Input() showRemoveMenuOption = true;
  @Input() domLayout: 'normal' | 'autoHeight' = 'normal'; // `autoHeight` should be used carefully, as this renders ALL table contents
  @Input() resizableColumns = false;
  @Input() flatSorting = false;
  @Input() columnSearchEnabled = false;
  @Input() expandableCellContents = false;
  @Input() showColumnMenu = false;
  @Input() lastSuccessfulLabel = 'Run';
  @Input() lastSuccessfulRunAt = '';
  @Input() size = 0;
  @Input() executionTime = '';
  @Input() hideColumnsCount = false;
  @Input() dataProfiling: DataProfilingTypes.DataProfilingMap = {}; // dataProfiling info returned from switch plan response
  @Input() conditionalFormatting: ConditionalFormattingTypes.ConditionalFormattingMap = {}; // conditionalFormatting info returned from switch plan response
  @Input() allowCustomPageSize = false;
  @Input() showConditionalFormattingMenuOption = false;
  @Input() conditionalFormattingEnabled = false;

  // Local state
  flatTableFormat: ChartTypes.ChartFormat['flatTable'];
  flatOptions: GridOptions;
  container: HTMLElement;
  nativeElement: HTMLElement;
  currentTable: Grid;
  tableColumns: Column[];
  tableFinishedLoading = false;
  defaultColumnWidth = 140;
  autosizeColumnWidthMax = 800;
  columnStatus = {};
  columnSearchOpen = false;
  columnSearchModel: ChartTypes.TableColumnSearch['model'];
  fallbackDataTooltip: string;
  executionInfoTooltip: string;
  lastRunFormatted: string;
  lastRunIsoFormatted: string;
  viewPortColumns: DataProfilingTypes.DataProfilingColInfo[] = [];
  dataProfilingInfoColMap: DataProfilingTypes.DataProfilingInfoColMap; // to store exhaustive list of all the columns with their dataProfiling info
  dataProfilingEventStream = new Subject<void>(); // this subject is used to handle events which are triggered to fetch dataProfiling info
  dataProfilingEventStream$: Observable<void>;
  dataProfilingSubscription: Subscription | undefined;
  textWrappingCounter = {
    current: 0,
    prev: 0,
  };
  sortSettingsTooltipContent = '';
  customPageSizeEnabled = false;

  legendsContainerInfo: LegendsContainerInfo = {
    container: null,
    width: 0,
    height: 0,
    padding: { left: 0, right: 0, top: 0, bottom: 0 },
  };
  // It stores the legends in sorted order.
  legendInfoList: LegendInfo[] = [];
  showLegend = true;

  columnsWithConditionalFormatting = new Map();
  conditionalFormattingLoading = false;

  dataProfilingLoading = false;

  // Forms
  pagingControl = new UntypedFormControl('', [Validators.required]);
  pageSizeControl = new UntypedFormControl('', [Validators.required]);

  // Output

  /** Event to signal the offset has changed */
  @Output() offsetChange: EventEmitter<number> = new EventEmitter<number>();

  /** Event to signal that the selected column has changed */
  @Output() columnSelectionChange: EventEmitter<string> = new EventEmitter<string>();

  /** Event to signal that a column width has changed */
  @Output() columnWidthChange: EventEmitter<{ newWidth: number; formula: string }> = new EventEmitter<{
    newWidth: number;
    formula: string;
  }>();

  /** Event to signal intention to edit field format */
  @Output() fieldFormatEdit: EventEmitter<string> = new EventEmitter<string>();

  /** Event to signal a sort change */
  @Output() sortChange: EventEmitter<{ column: string; descending: boolean }> = new EventEmitter<{
    column: string;
    descending: boolean;
  }>();

  @Output() sortSettingsChange = new EventEmitter<{ column: string; descending: boolean }>();

  /** Event to signal a sort clear */
  @Output() sortClear: EventEmitter<string> = new EventEmitter<string>();

  /** Event to signal a field has been removed */
  @Output() fieldRemoval: EventEmitter<string> = new EventEmitter<string>();

  /** Event to signal intention to edit a filter field */
  @Output() filterFieldChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Event to signal a change in table column search.
   * This represents feature work that started but did not finish. This feature is not live.
   * */
  @Output() tableColumnSearchChange: EventEmitter<ChartTypes.TableColumnSearch['model']> = new EventEmitter<
    ChartTypes.TableColumnSearch['model']
  >();

  /** Event to signal intention to apply conditional formatting */
  @Output() conditionalFormattingApply: EventEmitter<string> = new EventEmitter<string>();

  @Output() renderStart: EventEmitter<void> = new EventEmitter<void>();
  @Output() renderEnd: EventEmitter<void> = new EventEmitter<void>();
  @Output() setTableHeight: EventEmitter<void> = new EventEmitter<void>();
  @Output() getDataProfiling: EventEmitter<DataProfilingTypes.DataProfilingChartDetails> =
    new EventEmitter<DataProfilingTypes.DataProfilingChartDetails>();
  @Output() changePageSize: EventEmitter<{ pageSize: number; isUndoAction: boolean }> = new EventEmitter<{
    pageSize: number;
    isUndoAction: boolean;
  }>();

  constructor(
    element: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private featureFlagsFacade: FeatureFlagsFacade
  ) {
    // Note - the use of ElementRef here instead of @ViewChild helps to leverage the vanilla js agGrid implementation. This isn't
    // totally ideal, however, we aren't planning on multi platform any time soon, and this is a stopgap until we decide we want to
    // refactor our agGrid implementation to use the Angular version.
    this.nativeElement = element.nativeElement;
    this.dataProfilingEventStream$ = this.dataProfilingEventStream.asObservable();
    this.flatOptions = {
      // Define any custom ag-grid components
      components: {
        customTextFilter: CustomTextFilter,
        customTextFloatingFilter: CustomTextFloatingFilter,
        [TableCellRenderer.Url]: UrlCellRenderer,
        [TableCellRenderer.Image]: ImageCellRenderer,
      },
      rowBuffer: 40,
      enableRangeSelection: true,
      suppressContextMenu: true,
      suppressRowHoverHighlight: true,
      columnTypes: {
        rightAlignedColumn: {}, // empty utility definition
        centerAlignedColumn: {}, // empty utility definition
      },
      processCellForClipboard: (params: ProcessCellForExportParams) => {
        /**
         * All copied data will be copied as formatted values. In the future, we can implement another method
         * that calls the clipboard copy method and passes context to copy raw or copy formatted, etc, if we want.
         * Use only ag-grid internals to generate the formatter and formatted value for each item. If no custom formatter
         * is present, just return.
         */
        let colDef: ColDef = {
          ...params.column.getColDef(),
          floatingFilter: this.columnSearchEnabled && this.columnSearchOpen,
        };

        if (typeof colDef.valueFormatter === 'function') {
          const formatterParams: ValueFormatterParams = {
            node: params.node,
            data: params.value,
            colDef: params.column.getColDef(),
            column: params.column,
            api: params.api,
            columnApi: params.columnApi,
            context: params.context,
            value: params.value,
          };

          return colDef.valueFormatter(formatterParams);
        }

        return params.value;
      },
      onGridReady: (params) => {
        /**
         * Do any default grid init sizing here. We are also hiding the grid until it has been fully
         * initialized to make rendering in response to observable updates more consistent.
         * Also, need to first check if the table has been torn down (ie - from the component being destroyed)
         * before this event gets processed.
         */
        if (this.currentTable) {
          if (this.sizingStyle === 'sizeToFit') {
            fitColumnsEqually(this.flatOptions, this.container, this.defaultColumnWidth);
          } else if (this.sizingStyle === 'autoSize') {
            customAutosizeColumns(
              params.columnApi.getColumns(),
              this.autosizeColumnWidthMax,
              this.flatOptions,
              this.container
            );
          }
          this.tableFinishedLoading = true;
          this.renderEnd.emit();
        }
        /**
         * Check if all columns present in encoding are rendered or not.
         * If yes then trigger stream to make a call for switch query, otherwise
         * the same thing will be done in 'onVirtualColumnsChanged' event below
         */
        if (this.columns && detectAllColsRendered(this.container, this.columns.length)) {
          this.viewPortColumns = this.columns.map((col: FlamingoTypes.ResultColumn) => {
            return {
              field: col.alias,
              name: col.name,
            };
          });
          this.dataProfilingEventStream.next();
        }
      },
      overlayNoRowsTemplate: noRowsTemplate,
      onCellFocused: (params) => {
        if (params.column && params.column.getColId() !== 'ag_grid_row_header') {
          this.updateColumnSelection(params.column.getColId());
        }
      },
      onGridSizeChanged: (event) => {
        this.changeDetector.detectChanges();

        // When sizeToFit, fit to size until fixed width
        if (this.currentTable && this.flatTableFormat.general.sizingStyle === 'sizeToFit') {
          fitColumnsEqually(this.flatOptions, this.container, this.defaultColumnWidth);
        }

        if (event?.clientHeight !== 0) {
          this.setTableHeight.emit();
        }
      },
      onColumnResized: (params) => {
        // Finished is true only on the release of the event (ie - mouse up) which is when we save
        // We also only want to save width on manual ui dragging events
        if (params.source === 'uiColumnDragged' && this.sizingStyle !== 'sizeToFit') {
          if (params.finished) {
            this.columnSelectionChange.emit(this.activeTableColumn);
            this.updateColumnWidth(params.column.getActualWidth(), params.column.getColId());
          } else {
            this.updateColumnSelectionSettings(params.column?.getColId());
          }
        }
      },
      onRowDataChanged: (params) => {
        // Update rowHeader column width based on offset length if row headers are used
        const rowHeaderCol = params.columnApi.getColumn('ag_grid_row_header');
        if (rowHeaderCol) {
          params.columnApi.setColumnWidth(rowHeaderCol.getColId(), this.rowHeaderWidth);
        }
      },
      getMainMenuItems: (params) => {
        const field = params.column.getColDef().field;
        const colId = params.column.getColId();
        const colName = params.column.getColDef().headerName;
        const colStatus = params.context.columnStatus.hasOwnProperty(colId) ? params.context.columnStatus[colId] : null;

        let colType, isDimension;
        if (this.conditionalFormattingEnabled) {
          this.format.flatTable.columns.forEach((column) => {
            const currCol = column.id.slice(1, column.id.length - 1);
            if (colName === currCol) {
              colType = column.colType;
              return;
            }
          });

          isDimension = Switch.Attrs.detectDimension(colName, colType);
        }

        const menuItems = [];
        if (
          this.flatTableFormat.general.sizingStyle !== 'sizeToFit' &&
          this.resizableColumns &&
          params.column.isResizable()
        ) {
          menuItems.push({
            name: 'Autosize width',
            action: () => {
              customAutosizeColumn(params.column, this.autosizeColumnWidthMax, this.flatOptions, this.container);
            },
          });
        }

        if (menuItems.length > 0) {
          menuItems.push('separator');
        }

        menuItems.push({
          name: `Sort ascending`,
          action: () => {
            this.sortChange.emit({ column: colId, descending: false });
          },
          checked: colStatus && colStatus.sort && colStatus.sort.isActive && colStatus.sort.isDescending === false,
        });
        menuItems.push({
          name: `Sort descending`,
          action: () => {
            this.sortChange.emit({ column: colId, descending: true });
          },
          checked: colStatus && colStatus.sort && colStatus.sort.isActive && colStatus.sort.isDescending === true,
        });
        menuItems.push({
          name: 'Advanced sort settings...',
          action: () => {
            this.sortSettingsChange.emit({
              column: colId,
              descending: colStatus.sort ? colStatus.sort.isDescending : true,
            });
          },
        });
        menuItems.push({
          name: 'Clear sort',
          action: () => {
            this.sortClear.emit(colId);
          },
          disabled: !(colStatus && colStatus.sort && colStatus.sort.isActive),
        });

        if (this.showConditionalFormattingMenuOption) {
          if (this.conditionalFormattingEnabled) {
            menuItems.push('separator');

            menuItems.push({
              name: 'Conditional formatting',
              action: () => {
                this.triggerConditionalFormatting(colId);
              },
              disabled: isDimension,
            });
          }
        }

        if (this.showFormatMenuOption) {
          if (!this.conditionalFormattingEnabled) {
            menuItems.push('separator');
          }

          menuItems.push({
            name: 'Format...',
            action: () => {
              this.triggerAdvancedFormattingEdit(colId);
            },
          });
        }

        if (this.columnSearchEnabled) {
          menuItems.push('separator');

          menuItems.push({
            name: this.columnSearchOpen ? 'Close column search' : 'Search columns',
            action: () => {
              this.toggleColumnSearch();
            },
          });
        }

        if (this.showFilterMenuOption) {
          menuItems.push({
            name: 'Filter...',
            action: () => {
              this.filterFieldChange.emit(colName);
            },
          });
        }

        if (this.showFilterMenuOption || this.showRemoveMenuOption) {
          menuItems.push('separator');
        }

        if (this.showRemoveMenuOption) {
          menuItems.push({
            name: 'Remove column',
            action: () => {
              this.fieldRemoval.emit(field);
            },
          });
        }

        return menuItems;
      },
      onVirtualColumnsChanged: (params) => {
        /**
         * if number of columns present are not sufficient to be shown in the viewport at once,
         * then ag-grid does virtual rendering of columns and returns rendered columns from this
         * callback event.
         * It helps to fetch dataProfiling info only for those columns which are rendered right
         * now in the viewport and for the remaining ones info will be fetched as soon as they are
         * scrolled into the viewport
         */
        const colsList = params.columnApi.getAllDisplayedVirtualColumns();
        this.viewPortColumns = colsList.map((col) => {
          const colDef = col.getColDef();
          return {
            field: colDef.field,
            name: colDef.headerName,
          };
        });
        this.dataProfilingEventStream.next();
      },
    };
    this.subscribeToDataProfilingEventStream();

    this.featureFlagsFacade
      .asObservable(FeatureFlag.CustomPageSizeEnabled)
      .pipe(first())
      .subscribe((flag) => {
        this.customPageSizeEnabled = flag;
      });
  }

  ngOnInit(): void {
    const timeAgoPipe = new TimeAgoPipe();
    this.lastRunFormatted = timeAgoPipe.transform(this.lastSuccessfulRunAt);
    this.fallbackDataTooltip = `<p style='text-align:left;margin:0;'>The latest Dataset run contains an error.<br>This Report will use the last successful run from ${this.lastRunFormatted} until the error is resolved.</p>`;
    this.executionInfoTooltip = `<p style='text-align:left;margin:0;'>Displays the query execution time,<br>excluding data processing and<br>load times</p>`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update column status (sorts, filters, etc) if changed or unset
    if (changes.encoding || !this.columnStatus) {
      this.setColumnStatus();
      this.createSortSettingsTooltip();
    }

    // Set flatTableFormat from the main format object
    if (changes.format) {
      this.showLegend = this.format?.legend?.enabled ?? true;
      if (this.format != null) {
        this.flatTableFormat = this.format.flatTable;
      }
    }

    // Set the container reference
    if (!this.container) {
      this.container = this.nativeElement.querySelector('.flat-table-renderer');
    }

    // The following changes require tear down and re-initializatino of the table
    //   - If the token changed, we are rendering a completely new table
    if (this.currentTable && changes.token && this.token != null) {
      this.teardownTable();
    }

    // Pulling this case out separately as it's an outlier and doesn't currently need to be integrated
    // into the regular flow. The only time `domLayout` can currently change is in response to changing
    // settings in the builder, so we can handle it on it's own here.
    if (this.currentTable && changes.domLayout) {
      this.flatOptions.api.setDomLayout(this.domLayout);
    }

    if (this.conditionalFormattingEnabled && this.columns?.length) {
      const columnsMap = this.columns.reduce((acc, curr) => {
        acc.set(curr.name, curr);
        return acc;
      }, new Map());

      this.columnsWithConditionalFormatting = this.format.flatTable.columns.reduce((acc: any, column: any) => {
        const { id, colType, conditionalFormatting } = column;
        const colName = id.slice(1, id.length - 1);
        const isDimension = Switch.Attrs.detectDimension(colName, colType);

        if (!isDimension && conditionalFormatting?.isEnabled) {
          const { legendId, color, addToText, selectedColorPalette } = conditionalFormatting;

          acc.set(colName, {
            legendId,
            color,
            addToText,
            selectedColorPalette,
            ...columnsMap.get(colName),
          });
        }

        return acc;
      }, new Map());
    }

    if (this.conditionalFormattingEnabled && this.columnsWithConditionalFormatting.size) {
      const columnsArray = Array.from(this.columnsWithConditionalFormatting, ([_, value]) => value.alias);
      const isPresent = columnsArray.every((col) => this.conditionalFormatting?.[col]);
      if ((!changes?.conditionalFormatting?.firstChange && changes?.conditionalFormatting?.currentValue) || isPresent) {
        this.columnsWithConditionalFormatting.forEach((column) => {
          column.min = this.conditionalFormatting?.[column.alias]?.min;
          column.max = this.conditionalFormatting?.[column.alias]?.max;
        });
        this.conditionalFormattingLoading = false;
      } else {
        const filteredCols = [];
        this.columnsWithConditionalFormatting?.forEach((col) => {
          if (!this.conditionalFormatting?.[col.alias]) {
            filteredCols.push({
              field: col.alias,
              name: col.name,
            });
          }
        });
        if (filteredCols && filteredCols.length && !this.conditionalFormattingLoading) {
          this.getDataProfiling.emit({
            token: this.token,
            cols: filteredCols,
            referenceId: FLAMINGO_RESERVED_REFS.ConditionalFormatting,
          });
          this.conditionalFormattingLoading = true;
        }
      }
    }

    if (
      (changes.columns ||
        changes.content ||
        changes.format ||
        changes.fieldFormats ||
        changes.customConfig ||
        changes.conditionalFormatting) &&
      this.columns != null &&
      this.content != null &&
      this.flatTableFormat != null &&
      // @TODO: (@anthonysimone) [Datasets] return to this to be more explicit about what's necessary here
      // Is this even necessary at all? If paging isn't present, it won't render it, then it will render it when available.
      // The presence of the paging should have nothing to do with the actual table render I don't think, it's auxillary.
      // Validate in use cases.
      // (!this.$attrs.updateOffset || this.paging != null) &&
      this.isDataEncodingInSync &&
      this.isFormatInSync
    ) {
      // Render or re-render the table. On following conditions:
      // - on changes of any: columns, content, format, fieldFormats
      // - all of following must be defined: columns, content, format, fieldFormats
      // - either pagination is implemented (ie - ALL flamingo tables) AND paging is present, or paging is not implemented
      // - column / data is in sync
      // - column, fieldFormats, and format.flatTable.columns are in sync
      // - customConfig has been updated via a la mode api
      if (this.currentTable) {
        // Since we've increased the strictness of what causes a render, it's possible that the content change can
        // happen after all of the column and format changes. In these cases we must re-render columns always,
        // otherwise we can miss some render cycles that actually have new data.
        // TODO: Determine if we can further optimize this process, or just clean this up to make it simpler. Since we
        // must re-render on content changes, that only leaves paging changes, but paging changes will always come in with
        // content changes, so our else is never going to be run for now.
        if (
          changes.columns ||
          changes.format ||
          changes.fieldFormats ||
          changes.customConfig ||
          changes.content ||
          (changes.conditionalFormatting && !this.conditionalFormattingLoading)
        ) {
          // Determine if we need to reset the row heights
          const updateRowHeight =
            this.hasGeneralFormatOptionChanged('density', changes) || (changes.customConfig ? true : false);
          const updateDataProfiling =
            (this.isPropertyLengthChanged('columns', changes) || changes.content) &&
            this.flatTableFormat?.general.dataProfilingEnabled;
          this.updateTable(
            this.columns,
            this.content,
            this.flatTableFormat,
            this.fieldFormats,
            updateRowHeight,
            updateDataProfiling
          );

          // Followup with adjustments based on the type of update
          // Adjust column size presentation based on sizingStyle after col/data update
          if (this.sizingStyle === 'sizeToFit') {
            fitColumnsEqually(this.flatOptions, this.container, this.defaultColumnWidth);
          } else if (this.sizingStyle === 'custom') {
            if (this.hasGeneralFormatOptionChanged('sizingStyle', changes)) {
              setActualColumnWidth(this.flatOptions);
            }
          }
        } else {
          // exclude columns to only render new rows
          this.updateTable(null, this.content, this.flatTableFormat, this.fieldFormats);
        }
      } else if (
        changes?.conditionalFormatting?.currentValue || this.columnsWithConditionalFormatting.size
          ? !this.conditionalFormattingLoading
          : true
      ) {
        this.renderTable(this.container, this.columns, this.content, this.flatTableFormat, this.fieldFormats).then(
          (grid) => {
            this.currentTable = grid;
            this.prepareColInfoMap();
            // Add custom event handlers here
            // Listen for headerClick event from the custom header
            const renderer = this.nativeElement.querySelector('.flat-table-renderer');
            const tooltipElem = this.nativeElement.querySelector('.sort-settings-tooltip') as HTMLElement;

            renderer.addEventListener('agHeaderFocused', (event: any) => {
              this.updateColumnSelection(event.detail.colId);
              // TODO: add column selection in future as well

              if (event.target.matches('.floating-filter-input')) {
                // Refocus the input if the input was clicked. When selected column changes, the header cells re-render.
                setTimeout(() => {
                  const thing: HTMLElement = this.nativeElement.querySelector(
                    `.floating-filter-input--${event.detail.colId}`
                  );
                  thing.focus();
                  if (thing) {
                    thing.focus();
                  }
                }, 20);
              }
            });

            renderer?.addEventListener('agSortHoverActive', (event: { detail: { left: number; top: number } }) => {
              const wrapperRect = renderer.getBoundingClientRect();
              tooltipElem.style.left = `${event.detail.left - wrapperRect.left}px`;
              tooltipElem.style.height = '16px';
            });

            // Add the event listener for the buttons to trigger the search filter
            renderer?.addEventListener('agTriggerColumnSearch', () => {
              this.columnSearchModel = this.flatOptions.api.getFilterModel();
              this.handleColumnSearchEvent(this.columnSearchModel);
            });
          }
        );
      }
    }

    // When a new column is focused, we need to force refresh the new and old column render state to update styles
    if (this.currentTable && changes.activeTableColumn) {
      if (this.isActiveColumnPresent()) {
        this.flatOptions.context.activeTableColumnId = this.activeTableColumnString;
        this.flatOptions.api.refreshCells();
      } else {
        this.resetColumnSelection();
      }
    }

    // Update goToPageNumber on paging change (assuming valid pages changes come with content changes)
    if (changes.paging && changes.paging.currentValue && changes.content && changes.content.currentValue) {
      // reset to the first page if there's no content and there's only one page,
      // this scenario covers when a user is on any page that is no longer available due to filtering
      if (this.content.length === 0 && this.paging.total === 1) {
        this.setOffset(0);
      }
      const pageNumber = this.getPageFromOffsetStart(this.paging.offset);
      this.pagingControl.setValue(pageNumber);
      this.pageSizeControl.setValue(this.paging.pageSize);
    }

    if (
      this.flatTableFormat?.general.dataProfilingEnabled &&
      (changes.dataProfiling || changes.columns) &&
      (this.dataProfilingLoading || this.content?.length === 0)
    ) {
      this.dataProfilingLoading = Object.keys(this.dataProfiling ?? {}).length === 0;
      // to update ColInfoMap whenever there is any change in dataProfiling input
      this.prepareColInfoMap(this.content?.length === 0);
    }

    // when dataProfiling is turned on once table is completely rendered
    if (
      !changes.format?.firstChange &&
      !changes?.format?.previousValue?.flatTable?.general?.dataProfilingEnabled &&
      changes?.format?.currentValue?.flatTable?.general?.dataProfilingEnabled
    ) {
      this.prepareColInfoMap();
      this.dataProfilingEventStream.next();
    }

    //used to handle changes in formatting from undo/redo actions
    if (
      changes.format &&
      this.hasGeneralFormatOptionChanged('pageSize', changes) &&
      this.flatTableFormat.general.pageSize !== this.pageSizeControl.value
    ) {
      this.changePageSize.emit({
        pageSize: this.flatTableFormat.general.pageSize,
        isUndoAction: true,
      });
    }
  }

  prepareColInfoMap(contentInfo = false) {
    if (contentInfo) {
      this.dataProfilingInfoColMap = this.dataProfilingInfoColMap ?? {};
      this.columns.forEach((col) => {
        let alias = col.alias;
        this.dataProfilingInfoColMap[alias] = { loading: false }; //To prevent unnecessary API calls, setting the "loading" value to false when the content length is zero.
      });
    }
    this.dataProfilingInfoColMap = prepareDataProfilingColumnsInfoMap(
      this.columns,
      this.dataProfilingInfoColMap,
      this.dataProfiling
    );
    this.updateDataProfiling(this.dataProfilingInfoColMap);
  }

  updateDataProfiling(dataProfileMap: DataProfilingMap) {
    const flatOptionsApi = this.flatOptions.api;
    if (!flatOptionsApi) {
      return;
    }

    const colDefs = flatOptionsApi.getColumnDefs() || [];

    const colDefsWithDataProfileInfo = colDefs.map((def) => {
      const { headerComponentParams } = def;
      let dataProfileInfoResult = null;
      if (this.flatTableFormat?.general.dataProfilingEnabled && dataProfileMap[def.field]) {
        if (dataProfileMap[def.field].loading && this.content?.length) {
          dataProfileInfoResult = {
            loading: true,
          };
        } else {
          dataProfileInfoResult = {
            loading: false,
            data: this.prepareDataProfileInfo(
              headerComponentParams.attrType,
              dataProfileMap[def.field],
              headerComponentParams.type,
              headerComponentParams.textFormat
            ),
          };
        }
      }
      return {
        ...def,
        headerComponentParams: {
          ...headerComponentParams,
          dataProfileInfo: dataProfileInfoResult,
        },
      };
    });
    flatOptionsApi.setColumnDefs(colDefsWithDataProfileInfo);
    flatOptionsApi.refreshHeader();
  }

  subscribeToDataProfilingEventStream() {
    // Throttling is used to batch multiple events which are dispatched while scrolling horizontally
    this.dataProfilingSubscription = this.dataProfilingEventStream$
      .pipe(throttleTime(1000, undefined, { leading: true, trailing: true }))
      .subscribe(() => {
        if (this.flatTableFormat?.general.dataProfilingEnabled) {
          // out of all the visible columns, data will be fetched only for the ones which are in loading state
          const filteredCols = this.viewPortColumns?.filter(
            (col) => this.dataProfilingInfoColMap?.[col.field]?.loading
          );
          if (filteredCols && filteredCols.length) {
            this.dataProfilingLoading = true;
            this.getDataProfiling.emit({
              token: this.token,
              cols: filteredCols,
              referenceId: FLAMINGO_RESERVED_REFS.DataProfiling,
            });
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.currentTable) {
      this.teardownTable();
    }
    if (this.dataProfilingSubscription) {
      this.dataProfilingSubscription.unsubscribe();
      this.dataProfilingSubscription = undefined;
    }
  }

  setOffset(offset: number) {
    this.offsetChange.emit(offset);
  }

  /**
   * Ensure the content and columns are in sync, on some occasions, they may not be ready at the same time, like reinitializing a table
   *
   * Note: if content is empty, it will always return true.
   * TODO: handle no results state?
   */
  get isDataEncodingInSync() {
    if (this.content.length > 0) {
      const row = this.content[0];

      for (const column of this.columns) {
        if (!row.hasOwnProperty(column.alias)) {
          return false;
        }
      }
    }

    return true;
  }

  /**
   * Ensure format, fieldFormat, and columns are in sync before trying to render.
   *
   * Need the ternary to account for pivot results alternate column format.
   */
  get isFormatInSync(): boolean {
    for (const column of this.columns) {
      const colId = column.source;
      if (!this.flatTableFormat.columns.find((col) => col.id === colId) || !this.fieldFormats.hasOwnProperty(colId)) {
        return false;
      }
    }

    return true;
  }

  /**
   * Determine if you need to check for custom settings to apply or not.
   */
  get shouldCheckCustomSettings(): boolean {
    return this.customConfig && (this.isBuilder || this.isReport || this.isPhantom);
  }

  /**
   * Helper to check if a custom condition styles override exists for a column.
   */
  private colHasCustomStyleFunction(colName: string): boolean {
    return this.customConfig &&
      this.customConfig.tableColStyleFunctions &&
      this.customConfig.tableColStyleFunctions[colName]
      ? true
      : false;
  }

  /**
   * Helper to check if a custom renderer override exists for a column.
   */
  private colHasCustomRenderer(colName: string): boolean {
    return this.customConfig && this.customConfig.tableColRenderers && this.customConfig.tableColRenderers[colName]
      ? true
      : false;
  }

  /**
   * Helper to check if custom table settings config exist.
   */
  private hasCustomTableOptions(): boolean {
    return this.customConfig && Object.keys(this.customConfig.tableSettings).length > 0;
  }

  /**
   * Helper to check if a specific custom table config option exist.
   */
  private hasCustomTableOption(optionName: string): boolean {
    return (
      this.customConfig &&
      Object.keys(this.customConfig.tableSettings).length > 0 &&
      this.customConfig.tableSettings[optionName]
    );
  }

  /**
   * Getter for sizingStyle type with default value
   */
  get sizingStyle() {
    if (this.flatTableFormat) {
      const sizingStyle = this.flatTableFormat.general.sizingStyle;
      return sizingStyle ? sizingStyle : 'sizeToFit';
    }
    return null;
  }

  /**
   * Helper to determine if a specific general setting has changed.
   */
  private hasGeneralFormatOptionChanged(formatOption: keyof ChartTypes.FlatTableFormatting, changes: SimpleChanges) {
    if (changes.format && !changes.format.isFirstChange()) {
      return (
        changes.format.currentValue.flatTable.general[formatOption] !==
        changes.format.previousValue?.flatTable.general[formatOption]
      );
    }
    return false;
  }

  private isPropertyLengthChanged(key: string, changes: SimpleChanges): boolean {
    const propertyChange = changes[key];
    let lengthChanged = false;
    if (propertyChange && !propertyChange.isFirstChange()) {
      lengthChanged = propertyChange.currentValue?.length !== propertyChange.previousValue?.length;
    }
    return lengthChanged;
  }

  /**
   * Offset related helpers
   */
  get prevOffset() {
    return PagingHelpers.prevOffset(this.paging);
  }

  get nextOffset() {
    return PagingHelpers.nextOffset(this.paging);
  }

  get lastOffset() {
    return PagingHelpers.lastOffset(this.paging);
  }

  get pageRangeEnd() {
    return PagingHelpers.pageRangeEnd(this.paging);
  }

  get usesPagination() {
    return PagingHelpers.usesPagination(this.paging);
  }

  get lastPage() {
    return PagingHelpers.lastPage(this.paging);
  }

  get currentPage() {
    return PagingHelpers.getPageFromOffsetStart(this.paging, this.paging.offset);
  }

  private getPageFromOffsetStart(offsetStart: number) {
    return PagingHelpers.getPageFromOffsetStart(this.paging, offsetStart);
  }

  /**
   * Get current max range value whether or not paging is used.
   */
  get rowHeaderWidth() {
    const currentMaxRangeValue = this.paging ? this.pageRangeEnd : this.content.length;

    let width = 27;
    if (currentMaxRangeValue > 999999) {
      width = 82;
    } else if (currentMaxRangeValue > 9999) {
      width = 57;
    } else if (currentMaxRangeValue > 999) {
      width = 42;
    }

    // Add extra cell padding width to account for the possible change in density setting
    return (width += 2 * getCellPaddingFromDensity(this.flatTableFormat.general.density));
  }

  private getOffsetStartFromPage(pageNumber) {
    return PagingHelpers.getOffsetStartFromPage(this.paging, pageNumber);
  }

  /**
   * Ensure the page input cannot get cleared.
   *
   * If the page number input is cleared and triggered, reset to the first page.
   * Also handle additional edge cases if numbers outside of the range are typed
   * directly in, sneaky valid numbers like `e`, or some weird scientific notation
   * edge cases.
   */
  handlePaginationInputChange(goToPageNumber: number) {
    let validatedPageNumber = goToPageNumber;

    // Sanitize and validate the input value appropriately
    if (goToPageNumber === null || goToPageNumber < 1 || isNaN(goToPageNumber)) {
      validatedPageNumber = 1;
    } else if (goToPageNumber > this.lastPage) {
      validatedPageNumber = this.lastPage;
    } else if (!Number.isInteger(goToPageNumber)) {
      validatedPageNumber = Math.floor(goToPageNumber);
    }

    this.pagingControl.setValue(validatedPageNumber);
    this.offsetChange.emit(this.getOffsetStartFromPage(validatedPageNumber));
  }

  /**
   * Handle pagination input blur.
   */
  private handlePaginationBlur(pageNumber: number) {
    if (this.currentPage !== pageNumber) {
      this.handlePaginationInputChange(pageNumber);
    }
  }

  /**
   * Handle pagination keydown.
   */
  private handlePaginationKeydown(keyCode: number, pageNumber: number) {
    if (keyCode === 13 && this.currentPage !== pageNumber) {
      this.handlePaginationInputChange(pageNumber);
    }
  }

  /**
   * Toggle the column search fields.
   *
   * Sets necessary settings and refreshes the header.
   */
  toggleColumnSearch() {
    this.columnSearchOpen = !this.columnSearchOpen;
    // this.flatOptions.floatingFilter = this.columnSearchOpen;
    this.flatOptions.api.refreshHeader();
  }

  /**
   * Initial flat table render.
   *
   * Generates the ag-grid on first render.
   */
  private async renderTable(
    container: HTMLElement,
    columns: FlamingoTypes.RecordsResult['columns'],
    data: FlamingoTypes.RecordsResult['content'],
    flatTableFormat: ChartTypes.ChartFormat['flatTable'],
    fieldFormats: ChartTypes.FieldFormatMap
  ) {
    const [{ Grid }, { LicenseManager }] = await Promise.all([
      import('ag-grid-community'),
      import('ag-grid-enterprise'),
    ]);

    // Initialize ag-Grid license key, this needs to be in the component to handle iframes, and outside of the controller below as well to work as intended
    LicenseManager.setLicenseKey(
      'Using_this_AG_Grid_Enterprise_key_( AG-039710 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Mode Analytics, Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( MODE )_only_for_( 4 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( MODE )_need_to_be_licensed___( MODE )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 12 May 2024 )____[v2]_MTcxNTQ2ODQwMDAwMA==26eead9de74008e021cc179854fe4c9e'
    );

    const dpMap = [];

    this.textWrappingCounter.current = 0;

    this.prepareLegends(columns, flatTableFormat);

    const tableColumns = columns.map((column) => {
      const columnFormat = this.getColumnSettings(column, flatTableFormat);
      const textFormat = this.getColumnTextFormat(column, fieldFormats);
      this.textWrappingCounter.current += textFormat?.textWrappingEnabled ? 1 : 0;
      return this.makeTableColumn(column, columnFormat, textFormat);
    });

    if (this.flatTableFormat.general.rowNumbers) {
      tableColumns.unshift(this.getRowHeaderColDef());
    }
    const preparedData = this.prepareData(data);

    const { CustomHeaderComp, CustomTooltipComp, DisplayCellEditor } = await import('../custom-ag-grid-components');

    this.flatOptions = {
      ...this.flatOptions,
      components: {
        ...this.flatOptions.components,
        customHeaderComponent: CustomHeaderComp,
        customTooltipComponent: CustomTooltipComp,
        displayCellEditor: DisplayCellEditor,
      },
      context: {
        columnStatus: this.columnStatus,
        flatTableFormat: this.flatTableFormat,
        fieldFormats: this.fieldFormats,
        activeTableColumnId: this.activeTableColumnString,
        customConfig: this.customConfig,
        columnAliasMap: this.columns.reduce((acc, curr) => {
          acc.set(curr.alias, curr.name);
          return acc;
        }, new Map<string, any>()),
      },
      headerHeight: getSizeFromDensity(flatTableFormat.general.density),
      rowHeight: getSizeFromDensity(flatTableFormat.general.density),
      autoSizePadding: getCellPaddingFromDensity(flatTableFormat.general.density),
      deltaColumnMode: this.isBuilder,
      domLayout: this.domLayout,
      defaultColDef: {
        // Custom header component as well as custom params to pass to it
        headerComponent: 'customHeaderComponent',
        // Default col width for now, may decide something different
        width: this.defaultColumnWidth,
        minWidth: 90,
        suppressMenu: !this.showColumnMenu,
        menuTabs: ['generalMenuTab'],
      },
      rowData: preparedData,
      columnDefs: tableColumns,
      pagination: false,
      suppressPaginationPanel: true,
      suppressScrollOnNewData: true,
      dataProfile: dpMap,
    };

    // Only check for available overrides when necessary, then add if exist
    if (this.shouldCheckCustomSettings && this.hasCustomTableOptions) {
      this.flatOptions = {
        ...this.flatOptions,
        ...this.customConfig.tableSettings,
      };
    }

    return new Grid(container, this.flatOptions);
  }

  /**
   * Update flat table.
   *
   * Subsequent renders up data/encoding changes on an already existing table.
   */
  private updateTable(
    columns: FlamingoTypes.RecordsResult['columns'],
    data: FlamingoTypes.RecordsResult['content'],
    flatTableFormat: ChartTypes.ChartFormat['flatTable'],
    fieldFormats: ChartTypes.FieldFormatMap,
    resetRowHeights = false,
    updateDataProfiling = false
  ) {
    // Prepare Data - A couple notes on order
    // 1. Update data should be before rearranging columns (if it happens). Otherway around breaks animation.
    // 2. Data update triggers re-rendering, changing the styling render functions /after/ updating the data
    //    won't cause the values to re-render until the next time they would render in the normal lifecycle.
    //    For this reason, we need to actually set the preparedData within the context of column configuring.
    const preparedData = this.prepareData(data);
    // Update context
    this.flatOptions.context.columnStatus = this.columnStatus;
    this.flatOptions.context.flatTableFormat = this.flatTableFormat;
    this.flatOptions.context.fieldFormats = this.fieldFormats;
    this.textWrappingCounter.prev = this.textWrappingCounter.current;
    this.textWrappingCounter.current = 0;
    this.flatOptions.context.columnAliasMap = this.columns.reduce((acc, curr) => {
      acc.set(curr.alias, curr.name);
      return acc;
    }, new Map<string, any>());

    if (columns != null) {
      this.prepareLegends(columns, flatTableFormat);

      const tableColumns = columns.map((column) => {
        const columnFormat = this.getColumnSettings(column, flatTableFormat);
        const textFormat = this.getColumnTextFormat(column, fieldFormats);
        this.textWrappingCounter.current += textFormat?.textWrappingEnabled ? 1 : 0;
        return this.makeTableColumn(column, columnFormat, textFormat, this.dataProfilingInfoColMap);
      });

      if (this.flatTableFormat.general.rowNumbers) {
        tableColumns.unshift(this.getRowHeaderColDef());
      }

      // Set column defs, then set the preparedData
      this.flatOptions.api.setColumnDefs(tableColumns);
      this.flatOptions.api.setRowData(preparedData);

      // Update column definitions (with some added context):
      // Since ag-grid by default doesn't update certain attributes on column definitions update, we are manually
      // resetting the order when previous and new column number is equal. This is useful if we ever expose some
      // of the native ag-grid options to a user consuming a report, like hiding columns, dragging columns, pinning,
      // etc, outside of the scope of the state, but then update state related things like formatting, it won't reset
      // changes made by the user. If this becomes complicated we can potentially switch to the "absolute" model setting
      // `deltaColumnMode=true`, but that will incur complication and loss of functionality elsewhere.
      //
      // So, if the length of these are equal, we can assume a reorder and run moveColumns. This may need to be
      // adjusted in the future if there are new ways to make more complex adjustments to encoding in one go,
      // though running this when it's not actually necessary won't harm anything.

      // Re-arrange if equal count but not in same order. This prevents running twice in very quick succession when format
      // comes back as a change before columns, which for some reason causes a visual rendering issue. Else, reset
      // the state, the ensure the pill is added at the correct location.
      const previousColumnIds = this.flatOptions.columnApi.getColumns().map((col) => col.getId()),
        newColumnIds = tableColumns.map((col) => col.colId);

      if (previousColumnIds.length === newColumnIds.length && !isEqual(previousColumnIds, newColumnIds)) {
        // Reordering pills
        const colIds = tableColumns.map((column) => column.colId);
        this.flatOptions.columnApi.moveColumns(colIds, 0);
      } else if (previousColumnIds.length !== newColumnIds.length) {
        // In this case, a pill was added or removed. This filters out format specific updates. This
        // method affects the display port position, which is non-ideal when changing column settings.
        this.flatOptions.columnApi.resetColumnState();
      }
    } else {
      // If no column changes, we still need to set the prepared data
      this.flatOptions.api.setRowData(preparedData);
    }

    /**
     * Need to refresh header and row heights when changing the related format options
     * Also autoHeight config is used when text wrapping is applied to any of the columns,
     * then in that scenario resetting of rowHeights is not required. But it is required when
     * last text wrapped column is unwrapped. i.e current = 0 && prev > 0
     */
    if ((resetRowHeights || this.textWrappingCounter.prev > 0) && this.textWrappingCounter.current === 0) {
      let rowHeight = getSizeFromDensity(flatTableFormat.general.density);
      if (this.hasCustomTableOption('rowHeight')) {
        rowHeight = this.customConfig.tableSettings.rowHeight;
      }

      (this.flatOptions.headerHeight = getSizeFromDensity(flatTableFormat.general.density)),
        (this.flatOptions.rowHeight = rowHeight),
        this.flatOptions.api.refreshHeader();
      this.flatOptions.api.resetRowHeights();
    }

    if (updateDataProfiling) {
      this.dataProfiling = {};
      this.dataProfilingInfoColMap = {};
      this.prepareColInfoMap();
      this.dataProfilingEventStream.next();
    }
  }

  /**
   * Get the settings for a column.
   *
   * Helper function to guarantee a valid settings object is provided. If the corresponding settings
   * object isn't found for the column, a default will be used by column type.
   */
  private getColumnSettings(
    column: FlamingoTypes.ResultColumn,
    flatTableFormat: ChartTypes.ChartFormat['flatTable']
  ): ChartTypes.FlatTableColumnSettings {
    let columnSettings = flatTableFormat.columns.find((formatCol) => formatCol.id === column.source);
    if (!columnSettings) {
      columnSettings = getDefaultSettingsByColumn(column);
    }

    return columnSettings;
  }

  /**
   * Get the text format for a column.
   *
   * Helper function to guarantee a valid text format is provided. If the corresponding text format
   * object isn't found for the column, a default will be used by column type.
   */
  private getColumnTextFormat(
    column: FlamingoTypes.ResultColumn,
    fieldFormats: ChartTypes.FieldFormatMap
  ): ChartTypes.TextFormat {
    const fieldFormat = fieldFormats[column.source];
    let textFormat: ChartTypes.TextFormat;
    if (!fieldFormat) {
      textFormat = getDefaultTextFormat(column.type.name);
    } else {
      textFormat = fieldFormat.paneFormat[ChartTypes.FieldPaneTypes.Default];
    }

    return textFormat;
  }

  /**
   * Output column definitions expected by the table renderer
   *
   * TODO: we may need to append chart viz token here, or come up with another solution as soemtimes
   * the same component is actually used swapping between sets, this may not be an issue, but will have to test
   */
  private makeTableColumn(
    column: FlamingoTypes.ResultColumn,
    columnFormat: ChartTypes.FlatTableColumnSettings,
    textFormat: ChartTypes.TextFormat,
    dataProfilingMap: DataProfilingTypes.DataProfilingInfoColMap = {}
  ) {
    // Create column types array and set alignment type
    // Do any other column type logic before setting it on the def, the typing of the property makes it difficult afterward
    const columnTypes = [];
    switch (textFormat.horizontalAlignment) {
      case ChartTypes.HorizontalAlignment.Right:
        columnTypes.push('rightAlignedColumn');
        break;
      case ChartTypes.HorizontalAlignment.Center:
        columnTypes.push('centerAlignedColumn');
        break;
    }

    let dataProfileInfoResult = null;
    if (this.flatTableFormat?.general.dataProfilingEnabled && dataProfilingMap?.[column.alias]) {
      if (dataProfilingMap[column.alias].loading) {
        dataProfileInfoResult = {
          loading: true,
        };
      } else {
        dataProfileInfoResult = {
          loading: false,
          data: this.prepareDataProfileInfo(
            column.type.name,
            dataProfilingMap[column.alias],
            Switch.Attrs.detectDimension(column.name, column.type.name) ? 'dimension' : 'measure',
            textFormat
          ),
        };
      }
    }

    // Create initial colDef
    const flatColumn: ColDef = {
      headerName: column.name,
      headerTooltip: column.name,
      tooltipComponent: 'customTooltipComponent',
      field: column.alias,
      colId: column.source,
      suppressMovable: true,
      editable: true,
      type: columnTypes,
      filter: 'customTextFilter',
      floatingFilterComponent: 'customTextFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      autoHeaderHeight: true,
      resizable: this.sizingStyle !== 'sizeToFit' && this.resizableColumns,
      headerClass: (params) => {
        const classes = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const colDef = params.colDef as any;

        // Add class for active column
        if (colDef.colId === this.activeTableColumn) {
          classes.push('ag-active-column-header');
        }

        // Add class if column is filtered, sorted, or searched
        const colStatus = params.context.columnStatus[colDef.colId];
        if (colStatus) {
          if (colStatus.filter && colStatus.filter.isActive) {
            classes.push('ag-column-filtered');
          }
          if (colStatus.sort && colStatus.sort.isActive) {
            const direction = colStatus.sort.isDescending ? 'desc' : 'asc';
            classes.push('ag-column-sorted', `ag-column-sorted-${direction}`);
          }
          if (colStatus.columnSearch && colStatus.columnSearch.isActive) {
            classes.push('ag-column-searched');
          }
        }

        return classes;
      },
      cellClassRules: {
        'ag-active-column': (params: CellClassParams) =>
          this.activeTableColumnString.length > 0 ? params.colDef.colId === this.activeTableColumnString : false,
        'ag-right-aligned-cell': (params: CellClassParams) =>
          params.colDef.type ? params.colDef.type.includes('rightAlignedColumn') : false,
        'ag-center-aligned-cell': (params: CellClassParams) =>
          params.colDef.type ? params.colDef.type.includes('centerAlignedColumn') : false,
      },
      // Override default valueGetter in case the colId has dots in it.
      // Default valueGetter treats the id as a property path
      // Use `field` to access to ensure proper access when columns are aliased as well.
      valueGetter: (params: ValueGetterParams) => params.data[params.colDef.field],
      headerComponentParams: {
        attrType: column.type.name,
        textFormat: textFormat,
        type: Switch.Attrs.detectDimension(column.name, column.type.name) ? 'dimension' : 'measure',
        dataProfileInfo: dataProfileInfoResult,
        dataProfilingEnabled: this.flatTableFormat?.general.dataProfilingEnabled,
      },
      wrapText: textFormat?.textWrappingEnabled,
      autoHeight: textFormat?.textWrappingEnabled,
      wrapHeaderText: textFormat?.textWrappingEnabled,
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          switch (params.event.key) {
            case 'Backspace':
            case 'Delete':
            case 'Clear':
            case 'Cut':
            case 'Paste':
              return true;
            default:
              return false;
          }
        }
        return false;
      },
    };

    // If width is defined for this column and the sizingStyle supports it, add it
    if (columnFormat.width && this.sizingStyle !== 'sizeToFit') {
      flatColumn.width = columnFormat.width;
    }

    // Enable the view all contents editor when appropriate.
    // Use formatter is false for Default so expanded values aren't trimmed.
    if (this.expandableCellContents) {
      flatColumn.cellEditor = 'displayCellEditor';
      flatColumn.cellEditorParams = {
        useFormatter: textFormat.type !== ChartTypes.FormatType.Default,
        container: this.container,
      };
    }

    // Get the appropriate formatter
    flatColumn.valueFormatter = (params: ValueFormatterParams) => {
      if (params.value != null) {
        const formatterFunction = getFormatterByType(textFormat.type);
        return formatterFunction(params.value, textFormat);
      } else {
        return params.value;
      }
    };

    // Get the appropriate cell renderer if not default
    const cellRenderer = this.colHasCustomRenderer(column.name)
      ? this.customConfig.tableColRenderers[column.name]
      : getCellRendererNameByType(textFormat.type);
    if (cellRenderer !== TableCellRenderer.Default) {
      flatColumn.cellRenderer = cellRenderer;
    }

    // If custom style function exists, we'll add the `cellStyle` method that runs the rules
    // Note: this approach will only work if we don't ever use general defaults here, this might
    // end up needing to be adjusted whenever we build conditional formatting into the UI.
    if (this.colHasCustomStyleFunction(column.name)) {
      flatColumn.cellStyle = this.customConfig.tableColStyleFunctions[column.name];
    }

    const legend = this.legendInfoList.find((li) => li.fieldInfo.name === column.name);
    if (!this.conditionalFormattingLoading && legend) {
      const existingCellStyle = flatColumn.cellStyle;
      const existingCellStyleFn: CellStyleFunc<TData> =
        typeof existingCellStyle === 'function' ? existingCellStyle : () => existingCellStyle;
      flatColumn.cellStyle = (cellClassParams: CellClassParams<TData>): CellStyle | null | undefined => {
        const existingStyles = existingCellStyleFn(cellClassParams) || {};
        const newCellStyle: CellStyle = { ...existingStyles };

        const columnFormat = this.getColumnSettings(column, this.flatTableFormat);
        const { textColor, backgroundColor } = this.getCellColorFromLegend(
          legend.legendInst,
          cellClassParams.value,
          columnFormat?.conditionalFormatting
        );
        if (textColor) {
          newCellStyle.color = textColor;
        }
        if (backgroundColor) {
          newCellStyle['background-color'] = backgroundColor;
        }

        return newCellStyle;
      };
    }

    return flatColumn;
  }

  getCellColorFromLegend(
    legendInst: SimpleLegend,
    cellValue: DatumType,
    conditionalFormatting: any
  ): { textColor: string; backgroundColor: string } {
    const color = legendInst.getColor(cellValue);
    let textColor;
    let backgroundColor;

    const { addToText } = conditionalFormatting;
    if (addToText) {
      textColor = color;
    } else {
      backgroundColor = color;
      if (isDarkColor(backgroundColor)) {
        textColor = 'rgb(255, 255, 255)';
      }
    }

    return {
      textColor,
      backgroundColor,
    };
  }

  /**
   * Get the active table column simple name.
   *
   * Helper to get the name of a comlumn from the formula as activeTableColumn
   * is stored as the formula, which is most commonly what is needed.
   */
  private get activeTableColumnString(): string {
    return this.activeTableColumn ? this.activeTableColumn.slice(1, this.activeTableColumn.length - 1) : '';
  }

  /**
   * Get the colDef for the row numbers column.
   */
  private getRowHeaderColDef(): ColDef {
    return {
      headerName: '',
      field: 'ag_grid_row_header',
      colId: 'ag_grid_row_header',
      pinned: 'left',
      width: this.rowHeaderWidth,
      minWidth: 43,
      resizable: false,
      suppressSizeToFit: true,
      suppressMenu: true,
      cellClass: ['ag-right-aligned-cell'],
    };
  }

  /**
   * Prepare data to be used in the grid.
   *
   * Do any transformation that needs to be present in the source data here.
   * There probably shouldn't be much here as we generally don't transform
   * data from the db.
   */
  private prepareData(data: FlamingoTypes.RecordsResult['content']): any[] {
    // this is to account for type inconsistencies, can we do anything about this?
    let newData = data.slice(0);

    if (this.flatTableFormat.general.rowNumbers) {
      let rowNumber = this.paging ? this.paging.offset : 0;
      newData = data.map((datum) => {
        rowNumber++;
        datum['ag_grid_row_header'] = rowNumber;
        return datum;
      });
    }

    return newData;
  }

  private prepareDataProfileInfo(
    attrType: string,
    dataProfileInfo: DataProfilingTypes.DataProfilingMap,
    type: string,
    textFormat: ChartTypes.TextFormat
  ): DataProfileDisplayInfo[] {
    if (type === 'dimension') {
      switch (attrType) {
        case DataType.Name.Timestamp:
        case DataType.Name.DateTime:
          return [
            {
              key: 'MIN',
              value: `${
                dataProfileInfo.min
                  ? getFormatterByType(textFormat.type)(dataProfileInfo.min, {
                      ...textFormat,
                      ...(textFormat.shortcutType ? {} : { dateFormat: 'numeric_date' }),
                    })
                  : 'null'
              }`,
            },
            {
              key: 'MAX',
              value: `${
                dataProfileInfo.max
                  ? getFormatterByType(textFormat.type)(dataProfileInfo.max, {
                      ...textFormat,
                      ...(textFormat.shortcutType ? {} : { dateFormat: 'numeric_date' }),
                    })
                  : 'null'
              }`,
            },
          ];
        case DataType.Name.String:
          return [
            {
              key: 'COUNT DISTINCT',
              value: dataProfileInfo.countd
                ? getFormatterByType(textFormat.type)(dataProfileInfo.countd, textFormat)
                : 'null',
            },
          ];
        case DataType.Name.Boolean:
          return [
            {
              key: 'SUM',
              value: dataProfileInfo.count
                ? getFormatterByType(textFormat.type)(dataProfileInfo.count, textFormat)
                : 'null',
            },
          ];
        default:
          return [
            {
              key: 'COUNT DISTINCT',
              value: dataProfileInfo.countd
                ? getFormatterByType(textFormat.type)(dataProfileInfo.countd, textFormat)
                : 'null',
            },
          ];
      }
    } else {
      const getDefaultTextFormat = (value, textFormat) => {
        if (value % 1 !== 0) {
          return {
            ...textFormat,
            precision: Math.max(2, textFormat.precision || 0),
          };
        }
        return textFormat;
      };

      return [
        {
          key: 'SUM',
          value:
            dataProfileInfo.sum || dataProfileInfo.sum === 0
              ? getFormatterByType(textFormat.type)(
                  dataProfileInfo.sum,
                  getDefaultTextFormat(dataProfileInfo.sum, textFormat)
                )
              : 'null',
        },
        {
          key: 'AVG',
          value:
            dataProfileInfo.avg || dataProfileInfo.avg === 0
              ? getFormatterByType(textFormat.type)(
                  dataProfileInfo.avg,
                  getDefaultTextFormat(dataProfileInfo.avg, textFormat)
                )
              : 'null',
        },
        {
          key: 'MIN',
          value:
            dataProfileInfo.min || dataProfileInfo.min === 0
              ? `${getFormatterByType(textFormat.type)(
                  dataProfileInfo.min,
                  getDefaultTextFormat(dataProfileInfo.min, textFormat)
                )}`
              : 'null',
        },
        {
          key: 'MAX',
          value:
            dataProfileInfo.max || dataProfileInfo.max === 0
              ? `${getFormatterByType(textFormat.type)(
                  dataProfileInfo.max,
                  getDefaultTextFormat(dataProfileInfo.max, textFormat)
                )}`
              : 'null',
        },
      ];
    }
  }
  /**
   * Helper to set the selected column.
   */
  private updateColumnSelection(column: string) {
    if (this.activeTableColumn !== column) {
      this.updateColumnSelectionSettings(column);
      this.columnSelectionChange.emit(column);
    }
  }

  private updateColumnSelectionSettings(column: string) {
    if (this.activeTableColumn !== column) {
      this.flatOptions.context.activeTableColumnId = column;
      this.activeTableColumn = column;
      this.flatOptions.api.refreshHeader();
    }
  }

  private isActiveColumnPresent() {
    return this.columns.some((col) => col.source === this.activeTableColumn);
  }

  /**
   * Helper to re-set the selected column.
   */
  private resetColumnSelection() {
    this.columnSelectionChange.emit('');
  }

  /**
   * Helper to update col width.
   */
  private updateColumnWidth(newWidth: number, columnFormula: string) {
    this.columnWidthChange.emit({ newWidth, formula: columnFormula });
  }

  /**
   * Trigger an event that signals a desire to edit field formats.
   */
  private triggerAdvancedFormattingEdit(column) {
    this.fieldFormatEdit.emit(column);
  }

  private triggerConditionalFormatting(column) {
    this.conditionalFormattingApply.emit(column);
  }

  /**
   * Handle the filter updates
   *
   * Pass columnSearchModel up to be processed.
   */
  handleColumnSearchEvent(columnSearchModel: ChartTypes.TableColumnSearch['model']) {
    this.setColumnStatus();
    this.flatOptions.context.columnStatus = this.columnStatus;
    this.flatOptions.api.refreshHeader();

    // Filter out empty items and add the colType to each item in the model.
    const validKeys = Object.keys(columnSearchModel).filter(
      (key) => columnSearchModel[key] && columnSearchModel[key].value.length > 0
    );
    const filteredModel = {};
    validKeys.forEach((key) => {
      filteredModel[key] = {
        value: columnSearchModel[key].value,
        colType: this.flatTableFormat.columns.find((col) => col.id === key).colType, // TODO: update/confirm this when coming back to column search
      };
    });

    this.tableColumnSearchChange.emit(filteredModel);
  }

  /**
   * Helper to run the table teardown.
   *
   * Since there are some cases where the same component actually serves multiple different instances
   * of tables, like SQL results with multiple queries, or multiple tables in a single query,
   * we need to properly teardown and re-hide the table, then re-show after a new table has
   * been built. Typically this wouldn't be necessary, though, since those multipurpose components
   * are depending on multiple different selectors, it's not easy to transition between those states
   * consistently.
   */
  private teardownTable() {
    this.currentTable.destroy();
    this.currentTable = null;
    this.tableFinishedLoading = false;
    this.renderStart.emit();
  }

  /**
   * Create a column status object from encoding.
   *
   * Track filters, column search, and sorts by column in order to pass to context
   * when initializing the grid so the grid has easy access to this
   * information.
   */
  private setColumnStatus() {
    if (this.encoding) {
      // Get filterse, sorts, and column search values
      const filters = this.encoding.filter;
      const sorts = this.encoding.sorts;
      const columnSearchFilters = this.currentTable && this.columnSearchModel ? this.columnSearchModel : {};

      // Generate all of the current colIds to make iteration easier, then populate with all relevant values.
      const columns = {};
      const columnKeys = this.encoding.marks.properties.text.values.map((textEnc) => textEnc.formula);
      columnKeys.forEach((colKey) => (columns[colKey] = { filter: null, sort: null, columnSearch: null }));

      Object.keys(columnSearchFilters).forEach((columnName) => {
        const filterModel = columnSearchFilters[columnName];
        if (filterModel.value) {
          columns[columnName].columnSearch = { isActive: true };
        }
      });

      // Filters still need to check for the key, as you can have a filter on a column
      // not actually included in the table
      filters.forEach((filter) => {
        const colId = filter.formula;
        if (columns.hasOwnProperty(colId)) {
          columns[colId].filter = { isActive: true };
        } else {
          columns[colId] = { filter: { isActive: true } };
        }
      });

      sorts.forEach((sort) => {
        const colId = sort.source;
        columns[colId].sort = { isActive: true, isDescending: sort.descending };
      });

      this.columnStatus = columns;
    } else {
      this.columnStatus = {};
    }
  }

  protected colorSchemeCssClass() {
    const colorScheme = this.flatTableFormat?.general.colorScheme;
    return FlatTableColorScheme.getCssClass(colorScheme);
  }

  private createSortSettingsTooltip(clearTooltip = false) {
    if (this.encoding.sorts.length && !clearTooltip) {
      this.sortSettingsTooltipContent = `<div style='text-align:left'><span>Table sort order:</span><br/>${this.encoding.sorts
        .map((sort, index) => {
          return `<span>&nbsp;${index + 1}.&nbsp;${sort.source.slice(1, sort.source.length - 1)}&nbsp;(${
            sort.descending ? 'descending' : 'ascending'
          })</span><br/>`;
        })
        .join('')}</div>`;
    } else {
      this.sortSettingsTooltipContent = '';
    }
  }

  handlePageChange(isBlur: boolean, keyCode: number) {
    if ((isBlur || keyCode === 13) && this.pageSizeControl.value !== this.flatTableFormat.general.pageSize) {
      let validPageSize = this.pageSizeControl.value;
      if (this.pageSizeControl.value < 1) {
        validPageSize = 1;
      } else if (this.pageSizeControl.value > 1000) {
        validPageSize = 1000;
      }
      this.changePageSize.emit({
        pageSize: validPageSize,
        isUndoAction: false,
      });
    }
  }

  createFieldLegendInst(fieldInfo: LegendFieldInfo): SimpleLegend {
    const isDimension = Switch.Attrs.detectDimension(fieldInfo.name, fieldInfo.type.name);
    const legendDomain = this.getFieldDomain(fieldInfo);

    const legendFieldInfo: LegendFieldInfo = {
      name: fieldInfo.name,
      color: fieldInfo.color,
      type: isDimension ? FieldType.Dimension : FieldType.Measure,
      dataType: fieldInfo.type,
    };

    const legendConfig: ChartTypes.FlatTableLegendConfig = {
      range: fieldInfo.color.palette,
      domainRangeMap: {},
    };

    const legendInst: SimpleLegend = new GradientLegend(legendDomain, legendFieldInfo, legendConfig);
    return legendInst;
  }

  getFieldDomain(fieldInfo: LegendFieldInfo): LegendDomain {
    const info = this.columnsWithConditionalFormatting?.get(fieldInfo.name);
    const paletteType = info.color.gradient.gradientType;

    if (paletteType === ChartTypes.GradientType.DIVERGING) {
      return [info.min, (info.min + info.max) / 2, info.max];
    } else if (paletteType === ChartTypes.GradientType.SEQUENTIAL) {
      return [info.min, info.max];
    }

    return [];
  }

  prepareLegends(
    columns: FlamingoTypes.RecordsResult['columns'],
    flatTableFormat: ChartTypes.ChartFormat['flatTable']
  ) {
    if (columns != null && this.conditionalFormattingEnabled && !this.conditionalFormattingLoading) {
      const legendInstList = [];
      columns.forEach((column) => {
        const columnFormat = this.getColumnSettings(column, flatTableFormat);
        if (columnFormat.conditionalFormatting?.isEnabled) {
          const columnInfo = {
            ...column,
            color: columnFormat.conditionalFormatting?.color,
          };
          const legendInst = this.createFieldLegendInst(columnInfo);
          legendInstList.push(legendInst);
        }
      });

      this.legendsContainerInfo = this.prepareLegendsContainerInfo(legendInstList);
      this.legendInfoList = this.prepareLegendInfoList(legendInstList);
    }
  }

  prepareLegendsContainerInfo(legendInstList: SimpleLegend[]): LegendsContainerInfo {
    const info: LegendsContainerInfo = {
      container: null,
      width: 0,
      height: 0,
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
    };

    const hostElem = this.nativeElement;
    if (!hostElem) {
      return info;
    }

    const legendsContainerElem = hostElem.querySelector('.flat-table-legends-container');
    if (!legendsContainerElem) {
      return info;
    }
    info.container = legendsContainerElem;

    if (!legendInstList.length) {
      return info;
    }

    info.padding.left = FLAT_TABLE_LEGENDS_CONTAINER_DEFAULT_LEFT_PADDING;
    info.padding.right = FLAT_TABLE_LEGENDS_CONTAINER_DEFAULT_RIGHT_PADDING;

    const maxLegendWidth = legendInstList.reduce((acc, curr) => {
      return Math.max(curr.measurements().width, acc);
    }, 0);
    const maxLegendWidthWithPadding = maxLegendWidth + info.padding.left + info.padding.right;

    const { width: hostWidth, height: hostHeight } = hostElem.getBoundingClientRect();
    info.width = Math.min(maxLegendWidthWithPadding, hostWidth * FLAT_TABLE_LEGENDS_CONTAINER_MAX_WIDTH_PERC);
    info.height = hostHeight;

    return info;
  }

  prepareLegendInfoList(legendInstList: SimpleLegend[]): LegendInfo[] {
    if (!legendInstList.length) {
      return [];
    }

    const { width: containerWidth, height: containerHeight, padding } = this.legendsContainerInfo;
    const availableWidth = containerWidth - padding.left - padding.right; // @TODO: Subtract the scrollbar width(if any).
    const availableHeight = containerHeight - padding.top - padding.bottom;

    const gradientLegendDimens: { legend: SimpleLegend; measurements: LegendMeasurements }[] = [];
    legendInstList.forEach((legend) => {
      const dimen = {
        legend,
        measurements: legend.measurements(),
      };
      if (legend instanceof GradientLegend) {
        gradientLegendDimens.push(dimen);
      }
    });

    const allLegendDimens = [...gradientLegendDimens];

    let remainingHeight = availableHeight;
    let remainingLegendsLen = allLegendDimens.length;
    const legendInfoList = allLegendDimens.map((legendDimen) => {
      const { height: actualHeight } = legendDimen.measurements;
      let finalizedHeight = 0;

      // For GradientLegend.
      finalizedHeight = actualHeight;

      remainingHeight -= finalizedHeight;
      remainingLegendsLen--;

      const fieldInfo = legendDimen.legend.fieldInfo();
      const legendInfo: LegendInfo = {
        id: legendDimen.legend.id(),
        fieldInfo,
        legendInst: legendDimen.legend,
        width: availableWidth,
        height: finalizedHeight,
      };
      return legendInfo;
    });

    return legendInfoList;
  }

  legendTrackBy(index: number, item: LegendInfo): string {
    return item.id;
  }

  getLegendInfoMap(): Map<string, LegendInfo> {
    return this.legendInfoList.reduce<Map<string, LegendInfo>>((acc, curr) => {
      acc.set(curr.fieldInfo.name, curr);
      return acc;
    }, new Map());
  }
}
