<div class="title" mat-dialog-title>
  <mode-dialog-header>
    <h1 heading>Give feedback on AI Assist</h1>
  </mode-dialog-header>
</div>
<section class="body">
  <form class="section email-form" [formGroup]="feedbackForm">
    <div class="form-field">
      <label class="mode-label" for="message"> Message </label>
      <textarea
        class="mode-textarea"
        [class.error-max-len]="messageControl.errors?.['maxlength']"
        id="message"
        formControlName="message"
        maxlength="500"
        placeholder="Describe what went wrong and what you were expecting"
      ></textarea>
      <div *ngIf="messageControl.errors?.['maxlength']" class="error-msg">
        <span>Feedback must be between 1-500 characters</span>
      </div>
    </div>
  </form>
</section>
<div mat-dialog-actions>
  <capra-button
    class="cancel-btn"
    text="Cancel"
    data-test="cancel-feedback"
    variant="secondary"
    color="neutral"
    (clicked)="close()"
  ></capra-button>
  <capra-button
    text="Send"
    data-test="send-feedback"
    [disabled]="!feedbackForm.valid"
    (clicked)="submitFeedback()"
  ></capra-button>
</div>
