import { Action, createReducer, on } from '@ngrx/store';

import * as EnvironmentActions from './environment.actions';
import { EnvironmentResponse } from './environment.models';

export const ENVIRONMENT_FEATURE_KEY = 'environment';

export interface EnvironmentState {
  environment?: EnvironmentResponse; // which Environment record has been selected
  loaded: boolean; // has the Environment list been loaded
  error?: string | null; // last known error (if any)
}

export interface EnvironmentPartialState {
  readonly [ENVIRONMENT_FEATURE_KEY]: EnvironmentState;
}

export const initialEnvironmentState: EnvironmentState = {
  // set initial required properties
  loaded: false,
};

const reducer = createReducer(
  initialEnvironmentState,
  on(EnvironmentActions.initEnvironment, (state) => ({ ...state, loaded: false, error: null })),
  on(EnvironmentActions.loadEnvironmentSuccess, (state, { environment }) => ({ ...state, environment, loaded: true })),
  on(EnvironmentActions.loadEnvironmentFailure, (state, { error }) => ({ ...state, error }))
);

export function environmentReducer(state: EnvironmentState | undefined, action: Action) {
  return reducer(state, action);
}
