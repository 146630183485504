<div class="capra-toast capra-toast--{{ type }}"
  [class.pointer]="canTapToDismiss"
>
  <capra-status-icon
    *ngIf="!hideIcon"
    class="icon"
    [class.inline]="inline"
    [status]="type"
  ></capra-status-icon>

  <div class="content" data-test="toast-content">
    <ng-content></ng-content>
  </div>

  <div *ngIf="showCloseBtn"
    class="close-btn-container"
    [class.inline]="inline"
  >
    <capra-icon class="close-btn"
      icon="icon-close"
      (click)="dismissToast.emit()"
    ></capra-icon>
  </div>
</div>
