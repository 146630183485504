import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LegendInfo } from '../types';

@Component({
  standalone: true,
  selector: 'mode-flat-table-simple-legend',
  imports: [],
  templateUrl: './simple-legend.component.html',
  styleUrls: ['./simple-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatTableSimpleLegendComponent implements OnInit {
  @Input() legendInfo!: LegendInfo;

  title!: string;
  titleBodyDistance!: number;

  ngOnInit(): void {
    const { legendInst } = this.legendInfo;

    this.title = legendInst.getTitle();
    this.titleBodyDistance = legendInst.config().title.titleBodyDistance;
  }
}
