import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, HostListener, Input } from '@angular/core';
import * as Calendly from 'calendly';

@Component({
  standalone: true,
  selector: 'mode-calendly-popup-button',
  imports: [CommonModule],
  templateUrl: './calendly-popup-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendlyPopupButtonComponent {
  @Input()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  userDisplayName: string;

  @Input()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  userEmail: string;

  @Input()
  schedulerUrl = 'https://calendly.com/mode/free-trial-conclusion?primary_color=37b067';

  @HostListener('click') openCalendly() {
    let prefill;

    if (this.userDisplayName || this.userEmail) {
      prefill = {
        name: this.userDisplayName,
        email: this.userEmail,
      };
    } else {
      prefill = undefined;
    }

    // TODO: figure out why Jest won't see Calendly implicitly typed as any
    (Calendly as any).initPopupWidget({
      url: this.schedulerUrl,
      prefill,
    });
  }
}
