import { Injectable } from '@angular/core';
import { FeedbackDialogService, QueryViewerMetadata } from '@mode/queries/ai-assist/util';
import { DialogService } from '@mode/shared/ui';
import { SubmitFeedbackDialogComponent } from './submit-feedback-dialog.component';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { SubmitFeedbackFacade } from '@mode/queries/ai-assist/domain';
import { Subscription } from 'rxjs';

@Injectable()
export class SubmitFeedbackDialogService extends FeedbackDialogService {
  constructor(private dialogService: DialogService, private submitFeedbackFacade: SubmitFeedbackFacade) {
    super();
  }

  public override open(metadata: QueryViewerMetadata) {
    const currentDialog = this.dialogService.open({
      component: SubmitFeedbackDialogComponent,
    });

    this.listenToDialogEvent(currentDialog, metadata);
  }

  private listenToDialogEvent(
    currentDialog: MatLegacyDialogRef<SubmitFeedbackDialogComponent>,
    metadata: QueryViewerMetadata
  ) {
    const feedbackSubscription = currentDialog.componentInstance.sendFeedback.subscribe((feedback) => {
      this.sendFeedback(feedback, metadata, feedbackSubscription);
    });
  }

  private sendFeedback(feedback: string, metadata: QueryViewerMetadata, feedbackSubscription: Subscription): void {
    this.submitFeedbackFacade.dispatch(
      SubmitFeedbackFacade.Actions.sendFeedback({
        queryToken: metadata.queryToken,
        metadata,
        feedback,
      })
    );
    feedbackSubscription.unsubscribe();
  }
}
