export enum AvatarType {
  Uploaded = 'uploaded',
  Initials = 'initials',
  Gravatar = 'gravatar',
  Community = 'community',
  Placeholder = 'placeholder',
}

export interface Avatar {
  type: AvatarType;
  href?: string;
  seed?: string;
  initials?: string;
  color_class?: string;
}

export interface ReportContextAvatar {
  type: AvatarType;
  colorClass: string;
  initials: string;
  name: string;
  imageSrc: string;
  targetUrl: string;
}

export interface AvatarInitials {
  type: AvatarType.Initials;
  colorClass: string;
  color_class: string; // For legacy compatibility
  initials: string;
  seed: string;
}

export interface AvatarUploaded {
  type: AvatarType.Uploaded;
  href: string;
}

export interface AvatarGravatar {
  type: AvatarType.Gravatar;
  href: string;
}

export interface AvatarPlaceholder {
  type: AvatarType.Placeholder;
}

export type Avatars = AvatarInitials | AvatarUploaded | AvatarPlaceholder | AvatarGravatar;
