import { Injectable } from '@angular/core';

import { CapraIcon } from './capra-icons';

@Injectable({
  providedIn: 'root',
})
export class CapraIconsRegistry {
  private _registry = new Map<string, string>();

  registerIcons(icons: CapraIcon[]): void {
    icons.forEach((icon: CapraIcon) => this._registry.set(icon.name, icon.data));
  }

  getIcon(iconName: string): string | undefined {
    if (!this._registry.has(iconName)) {
      console.error(
        `The Icon with the name ${iconName} could not be found. It is likely missing from the Icon registry.`
      );
      return;
    }
    return this._registry.get(iconName);
  }
}
