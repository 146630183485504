import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'mode-table-loading',
  imports: [CommonModule],
  templateUrl: './table-loading.component.html',
  styleUrls: ['./table-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableLoadingComponent {
  // bindings
  @Input() withAnimation = true;

  // local props
  fakeRows = new Array(30);
}
