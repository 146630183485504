import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconComponent } from '../icon/icon.component';
import { capraIcon } from '../capra-icons';

export type Status = 'error' | 'info' | 'outdated' | 'processing' | 'prohibited' | 'success' | 'update' | 'warning';

type IconName = Extract<
  | 'icon-check-large'
  | 'icon-clock'
  | 'icon-close'
  | 'icon-info-outline'
  | 'icon-prohibited'
  | 'icon-refresh'
  | 'icon-update'
  | 'icon-warning',
  capraIcon
>;

@Component({
  standalone: true,
  selector: 'capra-status-icon',
  imports: [IconComponent],
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIconComponent {
  @Input({ required: true }) status: Status = 'success';

  readonly size = 16;
  readonly iconNameRecord: Readonly<Record<Status, IconName>> = {
    error: 'icon-close',
    info: 'icon-info-outline',
    outdated: 'icon-clock',
    processing: 'icon-refresh',
    prohibited: 'icon-prohibited',
    success: 'icon-check-large',
    update: 'icon-update',
    warning: 'icon-warning',
  };
}
