import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentResponse } from './+state/environment.models';

@Injectable({ providedIn: 'root' })
export class EnvironmentClientService {
  getEnvironment() {
    return this.http.get<EnvironmentResponse>('/environment.json');
  }
  constructor(private http: HttpClient) {}
}
