import { Pipe, PipeTransform } from '@angular/core';

import { formatTimeAgo, FormatTimeAgoStyle } from '@mode/shared/util-js';
@Pipe({
  standalone: true,
  name: 'timeAgo',
})
/*eslint-disable-next-line @angular-eslint/use-pipe-transform-interface*/
export class TimeAgoPipe implements PipeTransform {
  public transform(
    date: string | number | Date,
    { format }: { format: FormatTimeAgoStyle } = { format: 'lowercase' }
  ): string {
    return formatTimeAgo(date, { format });
  }
}
