import { inject, Injectable } from '@angular/core';
import { Route, UrlHandlingStrategy, UrlTree } from '@angular/router';
import { EmptyComponent } from '@mode/shared/ui';
import { getPerformanceReport } from '@mode/shared/util-dom';
import { FeatureFlag, FeatureFlagsFacade } from '@mode/shared/contract-common';
import { getInitialFlagValue } from '@mode/shared/util-dom';

@Injectable()
export class SxSRouteHandlingStrategy implements UrlHandlingStrategy {
  shouldProcessUrl(url: UrlTree) {
    /**
     * For now, we will start with an allow-list for Angular subroutes.
     * New routes should return true, and transitioning routes should return
     * based on a flag.
     *
     * If you need to read Angular route information from a ui-router managed
     * route then you can have it return true here as well. That route just has
     * to render to EmptyComponent. However, this will likely make transitioning
     * the route difficult because turning off routing based on a flag will also
     * prevent reading the route via Angular.
     */

    if (document.querySelector('[mode-app="mode.dataset-404"]')) {
      /*
       *  If we get a 404 at /:org/datasets/:datasetToken we want to use the
       *    existing backend generated template (404.html.erb).
       *
       *  This gives us some design consistency until we want to migrate
       *    that 404 - Not Found page to a new Angular route.
       */
      return false;
    }

    if (/^(?!\/editor).*\/datasets\/\w+/.test(url.toString())) {
      return true;
    }

    // Load performance report in angular if set by the backend.
    // Only loaded for report view routes, not 'embed' or 'details' sub views
    if (
      getInitialFlagValue(FeatureFlag.FastReportView) &&
      getPerformanceReport(document) &&
      /^(?!\/editor).*\/reports\/\w+/.test(url.toString()) &&
      !url.toString().includes('/embed') &&
      !url.toString().includes('/details')
    ) {
      return true;
    }

    // Will enable this once we do db connection migration
    // if (getInitialFlagValue(FeatureFlag.AngularSignupRevamp) && /^.+\/data_sources\/select/.test(url.toString())) {
    //   return true;
    // }

    // Enable for workspace settings
    if (/^\/organizations.*/.test(url.toString())) {
      return true;
    }
    if (getInitialFlagValue(FeatureFlag.AngularSignupRevamp) && /^\/signup.*/.test(url.toString())) {
      return true;
    }

    return false;
  }
  extract(url: UrlTree) {
    return url;
  }
  merge(url: UrlTree, newUrl: UrlTree) {
    return url;
  }
}

export const AppRoutes: Array<Route> = [
  /*
  First try any routes with a static path at the beginning.
  These can be matched easily and unambiguously
  */
  {
    path: 'editor',
    // @mode/charts/feature-encoding includes angularjs code
    // eslint-disable-next-line @nx/enforce-module-boundaries
    loadChildren: () => import('@mode/report-editor/shell').then((m) => m.ReportEditorShellModule),
  },

  {
    path: 'organizations/new',
    loadChildren: () => import('@mode/workspace/new/shell').then((m) => m.WorkspaceNewShellModule),
    canMatch: [() => inject(FeatureFlagsFacade).asObservable(FeatureFlag.AngularSignupRevamp)],
  },

  {
    path: 'organizations/:workspace',
    // @mode/workspace-settings/shell-angularjs includes angular js code
    // eslint-disable-next-line @nx/enforce-module-boundaries
    loadChildren: () => import('@mode/workspace-settings/shell-angularjs').then((m) => m.WorkspaceSettingsShellModule),
  },

  // Will enable this once we do db connection migration
  // {
  //   path: ':orgName/data_sources/select',
  //   loadChildren: () => import('@mode/datasources/shell').then((m) => m.DatasourcesShellModule),
  //   canMatch: [() => inject(FeatureFlagsFacade).asObservable(FeatureFlag.AngularSignupRevamp)],
  // },

  {
    path: ':orgName/datasets',
    loadChildren: () => import('@mode/dataset/shell').then((m) => m.DatasetShellModule),
  },

  {
    path: ':orgName/reports',
    loadChildren: () => import('@mode/report-viewer/shell').then((m) => m.ReportViewerShellModule),
  },

  {
    path: 'signup',
    loadChildren: () => import('@mode/signup/shell-signup').then((m) => m.ShellSignupModule),
    canMatch: [() => inject(FeatureFlagsFacade).asObservable(FeatureFlag.AngularSignupRevamp)],
  },

  /*
  This is the catch-all that basically will clear the router outlet, which
  should let ui-router show its component.

  Note that we do not have a "page not found" component. Only one router can
  handle that, and currently it's ui-router. However, it is possible in theory for
  shell modules to route to a 404 page in their own subroute configuration.
  */
  {
    //TODO: Define all the remaining routes instead of using a catch-all
    path: '**',
    component: EmptyComponent,
  },
];
