import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatLegacyDialogModule, MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import {
  IconButtonComponent,
  ButtonComponent,
  IconComponent,
  TooltipDirective,
  TooltipPlacements,
  TooltipSizes,
} from '@mode/capra';
import { DialogHeaderComponent } from '../dialog/header/dialog-header.component';
import { CommonModule } from '@angular/common';
import { LabeledMenuComponent, MenuItem } from '../labeled-menu/labeled-menu.component';
import { SortSettingsTypes } from '@mode/shared/contract-common';

// Local type that can accommodate an incomplete configuration.
interface PartialSort {
  descending: boolean;
  selected?: SortSettingsTypes.Column;
  highlighted: boolean;
}
@Component({
  standalone: true,
  selector: 'mode-sort-settings-dialog',
  templateUrl: './sort-settings-dialog.component.html',
  imports: [
    ButtonComponent,
    CommonModule,
    DialogHeaderComponent,
    DragDropModule,
    IconComponent,
    MatLegacyDialogModule,
    LabeledMenuComponent,
    TooltipDirective,
    IconButtonComponent,
  ],
  styleUrls: ['./sort-settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortSettingsDialogComponent implements OnInit {
  @Output() apply = new EventEmitter<SortSettingsTypes.Sort[]>();
  @Output() cancel = new EventEmitter<void>();
  isApplyDisabled = false;
  isChanged = false;

  sortsMap: Record<string, boolean> = {};

  // Local working copy of `Sort`s can be `PartialSort`s to accommodate `Column` selection.
  sorts: PartialSort[] = [];
  unsortedColumns: SortSettingsTypes.Column[] = [];

  readonly timestampTooltipSize = TooltipSizes.Mini;
  readonly timestampTooltipPlacement = TooltipPlacements.Bottom;

  constructor(
    private dialogRef: MatLegacyDialogRef<SortSettingsDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: SortSettingsTypes.SortSettingsDialogFormattedData
  ) {}

  ngOnInit(): void {
    this.sorts = this.data.sorts.map((sort) => {
      this.sortsMap[sort.selected.value] = true;
      return {
        ...sort,
      };
    });
    if (!this.sorts.length) {
      this.sorts.push({
        descending: true,
        highlighted: false,
      });
    }
    this.setUnsortedColumns();
    this.checkApplyValidity();
  }

  onChangeSortField(event: MenuItem, index: number) {
    const removedValue = this.sorts[index].selected?.value;
    if (removedValue) {
      this.sortsMap[removedValue] = false;
    }
    this.sorts[index].selected = event as SortSettingsTypes.Column;
    this.sortsMap[(event as SortSettingsTypes.Column).value] = true;
    this.isChanged = true;
    this.setUnsortedColumns();
    this.checkApplyValidity();
  }

  addMoreSorts() {
    this.sorts.push({
      descending: true,
      highlighted: false,
    });
    this.isChanged = true;
    this.checkApplyValidity();
  }

  removeSort(index: number) {
    const removedValue = this.sorts[index].selected?.value;
    if (removedValue) {
      this.sortsMap[removedValue] = false;
    }
    this.sorts.splice(index, 1);
    this.setUnsortedColumns();
    this.checkApplyValidity();
  }

  onCancel() {
    this.cancel.emit();
    this.dialogRef.close();
  }

  onApply() {
    this.apply.emit(
      this.sorts.filter((sort): sort is SortSettingsTypes.Sort => {
        return sort.selected != null;
      })
    );
    this.dialogRef.close();
  }

  setDescending(descending: boolean, index: number) {
    this.sorts[index].descending = descending;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sorts, event.previousIndex, event.currentIndex);
  }

  checkApplyValidity() {
    this.isApplyDisabled = this.sorts.filter((sort) => !sort.selected).length > 0;
  }

  setUnsortedColumns() {
    this.unsortedColumns = this.data.allColumns.filter((col) => !this.sortsMap[col.value]);
  }
}
