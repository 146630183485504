import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';

import { IconButtonComponent } from '@mode/capra';

@Component({
  standalone: true,
  selector: 'mode-dialog-header',
  imports: [CommonModule, IconButtonComponent, MatLegacyDialogModule],
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @HostBinding('class') classes = 'mode-dialog-header';

  @Input()
  hideClose = false;
}
