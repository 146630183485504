<button
  type="button"
  #menuButton
  [matMenuTriggerFor]="fieldOptionsMenu"
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"
  class="form-control"
  data-test="multiselect-menu-trigger"
>
  <span class="description">{{ descriptionOfSelectedItems$ | async }}</span>
  <capra-icon class="caret-icon" icon="icon-caret-down"></capra-icon>
</button>
<!-- DO NOT REMOVE .select-with-search-dialog. Classes on `mat-menu`s are mirrored in a modal dialog container children element. -->
<mat-menu #fieldOptionsMenu class="param-multi select-with-search-dialog" yPosition="below">
  <input
    matInput
    autocomplete="off"
    placeholder="Find..."
    aria-label="Search input"
    [formControl]="searchOptionsControl"
    (click)="$event.stopPropagation()"
    data-test="select-with-search-input"
    class="search-input"
    #search />

  <ng-container *ngIf="(visibleOptions$ | async) || []; let visibleOptions">
    <div class="header" *ngIf="fieldType === 'multiselect'">
      <mat-checkbox
        (change)="toggleSelectAll($event.checked, visibleOptions)"
        (click)="$event.stopPropagation()"
        [checked]="allOptionsAreSelected$ | async"
        [indeterminate]="(allOptionsAreSelected$ | async) === false && someOptionsAreSelected$ | async"
      >
      {{ selectAllText$ | async }}
      </mat-checkbox>
    </div>

    <mat-selection-list
      (click)="$event.stopPropagation()"
      (selectionChange)="optionSelected($event)"
      [style.min-width.px]="menuButton.clientWidth"
      [multiple]="fieldType === 'multiselect' ? 'true' : 'false'"
      [attr.data-select-type]="fieldType">
      <mat-list-option
        *ngFor="let option of visibleOptions; trackBy: trackOption"
        [value]="option.value"
        [selected]="option.isSelected"
        [checkboxPosition]="before"
        [title]="option.label"
        data-test="multiselect-option">
        {{ option.label }}
      </mat-list-option>
    </mat-selection-list>
  </ng-container>

  <div class="message" *ngIf="defaultOptions.length > maxItems">
    <div class="message-heading">Shows the first {{ maxItems }} values.</div>
    <div>Use search to access values that aren't displayed</div>
  </div>
</mat-menu>