import { QueryViewerMetadata } from '@mode/queries/ai-assist/util';
import { createAction, props } from '@ngrx/store';

export const generateSQL = createAction(
  '[AI Assist] Generate SQL',
  props<{ queryToken: string; rawQueryText: string; metadata: QueryViewerMetadata }>()
);
export const generateSQLSuccess = createAction(
  '[AI Assist] Generate SQL Success',
  props<{ queryToken: string; generatedSQL: string; requestId: string }>()
);
export const generateSQLFailure = createAction(
  '[AI Assist] Generate SQL Failure',
  props<{ queryToken: string; errorMsg: string; requestId: string }>()
);
export const submitPositiveFeedback = createAction(
  '[AI Assist] Submit Positive Feedback',
  props<{
    queryToken: string;
    requestId: string;
    metadata: QueryViewerMetadata;
  }>()
);
export const submitPositiveFeedbackSuccess = createAction(
  '[AI Assist] Submit Positive Feedback Success',
  props<{ queryToken: string }>()
);
export const submitPositiveFeedbackFailure = createAction(
  '[AI Assist] Submit Positive Feedback Failure',
  props<{ queryToken: string }>()
);
export const submitNegativeFeedback = createAction(
  '[AI Assist] Submit Negative Feedback',
  props<{
    queryToken: string;
    requestId: string;
    message?: string;
    metadata: QueryViewerMetadata;
  }>()
);
export const submitNegativeFeedbackSuccess = createAction(
  '[AI Assist] Submit Negative Feedback Success',
  props<{ queryToken: string; metadata: QueryViewerMetadata }>()
);
export const submitNegativeFeedbackFailure = createAction(
  '[AI Assist] Submit Negative Feedback Failure',
  props<{ queryToken: string }>()
);
export const discardGeneration = createAction('[AI Assist] Discard Generation', props<{ queryToken: string }>());
export const giveFeedback = createAction(
  '[AI Assist] Give Feedback',
  props<{ queryToken: string; metadata: QueryViewerMetadata }>()
);
export const sendFeedback = createAction(
  '[AI Assist] Send Feedback',
  props<{ queryToken: string; metadata: QueryViewerMetadata; feedback: string }>()
);
export const sendFeedbackSuccess = createAction('[AI Assist] Send Feedback Success', props<{ queryToken: string }>());
export const sendFeedbackFailure = createAction('[AI Assist] Send Feedback Failure', props<{ queryToken: string }>());

export const submitInsertFeedback = createAction(
  '[AI Assist] Submit Insert Feedback',
  props<{ metadata: QueryViewerMetadata }>()
);
