import * as _ from 'lodash';
import { ChartTypes } from '@mode/shared/contract-common';
import { DEFAULT_FLAT_TABLE_LEGEND_CONFIG, genLegendId, calculateTextDimens } from '@mode/shared/util-js';
import { DatumType, LegendData, LegendDomain, LegendFieldInfo, LegendMeasurements, ScaleType } from '../types';

export class SimpleLegend {
  private _domain: LegendDomain;
  private _fieldInfo: LegendFieldInfo;
  private _config: ChartTypes.FlatTableLegendConfig;
  private _measurements: LegendMeasurements;
  private _id: string;
  private _data: LegendData;
  private _scale: ScaleType;

  constructor(domain: LegendDomain, fieldInfo: LegendFieldInfo, config?: ChartTypes.FlatTableLegendConfig) {
    this._domain = domain;
    this._fieldInfo = fieldInfo;
    this._config = _.merge({}, DEFAULT_FLAT_TABLE_LEGEND_CONFIG, config);

    this._id = genLegendId();
    this._scale = this.createScale();
    this._data = this.calculateData();
    this._measurements = this.calculateLegendMeasurements();
  }

  type(): ChartTypes.FlatTableLegendType {
    throw new Error('Not yet implemented');
  }

  id(): string {
    return this._id;
  }

  config(): ChartTypes.FlatTableLegendConfig {
    return this._config;
  }

  domain(): LegendDomain {
    return this._domain;
  }

  fieldInfo(): LegendFieldInfo {
    return this._fieldInfo;
  }

  measurements(): LegendMeasurements {
    return this._measurements;
  }

  data(): LegendData {
    return this._data;
  }

  scale(): ScaleType {
    return this._scale;
  }

  calculateLegendTitleDimens(): ChartTypes.TextDimens {
    const title = this.getTitle();
    return calculateTextDimens(title);
  }

  getTitle(): string {
    const fieldInfo = this.fieldInfo();
    const title = fieldInfo.name;
    return title;
  }

  calculateData(): LegendData {
    throw new Error('Not yet implemented');
  }

  calculateLegendMeasurements(): LegendMeasurements {
    throw new Error('Not yet implemented');
  }

  createScale(): ScaleType {
    throw new Error('Not yet implemented');
  }

  createScaleDomainRange(): { domain: LegendDomain; range: string[] } {
    throw new Error('Not yet implemented');
  }

  getColor(val: DatumType): string {
    throw new Error('Not yet implemented');
  }

  dispose() {
    throw new Error('Not yet implemented');
  }
}
