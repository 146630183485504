import type { ICellRendererComp } from 'ag-grid-community';
import type { ICellRendererParams } from 'ag-grid-community';
import { ChartTypes } from '@mode/shared/contract-common';
import { getTextFormatOptionsByShortcutType, renderUrlCellContents } from '@mode/shared/util-js';

export class UrlCellRenderer implements ICellRendererComp {
  public eGui: any;
  public eValue: any;

  // gets called once before the renderer is used
  public init(params: ICellRendererParams) {
    this.eGui = this.generateGUI(params);
  }

  // gets called once when grid ready to insert the element
  public getGui() {
    return this.eGui;
  }

  // gets called whenever the user gets the cell to refresh
  public refresh(params: ICellRendererParams) {
    this.eGui = this.generateGUI(params);
    return true;
  }

  private generateGUI(params: ICellRendererParams): HTMLAnchorElement {
    // Get settings, if col doesn't exist, get default
    const colId = params.colDef?.colId;
    const textFormat: ChartTypes.TextFormat = colId
      ? params.context.fieldFormats[colId].paneFormat[ChartTypes.FieldPaneTypes.Default]
      : getTextFormatOptionsByShortcutType(ChartTypes.FormatShortcutType.Url);

    const urlPatternData = Object.keys(params.data).reduce((acc, curr) => {
      const colName = params.context.columnAliasMap.get(curr);
      acc[colName] = params.data[curr];
      return acc;
    }, {} as { [key: string]: any });

    // create the cell
    return renderUrlCellContents(params.value, textFormat, urlPatternData);
  }
}
