import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataIndexAuthInterceptor } from './data-index-auth.interceptor';

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: DataIndexAuthInterceptor, multi: true }],
})
export class SchemasDataAccessModule {}
