import { Injectable } from '@angular/core';
import { AccountsNeededForInitialization, OrgTypes, UserTypes } from '@mode/shared/contract-common';
import { getCurrentUsername, getOwnerUsername, getReportOwnerUsername } from '@mode/shared/util-dom';
import { AccountClientService } from './account-client.service';

@Injectable({ providedIn: 'root' })
export class InitialAccountsClientService {
  constructor(private accountClient: AccountClientService) {}

  public async getAccountsNeededForInitialization(): Promise<AccountsNeededForInitialization> {
    const username = getCurrentUsername(window.document);
    const orgUsername = getReportOwnerUsername(window.document) || getOwnerUsername(window.document);

    const [organization, user] = await Promise.all([this.maybeFetchOrg(orgUsername), this.maybeFetchUser(username)]);

    return { organization, user };
  }

  private maybeFetchUser(username: string | null): Promise<UserTypes.AuthenticatedUser | undefined> {
    return username ? this.accountClient.getAccount(username).toPromise() : Promise.resolve(undefined);
  }

  private async maybeFetchOrg(
    orgUsername: string | null
  ): Promise<OrgTypes.Organization | OrgTypes.OrgNotDeterminedYet> {
    if (orgUsername) {
      let result = await this.accountClient.getOrganization(orgUsername).toPromise();
      return result ?? this.createOrgNotDeterminedYet();
    } else {
      return this.createOrgNotDeterminedYet();
    }
  }

  private createOrgNotDeterminedYet(): OrgTypes.OrgNotDeterminedYet {
    return { isThoughtspotIntegrated: false };
  }
}
