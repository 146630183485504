export enum EventName {
  DragStart = 'Drag start',
  DragEnd = 'Drag end',
  ModalShown = 'Modal Shown',
  MenuItemSelected = 'Menu Item Selected',
  Search = 'Search',
  UiClicked = 'UI Clicked',
  UserExperienceStarted = 'User Experience - User Experience Started',
  UserExperienceMilestone = 'User Experience Milestone',
}

export enum Interactions {
  ExportCsv = 'export chart data to csv',
  DownloadCsv = 'download csv',
}

// Empty tracker for use if segment is not loaded
const FAKE_TRACKER = {
  track: () => {},
};

export class SegmentProvider {
  public readonly userExperienceId: string = '';

  constructor(private window: { analytics?: any; userExperienceId: string }) {
    if (typeof window !== 'undefined') {
      this.userExperienceId = window.userExperienceId;
    }

    if (this.api === FAKE_TRACKER) {
      console.warn('Segment not loaded');
    }
  }
  /**
   * Gets a reference to the segment API object. For whatever reason, if we just
   * keep a static reference, it will have some kind of buffering issue and will drop
   * track calls that happen in close succession.
   */
  private get api(): {
    track: Function;
  } {
    if (!this.window.analytics) {
      return FAKE_TRACKER;
    }

    return this.window.analytics;
  }

  track(eventName: string, ...args: any[]) {
    const api = this.api;
    api.track.apply(api, [eventName, ...args]);
  }
}
