import { cloneDeep } from 'lodash';
import { hsl } from 'd3-color';

// Helpful javascript functions

/**
 * Returns a shallow copy of an object excluding a given key
 * @param obj Object hash to filter
 * @param keyToRemove key to remove from the object
 */
export function immRemoveKey<T>(obj: T, keyToRemove: keyof T) {
  const { [keyToRemove]: removed, ...filtered } = obj;
  return filtered;
}

export function deepCopy<T>(obj: T): T {
  return cloneDeep(obj);
}

export function isPresent<TValue>(value: TValue | null | undefined | void): value is TValue {
  return value !== null && value !== undefined;
}

export function toDictionary<TVal extends { token: string }, R>(array: TVal[], map: (item: TVal) => R) {
  return array.reduce((acc, next) => {
    acc[next['token']] = map(next);
    return acc;
  }, {} as { [key: string]: R });
}

export async function pause(ms = 0): Promise<void> {
  return new Promise<void>((resolve): void => {
    setTimeout(resolve, ms);
  });
}

export function naturalSort(as: any, bs: any): number {
  let a: string,
    b: string,
    a1: any,
    b1: any,
    am,
    bm,
    rx = /(\d+)|(\D+)/g,
    rd = /\d/,
    rz = /^0/;

  if (typeof as === 'number' || typeof bs === 'number') {
    if (isNaN(as)) {
      return 1;
    }
    if (isNaN(bs)) {
      return -1;
    }
    return as - bs;
  }
  a = String(as).toLowerCase() || '';
  b = String(bs).toLowerCase() || '';
  if (a === b) {
    return 0;
  }
  if (!(rd.test(a) && rd.test(b))) {
    return a > b ? 1 : -1;
  }
  am = a.match(rx) || [];
  bm = b.match(rx) || [];
  while (am.length && bm.length) {
    a1 = am.shift();
    b1 = bm.shift();
    if (a1 && b1 && a1 !== b1) {
      if (rd.test(a1) && rd.test(b1)) {
        return a1.replace(rz, '.0') - b1.replace(rz, '.0');
      } else {
        return a1 > b1 ? 1 : -1;
      }
    }
  }
  return am.length - bm.length;
}

export function generateUUID(): string {
  try {
    // The randomUUID() method of the Crypto interface is used
    // to generate a v4 UUID using a cryptographically secure
    // random number generator.
    return crypto.randomUUID();
  } catch (insecureContext) {
    return generateLocalUUID();
  }
}

function generateLocalUUID(): string {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
  return uuid;
}

/**
 * Hash string
 *
 * @inspiredby https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 * @param str
 */
export function hashCode(str: string) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) {
    return hash;
  }
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
