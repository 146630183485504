import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';

import { IconComponent } from '@mode/capra';
import { ReportTypes } from '@mode/shared/contract-common';

@Component({
  standalone: true,
  selector: 'mode-report-type-icon',
  imports: [CommonModule, IconComponent],
  templateUrl: './report-type-icon.component.html',
  styleUrls: ['./report-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTypeIconComponent implements OnChanges {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2564
  @Input() type: ReportTypes.ReportType;

  icon = '';
  typeClass = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type'] && changes['type'].currentValue) {
      const isDatasetReport = changes['type'].currentValue === ReportTypes.ReportType.DatasetReport;
      this.icon = isDatasetReport ? 'icon-dataset' : 'icon-report-builder';
      this.typeClass = isDatasetReport ? 'is-dataset' : 'is-report';
    }
  }
}
