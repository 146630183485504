import { Injectable } from '@angular/core';
import { SortSettingsTypes } from '@mode/shared/contract-common';
import { DialogService } from '../dialog/dialog.service';

import { SortSettingsDialogComponent } from './sort-settings-dialog.component';

@Injectable()
export class SortSettingsModalDialogService {
  constructor(private dialogService: DialogService) {}

  public open(info: SortSettingsTypes.SortSettingsDialogData): void {
    let sorts: SortSettingsTypes.Sort[] = info.encoding.sorts.map((sort) => {
      return {
        descending: sort.descending,
        selected: {
          name: sort.source.slice(1, sort.source.length - 1),
          value: sort.source,
        },
        highlighted: false,
      };
    });

    const allColumns: SortSettingsTypes.Column[] = info.encoding.marks.properties.text.values.map((val) => {
      return {
        name: val.formula.slice(1, val.formula.length - 1),
        value: val.formula,
      };
    });

    if (info.selectedColumn) {
      if (!this.isColumnAlreadySorted(sorts, info.selectedColumn)) {
        sorts.push({
          descending: info.descending,
          selected: {
            name: info.selectedColumn.slice(1, info.selectedColumn.length - 1),
            value: info.selectedColumn,
          },
          highlighted: true,
        });
      } else {
        sorts = sorts.map((sort) => {
          return {
            ...sort,
            descending: info.selectedColumn === sort.selected.value ? info.descending : sort.descending,
            highlighted: info.selectedColumn === sort.selected.value,
          };
        });
      }
    }

    const data = {
      sorts,
      allColumns,
      chartTitle: info.chartTitle,
    };

    const dialogRef = this.dialogService.open({
      component: SortSettingsDialogComponent,
      matDialogConfig: {
        backdropClass: 'mode-sort-modal-backdrop',
        data,
      },
    });

    const confirmSubscription = dialogRef.componentInstance.apply.subscribe((sorts: SortSettingsTypes.Sort[]) => {
      if (info.applyAction) {
        info.applyAction(sorts);
      }
      confirmSubscription.unsubscribe();
    });

    const cancelSubscription = dialogRef.componentInstance.cancel.subscribe(() => {
      if (info.cancelAction) {
        info.cancelAction();
      }
      cancelSubscription.unsubscribe();
    });

    const backdropClickSubscription = dialogRef.backdropClick().subscribe(() => {
      if (info.cancelAction) {
        info.cancelAction();
      }
      backdropClickSubscription.unsubscribe();
    });
  }

  private isColumnAlreadySorted(sorts: SortSettingsTypes.Sort[] = [], selectedColumn: string): boolean {
    return sorts.findIndex((sort) => sort.selected && sort.selected.value === selectedColumn) >= 0;
  }
}
