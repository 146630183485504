import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FeatureFlagsFacade, FeatureFlagsInitializationService } from '@mode/shared/contract-common';
import { SharedUtilErrorHandlingModule } from '@mode/shared/util-error-handling';
import { FeatureFlagsService } from './feature-flags.service';
import { LaunchDarklyFlagsDataService } from './launch-darkly-flags-data.service';

@NgModule({
  imports: [CommonModule, SharedUtilErrorHandlingModule],
  providers: [
    { provide: FeatureFlagsFacade, useClass: FeatureFlagsService },
    {
      provide: FeatureFlagsInitializationService,
      useClass: LaunchDarklyFlagsDataService,
    },
  ],
})
export class SharedUtilFeatureFlagsModule {}
