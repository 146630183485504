import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { IconComponent } from '@mode/capra';
import { range, first, last } from 'lodash';

interface PageButton {
  pageNumber: number;
  active: boolean;
}

interface EllipsisSpacer {
  pageNumber: null;
  active?: false;
}

@Component({
  standalone: true,
  selector: 'mode-paginator',
  imports: [CommonModule, IconComponent],
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnChanges {
  @Output() pageChangeEvent = new EventEmitter<number>();

  @Input() numberOfPages = 0;
  @Input() currentPage = 0;

  pageButtons: (PageButton | EllipsisSpacer)[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    const { currentPage: currentPageChange, numberOfPages: numberOfPagesChange } = changes;
    if (currentPageChange || numberOfPagesChange) {
      this.setPageButtons(this.currentPage, this.numberOfPages);
    }
  }

  setPageButtons(currentPage: number, numberOfPages: number) {
    // determine the starting and ending range
    // then create a set of pages truncated on the starting and ending numbers
    const startingNumber = Math.max(1, currentPage - 2);
    const endingNumber = Math.min(numberOfPages + 1, currentPage + 3);
    const truncatedPages = range(startingNumber, endingNumber).map((pageNumber) => ({
      pageNumber: pageNumber,
      active: pageNumber === currentPage,
    }));
    //
    // if needed, add the first page and last page
    if (first(truncatedPages)?.pageNumber !== 1) {
      truncatedPages.unshift({
        pageNumber: 1,
        active: false,
      });
    }
    if (last(truncatedPages)?.pageNumber !== numberOfPages) {
      truncatedPages.push({
        pageNumber: numberOfPages,
        active: false,
      });
    }
    //
    // finally, check to see if ellipsis spacers need to be placed in the final array
    const truncatedWithEllipse: (PageButton | EllipsisSpacer)[] = truncatedPages.flatMap((page, pageIndex) => {
      const nextItem = truncatedPages[pageIndex + 1];
      if (nextItem && nextItem.pageNumber > page.pageNumber + 1) {
        return [
          page as PageButton,
          {
            pageNumber: null,
          } as EllipsisSpacer,
        ];
      }
      return [page];
    });
    //
    // and then set it as pageButtons property
    this.pageButtons = truncatedWithEllipse;
  }

  prevButtonClicked() {
    this.pageChangeEvent.emit(this.currentPage - 1);
  }

  nextButtonClicked() {
    this.pageChangeEvent.emit(this.currentPage + 1);
  }

  pageButtonClicked(pageNumber: number) {
    if (pageNumber !== this.currentPage) {
      this.pageChangeEvent.emit(pageNumber);
    }
  }
}
