import type { ColDef, Column, GridOptions, ValueFormatterParams } from 'ag-grid-community';

/**
 * Calculate and set equal column widths.
 *
 * Take into account the presence of row numbers and distribute equally the remaining space
 * between columns. Set a min width equal to the default column width x column number.
 * TODO: Determine what min width should actually be.
 */
export function fitColumnsEqually(flatOptions: GridOptions, container: HTMLElement, defaultColumnWidth: number): void {
  if (!flatOptions.columnApi) {
    return;
  }

  // get width of client or min
  let columns = flatOptions.columnApi.getColumns(),
    columnCount = flatOptions.columnApi.getColumns()?.length ?? 0,
    scrollableColsViewport = container.querySelector('.ag-center-cols-viewport'),
    // note - this will not include pinned columns or a scrollbar if it is present
    scrollableColsWidth = scrollableColsViewport?.clientWidth ?? 0,
    minWidth = columnCount * defaultColumnWidth,
    width = scrollableColsWidth < minWidth ? minWidth : scrollableColsWidth;

  // get all columns
  const rowHeaderCol = (columns ?? []).filter((col) => col.getColId() === 'ag_grid_row_header'),
    remainingCols = (columns ?? []).filter((col) => col.getColId() !== 'ag_grid_row_header');

  // if row headers are present don't include in size calculations
  if (rowHeaderCol.length === 1) {
    columnCount = columnCount - 1;
  }

  // divide rest equally
  const evenColWidth = Math.floor(width / columnCount);
  const colWidths = new Array(columnCount).fill(evenColWidth);
  const remainder = width % columnCount;

  for (let i = 0; i < remainder; i++) {
    colWidths[i] = evenColWidth + 1;
  }

  // set widths
  remainingCols.forEach((col, i) => {
    flatOptions.columnApi?.setColumnWidth(col, colWidths[i]);
  });
}

/**
 * Helper to reset column widths as defined.
 *
 * ag-Grid generally doesn't update width when column defs update in an effort to keep
 * consistency. So this helper will reset columns to defined widths when necessary instead
 * of destroying and rebuilding the entire table.
 */
export function setActualColumnWidth(flatOptions: GridOptions): void {
  flatOptions.columnApi && flatOptions.columnApi.resetColumnState();
}

/**
 * Custom autosize all columns with max width.
 *
 * Respects the `suppressSizeToFit` option if set on a column (ie - row numbers col)
 */
export function customAutosizeColumns(
  columns: Column[],
  maxWidth: number,
  flatOptions: GridOptions,
  container: HTMLElement
): void {
  columns.forEach((col) => {
    if (!col.getColDef().suppressSizeToFit) {
      customAutosizeColumn(col, maxWidth, flatOptions, container);
    }
  });
}

/**
 * Custom autosize single column with max width.
 */
export function customAutosizeColumn(
  column: Column,
  maxWidth: number,
  flatOptions: GridOptions,
  container: HTMLElement
): void {
  const estimatedWidth = getEstimatedColumnWidth(column, flatOptions, container);
  const targetWidth = estimatedWidth > maxWidth ? maxWidth : estimatedWidth;
  flatOptions.columnApi?.setColumnWidth(column, targetWidth);
}

/**
 * Estimate the column width.
 *
 * Return an estimate width for a given column based on visible row data formatted as
 * defined by that column's formatter.
 */
export function getEstimatedColumnWidth(column: Column, flatOptions: GridOptions, container: HTMLElement): number {
  // createDummyContainer to use to determine a width
  const dummyContainer = document.createElement('span');
  // Set position fixed, so it isn't restricted to the boundaries of the parent
  dummyContainer.style.position = 'fixed';
  // Place in the table body so it inherits the general css styles
  const tableBodyContainer = container.querySelector('.ag-center-cols-container');
  // It's occasionally possible for this to be undefined, if that's the case, return the default col value
  if (!tableBodyContainer) {
    return flatOptions.defaultColDef?.width ?? 140;
  }
  tableBodyContainer.appendChild(dummyContainer);

  // Get values from header and column to test length
  const columnContent = [];
  // Add three 0s to add some extra buffer to the column header entry since we need to account for buttons
  columnContent.push(column.getColId() + '000');
  // Get all visible row items from the specified column with formatting
  flatOptions.api?.getRenderedNodes().forEach((row) => {
    const field = column.getUserProvidedColDef()?.field;

    if (!field) {
      return;
    }

    let formattedData = row.data[field];

    // This takes more effort, but gets a more accurate result, as we're using formatted results when applicable.
    let colDef: ColDef = column.getColDef();

    if (typeof colDef.valueFormatter === 'function') {
      const formatterParams: ValueFormatterParams = {
        node: row,
        data: formattedData,
        colDef: column.getColDef(),
        column: column,
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        api: flatOptions.api!,
        columnApi: flatOptions.columnApi!,
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
        context: flatOptions.context,
        value: formattedData,
      };

      formattedData = colDef.valueFormatter(formatterParams);
    }

    columnContent.push(formattedData);
  });

  // Build out our dummy element with all content items
  columnContent.forEach((el, i) => {
    const row = document.createElement('div');
    row.style.display = 'table-row';
    row.textContent = el;
    dummyContainer.appendChild(row);
  });

  // Get our content width and remove dummy
  let dummyContainerWidth = dummyContainer.offsetWidth;
  tableBodyContainer.removeChild(dummyContainer);

  // Add additional space to account for the cell padding based on setting
  const extraWidth = flatOptions.autoSizePadding ?? 0;
  if (Number.isInteger(extraWidth)) {
    dummyContainerWidth += 2 * extraWidth;
  }

  return dummyContainerWidth;
}

export function detectAllColsRendered(container: HTMLElement, totalNumberOfCols: number): boolean {
  const virtuallyRenderedCols =
    container.querySelector('.ag-center-cols-container')?.children[0]?.children?.length ?? 0;
  return virtuallyRenderedCols === totalNumberOfCols;
}
