import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackClickDirective } from './analytics/track-click.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TrackClickDirective],
  exports: [TrackClickDirective],
  providers: [{ provide: Window, useValue: window }],
})
export class SharedUtilSegmentModule {}
