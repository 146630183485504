import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IconComponent } from '@mode/capra';

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent],
  selector: 'mode-sql-no-results-message',
  templateUrl: './sql-no-results-message.component.html',
  styleUrls: ['./sql-no-results-message.component.scss'],
})
export class SqlNoResultsMessageComponent {}
