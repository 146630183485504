import { DataType } from '@mode-switch/express';
import { ChartTypes } from '@mode/shared/contract-common';

const MAX_DATE = 8640000000000000;
const MIN_DATE = -8640000000000000;

/**
 * Converts a boolean string to a boolean.
 * If the argument is not a string, it will return as is without any conversion.
 * If it is a string, but not true/false, then it will return undefined so it can
 * be filtered out.
 */
export function safeStringToBool(boolString: string): boolean | undefined;
export function safeStringToBool(boolString: boolean): boolean;
export function safeStringToBool(boolString: any): any;
export function safeStringToBool(boolString: any) {
  const typeStr = typeof boolString;
  if (typeStr === 'string') {
    switch (boolString.toLowerCase().trim()) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return undefined;
    }
  } else if (typeStr === 'boolean') {
    return boolString;
  } else if (typeStr === 'number') {
    if (0 === boolString) {
      return false;
    }

    if (1 === boolString) {
      return true;
    }
  }

  return undefined;
}

/**
 * Converts a number string to a number.
 * If the argument is not a string, it will return as is without any conversion.
 * If it is a string, but parses to NaN, then it will return defaultReturn so it can
 * be filtered out.
 * This does not do sufficient precision loss checking, as that's extremely expensive to do.
 * If it is a string, but larger/smaller than the MAX_SAFE_INTEGER/MIN_SAFE_INTEGER,
 * then it will remain a string
 */
export function safeStringToNumber(numberString: string, defaultReturn?: any): number | string | undefined;
export function safeStringToNumber(numberString: number, defaultReturn?: any): number;
export function safeStringToNumber(numberString: any, defaultReturn?: any): any;
export function safeStringToNumber(numberString: any, defaultReturn?: any) {
  const typeStr = typeof numberString;
  if (typeStr === 'string') {
    const result = parseFloat(numberString);
    if (isNaN(result)) {
      return defaultReturn;
    } else {
      // VERY rudimentary and incomplete protection against precision loss
      if (result > Number.MAX_SAFE_INTEGER || result < Number.MIN_SAFE_INTEGER) {
        return numberString;
      }

      return result;
    }
  } else if (typeStr === 'number') {
    return numberString;
  }

  return defaultReturn;
}

/**
 * Converts a number string to an integer.
 * If the argument is not a string, it will return as is without any conversion.
 * If it is a string, but parses to NaN, then it will return defaultReturn so it can
 * be filtered out.
 * If it is a string, but larger/smaller than the MAX_SAFE_INTEGER/MIN_SAFE_INTEGER,
 * then it will remain a string
 */
export function safeStringToInteger(numberString: string, defaultReturn?: any): number | string | undefined;
export function safeStringToInteger(numberString: number, defaultReturn?: any): number;
export function safeStringToInteger(numberString: any, defaultReturn?: any): any;
export function safeStringToInteger(numberString: any, defaultReturn?: any) {
  const typeStr = typeof numberString;
  if (typeStr === 'string') {
    const result = parseInt(numberString);
    if (isNaN(result)) {
      return defaultReturn;
    }

    if (!Number.isSafeInteger(result)) {
      return numberString;
    }

    if (!isNaN(result)) {
      return result;
    }
  } else if (typeStr === 'number') {
    return numberString;
  }

  return defaultReturn;
}

const UTC_REGEX =
  /^(\d{4})-(\d{2})-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})(?:\.?(\d{3}))?(?:(?:([-+]\d{2})(?::?(\d{2}))?)|Z)?)?$/;

/**
 * Converts a ISO date string into a JS date as fast as possible.
 *
 * @param isoDateString A perfectly formatted ISO date string
 */
export function isoDateStringToDate(isoDateString: string) {
  const dm = UTC_REGEX.exec(isoDateString);

  if (dm) {
    const hourOffset = +dm[8] || 0;
    // Won't handle offsets between -00:01 to -00:59, but no countries use that range
    const minOffset = (+dm[9] || 0) * (hourOffset < 0 ? -1 : 1);

    // converts the time to UTC for consistent display
    return Date.UTC(
      +dm[1], // year
      +dm[2] - 1, // month with JS offset
      +dm[3], // day
      +(dm[4] || 0) - hourOffset, // hour, if < 0 or >= 24 it will move the day
      +(dm[5] || 0) - minOffset, // min
      +(dm[6] || 0), // second
      +(dm[7] || 0)
    ); // ms
  }

  return isoDateString;
}

/**
 * Fast date formatter to a fixed function
 * @param date
 */
export function fastToDateTimeString(date: Date) {
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hour = date.getUTCHours().toString().padStart(2, '0');
  const minute = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');

  if (milliseconds === '000') {
    return `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;
  } else {
    return `${year}-${month}-${day} ${hour}:${minute}:${seconds}.${milliseconds}`;
  }
}

const jsonLargeNumberRegex = /([,{[\s]"[^"]*":\s*)([-])?(\d{15,}\.?[0-9]*)/g;

export function textHasLargeNumber(target: string) {
  return jsonLargeNumberRegex.test(target);
}

export function stringifyLargeNumbers(target: string, substrPattern = '$1"$2$3"') {
  return target.replace(jsonLargeNumberRegex, substrPattern);
}

export function initialize(name: string) {
  if (!name || !(typeof name === 'string')) {
    return '';
  } else {
    const words = name.split(' ');
    if (words.length > 1) {
      return words[0].substring(0, 1).toUpperCase() + words[words.length - 1].substring(0, 1).toUpperCase();
    } else {
      return name.substring(0, 2).toUpperCase();
    }
  }
}

/**
 * Converts bytes to a readable string representation.
 */
export function readableBytes(size: number): string {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return parseFloat((size / Math.pow(1024, i)).toFixed(2)) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

function append(val: any, sep: boolean) {
  val = val == null ? '' : val;
  return sep ? '\t' + val : val;
}

function isDefined(value: any) {
  return typeof value !== 'undefined';
}

export function prepareDataForCopying(data: ChartTypes.RawData): string | null {
  if (!data) {
    return null;
  }
  if (isDefined(data.columns)) {
    // String concatenation instead of array joins because it's faster in JS
    let tsv = data.columns.map((col) => col.name).join('\t');

    for (const row of data.content) {
      tsv += '\n';
      data.columns.forEach((col, index) => {
        const entry = row[col.alias || col.name];
        if (typeof col.type === 'string' && (col.type === 'datetime' || col.type === 'date')) {
          // Format the date nicely
          const date = isoDateStringToDate(entry);
          if (typeof date !== 'number' || date > MAX_DATE || date < MIN_DATE) {
            tsv += append(entry, index > 0);
          } else {
            tsv += append(fastToDateTimeString(new Date(date)), index > 0);
          }
        } else if (
          typeof col.type === 'object' &&
          (col.type.name === DataType.Name.DateTime || col.type.name === DataType.Name.Timestamp)
        ) {
          if (entry != null) {
            const date = entry;
            if (date > MAX_DATE || date < MIN_DATE) {
              tsv += append(entry, index > 0);
            } else {
              tsv += append(fastToDateTimeString(new Date(date)), index > 0);
            }
          } else {
            tsv += append(null, index > 0);
          }
          // Flamingo dates
        } else {
          tsv += append(entry, index > 0);
        }
      });
    }

    return tsv;
  } else {
    return JSON.stringify(data);
  }
}
