import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconComponent } from '../icon/icon.component';
import { Status, StatusIconComponent } from '../status-icon/status-icon.component';

export type ToastType = Extract<Status, 'error' | 'processing' | 'success' | 'warning'>;

@Component({
  standalone: true,
  selector: 'capra-toast',
  imports: [CommonModule, IconComponent, StatusIconComponent],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
  @Input() type: ToastType = 'success';
  @Input() inline = false;
  @Input() hideIcon = false;
  @Input() showCloseBtn = false;
  @Input() canTapToDismiss = false;
  @Output() dismissToast = new EventEmitter<void>();
}
