import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { Resource } from '@opentelemetry/resources';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';

export class HoneycombProvider {
  public exporter: OTLPTraceExporter;
  public tracerProvider: WebTracerProvider;

  constructor(collectorUrl: string, tracerName: string) {
    this.exporter = new OTLPTraceExporter({
      url: collectorUrl,
      headers: {},
    });

    this.tracerProvider = new WebTracerProvider({
      resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: tracerName,
      }),
    });

    this.tracerProvider.addSpanProcessor(new BatchSpanProcessor(this.exporter));
  }
}
