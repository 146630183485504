import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output, TemplateRef } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

import { ButtonComponent, Color, IconComponent, Variant } from '@mode/capra';
import { DialogHeaderComponent } from '../dialog/header/dialog-header.component';

export type DialogTemplateRef = TemplateRef<{
  updateConfirmButtonState?: (enabled: boolean) => void;
}>;

export interface ConfirmDialogData {
  dialogTitle?: string;
  dialogText?: string;
  dialogTemplateRef?: DialogTemplateRef;
  confirmText?: string;
  cancelText?: string;
  icon?: string;
  confirmButtonColor?: Color;
  confirmButtonType?: Variant;
  confirmAction?: () => void;
  cancelAction?: () => void;
}

@Component({
  standalone: true,
  selector: 'mode-confirm-dialog',
  imports: [ButtonComponent, CommonModule, DialogHeaderComponent, DragDropModule, IconComponent, MatLegacyDialogModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();

  /**
   * Confirm button disabled state. This can be controlled via. {@link DialogTemplateRef}
   * through the context binding {@link updateConfirmButtonState}
   */
  disabled = false;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: ConfirmDialogData = { confirmButtonType: 'secondary' },
    public dialogRef: MatLegacyDialogRef<ConfirmDialogComponent>
  ) {
    data.confirmButtonType = data.confirmButtonType ?? 'secondary';
  }

  onConfirm(): void {
    this.confirm.emit();
    this.dialogRef.close();
  }

  onCancel(): void {
    this.cancel.emit();
    this.dialogRef.close();
  }

  updateConfirmButtonState = (enabled: boolean) => {
    this.disabled = !enabled;
  };
}
