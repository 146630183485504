import type { CellStyleFunc } from 'ag-grid-community';

// This interface should extend any custom settings interfaces defined for a la mode use
// by any visualization. So there must be no conflicts between config.
// Move this more globally when other interfaces are defined.
export type AlamodeUserConfig = FlatTableCustomConfig;

/**
 * This defines the structure of the object the user provides and must be in sync
 * with the a la mode documentation.
 */
export interface FlatTableUserProvidedSettings {
  vizId: string;
  formatByTable?: ByTableSettings;
  formatByColumn?: ByColumnSettings;
  addImages?: AddImageSettings;
}

/**
 * This represents the configuration that results from the user settings being processed
 * and is stored.
 */
export interface FlatTableCustomConfig {
  tableColStyleFunctions: { [key: string]: CellStyleFunc };
  tableColRenderers: { [key: string]: string };
  tableSettings: { [key: string]: any };
}

export interface AddImageSettings {
  columnNames: string[];
  rowHeightOverride?: number;
}

export interface ByTableSettings {
  columnNames: string[];
  rules: ColumnRule[];
}

export interface ByColumnSettings {
  columns: ColumnSettings[];
}

export interface ColumnSettings {
  name: string;
  rules: ColumnRule[];
}

export interface ColumnRule {
  type: '===' | '!==' | '>' | '<' | '>=' | '<=';
  value: string | number;
  color: string;
  textColor?: string;
}

export interface CustomSettingsValidation {
  isValid: boolean;
  error?: string;
}

export enum FlatTableLegendType {
  Discrete = 'discrete',
  Gradient = 'gradient',
}

export interface FlatTableLegendConfig {
  title: {
    titleBodyDistance: number;
  };
  gradient: {
    height: number;
  };
  item: {
    height: number;
    iconWidth: number;
    iconHeight: number;
    iconTextDistance: number;
    itemGap: number;
  };
  range: string[];
  domainRangeMap: Record<string, string>;
}

export type FlatTableLegendFieldList = ReadonlyArray<string>;

export enum FlatTableLegendColorEffectType {
  TextColor = 'text-color',
  BackgroundColor = 'background-color',
}

export type FlatTableFieldSettingMap = Map<string, FlatTableLegendFieldSetting>;

export interface FlatTableLegendFieldSetting {
  range: ReadonlyArray<string>;
  domainRangeMap: Record<string, string>;
}

export interface TextDimens {
  width: number;
  height: number;
}
