export class NoopStorage implements Storage {
  length = 0;
  [name: string]: any;
  setItem(key: string, value: string): void {}
  removeItem(key: string): void {}
  key(index: number): string | null {
    return null;
  }
  getItem(key: string): string | null {
    return null;
  }
  clear(): void {}
}
