import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, mergeMap, tap, combineLatestWith, take, filter } from 'rxjs';
import * as AiAssistActions from './ai-assist.actions';
import { AiGatewayClientService, AiQueryErrorResponse } from './ai-gateway-client.service';
import { ToastService } from '@mode/shared/util-toast';
import {
  FeedbackDialogService,
  NegativeFeedbackToast,
  PositiveFeedbackToast,
  QueryViewerFeedback,
} from '@mode/queries/ai-assist/util';
import { ComponentType } from 'ngx-toastr';
import { QueryViewerFacade } from './query-viewer.facade';
import { isPresent } from '@mode/shared/util-js';
import { ErrorReporter } from '@mode/shared/contract-common';

@Injectable()
export class AiAssistEffects {
  generateSQL$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiAssistActions.generateSQL),
      mergeMap((action) =>
        this.aiGatewayClientService.getSQL(action.rawQueryText, action.metadata).pipe(
          map(({ renderedQuery, requestId }) =>
            AiAssistActions.generateSQLSuccess({
              queryToken: action.queryToken,
              generatedSQL: renderedQuery,
              requestId,
            })
          ),
          catchError(({ error }: AiQueryErrorResponse) =>
            of(
              AiAssistActions.generateSQLFailure({
                queryToken: action.queryToken,
                requestId: error?.instanceId,
                errorMsg: error?.message,
              })
            )
          )
        )
      )
    )
  );

  submitPositiveFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiAssistActions.submitPositiveFeedback),
      mergeMap((action) =>
        this.aiGatewayClientService
          .submitFeedback(action.requestId, action.metadata, QueryViewerFeedback.Positive)
          .pipe(
            map(() =>
              AiAssistActions.submitPositiveFeedbackSuccess({
                queryToken: action.queryToken,
              })
            ),
            catchError(() =>
              of(
                AiAssistActions.submitPositiveFeedbackFailure({
                  queryToken: action.queryToken,
                })
              )
            )
          )
      )
    )
  );

  submitPositiveFeedbackSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AiAssistActions.submitPositiveFeedbackSuccess),
        tap(() => {
          this.toastService.success('', {
            tapToDismiss: false,
            toastComponent: this.positiveFeedbackToastComponent as unknown as ComponentType<NegativeFeedbackToast>,
          });
        })
      ),
    { dispatch: false }
  );

  submitNegativeFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiAssistActions.submitNegativeFeedback),
      mergeMap((action) =>
        this.aiGatewayClientService
          .submitFeedback(action.requestId, action.metadata, QueryViewerFeedback.Negative, action.message)
          .pipe(
            map(() =>
              AiAssistActions.submitNegativeFeedbackSuccess({
                queryToken: action.queryToken,
                metadata: action.metadata,
              })
            ),
            catchError(() =>
              of(
                AiAssistActions.submitNegativeFeedbackFailure({
                  queryToken: action.queryToken,
                })
              )
            )
          )
      )
    )
  );

  submitNegativeFeedbackSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiAssistActions.submitNegativeFeedbackSuccess),
      switchMap(({ queryToken, metadata }) => {
        const activeToast = this.toastService.success('', {
          tapToDismiss: false,
          closeButton: true,
          timeOut: 10000,
          extendedTimeout: 10000,
          toastComponent: this.negativeFeedbackToastComponent as unknown as ComponentType<NegativeFeedbackToast>,
        });
        return activeToast.onAction.pipe(
          take(1),
          map(() => AiAssistActions.giveFeedback({ queryToken, metadata }))
        );
      })
    )
  );

  giveFeedback$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AiAssistActions.giveFeedback),
        tap(({ metadata }) => {
          this.submitFeedbackDialogService.open(metadata);
        })
      ),
    { dispatch: false }
  );

  sendFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AiAssistActions.sendFeedback),
      concatLatestFrom((action) => this.queryViewerFacade.requestId$(action.queryToken).pipe(filter(isPresent))),
      mergeMap(([{ metadata, feedback }, requestId]) =>
        this.aiGatewayClientService.submitFeedback(requestId, metadata, QueryViewerFeedback.Negative, feedback).pipe(
          map(() =>
            AiAssistActions.sendFeedbackSuccess({
              queryToken: metadata.queryToken,
            })
          ),
          catchError(() =>
            of(
              AiAssistActions.sendFeedbackFailure({
                queryToken: metadata.queryToken,
              })
            )
          )
        )
      )
    )
  );

  sendFeedbackSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AiAssistActions.sendFeedbackSuccess),
        tap(() => {
          this.toastService.success('Feedback recorded.');
        })
      ),
    { dispatch: false }
  );

  submitInsertFeedback$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AiAssistActions.submitInsertFeedback),
        concatLatestFrom((action) =>
          this.queryViewerFacade.requestId$(action.metadata.queryToken).pipe(filter(isPresent))
        ),
        mergeMap(([{ metadata }, requestId]) =>
          this.aiGatewayClientService.submitFeedback(requestId, metadata).pipe(
            catchError((error) => {
              this.errorReporter.notify({
                error,
                severity: 'error',
              });
              return of(error);
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private aiGatewayClientService: AiGatewayClientService,
    private toastService: ToastService,
    private negativeFeedbackToastComponent: NegativeFeedbackToast,
    private positiveFeedbackToastComponent: PositiveFeedbackToast,
    private submitFeedbackDialogService: FeedbackDialogService,
    private queryViewerFacade: QueryViewerFacade,
    private errorReporter: ErrorReporter
  ) {}
}
