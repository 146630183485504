<div class="heading" cdkDrag mat-dialog-title cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <capra-icon *ngIf="data.icon" [icon]="data.icon"></capra-icon>
  <mode-dialog-header [class.header-with-icon]="data.icon">
    <h1 heading>{{ data.dialogTitle }}</h1>
  </mode-dialog-header>
</div>
<ng-container *ngIf="data.dialogTemplateRef; else dialogTextTemplate">
  <ng-container
    *ngTemplateOutlet="
      data.dialogTemplateRef;
      context: {
        updateConfirmButtonState
      }
    "
  ></ng-container>
</ng-container>
<ng-template #dialogTextTemplate>
  <div class="content-container" [innerHTML]="data.dialogText"></div>
</ng-template>
<div mat-dialog-actions class="actions">
  <capra-button
    class="action cancel"
    [text]="data.cancelText"
    variant="secondary"
    color="neutral"
    (clicked)="onCancel()"
  ></capra-button>
  <capra-button
    *ngIf="data.confirmButtonType === 'secondary'"
    class="action confirm capra-secondary-button"
    [text]="data.confirmText"
    variant="secondary"
    [color]="data.confirmButtonColor"
    [disabled]="disabled"
    (clicked)="onConfirm()"
    data-test="confirm-button"
  ></capra-button>
  <capra-button
    *ngIf="data.confirmButtonType === 'primary'"
    class="action confirm capra-primary-button"
    [text]="data.confirmText"
    [color]="data.confirmButtonColor"
    [disabled]="disabled"
    (clicked)="onConfirm()"
    data-test="confirm-button"
  ></capra-button>
</div>
