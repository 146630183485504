import { ApiTypes } from '@mode/shared/contract-common';

export function getTokenFromModeLink(link: { href: string }): string | undefined {
  if (link) {
    const parts = link.href.split('/');
    if (parts.length > 1) {
      return parts[parts.length - 1];
    }
  }

  return undefined;
}

export function getTypeFromModeLink(link: { href: string }): string | undefined {
  if (link) {
    const parts = link.href.split('/');
    if (parts.length > 1) {
      return parts[parts.length - 2];
    }
  }

  return undefined;
}

export function getLink(resource: ApiTypes.ModeHalResource, rel: string) {
  return resource._links?.[rel];
}

/**
 * If not an array, gets the link href, otherwise gets the first link's href.
 */
export function getHref(resource: ApiTypes.ModeHalResource, rel: string) {
  const link = resource._links?.[rel];
  if (link != null) {
    if (!Array.isArray(link)) {
      return link.href;
    } else {
      return link[0].href;
    }
  }

  return null;
}

export function hasLink(resource: ApiTypes.ModeHalResource, rel: string) {
  return getLink(resource, rel) != null;
}

export function getForm(resource: ApiTypes.ModeHalResource, rel: string) {
  return resource._forms?.[rel];
}

export function getEmbedded<U extends ApiTypes.ModeHalResource>(
  resource: ApiTypes.ModeHalResource,
  rel: string,
  options: ApiTypes.ResourceOptions = {}
): U | undefined {
  if (options.includes) {
    return undefined; // if there's an includes, get the resource from the API
  }

  const embed = resource._embedded?.[rel];
  if (embed != null && !Array.isArray(embed)) {
    return embed as U;
  }

  return undefined;
}

export function getEmbeddedCollection<U extends ApiTypes.ModeHalResource>(
  embed: ApiTypes.ModeHalResource,
  rel: string,
  secondaryRel?: string
): U[] | undefined {
  const collectionResource = embed._embedded?.[rel];
  if (collectionResource != null) {
    if (Array.isArray(collectionResource)) {
      return collectionResource as U[]; // this is a non-compliant embedded collection
    } else {
      const secondLevelRel = secondaryRel || rel;
      return collectionResource._embedded?.[secondLevelRel] as U[];
    }
  }

  return undefined;
}
