import { Injectable } from '@angular/core';
import { ErrorReporter } from '@mode/shared/contract-common';
import { BehaviorSubject, merge, Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { isPresent } from '@mode/shared/util-js';
import { BrowserEventsService } from './browser-events.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private itemStreams = new Map<string, BehaviorSubject<any>>();

  constructor(
    private _storage: Storage,
    private browserEventsService: BrowserEventsService,
    private errorReporter: ErrorReporter
  ) {}

  public getObject<T>(key: string): T | string | null | undefined {
    const item = this.getItem(key);

    if (item === 'undefined') {
      return undefined;
    }

    if (item) {
      try {
        return JSON.parse(item);
      } catch (e) {
        return item;
      }
    } else {
      return null;
    }
  }

  public getItem(key: string): string | null {
    return this._storage.getItem(key);
  }

  public setStringifiedItem(keyName: string, keyValue: unknown): void {
    this.setItem(keyName, JSON.stringify(keyValue));

    if (!this.itemStreams.has(keyName)) {
      this.initItemStream(keyName, keyValue);
    } else {
      this.itemStreams.get(keyName)?.next(keyValue);
    }
  }

  public setItem(key: string, value: string): void {
    this._storage.setItem(key, value);
  }

  public removeItem(keyName: string): void {
    this._storage.removeItem(keyName);
  }

  public observeItem<T>(keyName: string): Observable<T | string | null | undefined> {
    if (!this.itemStreams.has(keyName)) {
      this.initItemStream(keyName, this.getObject<T>(keyName));
    }

    const itemStream = this.itemStreams.get(keyName);
    const storageItemStream = this.browserEventsService.storageEvent$.pipe(
      filter(({ key }) => key === keyName),
      map(({ key }) => this.getObject<T>(key as string))
    );

    if (itemStream === undefined) {
      return throwError(`Storage item stream "${keyName}" is undefined`).pipe(
        catchError((err) => {
          console.error(err);
          this.errorReporter.notify({ error: err });

          return throwError(err);
        })
      );
    }

    return merge(itemStream.asObservable(), storageItemStream).pipe(filter(isPresent));
  }

  private initItemStream(keyName: string, keyValue: unknown = null): void {
    this.itemStreams.set(keyName, new BehaviorSubject<unknown>(keyValue));
  }
}
