/* eslint-disable @typescript-eslint/no-namespace */
export interface Column {
  readonly name: string;
  readonly type: Column.Type;
  readonly role: Column.Type | Column.Role;
  readonly header?: string;
}

export namespace Column {
  export type Type = Type.Boolean | Type.Integer | Type.Float | Type.Decimal | Type.Date | Type.String;

  export namespace Type {
    export enum Name {
      Boolean = 'BOOLEAN',
      Integer = 'INTEGER',
      Float = 'FLOAT',
      Decimal = 'DECIMAL',
      Date = 'DATE',
      String = 'STRING',
    }

    export interface Boolean {
      readonly name: Name.Boolean;
    }

    export interface Integer {
      readonly name: Name.Integer;
    }

    export interface Float {
      readonly name: Name.Float;
    }

    export interface Decimal {
      readonly name: Name.Decimal;
      readonly scale: number;
      readonly precision: number;
    }

    export interface Date {
      readonly name: Name.Date;
    }

    export interface String {
      readonly name: Name.String;
    }
  }

  export type Role = Role.DatePart;

  export namespace Role {
    export enum Name {
      DatePart = 'DATE_PART',
    }

    export interface DatePart {
      readonly name: Name.DatePart;
    }
  }
}

export interface Dataset {
  readonly count: number;
  readonly content: any[];
  readonly state: Dataset.State;
  readonly columns: ReadonlyArray<Column>;
}

export namespace Dataset {
  export enum State {
    Empty = 'empty',
    Failed = 'failed',
    Succeeded = 'succeeded',
    Oversized = 'oversized',
  }
}
