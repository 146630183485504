import type { IFilterComp, IFilterParams } from 'ag-grid-community';

interface CustomTextFilterModel {
  value: any;
}

export class CustomTextFilter implements IFilterComp {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2541
  filterText: string | null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2541
  filterElement: HTMLInputElement | null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2541
  gui: HTMLElement;

  /**
   * The init(params) method is called on the filter once when created.
   */
  init(params: IFilterParams): void {
    this.filterText = null;
    this.setUpGui(params);
  }

  /**
   * Helper function to handle ui setup.
   */
  setUpGui(params: IFilterParams) {
    this.gui = document.createElement('div');
    this.gui.classList.add('filter-container');
    this.gui.innerHTML = '<input type="text" class="filter-input">';
    this.filterElement = this.gui.querySelector('.filter-input');

    if (this.filterElement) {
      this.filterElement.addEventListener('input', (event: any) => {
        this.filterText = event.target;
        params.filterChangedCallback();
      });
    }
  }

  /**
   * Returns the gui for this filter.
   *
   * The GUI can be a) a string of html or b) a DOM element or node.
   */
  getGui(): any {
    return this.gui;
  }

  /**
   * Whether filter is active.
   *
   * If active, the data in the grid will be filtered. We are always returning `false` here, as we
   * do not want the grid to handle our filter. We're just using the filter/floating filter framework
   * that ag-grid provides out of the box to manage filter state.
   */
  isFilterActive(): boolean {
    return false;
  }

  /**
   * Filtering function that must be passed.
   *
   * Again, we are always returning true, though, really, we should never use this as the filter
   * should always be inactive based on the above.
   */
  doesFilterPass(): boolean {
    return true;
  }

  /**
   * Gets filter state.
   */
  getModel(): CustomTextFilterModel {
    return { value: this.filterElement?.value };
  }

  /**
   * Sets the filter state.
   */
  setModel(model: CustomTextFilterModel): void {
    if (this.filterElement) {
      this.filterElement.value = model.value;
    }
  }

  /**
   * Gets a string version of the model.
   *
   * This allows communication with a floating filter. At this point in time, the flowing filter is
   * the ONLY way a user can edit a filter. We are not displaying the full filter anywhere. It would
   * be possible to create full filters that are more complex than a simpler floating filter.
   */
  getModelAsString(): string {
    return this.getModel().value;
  }
}
