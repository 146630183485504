import { createAction, props } from '@ngrx/store';
import { Token } from '@mode/shared/authorization/contract';

/**********************/
/*   DATA INDEX AUTH  */
/**********************/
export const dataIndexAuthNeeded = createAction('[Authorization] DataIndex authorization needed');
export const dataIndexAuthNeededForDefinitionEditor = createAction(
  '[Authorization | Definition Editor] DataIndex authorization needed'
);

export const dataIndexAuthReceived = createAction(
  '[Authorization] DataIndex authorization received',
  props<{ token: Token }>()
);

export const dataIndexAuthExpired = createAction('[Authorization] DataIndex authorization expired');

/**********************/
/*   AI GATEWAY AUTH  */
/**********************/
export const aiGatewayAuthNeeded = createAction('[Authorization] AiGateway authorization needed');
export const aiGatewayAuthNeededForDefinitionEditor = createAction(
  '[Authorization | Definition Editor] AiGateway authorization needed'
);

export const aiGatewayAuthReceived = createAction(
  '[Authorization] AiGateway authorization received',
  props<{ token: Token }>()
);

export const aiGatewayAuthExpired = createAction('[Authorization] AiGateway authorization expired');
