/**
 * This file is an abstraction for icon bundles that need to be imported
 * for use with `<capra-icon>` in the product.
 */

import {
  capraIconCaretDown,
  capraIconCalendar,
  capraIconGear,
  capraIconCaretRight,
  capraIconCheck,
  capraIconChevronRight,
  capraIconClock,
  capraIconClose,
  capraIconDotIndicator,
  capraIconFieldBoolean,
  capraIconFieldCalcBoolean,
  capraIconFieldCalcDate,
  capraIconFieldCalcNumeric,
  capraIconFieldCalcString,
  capraIconFieldDate,
  capraIconFieldNumeric,
  capraIconFieldString,
  capraIconInfoOutline,
  capraIconPaginationFirst,
  capraIconPaginationLast,
  capraIconPaginationNext,
  capraIconPaginationPrevious,
  capraIconPencil,
  capraIconRelevantValues,
  capraIconSearch,
  capraIconWarning,
  capraIlloErrorDataset,
  capraIlloFunnelGray,
  capraIlloNoResultTable,
  completeIconSet,
  capraIconGranularityBack,
  capraRebrandIlloAddMembersError,
  capraRebrandIlloAddMembersSearch,
  capraRebrandIlloAddMembersSuccess,
  capraRebrandIlloAiGenerate,
  capraRebrandIlloAirBalloon,
  capraRebrandIlloChartThumbArea,
  capraRebrandIlloChartThumbArea100,
  capraRebrandIlloChartThumbBarHoriz,
  capraRebrandIlloChartThumbBarVert,
  capraRebrandIlloChartThumbBar100Horiz,
  capraRebrandIlloChartThumbBar100Vert,
  capraRebrandIlloChartThumbBigvalue,
  capraRebrandIlloChartThumbDonut,
  capraRebrandIlloChartThumbGroupedBarHoriz,
  capraRebrandIlloChartThumbGroupedBarVert,
  capraRebrandIlloChartThumbHistogram,
  capraRebrandIlloChartThumbLineBar,
  capraRebrandIlloChartThumbLine,
  capraRebrandIlloChartThumbPie,
  capraRebrandIlloChartThumbPivotTable,
  capraRebrandIlloChartThumbScatterplot,
  capraRebrandIlloChartThumbTable,
  capraRebrandIlloConnectDatabase,
  capraRebrandIlloDashboards,
  capraRebrandIlloDatasetSql,
  capraRebrandIlloDatasetUrl,
  capraRebrandIlloDocs,
  capraRebrandIlloEmptyReport,
  capraRebrandIlloErrorDataset,
  capraRebrandIlloFormError,
  capraRebrandIlloFormSuccess,
  capraRebrandIlloFunnelGray,
  capraRebrandIlloGeneratingExportFail,
  capraRebrandIlloGeneratingExportSuccess,
  capraRebrandIlloGeneratingExport,
  capraRebrandIlloGenericEmptyState,
  capraRebrandIlloGenericSuccess,
  capraRebrandIlloGenericWarning,
  capraRebrandIlloGhostUiWarning,
  capraRebrandIlloIconLock,
  capraRebrandIlloInvite,
  capraRebrandIlloLoading1,
  capraRebrandIlloLoading,
  capraRebrandIlloNewQueryEmptyState,
  capraRebrandIlloNoResultTable,
  capraRebrandIlloPdfReport,
  capraRebrandIlloQueryEditor,
  capraRebrandIlloSearchEmptyState,
  capraRebrandIlloStudioRunPopover,
  capraRebrandIlloTileNoPreview,
  capraRebrandIlloTsLogoEmblem,
  capraRebrandIlloUseData,
  capraRebrandIlloVisualExplorer,
  capraRebrandIlloVizEditorEmptyState,
  capraRebrandIlloWriteSql,
} from '@mode/capra';

const rebrandAppIcons = [
  capraRebrandIlloAddMembersError,
  capraRebrandIlloAddMembersSearch,
  capraRebrandIlloAddMembersSuccess,
  capraRebrandIlloAiGenerate,
  capraRebrandIlloAirBalloon,
  capraRebrandIlloChartThumbArea,
  capraRebrandIlloChartThumbArea100,
  capraRebrandIlloChartThumbBarHoriz,
  capraRebrandIlloChartThumbBarVert,
  capraRebrandIlloChartThumbBar100Horiz,
  capraRebrandIlloChartThumbBar100Vert,
  capraRebrandIlloChartThumbBigvalue,
  capraRebrandIlloChartThumbDonut,
  capraRebrandIlloChartThumbGroupedBarHoriz,
  capraRebrandIlloChartThumbGroupedBarVert,
  capraRebrandIlloChartThumbHistogram,
  capraRebrandIlloChartThumbLineBar,
  capraRebrandIlloChartThumbLine,
  capraRebrandIlloChartThumbPie,
  capraRebrandIlloChartThumbPivotTable,
  capraRebrandIlloChartThumbScatterplot,
  capraRebrandIlloChartThumbTable,
  capraRebrandIlloConnectDatabase,
  capraRebrandIlloDashboards,
  capraRebrandIlloDatasetSql,
  capraRebrandIlloDatasetUrl,
  capraRebrandIlloDocs,
  capraRebrandIlloEmptyReport,
  capraRebrandIlloErrorDataset,
  capraRebrandIlloFormError,
  capraRebrandIlloFormSuccess,
  capraRebrandIlloFunnelGray,
  capraRebrandIlloGeneratingExportFail,
  capraRebrandIlloGeneratingExportSuccess,
  capraRebrandIlloGeneratingExport,
  capraRebrandIlloGenericEmptyState,
  capraRebrandIlloGenericSuccess,
  capraRebrandIlloGenericWarning,
  capraRebrandIlloGhostUiWarning,
  capraRebrandIlloIconLock,
  capraRebrandIlloInvite,
  capraRebrandIlloLoading1,
  capraRebrandIlloLoading,
  capraRebrandIlloNewQueryEmptyState,
  capraRebrandIlloNoResultTable,
  capraRebrandIlloPdfReport,
  capraRebrandIlloQueryEditor,
  capraRebrandIlloSearchEmptyState,
  capraRebrandIlloStudioRunPopover,
  capraRebrandIlloTileNoPreview,
  capraRebrandIlloTsLogoEmblem,
  capraRebrandIlloUseData,
  capraRebrandIlloVisualExplorer,
  capraRebrandIlloVizEditorEmptyState,
  capraRebrandIlloWriteSql,
];
/**
 * Application Icons
 *
 * This bundle represents all icons that are included in the application context
 * (meaning this does not include the embeds).
 */
export const APP_ICONS_MODE = completeIconSet.filter(
  // filter out rebranded icons
  (icon) => !rebrandAppIcons.some((rIcon) => rIcon.name === icon.name && rIcon.data === icon.data)
);

export const APP_ICONS_ANALYST_STUDIO = [
  // filter out icons that will be rebranded
  ...APP_ICONS_MODE.filter((icon) => !rebrandAppIcons.some((rIcon) => rIcon.name === icon.name)),
  // add rebranded icons
  ...rebrandAppIcons,
];

/**
 * Embed Icons
 *
 * Add any icons that need to be accessible in the embed here. Embed icons need to be
 * registered in both the `embed-report.module` and the `embed-report-builder.module` as
 * they act as two separate entrypoints. This bundle will help facilitate maintaining
 * the icons available between those two entry points, as we can assume they're always
 * the same.
 *
 * Note - We should only add icons to this bundle when they are necessary in the embed.
 *   The intention is to keep this bundle smaller than the general app bundle by only
 *   including icons that are specifically required in the embed.
 */
export const EMBED_ICONS_MODE = [
  capraIconCaretDown,
  capraIconCalendar,
  capraIconGear,
  capraIconCaretRight,
  capraIconCheck,
  capraIconChevronRight,
  capraIconClock,
  capraIconClose,
  capraIconDotIndicator,
  capraIconFieldBoolean,
  capraIconFieldCalcBoolean,
  capraIconFieldCalcDate,
  capraIconFieldCalcNumeric,
  capraIconFieldCalcString,
  capraIconFieldDate,
  capraIconFieldNumeric,
  capraIconFieldString,
  capraIconGranularityBack,
  capraIconInfoOutline,
  capraIconPaginationFirst,
  capraIconPaginationLast,
  capraIconPaginationNext,
  capraIconPaginationPrevious,
  capraIconPencil,
  capraIconRelevantValues,
  capraIconSearch,
  capraIconWarning,
  capraIlloErrorDataset,
  capraIlloFunnelGray,
  capraIlloNoResultTable,
];

export const EMBED_ICONS_ANALYST_STUDIO = [...EMBED_ICONS_MODE];
