import { Injectable } from '@angular/core';
import { AuthFacade, Token } from '@mode/shared/authorization/contract';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';

@Injectable({ providedIn: 'root' })
export class AiGatewayAuthFacade implements AuthFacade {
  public authToken$ = this.store.select(AuthSelectors.selectAiGatewayToken).pipe(filter((t): t is Token => t !== null));

  public requestAuthorization() {
    this.store.dispatch(AuthActions.aiGatewayAuthNeeded());
  }

  public requestAuthorizationForDefinitionEditor(): void {
    this.store.dispatch(AuthActions.aiGatewayAuthNeededForDefinitionEditor());
  }

  constructor(private store: Store) {}

  public authorizationExpired(): void {
    this.store.dispatch(AuthActions.aiGatewayAuthExpired());
  }
}
