<button
  class="prev-button"
  [disabled]="pageButtons[0]?.active"
  (click)="prevButtonClicked()"
>
  <capra-icon icon="icon-chevron"></capra-icon>
  <span>Prev</span>
</button>

<ng-container *ngFor="let pageButton of pageButtons">
  <button
    *ngIf="pageButton.pageNumber"
    class="paginator-button"
    (click)="pageButtonClicked(pageButton.pageNumber)"
  >
    <span [ngClass]="{'is-active': pageButton.active}">{{pageButton.pageNumber}}</span>
  </button>
  <span *ngIf="pageButton.pageNumber === null" class="paginator-ellipsis">
    ...
  </span>
</ng-container>

<button
  class="next-button"
  [disabled]="pageButtons[pageButtons.length - 1]?.active"
  (click)="nextButtonClicked()"
>
  <span>Next</span>
  <capra-icon icon="icon-chevron"></capra-icon>
</button>
