export interface ReportListItem {
  token: string;
  creatorName: string;
  previewImgUrl: string;
  isShortcut: boolean; // For showing whether this report is just a link to another
  isUnknownPersonalSpace: boolean;
  isStarred: boolean;
  title: string;
  spaceName: string;
}

export interface SpaceReportListItem extends ReportListItem {
  isPinned: boolean; // if pinned in the space
}

export interface SpaceLink {
  token: string;
  name: string;
}

export interface SpaceListItem {
  token: string;
  name: string;
  creatorName: string;
  userIsMember: boolean; // if the current user is a member of this space
  reportCount: number;
  memberCount: number;
}

export const enum ReportListFilterOptions {
  All = 'all',
  Archived = 'archived',
  Titled = 'titled',
  Untitled = 'untitled',
}

// Sorts enumerated by algolia search indices
export const enum ReportListSortOptions {
  DateEdited = 'reportLastEdited',
  TitleAsc = 'reportAlphabetical',
  TitleDesc = 'reportAlphabeticalDesc',
}

export interface DatasourceLink {
  token: string;
  name: string;
}

export interface DefinitionListItem {
  token: string;
  name: string;
  creatorName: string;
  modifiedDate: number; // millisecond timestamp
  description: string;
}

export interface SpaceResource {
  name: string;
  'viewable?': boolean;
  space_type: SpaceType;
  default_access_level: string | null;

  _links: {
    web: { href: string };
  };
}

export enum SpaceType {
  private = 'private',
  community = 'community',
  custom = 'custom',
}

export enum CollectionAccessLevel {
  Edit = 'edit',
  View = 'view',
  Restricted = 'restricted',
}

export enum SpaceEntitlementType {
  User = 'User',
  UserGroup = 'UserGroup',
}

export interface EntitlementUpdateSuccessEvent {
  entitlementToken: string;
}

export interface EntitlementUpdateErrorEvent {
  entitlementToken: string;
  error: Error;
}

export interface CollectionUpdateSuccessEvent {
  collectionToken: string;
}

export interface CollectionUpdateErrorEvent {
  collectionToken: string;
  error: Error;
}
