import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { IconComponent, TooltipDirective } from '@mode/capra';
import { MenuItem } from './labeled-menu.component';

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent, MatLegacyMenuModule, MatDividerModule, TooltipDirective],
  selector: 'mode-labeled-menu-item',
  templateUrl: './labeled-menu-item-renderer.component.html',
  styleUrls: ['./labeled-menu-item-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledMenuItemRendererComponent implements AfterViewInit, OnChanges {
  @Input() isSelected = false;
  @Input() menuItem!: MenuItem;
  @Input() activeIcon: 'icon-dot-indicator' | 'icon-check' = 'icon-dot-indicator';

  @Output() menuChange = new EventEmitter<MenuItem>();

  @ViewChild('customComponent', { read: ViewContainerRef }) customComponent: ViewContainerRef | undefined;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.setDynamicRenderer();
  }

  ngAfterViewInit(): void {
    this.setDynamicRenderer();
  }

  setDynamicRenderer() {
    if (this.customComponent && this.menuItem.decorator?.component !== undefined) {
      // Clear the existing component
      this.customComponent.clear();
      // Create a new component based on the specified type
      const decorator = this.menuItem.decorator;
      const compRef = this.customComponent.createComponent(decorator.component);
      // Set the component inputs
      decorator.inputs.forEach((value, input) => {
        compRef.instance[input] = value;
      });
      this.changeDetectorRef.detectChanges();
    }
  }

  onSelect($event: MouseEvent) {
    $event.stopPropagation();
    this.menuChange.emit(this.menuItem);
  }
}
