import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { AvatarComponent, AvatarOptions, AvatarOptionsType, AvatarSize, AvatarType } from '@mode/capra';
import { AvatarTypes } from '@mode/shared/contract-common';

@Component({
  standalone: true,
  selector: 'mode-workspace-avatar',
  imports: [AvatarComponent, CommonModule],
  templateUrl: './workspace-avatar.component.html',
  styleUrls: ['./workspace-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceAvatarComponent implements OnChanges {
  @Input() type: AvatarTypes.AvatarType = AvatarTypes.AvatarType.Initials;
  @Input() initials = '';
  @Input() shape: 'square' | 'circle' = 'square';
  @Input() colorClass = '';
  @Input() imageSrc = '';
  @Input() imageName = '';
  @Input() isCommunity = false;
  public options: AvatarOptions = { type: AvatarOptionsType.Initials };
  public capraAvatarSize = AvatarSize.Small;
  public capraAvatarType = AvatarType.Org;
  public loading = true;

  public ngOnChanges(changes: SimpleChanges): void {
    this.loading = !this.colorClass;
    this.options = {
      type: changes['type']?.currentValue ?? this.type,
      initials: changes['initials']?.currentValue ?? this.initials,
      color_class: changes['colorClass']?.currentValue ?? this.colorClass,
      href: changes['imageSrc']?.currentValue ?? this.imageSrc,
    };
    if (changes['shape']) {
      if (changes['shape'].currentValue === 'square') {
        this.capraAvatarSize = AvatarSize.Small;
        this.capraAvatarType = AvatarType.Org;
      } else {
        this.capraAvatarSize = AvatarSize.ExtraSmall;
        this.capraAvatarType = AvatarType.User;
      }
    }
  }
}
