import { DataType, DateTime } from '@mode-switch/express';
import { QueryResult } from '@mode-switch/query';

import { ChartDataTypes, ChartTypes, DataProfilingTypes, FlamingoTypes } from '@mode/shared/contract-common';
import { renderUrlCellContents } from '../ag-grid-helpers/ag-grid-renderer-helpers';
import { DEFAULT_FLAMINGO_PAGE_SIZE } from '../flamingo/flamingo-helpers';
import * as FlatTableColorScheme from './flat-table-color-scheme';

export const DECIMAL_MAX = 12;

/**
 * Available table cell renderers
 */
export enum TableCellRenderer {
  Url = 'urlCellRenderer',
  Image = 'imageCellRenderer',
  Default = 'default',
}

// Helper to reflect the subset of format types that use the Default renderer.
type DefaultRendererFormatTypes = Exclude<ChartTypes.FormatType, ChartTypes.FormatType.Url>;

/**
 * Map shortcuts to a format type.
 *
 * Since multiple shortcuts can use the same format type, this helper is useful.
 */
export function getFormatFromShortcut(shortcutType: ChartTypes.FormatShortcutType): ChartTypes.FormatType {
  switch (shortcutType) {
    case ChartTypes.FormatShortcutType.Automatic:
    case ChartTypes.FormatShortcutType.PlainText:
      return ChartTypes.FormatType.Default;
    case ChartTypes.FormatShortcutType.ShortNumber:
    case ChartTypes.FormatShortcutType.LongNumber:
      return ChartTypes.FormatType.Number;
    case ChartTypes.FormatShortcutType.Percent:
      return ChartTypes.FormatType.Percent;
    case ChartTypes.FormatShortcutType.DateAndTime:
    case ChartTypes.FormatShortcutType.YearMonthDate:
    case ChartTypes.FormatShortcutType.MonthYear:
    case ChartTypes.FormatShortcutType.MonthDay:
    case ChartTypes.FormatShortcutType.Year:
      return ChartTypes.FormatType.Date;
    case ChartTypes.FormatShortcutType.Currency:
    case ChartTypes.FormatShortcutType.Accounting:
      return ChartTypes.FormatType.Financial;
    case ChartTypes.FormatShortcutType.Url:
      return ChartTypes.FormatType.Url;
    case ChartTypes.FormatShortcutType.FullName:
    case ChartTypes.FormatShortcutType.Numeric:
    case ChartTypes.FormatShortcutType.Ordinal:
    case ChartTypes.FormatShortcutType.ShortName:
      return ChartTypes.FormatType.DatePart;
    default:
      // Every type should be handled in a case above, but return Default in the catch all.
      return ChartTypes.FormatType.Default;
  }
}

/**
 * Helper to determine what col types can use which format shortcuts.
 */
export function canColTypeUseShortcut(shortcutType: ChartTypes.FormatShortcutType, columnType: DataType.Name): boolean {
  switch (shortcutType) {
    case ChartTypes.FormatShortcutType.Automatic:
      // All columns can use
      return true;
    case ChartTypes.FormatShortcutType.PlainText:
      // All columns can use
      return true;
    case ChartTypes.FormatShortcutType.ShortNumber:
      switch (columnType) {
        case DataType.Name.Float:
        case DataType.Name.Integer:
        case DataType.Name.Decimal:
          return true;
      }
      break;
    case ChartTypes.FormatShortcutType.LongNumber:
      switch (columnType) {
        case DataType.Name.Float:
        case DataType.Name.Integer:
        case DataType.Name.Decimal:
          return true;
      }
      break;
    case ChartTypes.FormatShortcutType.Percent:
      switch (columnType) {
        case DataType.Name.Float:
        case DataType.Name.Integer:
        case DataType.Name.Decimal:
          return true;
      }
      break;
    case ChartTypes.FormatShortcutType.DateAndTime:
    case ChartTypes.FormatShortcutType.YearMonthDate:
    case ChartTypes.FormatShortcutType.MonthYear:
    case ChartTypes.FormatShortcutType.MonthDay:
    case ChartTypes.FormatShortcutType.Year:
      switch (columnType) {
        case DataType.Name.Timestamp:
        case DataType.Name.DateTime:
          return true;
      }
      break;
    case ChartTypes.FormatShortcutType.Currency:
      switch (columnType) {
        case DataType.Name.Float:
        case DataType.Name.Integer:
        case DataType.Name.Decimal:
          return true;
      }
      break;
    case ChartTypes.FormatShortcutType.Accounting:
      switch (columnType) {
        case DataType.Name.Float:
        case DataType.Name.Integer:
        case DataType.Name.Decimal:
          return true;
      }
      break;
    case ChartTypes.FormatShortcutType.Url:
      switch (columnType) {
        case DataType.Name.String:
          return true;
      }
      break;
  }

  return false;
}

/**
 * Helper to determine what col types can use which formatters.
 */
export function canColTypeUseFormatter(formatterType: ChartTypes.FormatType, columnType: DataType.Name) {
  switch (formatterType) {
    case ChartTypes.FormatType.Default:
      // All columns can use
      return true;
    case ChartTypes.FormatType.Number:
      switch (columnType) {
        case DataType.Name.Decimal:
        case DataType.Name.Float:
        case DataType.Name.Integer:
          return true;
        default:
          return false;
      }
    case ChartTypes.FormatType.Percent:
      switch (columnType) {
        case DataType.Name.Decimal:
        case DataType.Name.Float:
        case DataType.Name.Integer:
          return true;
        default:
          return false;
      }
    case ChartTypes.FormatType.Financial:
      switch (columnType) {
        case DataType.Name.Decimal:
        case DataType.Name.Float:
        case DataType.Name.Integer:
          return true;
        default:
          return false;
      }
    case ChartTypes.FormatType.Date:
      switch (columnType) {
        case DataType.Name.Timestamp:
        case DataType.Name.DateTime:
          return true;
        default:
          return false;
      }
    case ChartTypes.FormatType.Url:
      switch (columnType) {
        case DataType.Name.String:
          return true;
        default:
          return false;
      }
    case ChartTypes.FormatType.DatePart:
      switch (columnType) {
        case DataType.Name.Integer:
          return true;
        default:
          return false;
      }
    default:
      return false;
  }
}

/**
 * Get allowed format types by the column type.
 *
 * Return format is a list with all types with a `disabled` option set. Can be
 * filtered if necessary, but is usabled for custom dropdown fields this way.
 */
export function getAllowedFormatTypesByCol(colType: DataType.Name, useNewFormating = false) {
  const formatKeys = [
    ChartTypes.FormatType.Date,
    ChartTypes.FormatType.Default,
    ChartTypes.FormatType.Financial,
    ChartTypes.FormatType.Number,
    ChartTypes.FormatType.Percent,
    ChartTypes.FormatType.Url,
  ];

  return formatKeys.map((key) => ({
    value: key,
    label: useNewFormating ? getNewFormatLabelByType(key) : getFormatLabelByType(key),
    disabled: !canColTypeUseFormatter(key, colType),
  }));
}

/**
 * Helper to determine if a format type should be able to change decimals.
 */
export function canFormatExpressDecimals(colFormat: ChartTypes.FormatType): boolean {
  switch (colFormat) {
    case ChartTypes.FormatType.Number:
    case ChartTypes.FormatType.Percent:
    case ChartTypes.FormatType.Financial:
      return true;
  }

  return false;
}

/**
 * Generate a default ChartTypes.FieldFormatMap from scratch for flatTables.
 */
export function getDefaultFieldFormatMapFromColumns(
  columns: FlamingoTypes.RecordsResult['columns']
): ChartTypes.FieldFormatMap {
  const fieldFormatMap: ChartTypes.FieldFormatMap = {};

  columns.forEach((column) => {
    fieldFormatMap[column.source] = getDefaultFieldFormatByColumn(column);
  });

  return fieldFormatMap;
}

export function getDefaultFieldFormat(type: DataType.Name): ChartTypes.FieldFormat {
  return {
    paneFormat: {
      [ChartTypes.FieldPaneTypes.Default]: getDefaultTextFormat(type),
    },
    axisFormat: {},
    headerFormat: {},
    legendFormat: {},
  };
}

/**
 * Convenience method to get default field format by column from table results.
 */
export function getDefaultFieldFormatByColumn(column: FlamingoTypes.ResultColumn): ChartTypes.FieldFormat {
  return getDefaultFieldFormat(column.type.name);
}

/**
 * Generate the default settings for the added table column.
 */
export function getDefaultTextFormat(
  type: DataType.Name.Boolean | DataType.Name.String | DataType.Name.Regexp | DataType.Name.Null
): ChartTypes.TextFormat.DefaultType;
export function getDefaultTextFormat(
  type: DataType.Name.Integer | DataType.Name.Float | DataType.Name.Decimal
): ChartTypes.TextFormat.NumberType;
export function getDefaultTextFormat(
  type: DataType.Name.DateTime | DataType.Name.Timestamp
): ChartTypes.TextFormat.DateType;
export function getDefaultTextFormat(type: DataType.Name): ChartTypes.TextFormat;
export function getDefaultTextFormat(type: DataType.Name): ChartTypes.TextFormat {
  let textFormat: ChartTypes.TextFormat = {
    colType: type,
    type: ChartTypes.FormatType.Default,
    horizontalAlignment: ChartTypes.HorizontalAlignment.Left,
  };
  switch (type) {
    // Numeric defaults.  Don't use separators, because values might be dates (ie. DatePart)
    case DataType.Name.Integer:
    case DataType.Name.Decimal:
    case DataType.Name.Float:
      textFormat.type = ChartTypes.FormatType.Number;
      textFormat.horizontalAlignment = ChartTypes.HorizontalAlignment.Right;
      textFormat.decimalStyle = '.';
      textFormat.separator = '';
      textFormat.displayUnit = '';
      textFormat.precision = undefined;
      textFormat.grouping = 3;
      break;

    // Timestamp defaults.
    case DataType.Name.DateTime:
    case DataType.Name.Timestamp:
      textFormat.type = ChartTypes.FormatType.Date;
      textFormat.horizontalAlignment = ChartTypes.HorizontalAlignment.Right;
      textFormat = {
        ...textFormat,
        ...getTextFormatOptionsByShortcutType(ChartTypes.FormatShortcutType.DateAndTime),
      };
      break;

    // Other defaults.
    case DataType.Name.Boolean:
    case DataType.Name.String:
    case DataType.Name.Null:
    case DataType.Name.Regexp:
      textFormat.type = ChartTypes.FormatType.Default;
      break;
  }

  return textFormat;
}

/**
 * Generate the settings for the 'PlainText' format.
 *
 * This is a special case since some col types will need to use different formatters for this shortcut.
 * Else, we can just get the default formats.
 */
export function getPlainTextFormat(formula: string, type: DataType.Name): ChartTypes.TextFormat {
  let textFormat: ChartTypes.TextFormat = {
    colType: type,
    type: ChartTypes.FormatType.Default,
    horizontalAlignment: ChartTypes.HorizontalAlignment.Left,
  };
  switch (type) {
    // timestamp default
    case DataType.Name.DateTime:
    case DataType.Name.Timestamp:
      textFormat.horizontalAlignment = ChartTypes.HorizontalAlignment.Right;
      textFormat.type = ChartTypes.FormatType.Date;
      textFormat = {
        ...textFormat,
        ...getTextFormatOptionsByShortcutType(ChartTypes.FormatShortcutType.TimeISO),
      };
      return textFormat;
  }

  return getDefaultTextFormat(type);
}

/**
 * Get default table column settings (format.flatTable.columns) from result columns
 */
export function getDefaultSettingsByColumns(
  columns: FlamingoTypes.RecordsResult['columns']
): ChartTypes.FlatTableColumnSettings[] {
  return columns.map((column) => getDefaultSettingsByColumn(column));
}

/**
 * Convenience method to get default settings by column from table results.
 */
export function getDefaultSettingsByColumn(column: FlamingoTypes.ResultColumn): ChartTypes.FlatTableColumnSettings {
  return getDefaultSettings(column.source, column.type.name);
}

/**
 * Generate the default settings for the added table column.
 */
export function getDefaultSettings(formula: string, type: DataType.Name): ChartTypes.FlatTableColumnSettings {
  const columnFormatting: ChartTypes.FlatTableColumnSettings = {
    id: formula,
    colType: type,
  };

  return columnFormatting;
}

/**
 * Get default column settings for all columns from a table query.
 */
export function getDefaultTableColumnSettingsFromColumns(
  columns: FlamingoTypes.RecordsResult['columns']
): ChartTypes.FlatTableColumnSettings[] {
  return columns.map((column) => getDefaultTableColumnSettingFromColumn(column));
}

/**
 * Generate default column settings for a flamingo results column.
 */
export function getDefaultTableColumnSettingFromColumn(
  column: FlamingoTypes.ResultColumn
): ChartTypes.FlatTableColumnSettings {
  return {
    id: column.source,
    colType: column.type.name,
  };
}

/**
 * Test if backfill is needed for the general format blob
 */
export function needsFlatTableFormatBackfill(flatTableFormat: ChartTypes.ChartFormat['flatTable']) {
  const generalSettings = flatTableFormat.general;
  const defaultGeneralSettings = getDefaultGeneralFormat(ChartTypes.FlatTableDefaultGeneralFormat.FlatTableViz);

  for (const property of Object.keys(defaultGeneralSettings)) {
    if (!generalSettings.hasOwnProperty(property)) {
      return true;
    }
  }

  return false;
}

/**
 * Test if backfill is needed for flatTable.
 *
 * This backfill accounts for the following cases:
 * - Very old flatTables that have no `format.flatTable` blob at all
 * - New general settings are added
 * - The flatTable -> fieldFormats refactor - filling out fieldFormats blob
 */
export function needsFlatTableFormatBlobBackfill(normalizedViz: ChartTypes.EncodedChart) {
  // If flat table format does not exist, it must be filled
  if (!normalizedViz.format.flatTable) {
    return true;
  }

  // Test if new general settings have been added
  const generalSettings = normalizedViz.format.flatTable.general;
  const defaultGeneralSettings = getDefaultGeneralFormat(ChartTypes.FlatTableDefaultGeneralFormat.FlatTableViz);

  for (const property of Object.keys(defaultGeneralSettings)) {
    if (!generalSettings.hasOwnProperty(property)) {
      return true;
    }
  }

  // If format.flatTable.columns exists, AND contains old properties, it also must be filled (new formula keys)
  const formatColumnSettings = normalizedViz.format.flatTable.columns;
  if (
    formatColumnSettings &&
    formatColumnSettings.length > 0 &&
    formatColumnSettings[0].hasOwnProperty('formatOptions')
  ) {
    return true;
  }

  return false;
}
/**
 * Test if backfill is needed for flatTable.
 *
 * This backfill accounts for the following cases:
 * - The flatTable -> fieldFormats refactor - filling out fieldFormats blob
 */
export function needsFlatTableFieldFormatBackfill(normalizedViz: ChartTypes.EncodedChart) {
  // If fieldFormats doesn't exist, it must be filled
  if (!normalizedViz.fieldFormats) {
    return true;
  }

  return false;
}

/**
 * Helper to spread stored settings over the default general settings to fill
 * any gaps.
 */
export function backfillFlatTableGeneralFormat(
  flatTableFormat: ChartTypes.ChartFormat['flatTable']
): ChartTypes.ChartFormat['flatTable'] {
  const defaults = getDefaultGeneralFormat(ChartTypes.FlatTableDefaultGeneralFormat.FlatTableViz);

  return {
    ...flatTableFormat,
    general: {
      ...defaults,
      ...flatTableFormat.general,
    },
  };
}

/**
 * Helper to change column id to use formula instead of name.
 */
export function backfillFlatTableColumnSettings(
  flatTableFormat: ChartTypes.ChartFormat['flatTable'],
  columns: FlamingoTypes.RecordsResult['columns']
): ChartTypes.ChartFormat['flatTable'] {
  if (flatTableFormat.columns.length > 0 && flatTableFormat.columns[0].hasOwnProperty('formatOptions')) {
    const newSettingsColumns: ChartTypes.FlatTableColumnSettings[] = columns.map((column) => ({
      id: column.source,
      colType: column.type.name,
    }));

    return {
      ...flatTableFormat,
      columns: newSettingsColumns,
    };
  }

  return flatTableFormat;
}

/**
 * Get default textFormat options.
 *
 * This is used as a global default. ie - spreading settings over to ensure all
 * options are present.
 */
export function getDefaultTextFormatOptions(): ChartTypes.TextFormat {
  return {
    type: ChartTypes.FormatType.Default,
    shortcutType: ChartTypes.FormatShortcutType.PlainText,
    precision: 2,
    decimalStyle: '.',
    separator: ',',
    grouping: 3,
    percentTransform: true,
    currencySymbolPrefix: '$',
    currencySymbolSuffix: '',
    currencyPreset: ChartTypes.CurrencyPreset.USD,
    horizontalAlignment: ChartTypes.HorizontalAlignment.Left,
    dateFormat: 'hour',
    negativeBehavior: 'minusSign',
    linkColor: 'blue',
    linkStyle: 'underline',
    displayUnit: '',
    shouldEncodeURL: true,
  };
}

/**
 * Get format shortcut definition
 */
export function getTextFormatOptionsByShortcutType(
  formatShortcut: ChartTypes.FormatShortcutType
): Partial<ChartTypes.TextFormat> {
  let textFormat = {};
  switch (formatShortcut) {
    case ChartTypes.FormatShortcutType.Automatic:
      // resets back to default styling for column type
      break;
    case ChartTypes.FormatShortcutType.PlainText:
      // No specific format, reset to empty format object
      textFormat = {};
      break;
    case ChartTypes.FormatShortcutType.ShortNumber:
      // comma separators
      // 1,006
      textFormat = {
        precision: 0,
        decimalStyle: '.',
        separator: ',',
        grouping: 3,
        displayUnit: '',
      };
      break;
    case ChartTypes.FormatShortcutType.LongNumber:
      // comma separators plus 2 decimal places (always 2 decimals)
      // 1,006.00
      textFormat = {
        precision: 2,
        decimalStyle: '.',
        separator: ',',
        grouping: 3,
        displayUnit: '',
      };
      break;
    case ChartTypes.FormatShortcutType.Percent:
      // move decimal place 2, always display 2 decimal places, add %
      // 34.65%
      textFormat = {
        precision: 2,
        percentTransform: true,
        decimalStyle: '.',
        separator: ',',
        grouping: 3,
      };
      break;
    case ChartTypes.FormatShortcutType.MonthYear:
      // ie - Feb 2019
      textFormat = {
        dateFormat: 'full_mon',
      };
      break;
    case ChartTypes.FormatShortcutType.MonthDay:
      // ie - 08/10
      textFormat = {
        dateFormat: 'mon_day',
      };
      break;
    case ChartTypes.FormatShortcutType.Year:
      // ie - 2021
      textFormat = {
        dateFormat: 'year',
      };
      break;
    case ChartTypes.FormatShortcutType.YearMonthDate:
      // ie - 2019-02-28
      textFormat = {
        dateFormat: 'numeric_date',
      };
      break;
    case ChartTypes.FormatShortcutType.DateAndTime:
      // ie - 2019-02-28 02:32:34
      textFormat = {
        dateFormat: 'hour',
      };
      break;
    case ChartTypes.FormatShortcutType.TimeISO:
      textFormat = {};
      break;
    case ChartTypes.FormatShortcutType.Currency:
      // Currency
      // $1,006
      textFormat = {
        precision: 0,
        negativeBehavior: 'minusSign',
        currencyPreset: ChartTypes.CurrencyPreset.USD,
      };
      break;
    case ChartTypes.FormatShortcutType.Accounting:
      // TODO: this shortcut might be getting eliminated soon. if so, clean this up along with the rest of
      // the accounting related config.
      // negatives are in parens
      // ($1,006)
      textFormat = {
        precision: 0,
        negativeBehavior: 'parens',
        currencyPreset: ChartTypes.CurrencyPreset.USD,
      };
      break;
    case ChartTypes.FormatShortcutType.Url:
      // themed like a url with link color
      textFormat = {
        linkColor: 'blue',
        linkStyle: 'underline',
        shouldEncodeURL: true,
      };
      break;
  }

  return textFormat;
}

/**
 * Get cell renderer name by format type.
 *
 * This is used when providing the renderer to ag-grid, as it has already been
 * registered by name.
 */
export function getCellRendererNameByType(formatType: ChartTypes.FormatType): TableCellRenderer {
  switch (formatType) {
    case ChartTypes.FormatType.Url:
      return TableCellRenderer.Url;
    default:
      return TableCellRenderer.Default;
  }
}

/**
 * Get the function that builds the contents of the renderer.
 *
 * This is used when needed outside of the context of ag-grid. So, for example,
 * in the "Example" area of the advanced formatting modal.
 */
export function getCellRendererFunctionByType(
  formatType: ChartTypes.FormatType.Url
): (href: string, textFormat: ChartTypes.TextFormat) => HTMLAnchorElement;
export function getCellRendererFunctionByType(formatType: DefaultRendererFormatTypes): null;
export function getCellRendererFunctionByType(formatType: ChartTypes.FormatType): any;
export function getCellRendererFunctionByType(formatType: ChartTypes.FormatType) {
  switch (formatType) {
    case ChartTypes.FormatType.Url:
      return renderUrlCellContents;
    default:
      return null;
  }
}

/**
 * Get the format group label by type.
 */
export function getFormatLabelByType(formatType: ChartTypes.FormatType): string {
  switch (formatType) {
    case ChartTypes.FormatType.Number:
      return 'Number';
    case ChartTypes.FormatType.Percent:
      return 'Percent';
    case ChartTypes.FormatType.Date:
      return 'Date';
    case ChartTypes.FormatType.Financial:
      return 'Currency';
    case ChartTypes.FormatType.Url:
      return 'Url';
    default:
      return 'Plain text';
  }
}

/**
 * Get the format group label by type - show 'URL' in case of URL formatting.
 */
export function getNewFormatLabelByType(formatType: ChartTypes.FormatType): string {
  switch (formatType) {
    case ChartTypes.FormatType.Number:
      return 'Number';
    case ChartTypes.FormatType.Percent:
      return 'Percent';
    case ChartTypes.FormatType.Date:
      return 'Date';
    case ChartTypes.FormatType.Financial:
      return 'Currency';
    case ChartTypes.FormatType.Url:
      return 'URL';
    default:
      return 'Plain text';
  }
}

/**
 * Get the actual row height from density option.
 */
export function getSizeFromDensity(density: ChartTypes.FlatTableDensities): number {
  switch (density) {
    case ChartTypes.FlatTableDensities.Compact:
      return 22;
    case ChartTypes.FlatTableDensities.Standard:
      return 26;
    case ChartTypes.FlatTableDensities.Open:
      return 32;
    default:
      return 26;
  }
}

/**
 * Get the cell padding to pass into ag-grid from density.
 */
export function getCellPaddingFromDensity(density: ChartTypes.FlatTableDensities): number {
  switch (density) {
    case ChartTypes.FlatTableDensities.Compact:
      return 8;
    case ChartTypes.FlatTableDensities.Standard:
      return 8;
    case ChartTypes.FlatTableDensities.Open:
      return 16;
    default:
      return 8;
  }
}

/**
 * Get the density label from type
 */
export function getDensityLabelByType(density: ChartTypes.FlatTableDensities): string {
  switch (density) {
    case ChartTypes.FlatTableDensities.Compact:
      return 'Compact';
    case ChartTypes.FlatTableDensities.Standard:
      return 'Standard';
    case ChartTypes.FlatTableDensities.Open:
      return 'Open';
    default:
      return 'Undefined';
  }
}

/**
 * Create an array of objects for densities for use with `ng-options`.
 */
export function getDensitySelectOptions() {
  const densityKeys = [
    ChartTypes.FlatTableDensities.Compact,
    ChartTypes.FlatTableDensities.Open,
    ChartTypes.FlatTableDensities.Standard,
  ];

  return densityKeys.map((key) => ({
    value: key,
    name: getDensityLabelByType(key),
  }));
}

/**
 * Given a currency preset, get the ChartTypes.TextFormat options necessary for the financialFormatter to process a value.
 *
 */
export function getTextFormatByCurrencyPreset(
  currencyPreset: ChartTypes.CurrencyPreset
): Partial<ChartTypes.TextFormat.CurrencyType> {
  switch (currencyPreset) {
    case ChartTypes.CurrencyPreset.USD:
      return {
        currencySymbolPrefix: '$',
        currencySymbolSuffix: '',
        separator: ',',
        decimalStyle: '.',
        grouping: 3,
      };
    case ChartTypes.CurrencyPreset.EUR:
      return {
        currencySymbolPrefix: '€',
        currencySymbolSuffix: '',
        separator: ',',
        decimalStyle: '.',
        grouping: 3,
      };
    case ChartTypes.CurrencyPreset.JPY:
      return {
        currencySymbolPrefix: '¥',
        currencySymbolSuffix: '',
        separator: ',',
        decimalStyle: '.',
        grouping: 3,
      };
    case ChartTypes.CurrencyPreset.GBP:
      return {
        currencySymbolPrefix: '£',
        currencySymbolSuffix: '',
        separator: ',',
        decimalStyle: '.',
        grouping: 3,
      };
    case ChartTypes.CurrencyPreset.AUD:
      return {
        currencySymbolPrefix: '$',
        separator: ',',
        decimalStyle: '.',
        grouping: 3,
      };
    case ChartTypes.CurrencyPreset.CHF:
      return {
        currencySymbolPrefix: 'CHF',
        currencySymbolSuffix: '',
        separator: ' ',
        decimalStyle: ',',
        grouping: 3,
      };
    case ChartTypes.CurrencyPreset.CAD:
      return {
        currencySymbolPrefix: '$',
        currencySymbolSuffix: '',
        separator: ',',
        decimalStyle: '.',
        grouping: 3,
      };
    case ChartTypes.CurrencyPreset.MXN:
      return {
        currencySymbolPrefix: '$',
        currencySymbolSuffix: '',
        separator: ',',
        decimalStyle: '.',
        grouping: 3,
      };
    case ChartTypes.CurrencyPreset.CNY:
      return {
        currencySymbolPrefix: '¥',
        currencySymbolSuffix: '',
        separator: ',',
        decimalStyle: '.',
        grouping: 3,
      };
    case ChartTypes.CurrencyPreset.NZD:
      return {
        currencySymbolPrefix: '$',
        currencySymbolSuffix: '',
        separator: ',',
        decimalStyle: '.',
        grouping: 3,
      };
    default:
      return {
        currencySymbolPrefix: '$',
        currencySymbolSuffix: '',
        separator: ',',
        decimalStyle: '.',
        grouping: 3,
      };
  }
}

/**
 * Takes dataset col type and returns the relevant flamingo col type.
 *
 * Dataset col types don't seem to exist in the project as types, which is
 * why the parameter is `any`.
 */
export function convertColFromDatasetToFlamingo(column: any): FlamingoTypes.ResultColumn {
  switch (column.type) {
    case 'float':
      return QueryResult.Column.make<FlamingoTypes.ModeRole>(column.name, column.name, column.name, {
        name: DataType.Name.Float,
      });
    case 'datetime':
    default:
      return QueryResult.Column.make<FlamingoTypes.ModeRole>(column.name, column.name, column.name, {
        name: DataType.Name.String,
      });
  }
}

/**
 * Takes pivot result col type and returns the relevant flamingo col type.
 */
export function convertColFromPivotToFlamingo(column: ChartDataTypes.Column): FlamingoTypes.ResultColumn {
  switch (column.type.name) {
    case ChartDataTypes.Column.Type.Name.Boolean:
      return QueryResult.Column.make<FlamingoTypes.ModeRole>(column.header || column.name, column.name, column.name, {
        name: DataType.Name.Boolean,
      });
    case ChartDataTypes.Column.Type.Name.Integer:
      return QueryResult.Column.make<FlamingoTypes.ModeRole>(column.header || column.name, column.name, column.name, {
        name: DataType.Name.Integer,
      });
    case ChartDataTypes.Column.Type.Name.Float:
      return QueryResult.Column.make<FlamingoTypes.ModeRole>(column.header || column.name, column.name, column.name, {
        name: DataType.Name.Float,
      });
    case ChartDataTypes.Column.Type.Name.Decimal:
      return QueryResult.Column.make<FlamingoTypes.ModeRole>(column.header || column.name, column.name, column.name, {
        name: DataType.Name.Decimal,
        scale: column.type.scale,
        precision: column.type.precision,
      });
    case ChartDataTypes.Column.Type.Name.Date:
      return QueryResult.Column.make<FlamingoTypes.ModeRole>(column.header || column.name, column.name, column.name, {
        name: DataType.Name.Timestamp,
        unit: DateTime.TimeUnit.Millis,
      });
    case ChartDataTypes.Column.Type.Name.String:
    default:
      return QueryResult.Column.make<FlamingoTypes.ModeRole>(column.header || column.name, column.name, column.name, {
        name: DataType.Name.String,
      });
  }
}

/**
 * Takes a pivot dataset and returns `RawData` with flamingo typed columsn.
 */
export function getFlamingoRawDataFromDataset(dataset: ChartDataTypes.Dataset): ChartTypes.RawData {
  let convertedData: ChartTypes.RawData;
  if (dataset != null) {
    convertedData = {
      columns: dataset.columns.map((col) => convertColFromPivotToFlamingo(col)),
      content: dataset.content,
    };
  } else {
    convertedData = { columns: [], content: [] };
  }
  return convertedData;
}

/**
 * Create a default general format object for flatTable.
 */
export function getDefaultGeneralFormat(
  formatType: ChartTypes.FlatTableDefaultGeneralFormat
): ChartTypes.FlatTableFormatting {
  switch (formatType) {
    case ChartTypes.FlatTableDefaultGeneralFormat.FlatTableViz:
      // This is the default for flat table visualizations and is also used to backfill flat tables
      // when new settings get added to
      return {
        sizingStyle: 'sizeToFit',
        colorScheme: FlatTableColorScheme.getDefault(),
        density: ChartTypes.FlatTableDensities.Standard,
        colGridlines: true,
        rowGridlines: true,
        rowNumbers: false,
        dataProfilingEnabled: true,
        pageSize: DEFAULT_FLAMINGO_PAGE_SIZE,
      };

    case ChartTypes.FlatTableDefaultGeneralFormat.Results:
      return {
        sizingStyle: 'autoSize',
        colorScheme: FlatTableColorScheme.getDefault(),
        density: ChartTypes.FlatTableDensities.Compact,
        colGridlines: true,
        rowGridlines: true,
        rowNumbers: true,
        dataProfilingEnabled: false,
        pageSize: DEFAULT_FLAMINGO_PAGE_SIZE,
      };
  }
}

export function prepareDataProfilingColumnsInfoMap(
  columns: FlamingoTypes.RecordsResult['columns'],
  dataProfilingInfoColMap: DataProfilingTypes.DataProfilingMap = {},
  dataProfiling: DataProfilingTypes.DataProfilingMap = {}
): DataProfilingTypes.DataProfilingMap {
  return (
    columns?.reduce((map, col) => {
      const colKey = col.alias;

      if (!dataProfilingInfoColMap[colKey]) {
        return {
          ...map,
          [colKey]: {
            loading: true,
          },
        };
      }

      if (!dataProfilingInfoColMap[colKey].loading) {
        return {
          ...map,
          [colKey]: {
            ...dataProfilingInfoColMap[colKey],
          },
        };
      }

      if (dataProfiling[colKey]) {
        return {
          ...map,
          [colKey]: {
            ...dataProfiling[colKey],
            loading: false,
          },
        };
      }

      return {
        ...map,
        [colKey]: {
          loading: true,
        },
      };
    }, {}) ?? {}
  );
}
