import { Injectable } from '@angular/core';
import { ErrorReporter } from '@mode/shared/contract-common';
import { BrowserEventsService } from './browser-events.service';
import { NoopStorage } from './noop-storage';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends StorageService {
  constructor(window: Window, browserEventsService: BrowserEventsService, errorReporter: ErrorReporter) {
    // Added try and catch to handle case where user "Block third-party cookies"
    try {
      super(window.localStorage, browserEventsService, errorReporter);
    } catch (e) {
      super(new NoopStorage(), browserEventsService, errorReporter);
    }
  }
}
