import { Toast } from 'ngx-toastr';

export interface QueryViewerMetadata {
  datasourceToken: string;
  reportToken: string;
  queryToken: string;
  organizationToken?: string;
  organizationHandle?: string;
  adapter?: string;
  accepted?: boolean;
}

export enum QueryViewerFeedback {
  Negative = 0,
  Positive = 1,
}

export abstract class NegativeFeedbackToast extends Toast {
  public abstract giveFeedback(): void;
}

export abstract class PositiveFeedbackToast extends Toast {}

export abstract class FeedbackDialogService {
  public abstract open(metadata: QueryViewerMetadata): void;
}
