import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconComponent } from '../icon/icon.component';

export type IconButtonSize = 'small' | 'medium' | 'large' | 'x-large';

@Component({
  standalone: true,
  selector: 'capra-icon-button',
  imports: [CommonModule, IconComponent],
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  @Input() icon = 'icon-question';
  @Input() disabled = false;
  @Input() size: IconButtonSize = 'medium';
  @Output() clicked = new EventEmitter<void>();
}
