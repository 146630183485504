import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconComponent, StatusIconComponent, ToastComponent } from '@mode/capra';
import { ToastService } from './toast.service';
import { MessageToastComponent } from './message-toast/messsage-toast.component';

@NgModule({
  declarations: [MessageToastComponent],
  imports: [CommonModule, IconComponent, ToastComponent, StatusIconComponent],
  providers: [ToastService],
  exports: [ToastComponent],
})
export class SharedUtilToastModule {}
