import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService, getEmbeddedCollection, TemplateParser, TEMPLATE_PARSER } from '@mode/shared/util-halogen';
import { from, Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { scheduleCache } from '../resource-cache';
import { AccountFetchService } from './account-fetch.service';
import { ScheduleResource, SchedulesResource } from './account-response.types';
import { SchedulesTypes } from '@mode/shared/contract-common';

@Injectable({
  providedIn: 'root',
})
export class ScheduleFetchService {
  private readonly _scheduleEntry = this.urlTemplateService.parse(
    '/api/{username}/schedules?query={query}&page={page}&filters={filters}&sort={sort}'
  );

  constructor(
    private accountFetchService: AccountFetchService,
    private apiService: ApiService,
    private httpClient: HttpClient,
    private location: Location,
    @Inject(TEMPLATE_PARSER) private urlTemplateService: TemplateParser
  ) {}

  public getWorkspaceSchedules(username: string, page: number, query: string, filters: string, sort: string) {
    return from(
      this.apiService.getFromPath<SchedulesResource>(
        this._scheduleEntry.expand({ username, page, query, filters, sort })
      )
    ).pipe(
      tap((response: SchedulesResource) => {
        const schedules = getEmbeddedCollection<ScheduleResource>(response, 'schedules') || [];
        schedules.map((schedule: ScheduleResource) => scheduleCache.set(schedule.token, schedule));
      })
    );
  }

  public deleteSchedule(token: string): Observable<void | Error> {
    const resource = scheduleCache.get(token);

    if (resource != null) {
      return from(this.apiService.destroy(resource));
    } else {
      return throwError(new Error(`Schedule ${token} is not in the cache`));
    }
  }

  getCurrentOrgUsername(): string | null {
    const match = this.location.path().match(/^\/organizations\/([^/]+)/);
    return match && match[1];
  }

  public bulkDeleteSchedule(payload: SchedulesTypes.BulkDeleteResource): Observable<void | Error> {
    if (payload != null) {
      const accountID = this.getCurrentOrgUsername();
      const url = `/api/${accountID}/reports/schedules/v1/bulk_delete`;
      return this.httpClient.delete<void>(url, {
        body: payload,
      });
    } else {
      return throwError(new Error(`Bulk delete schedules failed`));
    }
  }
}
