import { format, formatDistanceToNow, isValid } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { capitalize, lowerCase } from 'lodash';

/**
 * Returns a formatted timespan for the provided duration.
 * @example 1h 35m 20s
 * @example 535ms
 * @param elapsed duration in milliseconds
 */
export function getFormattedDuration(elapsed: number): string {
  const hours = Math.floor(elapsed / 3600000);
  let minutes = 0;
  let seconds = 0;

  if (hours > 0) {
    minutes = Math.floor((elapsed % 3600000) / 60000);
    return hours + 'h' + (minutes > 0 ? ' ' + minutes + 'm' : '');
  }
  minutes = Math.floor(elapsed / 60000);
  if (minutes > 0) {
    seconds = Math.floor((elapsed % 60000) / 1000);
    return minutes + 'm' + (seconds > 0 ? ' ' + seconds + 's' : '');
  }
  seconds = Math.floor(elapsed / 1000);
  if (seconds > 0) {
    return seconds + 's';
  }

  return elapsed + 'ms';
}

export const formatInTimeZone = (date: Date, dateFormat: string, tz: string) =>
  format(toZonedTime(date, tz), dateFormat);

export const formatInUTC = (date: Date, dateFormat: string) => formatInTimeZone(date, dateFormat, 'UTC');

export const convertToUTC = (date: string | number | Date): Date => toZonedTime(date, 'UTC');

export type FormatTimeAgoStyle = 'capitalized' | 'lowercase';
const formatStringDict: Record<FormatTimeAgoStyle, (str?: string) => string> = {
  capitalized: capitalize,
  lowercase: lowerCase,
};

/**
 * @description Converts a date input to a human-friendly description
 * of the chronological distance from then to now.
 */
export function formatTimeAgo(
  date: string | number | Date,
  { format }: { format: FormatTimeAgoStyle } = { format: 'lowercase' }
): string {
  const formattedDate = typeof date === 'string' ? new Date(date) : date;
  if (!isValid(formattedDate)) {
    return '';
  }
  const formatString = formatStringDict[format];
  let distance = formatString(formatDistanceToNow(formattedDate));

  // Maintain consistency with Moment.js formatting
  if (distance === 'less than a minute') {
    distance = 'a few seconds';
  }

  return `${distance} ago`;
}
