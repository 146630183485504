import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IconComponent } from '@mode/capra';
import { SharedUtilSegmentModule, TrackClickData } from '@mode/shared/util-segment';

export type ToggleSwitchSize = 'small' | 'large';

@Component({
  standalone: true,
  selector: 'mode-toggle-switch',
  imports: [CommonModule, FormsModule, IconComponent, SharedUtilSegmentModule],
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToggleSwitchComponent {
  @Input() disabled = false;
  @Input() onIcon = 'icon-check';
  @Input() offIcon = '';
  @Input() state = false;
  @Input() size: ToggleSwitchSize = 'large';
  @Input() customId = 'toggle';
  @Input() analyticsData: TrackClickData | undefined;
  @Output() stateChange = new EventEmitter<boolean>();

  @HostBinding('class')
  public hostClass = 'mode-toggle-switch';
}
