import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiTypes, ModeForm } from '@mode/shared/contract-common';
import { INITIATOR_CONTEXT } from './context-tokens';
import {
  getEmbedded,
  getEmbeddedCollection,
  getForm,
  getLink,
  getTokenFromModeLink,
  getTypeFromModeLink,
} from './hal-helpers';
import { serializeIncludes } from './include-serialization';
import { TemplateParser, TEMPLATE_PARSER } from './uri-template-parser.types';

/* eslint-disable @typescript-eslint/member-ordering */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly urlTemplateSerivce!: TemplateParser;

  constructor(private http: HttpClient, @Inject(TEMPLATE_PARSER) private urlTemplateService: TemplateParser) {
    this.urlTemplateService = urlTemplateService;
  }

  getFromPath<U extends ApiTypes.ModeHalResource>(
    entryPath: string,
    options: ApiTypes.ResourceOptions = {}
  ): Promise<U> {
    if (!entryPath.startsWith('/')) {
      entryPath = `/${entryPath}`;
    }
    const url = this.urlTemplateService.parse(entryPath).expand(options.params);
    return this.request('get', url, options);
  }

  getSelf<T extends ApiTypes.ModeHalResource>(
    resource: ApiTypes.ModeHalResource,
    options: ApiTypes.ResourceOptions = {}
  ): Promise<T> {
    if (getLink(resource, 'self')) {
      return this.follow(resource._links['self'], options);
    } else {
      return Promise.reject(new NoResourceError(resource, 'self'));
    }
  }

  getResource<U extends ApiTypes.ModeHalResource>(
    resource: ApiTypes.ModeHalResource,
    rel: string,
    options: ApiTypes.ResourceOptions = {}
  ): Promise<U> {
    if (!options.reload) {
      const embed = getEmbedded<U>(resource, rel);
      if (embed != null) {
        return Promise.resolve(embed);
      }
    }

    const link = getLink(resource, rel);
    if (link != null) {
      return this.follow(link, options);
    }

    return Promise.reject(new NoResourceError(resource, rel));
  }

  getResources<U extends ApiTypes.ModeHalResource>(
    resource: ApiTypes.ModeHalResource,
    rel: string,
    options: ApiTypes.ResourceOptions = {}
  ): Promise<U[]> {
    const relAs = options.relationAs || rel;
    const embed = getEmbedded(resource, rel, options);
    const link = getLink(resource, rel);
    if (embed != null) {
      const collection = getEmbeddedCollection<U>(embed, relAs);
      if (collection != null) {
        return Promise.resolve(collection);
      } else {
        return Promise.reject(new NoResourceError(embed, rel));
      }
    } else if (link != null) {
      return this.follow(link, options).then((r) => {
        const collection = getEmbeddedCollection<U>(r, relAs);
        if (collection != null) {
          return collection;
        }

        throw new NoResourceError(r, relAs);
      });
    } else {
      return Promise.reject(new NoResourceError(resource, rel));
    }
  }

  create<R extends ApiTypes.ModeHalResource>(
    parent: ApiTypes.ModeHalResource,
    rel: string,
    params: any,
    serializeParams = true
  ): Promise<R> {
    const formRepresenter = getEmbedded(parent, rel);
    if (formRepresenter != null) {
      const createForm = getForm(formRepresenter, 'create');
      if (createForm != null) {
        return this.submit(createForm, params, serializeParams);
      }

      return Promise.reject(new NoResourceError(formRepresenter, 'create'));
    }

    return Promise.reject(new NoResourceError(parent, rel));
  }

  destroy<U extends ApiTypes.ModeHalResource>(resource: U, formName = 'destroy') {
    const form = getForm(resource, formName);
    if (form != null) {
      return this.http
        .delete<void>(form.action, {
          context: this.getContext(new Error()),
        })
        .toPromise();
    } else {
      return Promise.reject(new NoResourceError(resource, formName));
    }
  }

  edit<U extends ApiTypes.ModeHalResource>(
    resource: ApiTypes.ModeHalResource,
    params: any,
    serializeParams = true
  ): Promise<U> {
    return this.submitForm(resource, 'edit', params, serializeParams);
  }

  submitForm<U extends ApiTypes.ModeHalResource>(
    resource: ApiTypes.ModeHalResource,
    formName: string,
    params: any,
    serializeParams = true
  ): Promise<U> {
    const form = getForm(resource, formName);
    if (form != null) {
      return this.submit(form, params, serializeParams);
    } else {
      return Promise.reject(new NoResourceError(resource, formName));
    }
  }

  follow<U extends ApiTypes.ModeHalResource>(
    link: ApiTypes.HalLink | ApiTypes.HalLink[],
    options?: ApiTypes.ResourceOptions
  ): Promise<U> {
    if (Array.isArray(link)) {
      return Promise.reject(new Error('Array links are currently unsupported'));
    }

    let href = link.href;
    if (link.templated) {
      if (options != null) {
        href = this.expand(href, options.params);
      } else {
        throw new Error('No parameters given for templated link: ' + href);
      }
    }

    return this.request<U>('get', href, options);
  }

  submit<U extends ApiTypes.ModeHalResource>(form: ApiTypes.ModeForm, params: any, serializeParams = true): Promise<U> {
    const formInput = form.input ?? form._inputs;

    if (formInput != null) {
      for (const key in params) {
        if (params[key] == null && formInput[key] != null) {
          return Promise.reject(new Error(`Could not find input: ${key}`));
        }
      }
    }

    if (form.method === 'get') {
      // GET submits will have all their parameters merged into the URL
      const url = this.expand(form.action, params);
      return this.request<U>('get', url);
    }

    const url = form.action;
    //
    if (serializeParams) {
      params = this.serialize(params, formInput);
    }

    return this.request<U>(form.method, url, {
      params,
      content_type: form.content_type,
    });
  }

  /**
   * Serializer helper for form params.
   *
   * This helper takes an input and params. Currently there are the following assumptions:
   * - The form has an input
   * - The input has a first layer that is a container and a second layer that is the list
   *   of properties that need to be stringafied
   * - If there is no param, it uses the value from the input, if there is, it stingifies
   *   the param value
   *
   * NOTE - This may not handle all cases. It's unclear what all the possible cases are.
   * This will have to be updated as we move forward.
   */
  serialize(params: { [key: string]: any }, input: ModeForm['input']) {
    const serializedParams: { [name: string]: { [prop: string]: string } } = {};

    for (const name in input) {
      serializedParams[name] = {};
      for (const prop in input[name]) {
        const formInput = input[name][prop];
        const paramsValue = params?.[name]?.[prop];
        let filledValue = '';
        if (prop in (params?.[name] ?? {})) {
          switch (typeof paramsValue) {
            case 'object':
            case 'symbol':
            case 'function':
              filledValue = JSON.stringify(paramsValue);
              break;
            default:
              filledValue = paramsValue;
              break;
          }
        } else {
          filledValue = formInput.value;
        }
        serializedParams[name][prop] = filledValue;
      }
    }

    return serializedParams;
  }

  private getContext(err: Error) {
    return new HttpContext().set(INITIATOR_CONTEXT, err);
  }

  private request<U extends ApiTypes.ModeHalResource>(
    method: string,
    url: string,
    options: ApiTypes.ResourceOptions = {}
  ): Promise<U> {
    switch (method) {
      case 'get':
        if (options.params) {
          url = this.expand(url, options.params);
        }

        if (options.includes != null) {
          return this.http
            .get<U>(url, {
              params: serializeIncludes(options.includes),
              responseType: 'json',
              context: this.getContext(new Error()),
            })
            .toPromise() as Promise<U>;
        } else {
          return this.http
            .get<U>(url, {
              context: this.getContext(new Error()),
            })
            .toPromise() as Promise<U>;
        }
      case 'patch':
      case 'post':
      case 'put':
        const headers: { [key: string]: string } = {};
        if (options.content_type) {
          headers['Content-Type'] = options.content_type;
        }
        return this.http
          .request<U>(method, url, {
            body: options.params,
            responseType: 'json',
            headers,
            context: this.getContext(new Error()),
          })
          .toPromise() as Promise<U>;
      case 'delete':
        return Promise.reject(new Error('use http.delete directly instead'));
      default:
        return Promise.reject(new Error('unknown method: ' + method));
    }
  }

  expand(url: string, params: any) {
    return this.urlTemplateService.parse(url).expand(params);
  }
}

export class NoResourceError extends Error {
  constructor(resource: ApiTypes.ModeHalResource, rel: string) {
    let link = resource._links['self'];
    if (Array.isArray(link)) {
      link = link[0]; // Temporary workaround. Representers will never return array links at the moment
    }

    const type = getTypeFromModeLink(link);
    const token = getTokenFromModeLink(link);
    super(`${type || 'Resource'} ${token} has no link '${rel}'`);
  }
}

export class ResourceNotLoadedError extends Error {
  constructor(token: string, type: string) {
    super(`${type || 'Resource'} ${token} is not loaded`);
    Object.setPrototypeOf(this, ResourceNotLoadedError.prototype);
  }
}
