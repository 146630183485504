<mode-flat-table-simple-legend [legendInfo]="legendInfo">
  <div [style.width]="'100%'" [style.height]="gradientHeight + 'px'">
    <svg class="gradient-bar">
      <g>
        <rect [attr.height]="'100%'" [attr.width]="'100%'" [style.fill]="'url(#' + linearGradientId + ')'"></rect>
      </g>
      <defs>
        <linearGradient [id]="linearGradientId" x1="0%" y1="0%" y2="0%" x2="100%">
          <stop
            *ngFor="let stop of linearGradientStops"
            [attr.offset]="stop.offset + '%'"
            [attr.stop-color]="stop.stopColor"
          ></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div class="ticks-container">
    <div *ngFor="let tick of ticks">
      {{ tick.text }}
    </div>
  </div>
</mode-flat-table-simple-legend>