import type { IFloatingFilterComp, IFloatingFilterParams } from 'ag-grid-community';

export class CustomTextFloatingFilter implements IFloatingFilterComp {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2564
  eGui: HTMLElement;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2564
  eFilterInput: HTMLInputElement;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2564
  eSubmitButton: HTMLButtonElement;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TS2564
  eClearButton: HTMLButtonElement;

  // The init(params) method is called on the floating filter once when created.
  init(params: IFloatingFilterParams): void {
    // Initialize state of floating filter
    const colId = params.column.getColId();
    const filterModel = params.api.getFilterModel();

    this.eGui = document.createElement('div');
    this.eGui.classList.add('floating-filter-container');
    if (colId === params.filterParams.context.activeTableColumnId) {
      this.eGui.classList.add('active-floating-filter-column');
    }
    this.eGui.innerHTML = `
      <div class="floating-filter-input-field">
        <label class="visually-hidden">${colId} column search</label>
        <input type="text" class="floating-filter-input form-control form-control-sm floating-filter-input--${colId}">
        <button class="clear-filter">x</button>
      </div>
      <button class="trigger-filter">Do</button>
    `;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TS2322
    this.eFilterInput = this.eGui.querySelector('.floating-filter-input');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TS2322
    this.eSubmitButton = this.eGui.querySelector('.trigger-filter');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TS2322
    this.eClearButton = this.eGui.querySelector('.clear-filter');

    if (filterModel[colId]) {
      this.eFilterInput.value = filterModel[colId].value;
      if (this.eFilterInput.value) {
        this.eClearButton.classList.add('show-clear');
      }
    }

    // Add event listeners for floating filter. Manually handling these things for now. Once we migrate to angular, we might
    // want to refactor this with the angular version of ag-grid and use actual components for our custom components.
    // We need to save filter model on every change because other header updates need to cause a reflow. We'll keep track of
    // current run model state in the parent table component.
    this.eGui.addEventListener('click', (event) => {
      const headerEvent = new CustomEvent('agHeaderFocused', {
        bubbles: true,
        detail: { colId: params.column.getColId() },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: TS2531
      event.target.dispatchEvent(headerEvent);
    });

    // Clear button, set model, clears input
    this.eClearButton.addEventListener('click', () => {
      this.eFilterInput.value = '';
      params.parentFilterInstance((instance) => {
        instance.setModel({ value: null });
      });
      this.eClearButton.classList.remove('show-clear');
    });

    // Sets model, shows/hides clear input button
    this.eFilterInput.addEventListener('input', () => {
      if (this.eFilterInput.value === '') {
        params.parentFilterInstance((instance) => {
          instance.setModel({ value: null });
        });
        this.eClearButton.classList.remove('show-clear');
      } else {
        params.parentFilterInstance((instance) => {
          instance.setModel({ value: this.eFilterInput.value });
        });
        this.eClearButton.classList.add('show-clear');
      }
    });

    // Trigger the search, send custom event
    this.eSubmitButton.addEventListener('click', (event: any) => {
      const headerEvent = new CustomEvent('agTriggerColumnSearch', {
        bubbles: true,
        detail: { colId: params.column.getColId() },
      });
      event.target.dispatchEvent(headerEvent);
    });
    this.eFilterInput.addEventListener('keydown', (event: any) => {
      if (event.keyCode === 13) {
        const headerEvent = new CustomEvent('agTriggerColumnSearch', {
          bubbles: true,
          detail: { colId: params.column.getColId() },
        });
        event.target.dispatchEvent(headerEvent);
      }
    });
  }

  /**
   * Responsds to parent model change, keeps floating filter in sync.
   *
   * Gets called every time the parent filter changes. Your floating
   * filter would typically refresh it's UI to reflect the new filter
   * state. The provided parentModel is what the parent filter returns
   * from it's getModel() method. The event is the FilterChangedEvent
   * that the grid fires.
   */
  onParentModelChanged(parentModel: any) {
    this.eFilterInput.value = parentModel.value;
  }

  /**
   * Returns the dom html element for this floating filter.
   */
  getGui(): HTMLElement {
    return this.eGui;
  }

  /**
   * Do any necessary cleanup.
   */
  destroy(): void {}
}
