import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogConfig, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

// todo add more mode defaults for dialogs here
const MODE_DIALOG_DEFAULTS: MatLegacyDialogConfig = {
  panelClass: 'mode-dialog',
  autoFocus: false,
  width: '472px',
};

interface CustomConfig {
  /** If true, the dialog will render in the center of the viewport. If false, the dialog will render near the top of the viewport. */
  centered: boolean;
}

@Injectable()
export class DialogService {
  constructor(private dialog: MatLegacyDialog) {}

  getDialogById(id: string) {
    return this.dialog.getDialogById(id);
  }

  getOptions<T>({
    matDialogConfig = {},
    customConfig = { centered: false },
  }: {
    matDialogConfig?: MatLegacyDialogConfig<T>;
    customConfig?: CustomConfig;
  }) {
    return { ...MODE_DIALOG_DEFAULTS, ...matDialogConfig, position: customConfig.centered ? {} : { top: '56px' } };
  }

  open<T, D = any, R = any>({
    component,
    matDialogConfig,
    customConfig,
  }: {
    /** The component to dynamically render inside the dialog. */
    component: ComponentType<T>;
    /** The Angular Material library's configuration options for the dialog. */
    matDialogConfig?: MatLegacyDialogConfig<D>;
    /** Mode's own configuration options for the dialog. */
    customConfig?: CustomConfig;
  }): MatLegacyDialogRef<T, R> {
    return this.dialog.open(component, this.getOptions<D>({ matDialogConfig, customConfig }));
  }

  closeAll() {
    this.dialog.closeAll();
  }
}
