<ng-container *ngIf="menuItem.section; then sectionTemplate; else menuTemplate"></ng-container>
<ng-template #menuTemplate>
  <button
    attr.data-test="menu-item-{{ menuItem.name }}"
    [class.selected]="isSelected"
    (click)="onSelect($event)"
    [disabled]="!!menuItem.disabled"
    mat-menu-item
  >
    <div class="item-text" *ngIf="menuItem.section === undefined">
      <capra-icon class="active-icon" [icon]="activeIcon" *ngIf="isSelected"></capra-icon>
      <span class="item-name" [class.selected]="isSelected">{{ menuItem.name }}</span>
      <span class="item-desc" *ngIf="menuItem.desc">{{ menuItem.desc }}</span>
    </div>

    <div class="item-custom-component" *ngIf="menuItem.decorator">
      <ng-container #customComponent></ng-container>
    </div>
  </button>
  <mat-divider *ngIf="menuItem.divider"></mat-divider>
</ng-template>
<ng-template #sectionTemplate>
  <div class="section" attr.data-test="section-{{ menuItem.name }}">
    <span class="section-text">{{ menuItem.name }}</span>
  </div>
</ng-template>
