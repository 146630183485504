import { StateRecord } from '../common.types';

export enum ReportRunFormFieldType {
  Date = 'date',
  Select = 'select',
  Multiselect = 'multiselect',
  Text = 'text',
}

export interface ReportRunFormField extends StateRecord {
  default: string;
  description: string;
  human_name: string;
  input_type: string;
  label: string;
  more_options: boolean;
  name: string;
  options: any[];
  options_error?: { message: string };
  is_dynamic: boolean;
  source_size: number;
  type: ReportRunFormFieldType;
  value: any;
  _links?: {
    options: {
      href: string;
    };
  };
}
