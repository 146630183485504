import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PositiveFeedbackToast } from '@mode/queries/ai-assist/util';
import { ToastAnimation } from '@mode/shared/util-toast';

@Component({
  selector: 'mode-positive-feedback-toast',
  templateUrl: './positive-feedback-toast.component.html',
  styleUrls: ['./positive-feedback-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ToastAnimation],
})
export class PositiveFeedbackToastComponent extends PositiveFeedbackToast {}
