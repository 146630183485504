import { Injectable } from '@angular/core';
import { ObservabilityService } from './observability-service';
import { Metric } from 'web-vitals';
import { getPageType } from '@mode/shared/util-js';

@Injectable({
  providedIn: 'root',
})
export class WebVitalsObservabilityService {
  constructor(private observabilityService: ObservabilityService) {}

  public startTrace(metric: Metric) {
    const { page_type, subpage } = getPageType(window.location.pathname);
    this.observabilityService.startWebvitalsTrace('web-vitals', {
      [`web_vital.name`]: metric.name, // 'CLS' | 'FCP' | 'INP' | 'LCP' | 'TTFB'
      [`web_vital.id`]: metric.id,
      [`web_vital.navigationType`]: metric.navigationType, // 'navigate' | 'reload' | 'back-forward' | 'back-forward-cache' | 'prerender' | 'restore'
      [`web_vital.delta`]: Math.round(metric.delta),
      [`web_vital.rating`]: metric.rating, // 'good' | 'needs-improvement' | 'poor'
      [`web_vital.value`]: Math.round(metric.value),
      [`web_vital.entries`]: JSON.stringify(metric.entries),
      [`page_type`]: page_type,
      [`sub_page`]: subpage,
      // @ts-ignore
      [`network_type`]: navigator?.connection?.effectiveType || 'NA',
    });
  }
}
