<table class="flamingo-loading-grid" [class.with-animation]="withAnimation">
  <tbody>
    <tr class="header-row">
      <td class="row-number"><span></span></td>
      <td class="td-0"><span></span></td>
      <td class="td-1"><span></span></td>
      <td class="td-2"><span></span></td>
      <td class="td-3"><span></span></td>
      <td class="td-4"><span></span></td>
      <td class="td-5"><span></span></td>
    </tr>
    <tr *ngFor="let row of fakeRows">
      <td class="row-number"></td>
      <td class="td-0"><span class="animate-cell"></span></td>
      <td class="td-1"><span class="animate-cell"></span></td>
      <td class="td-2"><span class="animate-cell"></span></td>
      <td class="td-3"><span class="animate-cell"></span></td>
      <td class="td-4"><span class="animate-cell"></span></td>
      <td class="td-5"><span class="animate-cell"></span></td>
    </tr>
  </tbody>
</table>
