import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import {
  generateSQL,
  submitPositiveFeedback,
  submitNegativeFeedback,
  discardGeneration,
  submitInsertFeedback,
} from './ai-assist.actions';
import {
  selectAiAssistLoading,
  selectErrorMsg,
  selectFeedback,
  selectGeneratedSQL,
  selectRequestId,
} from './ai-assist.selectors';
import { Observable, map } from 'rxjs';
import { QueryViewerFeedback } from '@mode/queries/ai-assist/util';

@Injectable()
export class QueryViewerFacade {
  static Actions = {
    generateSQL,
    submitPositiveFeedback,
    submitNegativeFeedback,
    discardGeneration,
    submitInsertFeedback,
  };

  constructor(private store: Store) {}

  loading$(queryToken: string): Observable<boolean> {
    return this.store.select(selectAiAssistLoading(queryToken)).pipe(map((isLoading) => isLoading ?? false));
  }

  requestId$(queryToken: string): Observable<string | null> {
    return this.store.select(selectRequestId(queryToken)).pipe(map((requestId) => requestId ?? null));
  }

  generatedSQL$(queryToken: string): Observable<string | null> {
    return this.store.select(selectGeneratedSQL(queryToken)).pipe(map((generatedSQL) => generatedSQL ?? null));
  }

  errorMsg$(queryToken: string): Observable<string | null> {
    return this.store.select(selectErrorMsg(queryToken)).pipe(map((errorMsg) => errorMsg ?? null));
  }

  feedback$(queryToken: string): Observable<QueryViewerFeedback | null> {
    return this.store.select(selectFeedback(queryToken)).pipe(map((rating) => rating ?? null));
  }

  public dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
