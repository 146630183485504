import { onCLS, onINP, onLCP, onTTFB, onFCP, Metric } from 'web-vitals';
import { WebVitalsObservabilityService } from '@mode/shared/util-observability';
import { FeatureFlag, FeatureFlagsFacade } from '@mode/shared/contract-common';

export function webVitalsInitializer(
  featureFlagsFacade: FeatureFlagsFacade,
  webVitalsObservabilityService: WebVitalsObservabilityService
) {
  return () =>
    featureFlagsFacade.asPromise(FeatureFlag.WebVitalsFrontendObservability).then((enabled) => {
      if (enabled) {
        const handleMetric = (metric: Metric) => webVitalsObservabilityService.startTrace(metric);

        // Capture Web Vitals metrics
        onLCP(handleMetric);
        onFCP(handleMetric);
        onCLS(handleMetric);
        onINP(handleMetric);
        onTTFB(handleMetric);
      }
    });
}
