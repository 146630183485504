import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { SessionService } from '../session.service';
import { AnalyticsEvent, AnalyticsService } from '@mode/shared/util-segment';

@Component({
  selector: 'mode-session-logged-out-dialog',
  templateUrl: './session-logged-out-dialog.component.html',
  styleUrls: ['./session-logged-out-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionLoggedOutDialogComponent {
  constructor(
    private window: Window,
    private sessionService: SessionService,
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  onConfirm() {
    this.analyticsService.track(AnalyticsEvent.Name.UiClicked, {
      location: 'Sign In Modal',
      action: 'Sign in',
    });

    setTimeout(() => {
      this.redirectToSignin();
    }, 100);
  }

  redirectToSignin() {
    this.document.location.assign(this.sessionService.getSignInUrl());
  }
}
