import { Injectable } from '@angular/core';
import { FeatureFlag, FeatureFlagsFacade } from '@mode/shared/contract-common';
import { Actions, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs';
import { BugsnagService } from './bugsnag.service';

@Injectable()
export class BugsnagEffects {
  actionBreadcrumbs$ = createEffect(
    () =>
      this.actions.pipe(tap((action) => this.bugsnagService.leaveBreadcrumb('DISPATCH:NGRX', { type: action.type }))),
    { dispatch: false }
  );

  featureFlagMetadata$ = createEffect(
    () =>
      this.featureFlagsFacade.allFlags$.pipe(
        tap((flags) =>
          this.bugsnagService.addMetadata(
            'feature_flags',
            Object.keys(flags).filter((name: string) => flags[name as FeatureFlag])
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions: Actions,
    private bugsnagService: BugsnagService,
    private featureFlagsFacade: FeatureFlagsFacade
  ) {}
}
