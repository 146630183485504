<div class="date-picker-header">
  <capra-icon-button
    class="nav-button"
    icon="icon-pagination-first"
    size="medium"
    (clicked)="previousYear()"
  ></capra-icon-button>
  <capra-icon-button
    class="nav-button"
    icon="icon-pagination-previous"
    size="medium"
    (clicked)="previousMonth()"
  ></capra-icon-button>
  <span class="date-picker-header-label">{{ periodLabel }}</span>
  <capra-icon-button
    class="nav-button"
    icon="icon-pagination-next"
    size="medium"
    (clicked)="nextMonth()"
  ></capra-icon-button>
  <capra-icon-button
    class="nav-button"
    icon="icon-pagination-last"
    size="medium"
    (clicked)="nextYear()"
  ></capra-icon-button>
</div>
