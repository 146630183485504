<capra-toast
  type="success"
  [showCloseBtn]="true"
  (dismissToast)="remove()"
  [canTapToDismiss]="false"
>
  <div class="feedback-toast-content">
    <div class="toast-body">
      <h4 class="title">Marked unhelpful.</h4>
      <p class="success-message">
        Thanks for improving AI Assist!
        <span>Want to give feedback on why it wasn't helpful?</span>
      </p>
      <div class="actions">
        <capra-button
          class="dismiss"
          text="Dismiss"
          variant="secondary"
          color="neutral"
          size="small"
          (clicked)="remove()"
        ></capra-button>
        <capra-button
          text="Give feedback"
          variant="secondary"
          color="neutral"
          size="small"
          (clicked)="giveFeedback()"
        ></capra-button>
      </div>
    </div>
  </div>
</capra-toast>
