import { Injectable } from '@angular/core';
import { MetaTags } from './meta-helpers';

/**
 * Provides a mockable service for retrieving meta tag values.
 *
 */

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  tagValues: {
    [key: string]: string | null;
  } = {};

  constructor() {}

  public loadFromWindow(window: Window) {
    const tags = window.document.head.querySelectorAll('meta');
    tags.forEach((tag) => {
      this.tagValues[tag.name] = tag.content || null;
    });
  }

  public loadFromJson(data: this['tagValues']) {
    Object.keys(data).forEach((key) => {
      this.tagValues[key] = data[key] || null;
    });
  }

  public getTag(name: MetaTags) {
    return this.tagValues[name] || null;
  }
}
