import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { IconButtonComponent } from '@mode/capra';
import { Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  imports: [IconButtonComponent],
  selector: 'mode-date-picker-header',
  styleUrls: ['./date-picker-header.component.scss'],
  templateUrl: './date-picker-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerHeaderComponent<D> implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.calendar.stateChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => this.changeDetector.markForCheck());
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get periodLabel() {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearA11yLabel);
  }

  previousMonth() {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
  }

  previousYear() {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  nextMonth() {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
  }

  nextYear() {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }
}
