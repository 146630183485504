import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mode-submit-feedback-dialog',
  templateUrl: './submit-feedback-dialog.component.html',
  styleUrls: ['./submit-feedback-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitFeedbackDialogComponent {
  public feedbackForm;
  public messageControl;

  @Output() sendFeedback = new EventEmitter<string>();

  constructor(
    protected dialogRef: MatLegacyDialogRef<SubmitFeedbackDialogComponent>,
    private _fb: FormBuilder = new FormBuilder()
  ) {
    this.feedbackForm = this._fb.nonNullable.group({
      message: this._fb.nonNullable.control('', [Validators.required, Validators.maxLength(500)]),
    });
    this.messageControl = this.feedbackForm.controls['message'];
  }

  submitFeedback(): void {
    this.sendFeedback.emit(this.messageControl.value);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
