export type EmittableError = Error;

export type ErrorEvent = {
  error: EmittableError;
  context?: string;
  severity?: 'info' | 'warning' | 'error';
  unhandled?: boolean;
  debugInfo?: any;
  groupingHash?: string;
};

export abstract class ErrorReporter {
  abstract notify(event: ErrorEvent): void;
}
