export const getPageType = (path: string) => {
  const updatedPath = path
    .split('/')
    .filter((part) => part !== '')
    .join('/');
  const [a, b, c, d, e] = updatedPath.split('/');

  if (a === 'home' || b === 'spaces') {
    return {
      page_type: 'Homepage',
      subpage: c === 'data_sources' ? 'data_sources' : b === 'spaces' ? 'spaces' : c || 'NA',
    };
  }

  if (a === 'editor') {
    return {
      page_type: 'Editorpage',
      subpage: ['reports', 'datasets'].includes(c) ? (e ? `${c}-${e}` : `${c}`) : 'NA',
    };
  }

  if (b === 'reports') {
    return {
      page_type: 'Viewerpage',
      subpage: d === 'details' ? (e ? `${d}-${e}` : `${d}`) : 'NA',
    };
  }

  return {
    page_type: 'Other',
    subpage: 'NA',
  };
};
