import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryViewerFeedback, QueryViewerMetadata } from '@mode/queries/ai-assist/util';
import { EnvironmentFacade } from '@mode/shared/environment/contract';
import { ACCEPT_BASIC_JSON, ADD_AI_GATEWAY_AUTH } from '@mode/shared/util-halogen';
import { Observable } from 'rxjs';

const DEFAULT_AI_GATEWAY_HOST = 'aigateway';

export type AiQueryPayload = {
  query: string;
  metadata: QueryViewerMetadata;
};

export type AiQueryResponse = {
  renderedQuery: string;
  requestId: string;
};

export type AiQueryErrorResponse = {
  error: {
    message: string;
    context?: {
      tableNames: string[];
    };
    instanceId: string;
    category?: string;
    code?: string;
  };
};

export type AiQueryFeedbackPayload = {
  rating?: QueryViewerFeedback;
  requestId: string;
  message?: string;
  metadata: QueryViewerMetadata;
  accepted?: boolean;
};

@Injectable({ providedIn: 'root' })
export class AiGatewayClientService {
  private aiGatewayBase = DEFAULT_AI_GATEWAY_HOST; // Fallback to dataindex if env is not loaded

  constructor(private http: HttpClient, private environmentFacade: EnvironmentFacade) {
    this.environmentFacade.aiGatewayBase$.subscribe((aiGatewayBase) => (this.aiGatewayBase = aiGatewayBase));
  }

  public getSQL(query: string, metadata: QueryViewerMetadata): Observable<AiQueryResponse> {
    const url = `${this.aiGatewayBase}/api/v1/generate/sql`;
    const aiQueryPayload: AiQueryPayload = { query, metadata };
    const context = new HttpContext();

    context.set(ACCEPT_BASIC_JSON, true);
    context.set(ADD_AI_GATEWAY_AUTH, true);

    return this.http.post<AiQueryResponse>(url, aiQueryPayload, { context });
  }

  public submitFeedback(
    requestId: string,
    metadata: QueryViewerMetadata,
    rating?: QueryViewerFeedback,
    message?: string
  ) {
    const url = `${this.aiGatewayBase}/api/v1/feedback`;
    const aiQueryFeedbackPayload: AiQueryFeedbackPayload = {
      rating,
      requestId,
      metadata,
      message,
      accepted: metadata.accepted,
    };
    const context = new HttpContext();

    context.set(ACCEPT_BASIC_JSON, true);
    context.set(ADD_AI_GATEWAY_AUTH, true);

    return this.http.post<AiQueryResponse>(url, aiQueryFeedbackPayload, { context });
  }
}
