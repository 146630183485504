import { ScaleLinear, scaleLinear } from 'd3-scale';
import { FLAT_TABLE_GRADIENT_LEGEND_MIN_HEIGHT, FLAT_TABLE_GRADIENT_LEGEND_MIN_WIDTH } from '@mode/shared/util-js';
import { ChartTypes } from '@mode/shared/contract-common';
import { DatumType, LegendData, LegendDomain, LegendMeasurements, ScaleType } from '../types';
import { SimpleLegend } from '../simple-legend';

export class GradientLegend extends SimpleLegend {
  override type(): ChartTypes.FlatTableLegendType {
    return ChartTypes.FlatTableLegendType.Gradient;
  }

  override calculateData(): LegendData {
    const scale = this.scale() as ScaleLinear<any, any>;

    const data = this.domain().map((d) => {
      return {
        color: scale(Number(d)),
        value: String(d), // @TODO: Display the numeric value upto 2 decimal positions.
        rawValue: d,
      };
    });

    return data;
  }

  override calculateLegendMeasurements(): LegendMeasurements {
    const legendWidth = FLAT_TABLE_GRADIENT_LEGEND_MIN_WIDTH;
    const legendHeight = FLAT_TABLE_GRADIENT_LEGEND_MIN_HEIGHT;

    return {
      width: legendWidth,
      height: legendHeight,
      minWidth: legendWidth,
      minHeight: legendHeight,
    };
  }

  override createScale(): ScaleType {
    const { domain: scaleDomain, range: scaleRange } = this.createScaleDomainRange();
    const scale = scaleLinear<string>()
      .domain(scaleDomain as number[])
      .range(scaleRange);

    return scale;
  }

  // @TODO: Now this gradient legend supports two colors in range, need to support for multi colors in range.
  // Reference: https://github.com/d3/d3-scale#continuous_domain
  // https://observablehq.com/@d3/d3-scalelinear#crayons
  override createScaleDomainRange(): { domain: LegendDomain; range: string[] } {
    const scaleDomain = this.domain();
    const scaleRange = this.config().range;
    return {
      domain: scaleDomain,
      range: scaleRange,
    };
  }

  override getColor(val: DatumType): string {
    const scale = this.scale() as ScaleLinear<any, any>;
    return scale(Number(val));
  }

  override dispose() {
    throw new Error('Not yet implemented');
  }
}
