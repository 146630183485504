import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { BugsnagService } from './bugsnag/bugsnag.service';
import { errorHandlerFactory } from './error-handler.factory';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: Window, useValue: window },
    { provide: ErrorHandler, useFactory: errorHandlerFactory, deps: [BugsnagService] },
  ],
})
export class SharedUtilErrorHandlingModule {}
