export const FLAMINGO_RESERVED_REFS = {
  ViewData: 'view-data',
  ChartExplorer: 'explorer-chart',
  ChartDesigner: 'designer-chart',
  Exploration: 'exploration',
  DataProfiling: 'data-profiling',
  BinSize: 'bin-size',
  ConditionalFormatting: 'conditional-formatting',
};

export const IMPORT_DATASET_STATUS = {
  Success: 'Success',
  BadUrl: 'Invalid query path',
  AlreadyImported: 'Could not import dataset: Dataset already associated',
  QueryNotFound: 'Query not found',
  DatasetNotFound: 'Dataset not found',
  QueryNoAccess: 'Query is from an external organization',
  Unknown: 'An unknown error occurred',
};

export const ConvertToVETooltipMap = {
  histogram: 'Enhance your histogram with data labels, additional aggregations, and custom colors',
};
