import { DOCUMENT } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy, ElementRef, Optional, Inject } from '@angular/core';

import { CapraIconsRegistry } from '../capra-icons-registry.service';

/**
 * This is a basic icon component. It will handle implementing icons across the
 * design system and webapp. It can be used on its own, but will often be used by
 * other components.
 */
@Component({
  standalone: true,
  selector: 'capra-icon, mode-capra-icon', // We need and additonal mode-* prefix selector here to support page that has both AngularJS and Angular dependency.
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  /** This is a title that is added to the svg output for accessibility use cases. */
  @Input() title = '';

  /**
   * The width of the svg canvas. It defaults at `16px` which is the base size for all of our
   * icons. Override if you need something explicit, but be aware that all icons won't
   * be guaranteed to render clearly at other sizes.
   *
   * This is often used to customize the size when used for illustrations. Optionally, the custom
   * css property `--svg-width` can be set by a parent implementation as well. This option should
   * generally only be leveraged by other design system components, but can potentially allow for
   * simpler implementations depending on the intention. Leveraging the binding will have low specificity
   * (only host).
   */
  @Input()
  set width(value: string) {
    this.element.nativeElement.style.setProperty('--svg-width', value);
  }

  /**
   * The height of the svg canvas. It defaults at `16px` which is the base size for all of our
   * icons. Override if you need something explicit, but be aware that all icons won't
   * be guaranteed to render clearly at other sizes.
   *
   * This is often used to customize the size when used for illustrations. Optionally, the custom
   * css property `--svg-height` can be set by a parent implementation as well. This option should
   * generally only be leveraged by other design system components, but can potentially allow for
   * simpler implementations depending on the intention. Leveraging the binding will have low specificity
   * (only host).
   */
  @Input()
  set height(value: string) {
    this.element.nativeElement.style.setProperty('--svg-height', value);
  }

  /**
   * This is the icon slug name. It matches up with the name of the `svg` file name without the `.svg` extension.
   */
  @Input()
  set icon(iconName: string) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }

    // Get icon from registry
    const svgData = this.capraIconRegistry.getIcon(iconName);

    if (svgData) {
      this.svgIcon = this.svgElementFromString(svgData);
    }

    if (this.svgIcon) {
      this.element.nativeElement.appendChild(this.svgIcon);
    }
  }

  /**
   * Reference to the current svg element.
   */
  private svgIcon: SVGElement | undefined;

  constructor(
    private element: ElementRef,
    private capraIconRegistry: CapraIconsRegistry,
    @Optional() @Inject(DOCUMENT) private document: Document
  ) {}

  private svgElementFromString(svgContent: string): SVGElement | undefined {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;

    // Create the title
    const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
    title.textContent = this.title;

    const svg = div.querySelector('svg');
    if (svg) {
      // Insert the title
      svg.prepend(title);
      return svg;
    } else {
      this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
    }
    return;
  }
}
