import { createFeature, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { Token } from '@mode/shared/authorization/contract';

export interface AuthState {
  dataIndexToken: Token | null;
  aiGatewayToken: Token | null;
}

export const authInitialState: AuthState = { dataIndexToken: null, aiGatewayToken: null };

export const authFeature = createFeature({
  name: 'auth',
  reducer: createReducer(
    authInitialState,
    on(AuthActions.dataIndexAuthReceived, (state, action) => ({
      ...state,
      dataIndexToken: action.token,
    })),
    on(AuthActions.aiGatewayAuthReceived, (state, action) => ({
      ...state,
      aiGatewayToken: action.token,
    }))
  ),
});
