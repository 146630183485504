import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { SharedUiModule } from '@mode/shared/ui';

import { SessionLoggedOutDialogComponent } from './session-logged-out-dialog/session-logged-out-dialog.component';
import { ButtonComponent } from '@mode/capra';

@NgModule({
  imports: [ButtonComponent, CommonModule, SharedUiModule, MatLegacyDialogModule],
  declarations: [SessionLoggedOutDialogComponent],
})
export class SessionsFeatureSessionModule {}
