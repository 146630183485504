import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
} else {
  // To debug preact components
  // require('@algolia/autocomplete-js/node_modules/preact/debug/src/index.js');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

__markMeasure('main.js', 'mode.webapp_ui.resource.js.main');
