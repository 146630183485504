import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const STORE_DEV_TOOLS = StoreDevtoolsModule.instrument({
  maxAge: 25,
  logOnly: true,
  actionSanitizer: (action) => {
    //remove large data fields from actions

    if (action.type === 'LoadQueryResultSuccess') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const queryAction = action as any; // Can't import legacy actions from Angular
      if (queryAction?.resource?.bytes >= 2000000) {
        // remove large data if result >2MB, to prevent devtools from crashing
        return {
          ...queryAction,
          resource: '<<LONG_BLOB>>',
          dataset: '<<LONG_BLOB>>',
          model: '<<LONG_BLOB>>',
        };
      }
    }

    if (action.type === '[LegacyContent] Load LegacyContent Success') {
      return {
        ...action,
        content: '<<LONG_BLOB>>',
      };
    }

    if (action.type === 'Flamingo.PlanSuccess') {
      return {
        ...action,
        result: '<<LONG_BLOB>>',
      };
    }

    return action;
  },
  stateSanitizer: (state) => {
    return {
      ...state, // remove state objects which are too big and cause devtools to crash.
      router: {
        name: state.router?.name,
        params: { ...(state.router?.params ?? {}) },
      },
      client: '<<LONG_BLOB>>',
      transient: '<<LONG_BLOB>>',
      switchView: '<<LONG_BLOB>>',
      datasetResult: '<<LONG_BLOB>>',
    };
  },
});
