import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthFacade, DATA_INDEX_AUTH_FACADE } from '@mode/shared/authorization/contract';
import { ADD_DATA_INDEX_AUTH } from '@mode/shared/util-halogen';
import { catchError, of, retry, switchMap, take, throwError, timer } from 'rxjs';

@Injectable()
export class DataIndexAuthInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.context.get(ADD_DATA_INDEX_AUTH) === true) {
      return this.authFacade.authToken$.pipe(
        take(1),
        switchMap((token) => {
          const requestWithJWT = request.clone({
            setHeaders: { Authorization: `Bearer ${token.jwt}` },
          });

          return next.handle(requestWithJWT);
        }),
        catchError((error) => {
          if (error.status === 412) {
            this.authFacade.authorizationExpired();
            return throwError(error);
          }
          return of(error);
        }),
        retry({
          count: 3,
          delay: (_, retryCount) => timer(retryCount * 100),
        })
      );
    }

    return next.handle(request);
  }

  constructor(@Inject(DATA_INDEX_AUTH_FACADE) private authFacade: AuthFacade) {}
}
