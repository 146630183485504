/* eslint-disable @typescript-eslint/no-namespace */
import { FlamingoTypes } from '@mode/shared/contract-common';

export namespace PagingHelpers {
  export function prevOffset(paging: FlamingoTypes.PagingResult) {
    return Math.max(paging.offset - paging.pageSize, 0);
  }

  export function nextOffset(paging: FlamingoTypes.PagingResult) {
    return Math.min(paging.offset + paging.pageSize, paging.total);
  }

  export function lastOffset(paging: FlamingoTypes.PagingResult) {
    return Math.floor((paging.total - 1) / paging.pageSize) * paging.pageSize;
  }

  export function pageRangeEnd(paging: FlamingoTypes.PagingResult) {
    return paging.hasNext ? paging.offset + paging.pageSize : paging.total;
  }

  export function usesPagination(paging: FlamingoTypes.PagingResult) {
    return paging && paging.total > paging.pageSize;
  }

  export function lastPage(paging: FlamingoTypes.PagingResult) {
    return paging.total > 0 ? Math.floor((paging.total - 1) / paging.pageSize) + 1 : 0;
  }

  export function getPageFromOffsetStart(paging: FlamingoTypes.PagingResult, offsetStart: number) {
    if (paging.pageSize === 0) {
      return 0;
    }

    return Math.floor(offsetStart / paging.pageSize) + 1;
  }

  export function getOffsetStartFromPage(paging: FlamingoTypes.PagingResult, pageNumber: number) {
    return (pageNumber - 1) * paging.pageSize;
  }
}
