import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LegacyRouterLinkActiveDirective } from './legacy-router-link-active.directive';
import { LegacyRouterLinkDirective } from './legacy-router-link.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [LegacyRouterLinkActiveDirective, LegacyRouterLinkDirective],
  providers: [LegacyRouterLinkDirective, [{ provide: Window, useValue: window }]],
  exports: [LegacyRouterLinkActiveDirective, LegacyRouterLinkDirective],
})
export class SharedUtilDomModule {}
