import { HttpContextToken } from '@angular/common/http';

/**
 * For storing an Error object that can be used to provide Bugsnag with stack trace
 * of the initiator of the API call in the event of an API error response.
 */
export const INITIATOR_CONTEXT = new HttpContextToken(() => undefined);

export const ACCEPT_BASIC_JSON = new HttpContextToken(() => false);

export const ADD_DATA_INDEX_AUTH = new HttpContextToken(() => false);

export const ADD_AI_GATEWAY_AUTH = new HttpContextToken(() => false);
