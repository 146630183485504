<button
  class="capra-button {{ variant }}"
  attr.data-test="capra-button-{{ variant }}"
  [ngClass]="['size-' + size, 'color-' + color, 'location-icon-' + iconLocation]"
  [class.with-icon]="!!icon"
  [disabled]="disabled"
  [type]="type"
  [attr.form]="form"
  [attr.aria-label]="ariaLabel"
  (click)="onClick($event)"
>
  <capra-icon *ngIf="!!icon"
    [icon]="icon"
  ></capra-icon>
  {{ text }}
</button>
