import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';

import { getSecretToken } from '@mode/shared/util-dom';

@Injectable()
export class SecretKeyInterceptor implements HttpInterceptor {
  constructor(@Inject(Window) private window: Window) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/api/') || request.url.includes('/internal/')) {
      // Easier to test if we check these properties here rather than constructor
      const metaSecretToken = getSecretToken(this.window.document.head) || ''; // default to empty string for type checker
      const secretKey = new URL(this.window.document.location.toString()).searchParams.get('secret_key');

      if (metaSecretToken || secretKey) {
        if (request.method === 'GET') {
          request = request.clone({
            params: request.params.set('secret_key', secretKey || metaSecretToken),
          });
        } else if (request.method === 'POST') {
          // this is untested
          if (metaSecretToken || secretKey) {
            request = request.clone({
              body: {
                ...(request.body as any),
                secret_key: secretKey,
                embed_secret: metaSecretToken,
              },
            });
          }
        }
      }
    }

    return next.handle(request);
  }
}
