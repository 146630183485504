import { Injectable } from '@angular/core';
import { ApiKeysResource, ApiKeyResource, OrganizationResource } from './account-response.types';
import { map, switchMap, tap } from 'rxjs/operators';
import { ApiService, getEmbeddedCollection, getLink } from '@mode/shared/util-halogen';
import { ApiKeyType, OrgTypes } from '@mode/shared/contract-common';
import { from, Observable, throwError } from 'rxjs';
import { AccountFetchService } from './account-fetch.service';
import { apiKeyCache, apiKeysCache } from '../resource-cache';
import { ApiTypes, ModeForm } from '@mode/shared/contract-common';
import { toOrganization } from '../data-mappers/account-data-mappers';

@Injectable({
  providedIn: 'root',
})
export class ApiKeyFetchService {
  constructor(private accountFetcher: AccountFetchService, private apiService: ApiService) {}

  public getWorkspaceApiKeys(
    username: string,
    page: number,
    query: string,
    filter: 'all' | 'admin' | 'member' = 'all',
    member?: string
  ) {
    return this.accountFetcher.getAccountResource(username).pipe(
      switchMap((account) => {
        const apiKeysLink = getLink(account, 'api_keys') as ApiTypes.HalLink;
        const apiKeysOptions = {
          params: { username, page, query, filter, ...(member ? { member } : {}) },
          includes: {
            api_keys: ['creator', 'revoker'],
          },
        };
        return from(this.apiService.getFromPath<ApiKeysResource>(apiKeysLink?.href, apiKeysOptions));
      }),
      tap((response: ApiKeysResource) => {
        apiKeysCache.set(username, response);
        const apiKeys = getEmbeddedCollection<ApiKeyResource>(response, 'api_keys') || [];
        apiKeys.map((apiKey: ApiKeyResource) => apiKeyCache.set(apiKey['token_name'], apiKey));
      })
    );
  }

  public getWorkspaceMemberApiKeys(username: string, page: number, query: string) {
    return this.getWorkspaceApiKeys(username, page, query, 'member');
  }

  public revokeApiKey(token: string) {
    const resource = apiKeyCache.get(token);

    if (resource != null) {
      return from(this.apiService.submitForm<ApiKeyResource>(resource, 'revoke', {}));
    } else {
      return throwError(new Error(`ApiKey ${token} is not in the cache`));
    }
  }

  public revokeAllApiKeys(workspace: string) {
    const resource = apiKeysCache.get(workspace) as ApiKeysResource;
    const revokeAllLink = getLink(resource, 'revoke_all') as ApiTypes.HalLink;
    const revokeAllForm = { action: revokeAllLink?.href, method: 'patch' } as ModeForm;
    const revokeAllParams = {};

    if (resource != null) {
      return from(this.apiService.submit<ApiKeysResource>(revokeAllForm, revokeAllParams));
    } else {
      return throwError(new Error(`ApiKeys for workspace "${workspace}" is out of sync with cache`));
    }
  }

  public revokeBulkApiKeys({
    workspace,
    member,
    type,
  }: {
    workspace: string;
    member?: string;
    type?: ApiKeyType;
  }): Observable<ApiKeysResource> {
    const resource = apiKeysCache.get(workspace) as ApiKeysResource;
    const params: { workspace_api_key: { member?: string; type?: ApiKeyType } } = {
      workspace_api_key: {
        ...(member ? { member } : {}),
        ...(type ? { type } : {}),
      },
    };

    if (resource != null) {
      return from(this.apiService.submitForm<ApiKeysResource>(resource, 'revoke_all', params));
    } else {
      return throwError(new Error(`ApiKeys for workspace "${workspace}" is out of sync with cache`));
    }
  }

  public createApiKey({
    workspace,
    member,
    config,
  }: {
    workspace: string;
    member?: string;
    config: OrgTypes.ApiKeyConfig;
  }): Observable<ApiKeyResource> {
    const resource = apiKeysCache.get(workspace);

    const apiKey = {
      name: config.name,
      expires_at: config.expiresAt,
      ...(member ? { member } : {}),
    };

    const params = {
      workspace_api_key: apiKey,
    };

    if (resource != null) {
      return from(this.apiService.submitForm<ApiKeyResource>(resource, 'create', params));
    } else {
      return throwError(new Error(`ApiKeys for ${workspace} is not in the cache`));
    }
  }

  public updateMemberApiKeysEnabled(username: string, enabled: boolean): Observable<OrgTypes.Organization> {
    const params = {
      organization: {
        organization_account: {
          member_api_keys_enabled: enabled,
        },
      },
    };

    return this.accountFetcher.getAccountResource(username).pipe(
      switchMap((account) => from(this.apiService.submitForm<OrganizationResource>(account, 'edit', params, false))),
      map((org) => toOrganization(org))
    );
  }
}
