import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as EnvironmentActions from './environment.actions';

import { catchError, map, of, switchMap } from 'rxjs';
import { EnvironmentClientService } from '../environment-client.service';

@Injectable()
export class EnvironmentEffects {
  private actions$ = inject(Actions);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.initEnvironment),
      switchMap(() =>
        this.client.getEnvironment().pipe(
          map((environment) => EnvironmentActions.loadEnvironmentSuccess({ environment })),
          catchError((error) => of(EnvironmentActions.loadEnvironmentFailure({ error })))
        )
      )
    )
  );

  constructor(private client: EnvironmentClientService) {}
}
