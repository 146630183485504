import { Component, OnInit } from '@angular/core';
/* eslint-disable */
@Component({
  selector: 'mode-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showUIView = true;

  constructor() {}

  ngOnInit(): void {
    const legacyApp = window.document.body.getAttribute('mode-app');

    if (['mode.signup', 'mode.search', 'mode.main', 'mode.importer', 'mode.sign-in'].includes(legacyApp)) {
      this.showUIView = false;
    }
  }
}
