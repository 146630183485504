import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { textHasLargeNumber, stringifyLargeNumbers } from '@mode/shared/util-js';

const DATASET_URL = /\/results\/content.json/i;

@Injectable()
export class LegacyDatasetInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (DATASET_URL.test(request.url) && request.responseType === 'json') {
      // Change responsetype to avoid default Angular deserializer
      const textRequest = request.clone({
        responseType: 'text',
      });

      return next.handle(textRequest).pipe(
        map((event) => {
          if (event instanceof HttpResponse && typeof event.body === 'string') {
            if (request.responseType === 'text') {
              // leave the body as unparsed text
              return event;
            }

            if (textHasLargeNumber(event.body)) {
              const converted = stringifyLargeNumbers(event.body);

              return event.clone({
                body: JSON.parse(converted),
                headers: event.headers.append('x-has-long-number', 'true'),
              });
            } else {
              return event.clone({
                body: JSON.parse(event.body),
              });
            }
          }

          return event;
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
