import { Adapter, Compiler } from '@mode-switch/adapt';
import { FlamingoAdapter } from '@mode-switch/adapter-flamingo';
import {
  DataPath,
  DataRole,
  DataType,
  DateTime,
  Expr,
  ExprCall,
  ExprForm,
  ExprFrame,
  ExprStage,
  ExprState,
  Ident,
  ParseNode,
  ParseResult,
  Parser,
} from '@mode-switch/express';
import { Attr, Calc, Column, Defaults, From, Model, Names, Values, View } from '@mode-switch/model';
import {
  ColumnType,
  DataType as VegasDataType,
  FieldTypes as VegasField,
  Model as VegasModel,
  RoleType as VegasRoleType,
  VariableType as VegasVariableType,
} from '@mode-vegas/vegas';
import { ChartTypes, DataViewTypes, FlamingoTypes } from '@mode/shared/contract-common';
import { escapeAttrName, isPresent } from '@mode/shared/util-js';

type ModeRole = FlamingoTypes.ModeRole;

const viewCache = new Map<string, FlamingoTypes.ModeView>();
const compilerCache: {
  [viewName: string]: {
    [formula: string]: Compiler.Result<ModeRole>;
  };
} = {};

/* eslint-disable @typescript-eslint/no-namespace, no-case-declarations, no-inner-declarations */
export namespace Switch {
  export const adapter = new FlamingoAdapter();

  export function validate(model: Model<ModeRole>, viewName: string, source: string): [boolean, string | null] {
    switch (source) {
      case Names.Key:
      case Values.Key:
        return [true, null];
      default:
        const hasDupe = Formulas.hasDuplicate(model, viewName, source);
        if (hasDupe) {
          return [false, `There is a duplicate field in the encoding.`];
        }

        const result = Formulas.validateFormula(model, viewName, source);
        if (result === true) {
          return [true, null];
        } else {
          return [false, result[0].message];
        }
    }
  }

  export function parse(source: string): Expr.Parsed | undefined {
    const parsed = Parser.parse(source);
    switch (parsed.type) {
      case ParseResult.Type.Failure: {
        return undefined;
      }
      case ParseResult.Type.Success: {
        return Expr.Parsed.make(parsed.root);
      }
    }
  }

  export function compile(model: Model<ModeRole>, viewName: string, source: string): Compiler.Result<ModeRole> {
    const view = Switch.Views.find(model, viewName);
    if (view && (!viewCache.has(viewName) || viewCache.get(viewName) !== view)) {
      compilerCache[viewName] = {};
      viewCache.set(viewName, view);
    }

    const cache = compilerCache[viewName];
    if (cache[source] != null) {
      const result = cache[source];
      return result;
    } else {
      const result = adapter.compile(model, viewName, source);
      cache[source] = result;
      return result;
    }
  }

  export function getCompiledExpr(result: Compiler.Result<ModeRole>) {
    if (result.type === Compiler.Result.Type.Success) {
      return result.expr;
    } else {
      return undefined;
    }
  }

  export function compileToExpr(
    model: Model<ModeRole>,
    viewName: string,
    source: string
  ): Expr.Compiled<ModeRole> | undefined {
    const result = compile(model, viewName, source);
    if (result.type === Compiler.Result.Type.Success) {
      return result.expr;
    } else {
      return undefined;
    }
  }

  export function getCallFunction(expr: FlamingoTypes.ModeExpr) {
    if (expr.root.type === ParseNode.Type.CallExpression) {
      return expr.root.callee;
    }

    return;
  }

  export function getFieldSource(attrField: FlamingoTypes.ModeField | FlamingoTypes.ModeAttr): string {
    switch (attrField.source) {
      case Attr.Source.Field:
        return attrField.formula.source;
      default:
        return `[${attrField.name}]`;
    }
  }

  export function column(
    name: string,
    source: string,
    type: Attr.Type,
    role: Attr.Role,
    scale: Attr.Scale,
    dataType: DataType,
    dataRole?: DataRole
  ): Column<ModeRole> {
    /// Why do I have to include scale here?
    return Column.make(name, source, type, role, scale, dataType, dataRole);
  }

  export function calc(
    model: Model<ModeRole>,
    viewName: string,
    source: string,
    name: string,
    type: Attr.Type,
    desc: string
  ): Calc<ModeRole> {
    return Adapter.Calcs.make(adapter, model, viewName, name, source, type, desc);
  }

  export function field(
    model: Model<ModeRole>,
    viewName: string,
    source: string,
    type: Attr.Type,
    alias?: string,
    frame?: ExprFrame
  ): FlamingoTypes.ModeField | undefined {
    try {
      return Adapter.Fields.make(adapter, model, viewName, source, type, { alias, frame });
    } catch (err) {
      return undefined;
    }
  }

  export type TryFieldResult =
    // Valid: field, no error
    | { field: FlamingoTypes.ModeField; error: undefined }
    // Invalid: no field, with error
    | { field: undefined; error: Error };

  export function tryField(
    model: Model<ModeRole>,
    viewName: string,
    source: string,
    type: Attr.Type,
    alias?: string,
    frame?: ExprFrame
  ): TryFieldResult {
    try {
      return {
        field: Adapter.Fields.make(adapter, model, viewName, source, type, { alias, frame }),
        error: undefined,
      };
    } catch (fieldError: unknown) {
      return {
        field: undefined,
        error:
          fieldError instanceof Error
            ? fieldError
            : // Shouldn't happen ..
              new Error(`Could not compile formula with source "${source}"`),
      };
    }
  }

  export namespace Formulas {
    export function isValid(model: Model<ModeRole>, viewName: string, source: string): boolean {
      return validateFormula(model, viewName, source) === true;
    }

    export function hasDuplicate(model: Model<ModeRole>, viewName: string, source: string): boolean {
      const allAttrs = Attrs.getAll(model, viewName);
      const formulaName = getFieldFormula(model, viewName, source);

      return allAttrs.filter((attr) => attr.name === formulaName).length > 1;
    }

    export function validateFormula(model: Model<ModeRole>, viewName: string, source: string): Error[] | true {
      try {
        const result = compile(model, viewName, source);
        if (result.type === Compiler.Result.Type.Success) {
          return true;
        } else {
          return result.errors;
        }
      } catch (err) {
        if (err instanceof Error) {
          return [err];
        } else {
          return [new Error(JSON.stringify(err))];
        }
      }
    }

    export function getFieldFormula(model: Model<ModeRole>, viewName: string, source: string): string | null {
      const result = compile(model, viewName, source);
      switch (result.type) {
        case Compiler.Result.Type.Success:
          if ('path' in result.expr) {
            return result.expr.path.name;
          }
          break;
        default:
          break;
      }

      return null;
    }

    export function isNames(source: string): boolean {
      return source === Names.Key;
    }

    export function isValues(source: string): boolean {
      return source === Values.Key;
    }

    export function reference(formula: FlamingoTypes.ModeExpr): DataPath | null {
      switch (formula.form) {
        case ExprForm.Access:
        case ExprForm.Aggregate:
        case ExprForm.Bin:
        case ExprForm.DatePart:
        case ExprForm.DateTrunc:
          return formula.path;
        default:
          return null;
      }
    }

    export function isNumeric(formulaDataType: DataType.Name): boolean {
      switch (formulaDataType) {
        case DataType.Name.Float:
        case DataType.Name.Integer:
        case DataType.Name.Decimal:
          return true;
        default:
          return false;
      }
    }

    export function isDate(formulaDataType: DataType.Name): boolean {
      switch (formulaDataType) {
        case DataType.Name.DateTime:
        case DataType.Name.Timestamp:
          return true;
        default:
          return false;
      }
    }
  }

  export namespace Attrs {
    export function isNames(attr: View.Attr<ModeRole>): boolean {
      return attr.source === Attr.Source.Names;
    }

    export function isValues(attr: View.Attr<ModeRole>): boolean {
      return attr.source === Attr.Source.Values;
    }

    export function isContinuous(attr: View.Attr<ModeRole>): boolean {
      return attr.type === Attr.Type.Continuous;
    }

    export function isDiscrete(attr: View.Attr<ModeRole>): boolean {
      return attr.type === Attr.Type.Discrete;
    }

    export function find(model: Model<ModeRole>, viewName: string, name: string): View.Attr<ModeRole> | undefined {
      return Model.Views.findAttr(model, viewName, name);
    }

    export function findByFormula(
      model: Model<ModeRole>,
      viewName: string,
      formula: Expr.Parsed | FlamingoTypes.ModeExpr
    ): View.Attr<ModeRole> | null | undefined {
      switch (formula.form) {
        case ExprForm.Access:
        case ExprForm.Aggregate:
        case ExprForm.Bin:
        case ExprForm.DatePart:
        case ExprForm.DateTrunc:
          return find(model, viewName, formula.path.name);
        default:
          return null;
      }
    }

    /**
     * Detects if the given name is a key
     * Attempts to match the rules at https://help.tableau.com/current/pro/desktop/en-us/data_clean_adm.htm
     * @param name Name of the Switch Attr
     */
    export function detectKey(name: string) {
      // Unoptimized, maybe could be combined into fewer regex checks
      // Detect if name starts with Code, Key, or ID excluding when followed by a capital
      if (name.match(/^(code|id|key)((\W|[_0-9]).*?)?$/i)) {
        // guardrails-disable-line
        return true;
      }

      // Detect if name starts with Code, Key, or ID and is followed by a capital
      if (name.match(/^([cC][oO][dD]e|[iI]d|[kK][eE]y)[A-Z].*?$/)) {
        return true;
      }

      // Detect if name ends with Code, Key, ID, Number, Nbr, and Num excluding when trailed by a lowercase
      if (name.match(/.*(\W|[_0-9])(code|id|key|number|nbr|num)$/i)) {
        return true;
      }

      // Detect if name is Number, Nbr, and Num
      if (name.match(/^(number|nbr|num)$/i)) {
        return true;
      }

      // Detect if name ends with Code, Key, or ID and is trailed by a lowercase
      if (name.match(/[a-z](C[oO][dD][eE]|I[dD]|K[eE][yY])$/)) {
        return true;
      }

      // TODO: follow Tableau rules around date-related words

      return false;
    }

    /**
     * Detects if the attribute name and data type should be treated as a discrete dimension
     * even if it's a numeric formula.
     * Uses logic from @see {detectKey}
     * @param name Name of the Switch Attr
     * @param formulaDataType return type of attribute
     */
    export function detectDimension(name: string, formulaDataType: DataType.Name) {
      if (Formulas.isNumeric(formulaDataType)) {
        if (detectKey(name)) {
          return true;
        }

        return false;
      }

      return true;
    }

    export function dimensions(model: Model<ModeRole>, viewName: string): ReadonlyArray<View.Attr<ModeRole>> {
      const view = Switch.Views.find(model, viewName);
      if (view != null) {
        return view.attrs.filter((a) => {
          if (a.source !== Attr.Source.Calc && detectDimension(a.name, a.formula.type.name)) {
            return true;
          }

          return !Formulas.isNumeric(a.formula.type.name);
        });
      }

      return [];
    }

    export function measures(model: Model<ModeRole>, viewName: string): ReadonlyArray<View.Attr<ModeRole>> {
      const view = Switch.Views.find(model, viewName);
      if (view != null) {
        const dims = dimensions(model, viewName);
        return view.attrs.filter((a) => !dims.includes(a));
      }

      return [];
    }

    export function isAttr(attr: FlamingoTypes.ModeField | FlamingoTypes.ModeAttr): attr is FlamingoTypes.ModeAttr {
      if (attr == null) {
        return false;
      }

      switch (attr.source) {
        case Attr.Source.Names:
        case Attr.Source.Values:
        case Attr.Source.Column:
        case Attr.Source.Calc:
          if (!('alias' in attr)) {
            return true;
          } else {
            return false;
          }
        default:
          return false;
      }
    }

    export function defaultAggregate(attr: FlamingoTypes.ModeAttr): ExprCall.Name | undefined {
      switch (attr.source) {
        case Attr.Source.Calc:
        case Attr.Source.Column: {
          return attr.defaults.aggregate as ExprCall.Name;
        }
        default:
          return undefined;
      }
    }

    export function defaultFormat(attr: FlamingoTypes.ModeAttr): ChartTypes.TextFormat | undefined {
      return attr.format ?? undefined;
    }

    export function defaultColor(attr: FlamingoTypes.ModeAttr): ChartTypes.FieldColor | undefined {
      return attr.color ?? undefined;
    }

    export function getAll(model: Model<ModeRole>, viewName: string): ReadonlyArray<FlamingoTypes.ModeAttr> {
      return Model.Views.find(model, viewName)?.attrs || [];
    }

    export function getCalcs(model: Model<ModeRole>, viewName: string): FlamingoTypes.ModeCalc[] {
      return getAll(model, viewName).filter((attr): attr is FlamingoTypes.ModeCalc => attr.source === Attr.Source.Calc);
    }

    export function getColumns(model: Model<ModeRole>, viewName: string): FlamingoTypes.ModeColumn[] {
      return getAll(model, viewName).filter(
        (attr): attr is FlamingoTypes.ModeColumn => attr.source === Attr.Source.Column
      );
    }

    export function isCalc(attr: FlamingoTypes.ModeAttr): boolean {
      return attr.source === Attr.Source.Calc;
    }

    export function isParentCalc(attr: FlamingoTypes.ModeAttr): boolean {
      return isCalc(attr) && attr.isLocal === false;
    }

    export function isOwnCalc(attr: FlamingoTypes.ModeAttr) {
      return isCalc(attr) && attr.isLocal !== false;
    }
  }

  export namespace Calcs {
    export function defaults(dataType: DataType, callName?: ExprCall.Name | string) {
      return Defaults.make(dataType, {
        aggregate: callName,
      });
    }

    export function duplicate(model: Model<ModeRole>, viewName: string, original: Calc<ModeRole>): Calc<ModeRole> {
      const origName = original.name;

      let counter = 1;
      let propName = `${origName} (copy)`;
      while (Model.Views.findAttr(model, viewName, propName)) {
        propName = `${origName} (copy ${counter++})`;
      }

      const {
        type,
        role,
        scale,
        formula,
        formula: { source },
        desc,
        defaults,
      } = original;
      return Calc.make(propName, source, type, role, scale, formula.type, formula.role, {
        desc,
        defaults,
      });
    }
  }

  export namespace Columns {
    export function defaults(colName: string, dataType: DataType, callName?: ExprCall.Name | string) {
      return Defaults.make(dataType, {
        aggregate: resolveDefaultAggregate(colName, dataType, callName),
      });
    }

    export function resolveDefaultAggregate(colName: string, dataType: DataType, callName?: ExprCall.Name | string) {
      const defaults = Defaults.make(dataType, { aggregate: callName });
      if (defaults.aggregate !== callName) {
        if (Attrs.detectDimension(colName, dataType.name)) {
          return ExprCall.Name.Count;
        }
      }
      return defaults.aggregate;
    }
  }

  export namespace Expressions {
    export function isCompiled(expr: Expr.Base | undefined): expr is FlamingoTypes.ModeCompiledExpr {
      return expr?.state === ExprState.Compiled;
    }

    export function isAggregate(expr: Expr.Base | undefined): expr is FlamingoTypes.BaseAggregate {
      return expr?.form === ExprForm.Aggregate;
    }

    export function isAccess(
      expr: FlamingoTypes.ModeCompiledExpr | Expr.Base | undefined
    ): expr is FlamingoTypes.CompiledAccess {
      return expr?.form === ExprForm.Access;
    }

    export function isDatePart(
      expr: FlamingoTypes.ModeCompiledExpr | undefined
    ): expr is FlamingoTypes.CompiledDatePart {
      return expr?.form === ExprForm.DatePart;
    }

    export function isDateTrunc(
      expr: FlamingoTypes.ModeCompiledExpr | undefined
    ): expr is FlamingoTypes.CompiledDateTrunc {
      return expr?.form === ExprForm.DateTrunc;
    }

    export function isBin(expr: FlamingoTypes.ModeCompiledExpr | undefined): expr is FlamingoTypes.CompiledBin {
      return expr?.form === ExprForm.Bin;
    }

    export function isAnalytic(
      expr: FlamingoTypes.ModeCompiledExpr | undefined
    ): expr is FlamingoTypes.CompiledAnalytic {
      switch (expr?.form) {
        case ExprForm.PctDifference:
        case ExprForm.Difference:
        case ExprForm.PctOfTotal:
          return true;
        default:
          return false;
      }
    }

    export function isAnalyticWithRelTo(
      expr: FlamingoTypes.ModeCompiledExpr | undefined
    ): expr is FlamingoTypes.CompiledAnalyticWithRelTo {
      switch (expr?.form) {
        case ExprForm.PctDifference:
        case ExprForm.Difference:
          return true;
        default:
          return false;
      }
    }

    export function isAnalyticWithTotalUsing(
      expr: FlamingoTypes.ModeCompiledExpr | undefined
    ): expr is FlamingoTypes.CompiledAnalyticWithTotalUsing {
      return expr?.form === ExprForm.PctOfTotal;
    }

    export function isAggregateStage(
      expr: FlamingoTypes.ModeCompiledExpr | undefined
    ): expr is FlamingoTypes.CompiledAggregate {
      return expr?.stage === ExprStage.Aggregate;
    }

    export function isAnalyticStage(
      expr: FlamingoTypes.ModeCompiledExpr | undefined
    ): expr is FlamingoTypes.CompiledAggregate {
      return expr?.stage === ExprStage.Analytic;
    }

    export function getPath(expr: Expr.Base) {
      switch (expr.form) {
        case ExprForm.Access:
        case ExprForm.Aggregate:
        case ExprForm.Bin:
        case ExprForm.DatePart:
        case ExprForm.DateTrunc:
          return DataPath.name(expr.path);
        default:
          return undefined;
      }
    }

    export function getAttrName(expr: Expr.Base): string | undefined {
      if (isCompiled(expr)) {
        if (isAnalytic(expr)) {
          return getPath(expr.expr);
        }
      }

      return getPath(expr);
    }

    export function getAggFunc(expr?: FlamingoTypes.ModeCompiledExpr | Expr.Parsed): string | undefined {
      if (expr) {
        if (isAggregate(expr)) {
          return expr.call;
        } else if ('expr' in expr) {
          if (isAggregate(expr.expr)) {
            return expr.expr.call;
          }
        }
      }

      return;
    }

    /**
     * Returns the suggested {@link ExprForm.TotalUsing} function per the expr's applied
     * aggregate function, if one can be determined; calc fields will not have this defined.
     * While SUM & COUNT may use the TOTAL function, COUNTD & AVG should not by default.
     * See "Quick Calculation produces inaccurate percentage results: COUNTD aggregate
     * calculation exceeds 100% when using percent of total." If the applied aggregate
     * function cannot be determined, TOTAL is returned
     */
    export function getDefaultTotalUsing(expr?: FlamingoTypes.ModeCompiledExpr | Expr.Parsed): ExprForm.TotalUsing {
      const aggFunc = getAggFunc(expr);
      if (aggFunc === undefined) {
        return ExprForm.TotalUsing.Total;
      }
      switch (aggFunc) {
        case ExprCall.Name.Sum:
        case ExprCall.Name.Count: {
          return ExprForm.TotalUsing.Total;
        }
        default: {
          return ExprForm.TotalUsing.Sum;
        }
      }
    }
  }

  export namespace Fields {
    export function names(alias?: string): FlamingoTypes.SystemField {
      return Adapter.Fields.names(adapter, alias);
    }

    export function values(alias?: string): FlamingoTypes.SystemField {
      return Adapter.Fields.values(adapter, alias);
    }

    export function isNames(attr: FlamingoTypes.ModeField | FlamingoTypes.ModeAttr): boolean {
      return attr.source === Attr.Source.Names;
    }

    export function isValues(attr: FlamingoTypes.ModeField | FlamingoTypes.ModeAttr): boolean {
      return attr.source === Attr.Source.Values;
    }

    export function isSystem(attr: FlamingoTypes.ModeField | FlamingoTypes.ModeAttr): boolean {
      return isValues(attr) || isNames(attr);
    }
  }

  export function makeAttrs(
    columnResources: DataViewTypes.DatasetResourceColumn[] = [],
    viewAttrs: ReadonlyArray<View.Attr<ModeRole>> = []
  ): Array<FlamingoTypes.ModeAttr> {
    const attrs = viewAttrs.filter((attr): attr is FlamingoTypes.ModeAttr =>
      attr.source === Attr.Source.Column
        ? // Column attrs must exist in the set of meta columns
          columnResources.some((column) => column.name === attr.name)
        : // Calcs are always included
          true
    );

    {
      // Add or merge in columns
      // ///

      const persistedColumns = new Map(
        (function* () {
          for (const [index, attr] of attrs.entries()) {
            if (attr.source === Attr.Source.Column) {
              yield [attr.name, { attr, index }];
            }
          }
        })()
      );

      const columnAttrs = columnResources.map(makeColumnFromResource).filter(isPresent);
      for (const column of columnAttrs) {
        const persisted = persistedColumns.get(column.name);

        if (persisted) {
          const { formula } = column;
          attrs[persisted.index] = {
            ...persisted.attr,
            formula,
          };
        } else {
          attrs.push(column);
        }
      }

      // Ensure Attrs are configured _correctly_ per their compiled state
      // ///

      const viewName = Ident.next('V');
      const model = Models.make(viewName, [
        Models.view(viewName, viewName, {
          attrs,
        }),
      ]);

      for (const [index, attr] of attrs.entries()) {
        const { field } = tryField(model, viewName, attr.formula.source, attr.type);
        if (field) {
          attrs[index] = [assignAttrFormula, assignAttrRole, assignAttrType, assignAttrDefaults].reduce(
            (attr, func) => func(attr, field),
            attr
          );
        }
      }
    }

    return attrs;
  }

  /**
   * Attr should always use the field's formula as it is resolved against the view (ie. compiled & up to date).
   * Calcs, for example, may have their formula data type change as a result of an update to another calc they
   * reference (ie. their dependencies)
   */
  function assignAttrFormula(attr: FlamingoTypes.ModeAttr, field: FlamingoTypes.ModeField): FlamingoTypes.ModeAttr {
    const formula = field.formula;
    return <FlamingoTypes.ModeAttr>{
      ...attr,
      formula,
    };
  }

  /**
   * Calcs currently assume the role semantics of Switch, so dimension -> unaggregated and measure -> aggregated;
   * this is consistent with how they're persisted from the calc field editor. Columns on the other hand can be
   * dimensions or measures based on their data type. Qualitative data types will always be dimensions while
   * quantitative data types can be both dimensions or measures
   */
  function assignAttrRole(attr: FlamingoTypes.ModeAttr, field: FlamingoTypes.ModeField): FlamingoTypes.ModeAttr {
    if (attr.source === Attr.Source.Calc) {
      const role = field.role;
      return <FlamingoTypes.ModeAttr>{
        ...attr,
        role,
      };
    }

    switch (field.formula.type.name) {
      case DataType.Name.Boolean:
      case DataType.Name.Regexp:
      case DataType.Name.String:
      case DataType.Name.DateTime:
      case DataType.Name.Timestamp: {
        const role = Attr.Role.Dimension;
        return <FlamingoTypes.ModeAttr>{
          ...attr,
          role,
        };
      }
      default: {
        return attr;
      }
    }
  }

  /**
   * Ensure the attr type is valid per the formula data type. All data types may be discrete, but not all data
   * types may be continuous
   */
  function assignAttrType(attr: FlamingoTypes.ModeAttr, field: FlamingoTypes.ModeField): FlamingoTypes.ModeAttr {
    switch (field.formula.type.name) {
      case DataType.Name.Boolean:
      case DataType.Name.String:
      case DataType.Name.Regexp: {
        const type = Attr.Type.Discrete;
        return <FlamingoTypes.ModeAttr>{
          ...attr,
          type,
        };
      }
      default: {
        return attr;
      }
    }
  }

  /**
   * Ensure the attr defaults are valid per the formula data type. Columns and Calcs have different rules for assigning
   * a default aggregate and as such use different `defaults` APIs; Columns have an extra step for detecting dimensions
   * (countable fields) while Calcs assign an aggregate per the formula data type
   */
  function assignAttrDefaults(attr: FlamingoTypes.ModeAttr, field: FlamingoTypes.ModeField): FlamingoTypes.ModeAttr {
    switch (attr.source) {
      case Attr.Source.Column: {
        const defaults = Columns.defaults(attr.name, field.formula.type, attr.defaults.aggregate);
        return <FlamingoTypes.ModeAttr>{
          ...attr,
          defaults,
        };
      }
      case Attr.Source.Calc: {
        const defaults = Calcs.defaults(field.formula.type, attr.defaults.aggregate);
        return <FlamingoTypes.ModeAttr>{
          ...attr,
          defaults,
        };
      }
      default: {
        return attr;
      }
    }
  }

  function makeColumnFromResource(column: DataViewTypes.DatasetResourceColumn): Column<ModeRole> | undefined {
    const { name } = column;
    const formula = `[${escapeAttrName(name)}]`;

    switch (column.type) {
      case 'boolean':
        return Column.make(
          name,
          formula,
          Attr.Type.Discrete,
          Attr.Role.Dimension,
          Attr.Scale.Categorical,
          DataType.BooleanType.make()
        );
      case 'float':
      case 'decimal':
      case 'double precision':
      case 'double':
      case 'real':
      case 'fixed':
      case 'number': {
        const isKey = Attrs.detectKey(name);
        const dataType = DataType.FloatType.make();
        const defaults = Columns.defaults(name, dataType);
        return Column.make(
          name,
          formula,
          isKey ? Attr.Type.Discrete : Attr.Type.Continuous,
          isKey ? Attr.Role.Dimension : Attr.Role.Measure,
          isKey ? Attr.Scale.Categorical : Attr.Scale.Quantitative,
          dataType,
          undefined,
          {
            defaults,
          }
        );
      }
      case 'int':
      case 'bigint':
      case 'integer': {
        const isKey = Attrs.detectKey(name);
        const dataType = DataType.IntegerType.make();
        const defaults = Columns.defaults(name, dataType);
        return Column.make(
          name,
          formula,
          isKey ? Attr.Type.Discrete : Attr.Type.Continuous,
          isKey ? Attr.Role.Dimension : Attr.Role.Measure,
          isKey ? Attr.Scale.Categorical : Attr.Scale.Quantitative,
          dataType,
          undefined,
          {
            defaults,
          }
        );
      }
      case 'time':
      case 'json':
      case 'text':
      case 'string':
      case 'character':
      case 'character varying':
      case 'varchar':
      case 'varchar2':
      case 'nvarchar2':
        return Column.make(
          name,
          formula,
          Attr.Type.Discrete,
          Attr.Role.Dimension,
          Attr.Scale.Categorical,
          DataType.StringType.make()
        );
      case 'date':
      case 'datetime':
      case 'timestamp':
      case 'timestamp with time zone':
      case 'timestamp without time zone':
        return Column.make(
          name,
          formula,
          Attr.Type.Discrete, // Tableau makes timestamps discrete by default
          Attr.Role.Dimension,
          Attr.Scale.Ordinal,
          /// Specifies the backend time representation
          DataType.TimestampType.make(DateTime.TimeUnit.Micros)
        );
      default: {
        return undefined;
      }
    }
  }

  export namespace Views {
    export function make(
      viewName: string,
      tableName: string,
      columns: DataViewTypes.DatasetResourceColumn[],
      attrs: ReadonlyArray<View.Attr<ModeRole>> = [],
      opts: View.Opts<ModeRole> = {}
    ): View<ModeRole> {
      opts = { ...opts, attrs: makeAttrs(columns, attrs) };
      return View.make(From.table(tableName), viewName, opts);
    }

    export function add(model: Model<ModeRole>, view: View<ModeRole>): Model<ModeRole> {
      return Model.Views.add(model, view);
    }

    export function remove(model: Model<ModeRole>, viewName: string): Model<ModeRole> {
      return Model.Views.remove(model, viewName);
    }

    export function merge(model: Model<ModeRole>, view: View<ModeRole>): Model<ModeRole> {
      let mergedView = Model.Views.find(model, view.name);

      if (mergedView == null) {
        return Model.Views.add(model, view);
      } else {
        const calcs = View.Calcs.all(view);
        const columns = View.Columns.all(view);
        const vColumns = new Map<string, Column<DataRole>>();

        for (const vColumn of columns) {
          vColumns.set(vColumn.name, vColumn);
        }

        // Remove columns not in the new view
        mergedView = View.Columns.filter(mergedView, (vColumn) => vColumns.has(vColumn.name));

        // Add columns into the existing view
        mergedView = View.Columns.replace(mergedView, ...columns);

        // Add calcs into the new view
        mergedView = View.Calcs.replace(mergedView, ...calcs);

        mergedView = {
          ...mergedView,
          from: view.from,
          attrs: mergedView.attrs
            .slice()
            .sort((a, b) => View.Attrs.indexOf(view, a.name) - View.Attrs.indexOf(view, b.name)),
        };

        // Replace from and return
        return Model.Views.add(model, mergedView);
      }
    }

    export function find(model: FlamingoTypes.ModeModel, name: string): FlamingoTypes.ModeView | undefined {
      return model.views.find((v) => v.name === name);
    }

    export function isFrom(model: FlamingoTypes.ModeModel, name: string, runToken: string): boolean {
      const view = Model.Views.find(model, name);
      return view != null && view.from.alias === runToken;
    }
  }

  export namespace Models {
    export function empty(): Model<ModeRole> {
      return make('');
    }

    export function make(name: string, views: View<ModeRole>[] = []): Model<ModeRole> {
      return Model.make(name, { views });
    }

    export function view(name: string, from?: string, opts?: View.Opts<ModeRole>): View<ModeRole> {
      return View.make(From.table(from || name), name, opts);
    }

    export function makeFromView(name: string, initialView: View<ModeRole>): FlamingoTypes.ModeModel {
      return Views.add(make(name), initialView);
    }
  }

  export namespace Compat {
    export function makeField(model: VegasModel, attr: View.Attr<ModeRole> | FlamingoTypes.ModeField): VegasField {
      const buildDataField = (): VegasField => {
        const roleType = makeRoleType(attr.role);
        const varType = makeVariableType(attr.type);
        const dataType = makeDataType(attr.formula.type.name);

        return model.buildDataField(attr.name, dataType, roleType, varType);
      };

      const buildViewCalcField = (alias: string): VegasField => {
        const { source } = attr.formula;
        const varType = makeVariableType(attr.type);

        return model.buildViewCalcField(source, varType, alias);
      };

      switch (attr.source) {
        case Attr.Source.Calc:
        case Attr.Source.Column:
          return buildDataField();
        case Attr.Source.Field:
          return buildViewCalcField(attr.alias);
        case Attr.Source.Names:
          return model.buildViewNamesField(adapter.alias());
        case Attr.Source.Values:
          return model.buildViewValuesField(adapter.alias());
      }
    }

    export function makeVegasModel(view: FlamingoTypes.ModeView): VegasModel {
      const schema = View.Columns.all(view).map((attr) => ({
        name: attr.name,
        type: makeVegasColumnType(attr.formula.type),
      }));
      return VegasModel.build(schema, []);
    }

    export function makeVegasColumnType(dataType: DataType): ColumnType {
      switch (dataType.name) {
        case DataType.Name.Boolean:
          return ColumnType.BOOLEAN;
        case DataType.Name.Float:
        case DataType.Name.Decimal:
          return ColumnType.FLOAT;
        case DataType.Name.Integer:
          return ColumnType.INTEGER;
        case DataType.Name.DateTime:
        case DataType.Name.Timestamp:
          return ColumnType.DATETIME;
        case DataType.Name.Regexp:
        case DataType.Name.String:
        case DataType.Name.Null:
          return ColumnType.STRING;
      }
    }

    export function makeDataType(dataType: DataType.Name): VegasDataType {
      switch (dataType) {
        case DataType.Name.Float:
        case DataType.Name.Decimal:
          return VegasDataType.FLOAT;
        case DataType.Name.Numeric:
          return VegasDataType.NUMBER;
        case DataType.Name.Integer:
          return VegasDataType.INTEGER;
        case DataType.Name.DateTime:
        case DataType.Name.Timestamp:
          return VegasDataType.DATETIME;
        case DataType.Name.Regexp:
          return VegasDataType.REGEXP;
        case DataType.Name.String:
          return VegasDataType.STRING;
        case DataType.Name.Boolean:
          return VegasDataType.BOOLEAN;
        case DataType.Name.Null:
          return VegasDataType.NULL;
      }
    }

    export function makeRoleType(attrRole: Attr.Role): VegasRoleType {
      switch (attrRole) {
        case Attr.Role.Dimension:
          return VegasRoleType.DIMENSION;
        case Attr.Role.Measure:
          return VegasRoleType.MEASURE;
      }
    }

    export function makeVariableType(attrType: Attr.Type): VegasVariableType {
      switch (attrType) {
        case Attr.Type.Continuous:
          return VegasVariableType.CONTINUOUS;
        case Attr.Type.Discrete:
          return VegasVariableType.DISCRETE;
      }
    }

    export function boolToAttrType(continuous: boolean): Attr.Type {
      return continuous ? Attr.Type.Continuous : Attr.Type.Discrete;
    }

    export function fromVegasVarType(varType: VegasVariableType): Attr.Type {
      switch (varType) {
        case VegasVariableType.DISCRETE:
          return Attr.Type.Discrete;
        case VegasVariableType.CONTINUOUS:
          return Attr.Type.Continuous;
      }
    }

    export function isVegasVarType(varType: VegasVariableType | Attr.Type): varType is VegasVariableType {
      switch (varType) {
        case VegasVariableType.DISCRETE:
        case VegasVariableType.CONTINUOUS:
          return true;
        default:
          return false;
      }
    }
  }
}
