import { OpenTelemetryConfig } from '@mode/shared/contract-common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ENVIRONMENT_FEATURE_KEY, EnvironmentState } from './environment.reducer';

// Lookup the 'Environment' feature state managed by NgRx
export const selectEnvironmentState = createFeatureSelector<EnvironmentState>(ENVIRONMENT_FEATURE_KEY);

export const selectEnvironmentLoaded = createSelector(
  selectEnvironmentState,
  (state: EnvironmentState) => state.loaded
);

export const selectEnvironmentError = createSelector(selectEnvironmentState, (state: EnvironmentState) => state.error);

export const selectEnvironment = createSelector(selectEnvironmentState, (state: EnvironmentState) => state.environment);

export const selectEnvironmentName = createSelector(selectEnvironment, (environment) => environment?.ENVIRONMENT || '');
export const selectDataIndexBase = createSelector(
  selectEnvironment,
  (environment) => environment?.DATA_INDEX_BASE || ''
);
export const selectAiGatewayBase = createSelector(
  selectEnvironment,
  (environment) => environment?.AI_GATEWAY_BASE || ''
);
export const selectOpenTelemetryConfig = createSelector(
  selectEnvironment,
  (environment) =>
    <OpenTelemetryConfig>{
      collectorUrl: environment?.OPEN_TELEMETRY_COLLECTOR_URL || '',
      logging: environment?.OPEN_TELEMETRY_LOGGING?.toLowerCase() === 'true',
      batchingConfig: {},
    }
);

export const selectReculryPublicKey = createSelector(
  selectEnvironment,
  (environment) => environment?.RECURLY_PUBLIC_KEY || ''
);
