import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { QueryResult, SelectResult } from '@mode-switch/query';
import { DataType, DateTime } from '@mode-switch/express';
import { FlamingoTypes } from '@mode/shared/contract-common';
import { MetaTagsService } from '@mode/shared/util-dom';
import { requestWithMetaHeaders } from '../flamingo-headers';
import { FlamingoResponse } from '@mode/shared/contract-common';

const SELECT_URL = /\/selects$/;
const HELIX_SELECT_URL = /\/helix\/switch_plan_executions\/v1(.*)+$/;

/**
 * Converts datetimes coming in from Flamingo from microseconds to milliseconds
 *
 * @remarks
 * This is an impure function that mutates data in place to reduce memory costs,
 * but also because it obviates the need to convert the data again elsewhere
 * throughout the application.
 *
 * @param selectResult - A successful select from Flamingo
 * @returns void
 *
 */
export function convertMicrosecondsToMilliseconds(selectResult: SelectResult<FlamingoTypes.ModeRole>): void {
  selectResult.results.forEach((result) => {
    if (result.type === QueryResult.Type.Inline) {
      const microSecondDateColumns = result.columns.filter(
        (column) =>
          (column.type.name === DataType.Name.Timestamp || column.type.name === DataType.Name.DateTime) &&
          column.type.unit === DateTime.TimeUnit.Micros
      );

      if (microSecondDateColumns.length > 0) {
        microSecondDateColumns.forEach((column) => {
          (column.type as any).unit = DateTime.TimeUnit.Millis;
        });

        result.items.forEach((record) => {
          microSecondDateColumns.forEach((dateColumn) => {
            if (record[dateColumn.alias] !== null) {
              record[dateColumn.alias] /= 1000;
            }
          });
        });
      }
    }
  });
}

@Injectable()
export class FlamingoSelectInterceptor implements HttpInterceptor {
  constructor(private metaTagsService: MetaTagsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (HELIX_SELECT_URL.test(request.url)) {
      const reqWithHeaders = requestWithMetaHeaders(this.metaTagsService, request);
      return next.handle(reqWithHeaders).pipe(
        tap<HttpEvent<any>>((response) => {
          if (response.type === HttpEventType.Response) {
            if (
              response.ok &&
              'results' in response.body &&
              'resultsType' in response.body &&
              response.body.resultsType === 'EMBEDDED'
            ) {
              const selectResult = response.body.results as SelectResult<FlamingoTypes.ModeRole>;
              convertMicrosecondsToMilliseconds(selectResult);
            }
          }
        })
      );
    } else if (SELECT_URL.test(request.url) && request.method === 'POST') {
      const reqWithHeaders = requestWithMetaHeaders(this.metaTagsService, request);
      return next.handle(reqWithHeaders).pipe(
        tap<HttpEvent<any>>((response) => {
          if (response.type === HttpEventType.Response) {
            if (response.ok && typeof response.body !== 'string' && 'results' in response.body) {
              const selectResult = response.body as SelectResult<FlamingoTypes.ModeRole>;
              convertMicrosecondsToMilliseconds(selectResult);
            }
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
