<div class="loading-view">
  <capra-icon
    width="312px"
    height="120px"
    icon="illo-loading"
    title="Loading indicator"></capra-icon>
  <span class="title">
    {{ title }}<span *ngIf="titleEllipsis">&hellip;</span>
  </span>
  <span class="subtitle" *ngIf="subtitle">{{ subtitle }}</span>
</div>
