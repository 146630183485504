<ng-container *ngIf="rebrandM2Enabled$ | async; then studioLoader; else modeLoader"></ng-container>
<ng-template #modeLoader>
  <ng-container [ngSwitch]="loader.element">
    <img
      *ngSwitchCase="'image'"
      [ngSrc]="loader.value"
      [width]="loader.size"
      [height]="loader.size"
      data-test="mode-loader-image"
      priority
    />

    <capra-icon
      *ngSwitchCase="'icon'"
      [icon]="loader.value"
      width="{{ loader.size }}px"
      height="{{ loader.size }}px"
      data-test="mode-loader-icon"
    ></capra-icon>
  </ng-container>
</ng-template>
<ng-template #studioLoader>
  <div class="loader-container" [style.width.px]="loader.size" [style.height.px]="loader.size">
    <div class="loader-circles">
      <div class="circle left"></div>
      <div class="circle center"></div>
      <div class="circle right"></div>
    </div>
  </div>
</ng-template>
