import { capraIconLink } from '@mode/capra';
import { ChartTypes } from '@mode/shared/contract-common';

/**
 * Create the img used in the cell renderer.
 *
 * Extracting this to its own function so it can be used elsewhere without the
 * ag-grid type dependencies.
 */
export function renderImageCellContents(src: string, colFormat: ChartTypes.FlatTableColumnSettings): HTMLImageElement {
  const image = document.createElement('img');

  image.src = encodeURI(src);
  image.classList.add('table-image');

  return image;
}

/**
 * Create the url used in the cell renderer.
 *
 * Extracting this to its own function so it can be used elsewhere without the
 * ag-grid type dependencies.
 */
export function renderUrlCellContents(
  rawValue: string,
  textFormat: ChartTypes.TextFormat,
  urlPatternData?: { [key: string]: any }
): HTMLAnchorElement {
  const { linkDisplayText: customDisplayText, linkURLPattern: customURLPattern, shouldEncodeURL } = textFormat;

  const resolvedValue = rawValue && rawValue !== '' ? rawValue : '';

  const anchor = document.createElement('a');
  anchor.rel = 'nofollow';
  anchor.target = '_blank';

  if (resolvedValue) {
    const linkText = customDisplayText ? customDisplayText : resolvedValue;
    const linkURL = customURLPattern ? resolveUrlFromPattern(customURLPattern, urlPatternData || {}) : resolvedValue;

    anchor.text = linkText;

    let absoluteHref = '';
    try {
      // This will throw an error if the href is relative
      absoluteHref = new URL(linkURL).href;
    } catch {
      // Construct a relative URL against the parent window's origin, not modesandbox.com
      // absoluteHref =
      // document.referrer?.length > 0 ? new URL(linkURL, document.referrer).href : new URL(linkURL, document.URL).href;
      absoluteHref = `http://${linkURL}`;
    }

    if (shouldEncodeURL) {
      anchor.href = encodeURI(absoluteHref);
    } else {
      anchor.href = absoluteHref;
    }

    const classes: string[] = [];
    if (textFormat.linkStyle) {
      classes.push(`link-style-${textFormat.linkStyle}`);
      if (textFormat.linkStyle === 'link-icon') {
        // Only show the link icon.
        anchor.text = '';
        anchor.innerHTML = capraIconLink.data;
      }
    }
    if (textFormat.linkColor) {
      classes.push(`link-color-${textFormat.linkColor}`);
    }
    anchor.classList.add(...classes);
  } else {
    anchor.text = '';
  }

  return anchor;
}

/**
 * Replace the `{}` placeholders in the URL pattern with actual value from the data.
 *
 * Example: URL Pattern: `https://example.com/abcd/{column_1}/xyz/{column_2}`;
 * Here the `{column_1}` and `{column_2}` will be replaced with the actual value
 * from the data.
 */
export function resolveUrlFromPattern(urlPattern: string, urlPatternData: { [key: string]: any }): string {
  const re = /\{([^{}]+)\}/g;

  const resolvedURL = urlPattern.replace(re, (_, key) => {
    const val = urlPatternData[key];

    if (val === undefined || val === null) {
      return `{${key}}`; // Return as it was.
    } else {
      return String(val);
    }
  });

  return resolvedURL;
}
