import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AI_GATEWAY_AUTH_FACADE, AuthFacade } from '@mode/shared/authorization/contract';
import { ADD_AI_GATEWAY_AUTH } from '@mode/shared/util-halogen';
import { Observable, retry, switchMap, take, throwError, timer } from 'rxjs';

@Injectable()
export class AiGatewayAuthInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(ADD_AI_GATEWAY_AUTH) === true) {
      return this.authFacade.authToken$.pipe(
        take(1),
        switchMap((token) => {
          const requestWithJWT = request.clone({
            setHeaders: { Authorization: `Bearer ${token.jwt}` },
          });

          return next.handle(requestWithJWT);
        }),
        retry({
          count: 3,
          delay: (error, retryCount) => {
            if (error.status === 412) {
              this.authFacade.authorizationExpired();
              return timer(retryCount * 100);
            }
            return throwError(error);
          },
        })
      );
    }

    return next.handle(request);
  }

  constructor(@Inject(AI_GATEWAY_AUTH_FACADE) private authFacade: AuthFacade) {}
}
