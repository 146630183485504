import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IconComponent } from '../icon/icon.component';

export type Variant = 'primary' | 'secondary';

export type Size = 'small' | 'medium';

export type IconLocation = 'left' | 'right';

export type Color =
  | 'default'
  | 'alert'
  | 'neutral'
  | 'neutral-light'
  | 'neutral-green'
  | 'neutral-dark'
  | 'inverted-green';

@Component({
  standalone: true,
  selector: 'capra-button',
  imports: [CommonModule, IconComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  /**
   * The foundational appearance of the button

  * @type `primary`: solid color throughout
  * @type `secondary`: transparent (sometimes translucent) body with a solid border; aka "ghost" style
  */
  @Input() variant: Variant = 'primary';

  /** The button text */
  @Input() text = '';

  /** Capra icon name. If omitted, no icon will be shown */
  @Input() icon: string | null = null;

  /** The position of the icon (if it exists) relative to the text */
  @Input() iconLocation: IconLocation = 'left';

  @Input() size: Size = 'medium';

  /**
   * The context-specific appearance of the button

  * @type `default`: for standard actions. Valid for all variants
  * @type `alert`: for dangerous actions. Valid for all variants
  * @type `neutral`: for standard actions. Has a monochrome appearance. Valid **only** for `secondary` variant
  * @type `neutral-light`: for standard actions. Visible against dark backgrounds. Valid **only** for `secondary` variant
  * @type `neutral-green`: for standard actions. Same as neutral-light, but with green icon. Valid **only** for `secondary` variant
  * @type `neutral-dark`: for standard actions. Visible against dark backgrounds. Valid **only** for `primary` variant
  * @type `inverted-green`: for standard actions. Visible against dark backgrounds. Valid **only** for `secondary` variant.
  */
  @Input() color: Color = 'default';

  /** Whether the button is disabled */
  @Input() disabled = false;

  /** Pass-through value for the native HTML button `type` attribute */
  @Input() type: 'submit' | 'button' | 'reset' = 'submit';

  /** Pass-through value for the native HTML button `form` attribute */
  @Input() form: string | null = null;

  /** Optional aria-label when button text does not provide enough contextual info */
  @Input() ariaLabel: string | null = null;

  /** Triggered on button click */
  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  /**
   * Handles the button click and triggers the `clicked` output if appropriate.
   */
  onClick(event: MouseEvent) {
    if (!this.disabled) {
      this.clicked.emit(event);
    }
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isRebrand = window['MODE_FLAGS' as any]?.['temp-rebrand-milestone-3-2024.07' as any]; // Rebrand Milestone 3
    if (isRebrand && this.color === 'alert') {
      this.color = 'default';
      this.variant = 'primary';
    }
  }
}
