import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';

import { IconComponent } from '@mode/capra';

@Component({
  standalone: true,
  selector: 'mode-animated-refresh-icon',
  imports: [CommonModule, IconComponent],
  templateUrl: './animated-refresh-icon.component.html',
  styleUrls: ['./animated-refresh-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimatedRefreshIconComponent {}
