<div mat-dialog-title>
  <mode-dialog-header [hideClose]="true">
    <h1 heading>You’ve been signed out</h1>
  </mode-dialog-header>
</div>
<div mat-dialog-content>
  <p>You are no longer signed in to Mode. Sign in again to access content and edit your work.</p>
</div>
<div mat-dialog-actions>
  <capra-button class="action confirm" text="Sign in" color="default" (click)="onConfirm()"></capra-button>
</div>
