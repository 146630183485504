<div class="date-picker">
  <input
    class="form-control"
    matInput
    [matDatepicker]="picker"
    [placeholder]="placeholder"
    [ngModel]="currentDate"
    (dateChange)="onDateChange($event)"
  />
  <capra-icon-button icon="icon-calendar" (clicked)="picker.open()" class="picker-button"></capra-icon-button>
  <mat-datepicker
    #picker
    [calendarHeaderComponent]="header"
    [dateClass]="dateCellCSSClass"
    [startAt]="startDate"
  ></mat-datepicker>
</div>
