<input
  [id]="customId"
  class="hidden-check"
  type="checkbox"
  data-test="toggle-switch"
  modeTrackClick
  [disabled]="disabled"
  [ngModel]="state"
  [trackClickData]="analyticsData"
  (ngModelChange)="stateChange.emit(!state)"
/>

<label [for]="customId"
  class="container {{ size }}"
  [class.container-on]="state"
  [class.container-disabled]="disabled"
>
  <capra-icon *ngIf="state && onIcon"
    class="icon on-icon {{ size }}"
    [icon]="onIcon"
  ></capra-icon>
  <div class="switch {{ size }}"
    [class.switch-on]="state"
  ></div>
  <capra-icon *ngIf="!state && offIcon"
    class="icon off-icon {{ size }}"
    [icon]="offIcon"
  ></capra-icon>
</label>
