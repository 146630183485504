import { TrackByFunction } from '@angular/core';

/**
 * A factory function that returns an Angular `trackBy` function, which is passed
 * in as an optional argument to the `*ngFor` directive as a performance enhancement.
 * @param getId A callback function to return a unique property used to identify the current item
 * @returns A value of any type. The value should be unique to the current item
 *
 * @example
 * ```
 * // TS
 * trackByFn = trackByFactory<ItemWithId>((item) => item.id);
 *
 * // HTML
 * <li *ngFor="let item of items; trackBy: trackByFn">
 *   {{ item.name }}
 * </li>
 * ```
 */
export function trackByFactory<T>(getId: (item: T) => unknown): TrackByFunction<T> {
  return function <U extends T>(index: number, item: T & U): unknown {
    return getId(item);
  };
}

export const trackBySelf = trackByFactory<string>((item) => item);
